import {
  Button,
  EditButton,
  Link,
  ReferenceField,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { TitledShow } from "../../ra-lb-tools/components/titledRecord";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { JsonField } from "../../ra-lb-tools/components/jsonField";
import { TowerList } from "../tower/towers";
import { FederationList } from "../federatios";
import { LabeledColumn } from "../../ra-lb-tools/components/labeledColumn";
import { GoBackButton } from "../../ra-lb-tools/components/goBackButton";
import { MyEdit } from "../../ra-lb-tools/components/myMutator";
import { Edit } from "@mui/icons-material";
import { TagNameField } from "../firmware";

export const LocationFirmwareEdit = (props) => {
  return (
    <MyEdit
      {...props}
      formProps={{
        shouldUnregister: true,
      }}
      mutationOptions={{
        meta: { customSegment: "firmware", customMethod: "POST" },
      }}
    >
      <TextInput source="id" disabled />
      <TextInput source="name" disabled />
      <TextInput source="tagName" />
    </MyEdit>
  );
};

const EditFirmwareButton = () => {
  const record = useRecordContext();

  if (!record) return null;
  const path = `/locations/${record.id}/firmware`;

  return (
    <Button
      component={Link}
      to={path}
      label={"Firmware"}
      onClick={(e) => e.stopPropagation()}
    >
      <Edit />
    </Button>
  );
};

const LocationShowActions = () => (
  <TopToolbar>
    <EditButton />
    <EditFirmwareButton />
    <GoBackButton size="small" variant="text" />
  </TopToolbar>
);

export const LocationShow = () => {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow actions={<LocationShowActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row" className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="address1" />
              <TextField source="address2" />
              <TextField source="description" />
              <TagNameField source="firmware.tagName" />
              <TextField source="firmware.version" />
            </LabeledColumn>
            <LabeledColumn>
              <TextField source="lat" />
              <TextField source="lon" />
              <TextField source="city" />
              <TextField source="state" />
              <TextField source="zip" />
              <TextField source="country" />
              <TextField source="timezone" />
            </LabeledColumn>
            <LabeledColumn>
              <ReferenceField source="ownerId" reference="users" link="show">
                <TextField source="email" />
              </ReferenceField>
              <TextField source="notes" />
              <JsonField source="accessHours" maxLines={null} />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Towers" path="towers">
          <TowerList filter={{ location_id: id }} filters={null} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Retired Towers" path="towers-retired">
          <TowerList
            filter={{ location_id: id, status_id: 4 }}
            filters={null}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Federations" path="federations">
          <FederationList filter={{ location_id: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};
