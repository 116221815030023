import * as React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { errorIconStyle } from "../../theme/errorIcon";
import {
  MyReferenceField,
  MyReferenceInput,
} from "../../ra-lb-tools/components/myReference";
import { Autocomplete, TextField } from "@mui/material";
import { countries } from "../../config/geo/countries";
import { states } from "../../config/geo/states";
import inflection from "inflection";

export const AddressInfoTooltip = (props) => {
  const address = props.address || {};

  const empty = [];
  for (const key in address) {
    if (Object.hasOwnProperty.call(address, key)) {
      const element = address[key];
      if (!element) {
        empty.push(key);
      }
    }
  }

  if (!empty.length) return null;

  const icon = <InfoIcon style={errorIconStyle} />;

  const emptyTags = empty.map((val) => <dd key={val}>{val}</dd>);

  const tooltip = (
    <dl>
      <dt>Missing fields</dt>
      {emptyTags}
    </dl>
  );

  return (
    <Tooltip
      title={tooltip}
      placement="right-end"
      className="AddressInfoTooltip"
    >
      {icon}
    </Tooltip>
  );
};

export const LocationInput = (props) => {
  return <MyReferenceInput {...props} secondaryField="address1" />;
};

LocationInput.defaultProps = {
  source: "locationId",
  reference: "locations",
  label: "Location",
};

export const LocationField = () => {
  return (
    <MyReferenceField
      source="locationId"
      reference="locations"
      secondaryField="address1"
    />
  );
};

export const MyAutocompleteCreatable = ({
  options,
  value,
  label,
  onChange,
}) => {
  const valueObj =
    typeof value === "string"
      ? { name: value, id: options.length * 10 }
      : value;
  const selectedValue =
    options.find((option) => option.name === valueObj.name) || valueObj;

  const renderInput = (params) => (
    <TextField
      {...params}
      id={label}
      name={label}
      label={inflection.titleize(label)}
      style={{ margin: "5px" }}
    />
  );

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };

  return (
    <Autocomplete
      freeSolo={true}
      options={options}
      value={selectedValue}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.name === value.name}
      renderInput={renderInput}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
};

export const CountrySelector = ({ country, setCountry, setCountryStates }) => {
  return (
    <MyAutocompleteCreatable
      options={countries}
      value={country}
      label={"country"}
      onChange={(event, newValue) => {
        const value = typeof newValue == "string" ? newValue : newValue.name;

        setCountry(value);
        setCountryStates(states.filter((st) => st.countryId == newValue.id));
      }}
    />
  );
};

export const StateSelector = ({ countryStates, state, setState }) => {
  return (
    <MyAutocompleteCreatable
      options={countryStates}
      value={state}
      label={"state"}
      onChange={(event, newValue) => {
        const value = typeof newValue == "string" ? newValue : newValue.name;
        setState(value);
      }}
    />
  );
};

export const LocationDetails = ({ country, state, setCountry, setState }) => {
  const [countryStates, setCountryStates] = React.useState([]);

  return (
    <>
      <CountrySelector
        country={country}
        setCountry={setCountry}
        setCountryStates={setCountryStates}
      />
      <StateSelector
        countryStates={countryStates}
        state={state}
        setState={setState}
      />
    </>
  );
};
