//https://github.com/mustafasolak/country_state_city/blob/main/states.json

export const states = [
  { id: "1", name: "Badakhshan", stateCode: "BDS", countryId: "1" },
  { id: "2", name: "Badghis", stateCode: "BDG", countryId: "1" },
  { id: "3", name: "Baghlan", stateCode: "BGL", countryId: "1" },
  { id: "4", name: "Balkh", stateCode: "BAL", countryId: "1" },
  { id: "5", name: "Bamyan", stateCode: "BAM", countryId: "1" },
  { id: "6", name: "Daykundi", stateCode: "DAY", countryId: "1" },
  { id: "7", name: "Farah", stateCode: "FRA", countryId: "1" },
  { id: "8", name: "Faryab", stateCode: "FYB", countryId: "1" },
  { id: "9", name: "Ghazni", stateCode: "GHA", countryId: "1" },
  { id: "10", name: "Ghōr", stateCode: "GHO", countryId: "1" },
  { id: "11", name: "Helmand", stateCode: "HEL", countryId: "1" },
  { id: "12", name: "Herat", stateCode: "HER", countryId: "1" },
  { id: "13", name: "Jowzjan", stateCode: "JOW", countryId: "1" },
  { id: "14", name: "Kabul", stateCode: "KAB", countryId: "1" },
  { id: "15", name: "Kandahar", stateCode: "KAN", countryId: "1" },
  { id: "16", name: "Kapisa", stateCode: "KAP", countryId: "1" },
  { id: "17", name: "Khost", stateCode: "KHO", countryId: "1" },
  { id: "18", name: "Kunar", stateCode: "KNR", countryId: "1" },
  { id: "19", name: "Kunduz Province", stateCode: "KDZ", countryId: "1" },
  { id: "20", name: "Laghman", stateCode: "LAG", countryId: "1" },
  { id: "21", name: "Logar", stateCode: "LOG", countryId: "1" },
  { id: "22", name: "Nangarhar", stateCode: "NAN", countryId: "1" },
  { id: "23", name: "Nimruz", stateCode: "NIM", countryId: "1" },
  { id: "24", name: "Nuristan", stateCode: "NUR", countryId: "1" },
  { id: "25", name: "Paktia", stateCode: "PIA", countryId: "1" },
  { id: "26", name: "Paktika", stateCode: "PKA", countryId: "1" },
  { id: "27", name: "Panjshir", stateCode: "PAN", countryId: "1" },
  { id: "28", name: "Parwan", stateCode: "PAR", countryId: "1" },
  { id: "29", name: "Samangan", stateCode: "SAM", countryId: "1" },
  { id: "30", name: "Sar-e Pol", stateCode: "SAR", countryId: "1" },
  { id: "31", name: "Takhar", stateCode: "TAK", countryId: "1" },
  { id: "32", name: "Urozgan", stateCode: "URU", countryId: "1" },
  { id: "33", name: "Zabul", stateCode: "ZAB", countryId: "1" },
  { id: "34", name: "Berat County", stateCode: "01", countryId: "3" },
  { id: "35", name: "Berat District", stateCode: "BR", countryId: "3" },
  { id: "36", name: "Bulqizë District", stateCode: "BU", countryId: "3" },
  { id: "37", name: "Delvinë District", stateCode: "DL", countryId: "3" },
  { id: "38", name: "Devoll District", stateCode: "DV", countryId: "3" },
  { id: "39", name: "Dibër County", stateCode: "09", countryId: "3" },
  { id: "40", name: "Dibër District", stateCode: "DI", countryId: "3" },
  { id: "41", name: "Durrës County", stateCode: "02", countryId: "3" },
  { id: "42", name: "Durrës District", stateCode: "DR", countryId: "3" },
  { id: "43", name: "Elbasan County", stateCode: "03", countryId: "3" },
  { id: "44", name: "Fier County", stateCode: "04", countryId: "3" },
  { id: "45", name: "Fier District", stateCode: "FR", countryId: "3" },
  { id: "46", name: "Gjirokastër County", stateCode: "05", countryId: "3" },
  { id: "47", name: "Gjirokastër District", stateCode: "GJ", countryId: "3" },
  { id: "48", name: "Gramsh District", stateCode: "GR", countryId: "3" },
  { id: "49", name: "Has District", stateCode: "HA", countryId: "3" },
  { id: "50", name: "Kavajë District", stateCode: "KA", countryId: "3" },
  { id: "51", name: "Kolonjë District", stateCode: "ER", countryId: "3" },
  { id: "52", name: "Korçë County", stateCode: "06", countryId: "3" },
  { id: "53", name: "Korçë District", stateCode: "KO", countryId: "3" },
  { id: "54", name: "Krujë District", stateCode: "KR", countryId: "3" },
  { id: "55", name: "Kuçovë District", stateCode: "KC", countryId: "3" },
  { id: "56", name: "Kukës County", stateCode: "07", countryId: "3" },
  { id: "57", name: "Kukës District", stateCode: "KU", countryId: "3" },
  { id: "58", name: "Kurbin District", stateCode: "KB", countryId: "3" },
  { id: "59", name: "Lezhë County", stateCode: "08", countryId: "3" },
  { id: "60", name: "Lezhë District", stateCode: "LE", countryId: "3" },
  { id: "61", name: "Librazhd District", stateCode: "LB", countryId: "3" },
  { id: "62", name: "Lushnjë District", stateCode: "LU", countryId: "3" },
  {
    id: "63",
    name: "Malësi e Madhe District",
    stateCode: "MM",
    countryId: "3",
  },
  { id: "64", name: "Mallakastër District", stateCode: "MK", countryId: "3" },
  { id: "65", name: "Mat District", stateCode: "MT", countryId: "3" },
  { id: "66", name: "Mirditë District", stateCode: "MR", countryId: "3" },
  { id: "67", name: "Peqin District", stateCode: "PQ", countryId: "3" },
  { id: "68", name: "Përmet District", stateCode: "PR", countryId: "3" },
  { id: "69", name: "Pogradec District", stateCode: "PG", countryId: "3" },
  { id: "70", name: "Pukë District", stateCode: "PU", countryId: "3" },
  { id: "71", name: "Sarandë District", stateCode: "SR", countryId: "3" },
  { id: "72", name: "Shkodër County", stateCode: "10", countryId: "3" },
  { id: "73", name: "Shkodër District", stateCode: "SH", countryId: "3" },
  { id: "74", name: "Skrapar District", stateCode: "SK", countryId: "3" },
  { id: "75", name: "Tepelenë District", stateCode: "TE", countryId: "3" },
  { id: "76", name: "Tirana County", stateCode: "11", countryId: "3" },
  { id: "77", name: "Tirana District", stateCode: "TR", countryId: "3" },
  { id: "78", name: "Tropojë District", stateCode: "TP", countryId: "3" },
  { id: "79", name: "Vlorë County", stateCode: "12", countryId: "3" },
  { id: "80", name: "Vlorë District", stateCode: "VL", countryId: "3" },
  { id: "81", name: "Adrar", stateCode: "01", countryId: "4" },
  { id: "82", name: "Aïn Defla", stateCode: "44", countryId: "4" },
  { id: "83", name: "Aïn Témouchent", stateCode: "46", countryId: "4" },
  { id: "84", name: "Algiers", stateCode: "16", countryId: "4" },
  { id: "85", name: "Annaba", stateCode: "23", countryId: "4" },
  { id: "86", name: "Batna", stateCode: "05", countryId: "4" },
  { id: "87", name: "Béchar", stateCode: "08", countryId: "4" },
  { id: "88", name: "Béjaïa", stateCode: "06", countryId: "4" },
  { id: "89", name: "Béni Abbès", stateCode: "53", countryId: "4" },
  { id: "90", name: "Biskra", stateCode: "07", countryId: "4" },
  { id: "91", name: "Blida", stateCode: "09", countryId: "4" },
  { id: "92", name: "Bordj Baji Mokhtar", stateCode: "52", countryId: "4" },
  { id: "93", name: "Bordj Bou Arréridj", stateCode: "34", countryId: "4" },
  { id: "94", name: "Bouïra", stateCode: "10", countryId: "4" },
  { id: "95", name: "Boumerdès", stateCode: "35", countryId: "4" },
  { id: "96", name: "Chlef", stateCode: "02", countryId: "4" },
  { id: "97", name: "Constantine", stateCode: "25", countryId: "4" },
  { id: "98", name: "Djanet", stateCode: "56", countryId: "4" },
  { id: "99", name: "Djelfa", stateCode: "17", countryId: "4" },
  { id: "100", name: "El Bayadh", stateCode: "32", countryId: "4" },
  { id: "101", name: "El M\\'ghair", stateCode: "49", countryId: "4" },
  { id: "102", name: "El Menia", stateCode: "50", countryId: "4" },
  { id: "103", name: "El Oued", stateCode: "39", countryId: "4" },
  { id: "104", name: "El Tarf", stateCode: "36", countryId: "4" },
  { id: "105", name: "Ghardaïa", stateCode: "47", countryId: "4" },
  { id: "106", name: "Guelma", stateCode: "24", countryId: "4" },
  { id: "107", name: "Illizi", stateCode: "33", countryId: "4" },
  { id: "108", name: "In Guezzam", stateCode: "58", countryId: "4" },
  { id: "109", name: "In Salah", stateCode: "57", countryId: "4" },
  { id: "110", name: "Jijel", stateCode: "18", countryId: "4" },
  { id: "111", name: "Khenchela", stateCode: "40", countryId: "4" },
  { id: "112", name: "Laghouat", stateCode: "03", countryId: "4" },
  { id: "113", name: "M\\'Sila", stateCode: "28", countryId: "4" },
  { id: "114", name: "Mascara", stateCode: "29", countryId: "4" },
  { id: "115", name: "Médéa", stateCode: "26", countryId: "4" },
  { id: "116", name: "Mila", stateCode: "43", countryId: "4" },
  { id: "117", name: "Mostaganem", stateCode: "27", countryId: "4" },
  { id: "118", name: "Naama", stateCode: "45", countryId: "4" },
  { id: "119", name: "Oran", stateCode: "31", countryId: "4" },
  { id: "120", name: "Ouargla", stateCode: "30", countryId: "4" },
  { id: "121", name: "Ouled Djellal", stateCode: "51", countryId: "4" },
  { id: "122", name: "Oum El Bouaghi", stateCode: "04", countryId: "4" },
  { id: "123", name: "Relizane", stateCode: "48", countryId: "4" },
  { id: "124", name: "Saïda", stateCode: "20", countryId: "4" },
  { id: "125", name: "Sétif", stateCode: "19", countryId: "4" },
  { id: "126", name: "Sidi Bel Abbès", stateCode: "22", countryId: "4" },
  { id: "127", name: "Skikda", stateCode: "21", countryId: "4" },
  { id: "128", name: "Souk Ahras", stateCode: "41", countryId: "4" },
  { id: "129", name: "Tamanghasset", stateCode: "11", countryId: "4" },
  { id: "130", name: "Tébessa", stateCode: "12", countryId: "4" },
  { id: "131", name: "Tiaret", stateCode: "14", countryId: "4" },
  { id: "132", name: "Timimoun", stateCode: "54", countryId: "4" },
  { id: "133", name: "Tindouf", stateCode: "37", countryId: "4" },
  { id: "134", name: "Tipasa", stateCode: "42", countryId: "4" },
  { id: "135", name: "Tissemsilt", stateCode: "38", countryId: "4" },
  { id: "136", name: "Tizi Ouzou", stateCode: "15", countryId: "4" },
  { id: "137", name: "Tlemcen", stateCode: "13", countryId: "4" },
  { id: "138", name: "Touggourt", stateCode: "55", countryId: "4" },
  { id: "139", name: "Andorra la Vella", stateCode: "07", countryId: "6" },
  { id: "140", name: "Canillo", stateCode: "02", countryId: "6" },
  { id: "141", name: "Encamp", stateCode: "03", countryId: "6" },
  { id: "142", name: "Escaldes-Engordany", stateCode: "08", countryId: "6" },
  { id: "143", name: "La Massana", stateCode: "04", countryId: "6" },
  { id: "144", name: "Ordino", stateCode: "05", countryId: "6" },
  { id: "145", name: "Sant Julià de Lòria", stateCode: "06", countryId: "6" },
  { id: "146", name: "Bengo Province", stateCode: "BGO", countryId: "7" },
  { id: "147", name: "Benguela Province", stateCode: "BGU", countryId: "7" },
  { id: "148", name: "Bié Province", stateCode: "BIE", countryId: "7" },
  { id: "149", name: "Cabinda Province", stateCode: "CAB", countryId: "7" },
  {
    id: "150",
    name: "Cuando Cubango Province",
    stateCode: "CCU",
    countryId: "7",
  },
  {
    id: "151",
    name: "Cuanza Norte Province",
    stateCode: "CNO",
    countryId: "7",
  },
  { id: "152", name: "Cuanza Sul", stateCode: "CUS", countryId: "7" },
  { id: "153", name: "Cunene Province", stateCode: "CNN", countryId: "7" },
  { id: "154", name: "Huambo Province", stateCode: "HUA", countryId: "7" },
  { id: "155", name: "Huíla Province", stateCode: "HUI", countryId: "7" },
  { id: "156", name: "Luanda Province", stateCode: "LUA", countryId: "7" },
  { id: "157", name: "Lunda Norte Province", stateCode: "LNO", countryId: "7" },
  { id: "158", name: "Lunda Sul Province", stateCode: "LSU", countryId: "7" },
  { id: "159", name: "Malanje Province", stateCode: "MAL", countryId: "7" },
  { id: "160", name: "Moxico Province", stateCode: "MOX", countryId: "7" },
  { id: "161", name: "Uíge Province", stateCode: "UIG", countryId: "7" },
  { id: "162", name: "Zaire Province", stateCode: "ZAI", countryId: "7" },
  { id: "163", name: "Barbuda", stateCode: "10", countryId: "10" },
  { id: "164", name: "Redonda", stateCode: "11", countryId: "10" },
  { id: "165", name: "Saint George Parish", stateCode: "03", countryId: "10" },
  { id: "166", name: "Saint John Parish", stateCode: "04", countryId: "10" },
  { id: "167", name: "Saint Mary Parish", stateCode: "05", countryId: "10" },
  { id: "168", name: "Saint Paul Parish", stateCode: "06", countryId: "10" },
  { id: "169", name: "Saint Peter Parish", stateCode: "07", countryId: "10" },
  { id: "170", name: "Saint Philip Parish", stateCode: "08", countryId: "10" },
  { id: "171", name: "Buenos Aires", stateCode: "B", countryId: "11" },
  { id: "172", name: "Catamarca", stateCode: "K", countryId: "11" },
  { id: "173", name: "Chaco", stateCode: "H", countryId: "11" },
  { id: "174", name: "Chubut", stateCode: "U", countryId: "11" },
  {
    id: "175",
    name: "Ciudad Autónoma de Buenos Aires",
    stateCode: "C",
    countryId: "11",
  },
  { id: "176", name: "Córdoba", stateCode: "X", countryId: "11" },
  { id: "177", name: "Corrientes", stateCode: "W", countryId: "11" },
  { id: "178", name: "Entre Ríos", stateCode: "E", countryId: "11" },
  { id: "179", name: "Formosa", stateCode: "P", countryId: "11" },
  { id: "180", name: "Jujuy", stateCode: "Y", countryId: "11" },
  { id: "181", name: "La Pampa", stateCode: "L", countryId: "11" },
  { id: "182", name: "La Rioja", stateCode: "F", countryId: "11" },
  { id: "183", name: "Mendoza", stateCode: "M", countryId: "11" },
  { id: "184", name: "Misiones", stateCode: "N", countryId: "11" },
  { id: "185", name: "Neuquén", stateCode: "Q", countryId: "11" },
  { id: "186", name: "Río Negro", stateCode: "R", countryId: "11" },
  { id: "187", name: "Salta", stateCode: "A", countryId: "11" },
  { id: "188", name: "San Juan", stateCode: "J", countryId: "11" },
  { id: "189", name: "San Luis", stateCode: "D", countryId: "11" },
  { id: "190", name: "Santa Cruz", stateCode: "Z", countryId: "11" },
  { id: "191", name: "Santa Fe", stateCode: "S", countryId: "11" },
  { id: "192", name: "Santiago del Estero", stateCode: "G", countryId: "11" },
  { id: "193", name: "Tierra del Fuego", stateCode: "V", countryId: "11" },
  { id: "194", name: "Tucumán", stateCode: "T", countryId: "11" },
  { id: "195", name: "Aragatsotn Region", stateCode: "AG", countryId: "12" },
  { id: "196", name: "Ararat Province", stateCode: "AR", countryId: "12" },
  { id: "197", name: "Armavir Region", stateCode: "AV", countryId: "12" },
  { id: "198", name: "Gegharkunik Province", stateCode: "GR", countryId: "12" },
  { id: "199", name: "Kotayk Region", stateCode: "KT", countryId: "12" },
  { id: "200", name: "Lori Region", stateCode: "LO", countryId: "12" },
  { id: "201", name: "Shirak Region", stateCode: "SH", countryId: "12" },
  { id: "202", name: "Syunik Province", stateCode: "SU", countryId: "12" },
  { id: "203", name: "Tavush Region", stateCode: "TV", countryId: "12" },
  { id: "204", name: "Vayots Dzor Region", stateCode: "VD", countryId: "12" },
  { id: "205", name: "Yerevan", stateCode: "ER", countryId: "12" },
  {
    id: "206",
    name: "Australian Capital Territory",
    stateCode: "ACT",
    countryId: "14",
  },
  { id: "207", name: "New South Wales", stateCode: "NSW", countryId: "14" },
  { id: "208", name: "Northern Territory", stateCode: "NT", countryId: "14" },
  { id: "209", name: "Queensland", stateCode: "QLD", countryId: "14" },
  { id: "210", name: "South Australia", stateCode: "SA", countryId: "14" },
  { id: "211", name: "Tasmania", stateCode: "TAS", countryId: "14" },
  { id: "212", name: "Victoria", stateCode: "VIC", countryId: "14" },
  { id: "213", name: "Western Australia", stateCode: "WA", countryId: "14" },
  { id: "214", name: "Burgenland", stateCode: "1", countryId: "15" },
  { id: "215", name: "Carinthia", stateCode: "2", countryId: "15" },
  { id: "216", name: "Lower Austria", stateCode: "3", countryId: "15" },
  { id: "217", name: "Salzburg", stateCode: "5", countryId: "15" },
  { id: "218", name: "Styria", stateCode: "6", countryId: "15" },
  { id: "219", name: "Tyrol", stateCode: "7", countryId: "15" },
  { id: "220", name: "Upper Austria", stateCode: "4", countryId: "15" },
  { id: "221", name: "Vienna", stateCode: "9", countryId: "15" },
  { id: "222", name: "Vorarlberg", stateCode: "8", countryId: "15" },
  { id: "223", name: "Absheron District", stateCode: "ABS", countryId: "16" },
  { id: "224", name: "Agdam District", stateCode: "AGM", countryId: "16" },
  { id: "225", name: "Agdash District", stateCode: "AGS", countryId: "16" },
  { id: "226", name: "Aghjabadi District", stateCode: "AGC", countryId: "16" },
  { id: "227", name: "Agstafa District", stateCode: "AGA", countryId: "16" },
  { id: "228", name: "Agsu District", stateCode: "AGU", countryId: "16" },
  { id: "229", name: "Astara District", stateCode: "AST", countryId: "16" },
  { id: "230", name: "Babek District", stateCode: "BAB", countryId: "16" },
  { id: "231", name: "Baku", stateCode: "BA", countryId: "16" },
  { id: "232", name: "Balakan District", stateCode: "BAL", countryId: "16" },
  { id: "233", name: "Barda District", stateCode: "BAR", countryId: "16" },
  { id: "234", name: "Beylagan District", stateCode: "BEY", countryId: "16" },
  { id: "235", name: "Bilasuvar District", stateCode: "BIL", countryId: "16" },
  { id: "236", name: "Dashkasan District", stateCode: "DAS", countryId: "16" },
  { id: "237", name: "Fizuli District", stateCode: "FUZ", countryId: "16" },
  { id: "238", name: "Ganja", stateCode: "GA", countryId: "16" },
  { id: "239", name: "Gədəbəy", stateCode: "GAD", countryId: "16" },
  { id: "240", name: "Gobustan District", stateCode: "QOB", countryId: "16" },
  { id: "241", name: "Goranboy District", stateCode: "GOR", countryId: "16" },
  { id: "242", name: "Goychay", stateCode: "GOY", countryId: "16" },
  { id: "243", name: "Goygol District", stateCode: "GYG", countryId: "16" },
  { id: "244", name: "Hajigabul District", stateCode: "HAC", countryId: "16" },
  { id: "245", name: "Imishli District", stateCode: "IMI", countryId: "16" },
  { id: "246", name: "Ismailli District", stateCode: "ISM", countryId: "16" },
  { id: "247", name: "Jabrayil District", stateCode: "CAB", countryId: "16" },
  { id: "248", name: "Jalilabad District", stateCode: "CAL", countryId: "16" },
  { id: "249", name: "Julfa District", stateCode: "CUL", countryId: "16" },
  { id: "250", name: "Kalbajar District", stateCode: "KAL", countryId: "16" },
  { id: "251", name: "Kangarli District", stateCode: "KAN", countryId: "16" },
  { id: "252", name: "Khachmaz District", stateCode: "XAC", countryId: "16" },
  { id: "253", name: "Khizi District", stateCode: "XIZ", countryId: "16" },
  { id: "254", name: "Khojali District", stateCode: "XCI", countryId: "16" },
  { id: "255", name: "Kurdamir District", stateCode: "KUR", countryId: "16" },
  { id: "256", name: "Lachin District", stateCode: "LAC", countryId: "16" },
  { id: "257", name: "Lankaran", stateCode: "LAN", countryId: "16" },
  { id: "258", name: "Lankaran District", stateCode: "LA", countryId: "16" },
  { id: "259", name: "Lerik District", stateCode: "LER", countryId: "16" },
  { id: "260", name: "Martuni", stateCode: "XVD", countryId: "16" },
  { id: "261", name: "Masally District", stateCode: "MAS", countryId: "16" },
  { id: "262", name: "Mingachevir", stateCode: "MI", countryId: "16" },
  {
    id: "263",
    name: "Nakhchivan Autonomous Republic",
    stateCode: "NX",
    countryId: "16",
  },
  { id: "264", name: "Neftchala District", stateCode: "NEF", countryId: "16" },
  { id: "265", name: "Oghuz District", stateCode: "OGU", countryId: "16" },
  { id: "266", name: "Ordubad District", stateCode: "ORD", countryId: "16" },
  { id: "267", name: "Qabala District", stateCode: "QAB", countryId: "16" },
  { id: "268", name: "Qakh District", stateCode: "QAX", countryId: "16" },
  { id: "269", name: "Qazakh District", stateCode: "QAZ", countryId: "16" },
  { id: "270", name: "Quba District", stateCode: "QBA", countryId: "16" },
  { id: "271", name: "Qubadli District", stateCode: "QBI", countryId: "16" },
  { id: "272", name: "Qusar District", stateCode: "QUS", countryId: "16" },
  { id: "273", name: "Saatly District", stateCode: "SAT", countryId: "16" },
  { id: "274", name: "Sabirabad District", stateCode: "SAB", countryId: "16" },
  { id: "275", name: "Sadarak District", stateCode: "SAD", countryId: "16" },
  { id: "276", name: "Salyan District", stateCode: "SAL", countryId: "16" },
  { id: "277", name: "Samukh District", stateCode: "SMX", countryId: "16" },
  { id: "278", name: "Shabran District", stateCode: "SBN", countryId: "16" },
  { id: "279", name: "Shahbuz District", stateCode: "SAH", countryId: "16" },
  { id: "280", name: "Shaki", stateCode: "SA", countryId: "16" },
  { id: "281", name: "Shaki District", stateCode: "SAK", countryId: "16" },
  { id: "282", name: "Shamakhi District", stateCode: "SMI", countryId: "16" },
  { id: "283", name: "Shamkir District", stateCode: "SKR", countryId: "16" },
  { id: "284", name: "Sharur District", stateCode: "SAR", countryId: "16" },
  { id: "285", name: "Shirvan", stateCode: "SR", countryId: "16" },
  { id: "286", name: "Shusha District", stateCode: "SUS", countryId: "16" },
  { id: "287", name: "Siazan District", stateCode: "SIY", countryId: "16" },
  { id: "288", name: "Sumqayit", stateCode: "SM", countryId: "16" },
  { id: "289", name: "Tartar District", stateCode: "TAR", countryId: "16" },
  { id: "290", name: "Tovuz District", stateCode: "TOV", countryId: "16" },
  { id: "291", name: "Ujar District", stateCode: "UCA", countryId: "16" },
  { id: "292", name: "Yardymli District", stateCode: "YAR", countryId: "16" },
  { id: "293", name: "Yevlakh", stateCode: "YE", countryId: "16" },
  { id: "294", name: "Yevlakh District", stateCode: "YEV", countryId: "16" },
  { id: "295", name: "Zangilan District", stateCode: "ZAN", countryId: "16" },
  { id: "296", name: "Zaqatala District", stateCode: "ZAQ", countryId: "16" },
  { id: "297", name: "Zardab District", stateCode: "ZAR", countryId: "16" },
  { id: "298", name: "Capital", stateCode: "13", countryId: "17" },
  { id: "299", name: "Central", stateCode: "16", countryId: "17" },
  { id: "300", name: "Muharraq", stateCode: "15", countryId: "17" },
  { id: "301", name: "Northern", stateCode: "17", countryId: "17" },
  { id: "302", name: "Southern", stateCode: "14", countryId: "17" },
  { id: "303", name: "Bagerhat District", stateCode: "05", countryId: "18" },
  { id: "304", name: "Bahadia", stateCode: "33", countryId: "18" },
  { id: "305", name: "Bandarban District", stateCode: "01", countryId: "18" },
  { id: "306", name: "Barguna District", stateCode: "02", countryId: "18" },
  { id: "307", name: "Barisal District", stateCode: "06", countryId: "18" },
  { id: "308", name: "Barisal Division", stateCode: "A", countryId: "18" },
  { id: "309", name: "Bhola District", stateCode: "07", countryId: "18" },
  { id: "310", name: "Bogra District", stateCode: "03", countryId: "18" },
  {
    id: "311",
    name: "Brahmanbaria District",
    stateCode: "04",
    countryId: "18",
  },
  { id: "312", name: "Chandpur District", stateCode: "09", countryId: "18" },
  {
    id: "313",
    name: "Chapai Nawabganj District",
    stateCode: "45",
    countryId: "18",
  },
  { id: "314", name: "Chittagong District", stateCode: "10", countryId: "18" },
  { id: "315", name: "Chittagong Division", stateCode: "B", countryId: "18" },
  { id: "316", name: "Chuadanga District", stateCode: "12", countryId: "18" },
  { id: "317", name: "Comilla District", stateCode: "08", countryId: "18" },
  {
    id: "318",
    name: "Cox\\'s Bazar District",
    stateCode: "11",
    countryId: "18",
  },
  { id: "319", name: "Dhaka District", stateCode: "13", countryId: "18" },
  { id: "320", name: "Dhaka Division", stateCode: "C", countryId: "18" },
  { id: "321", name: "Dinajpur District", stateCode: "14", countryId: "18" },
  { id: "322", name: "Faridpur District", stateCode: "15", countryId: "18" },
  { id: "323", name: "Feni District", stateCode: "16", countryId: "18" },
  { id: "324", name: "Gaibandha District", stateCode: "19", countryId: "18" },
  { id: "325", name: "Gazipur District", stateCode: "18", countryId: "18" },
  { id: "326", name: "Gopalganj District", stateCode: "17", countryId: "18" },
  { id: "327", name: "Habiganj District", stateCode: "20", countryId: "18" },
  { id: "328", name: "Jamalpur District", stateCode: "21", countryId: "18" },
  { id: "329", name: "Jessore District", stateCode: "22", countryId: "18" },
  { id: "330", name: "Jhalokati District", stateCode: "25", countryId: "18" },
  { id: "331", name: "Jhenaidah District", stateCode: "23", countryId: "18" },
  { id: "332", name: "Joypurhat District", stateCode: "24", countryId: "18" },
  { id: "333", name: "Khagrachari District", stateCode: "29", countryId: "18" },
  { id: "334", name: "Khulna District", stateCode: "27", countryId: "18" },
  { id: "335", name: "Khulna Division", stateCode: "D", countryId: "18" },
  { id: "336", name: "Kishoreganj District", stateCode: "26", countryId: "18" },
  { id: "337", name: "Kurigram District", stateCode: "28", countryId: "18" },
  { id: "338", name: "Kushtia District", stateCode: "30", countryId: "18" },
  { id: "339", name: "Lakshmipur District", stateCode: "31", countryId: "18" },
  { id: "340", name: "Lalmonirhat District", stateCode: "32", countryId: "18" },
  { id: "341", name: "Madaripur District", stateCode: "36", countryId: "18" },
  { id: "342", name: "Meherpur District", stateCode: "39", countryId: "18" },
  { id: "343", name: "Moulvibazar District", stateCode: "38", countryId: "18" },
  { id: "344", name: "Munshiganj District", stateCode: "35", countryId: "18" },
  { id: "345", name: "Mymensingh District", stateCode: "34", countryId: "18" },
  { id: "346", name: "Mymensingh Division", stateCode: "H", countryId: "18" },
  { id: "347", name: "Naogaon District", stateCode: "48", countryId: "18" },
  { id: "348", name: "Narail District", stateCode: "43", countryId: "18" },
  { id: "349", name: "Narayanganj District", stateCode: "40", countryId: "18" },
  { id: "350", name: "Natore District", stateCode: "44", countryId: "18" },
  { id: "351", name: "Netrokona District", stateCode: "41", countryId: "18" },
  { id: "352", name: "Nilphamari District", stateCode: "46", countryId: "18" },
  { id: "353", name: "Noakhali District", stateCode: "47", countryId: "18" },
  { id: "354", name: "Pabna District", stateCode: "49", countryId: "18" },
  { id: "355", name: "Panchagarh District", stateCode: "52", countryId: "18" },
  { id: "356", name: "Patuakhali District", stateCode: "51", countryId: "18" },
  { id: "357", name: "Pirojpur District", stateCode: "50", countryId: "18" },
  { id: "358", name: "Rajbari District", stateCode: "53", countryId: "18" },
  { id: "359", name: "Rajshahi District", stateCode: "54", countryId: "18" },
  { id: "360", name: "Rajshahi Division", stateCode: "E", countryId: "18" },
  {
    id: "361",
    name: "Rangamati Hill District",
    stateCode: "56",
    countryId: "18",
  },
  { id: "362", name: "Rangpur District", stateCode: "55", countryId: "18" },
  { id: "363", name: "Rangpur Division", stateCode: "F", countryId: "18" },
  { id: "364", name: "Satkhira District", stateCode: "58", countryId: "18" },
  { id: "365", name: "Shariatpur District", stateCode: "62", countryId: "18" },
  { id: "366", name: "Sherpur District", stateCode: "57", countryId: "18" },
  { id: "367", name: "Sirajganj District", stateCode: "59", countryId: "18" },
  { id: "368", name: "Sunamganj District", stateCode: "61", countryId: "18" },
  { id: "369", name: "Sylhet District", stateCode: "60", countryId: "18" },
  { id: "370", name: "Sylhet Division", stateCode: "G", countryId: "18" },
  { id: "371", name: "Tangail District", stateCode: "63", countryId: "18" },
  { id: "372", name: "Thakurgaon District", stateCode: "64", countryId: "18" },
  { id: "373", name: "Christ Church", stateCode: "01", countryId: "19" },
  { id: "374", name: "Saint Andrew", stateCode: "02", countryId: "19" },
  { id: "375", name: "Saint George", stateCode: "03", countryId: "19" },
  { id: "376", name: "Saint James", stateCode: "04", countryId: "19" },
  { id: "377", name: "Saint John", stateCode: "05", countryId: "19" },
  { id: "378", name: "Saint Joseph", stateCode: "06", countryId: "19" },
  { id: "379", name: "Saint Lucy", stateCode: "07", countryId: "19" },
  { id: "380", name: "Saint Michael", stateCode: "08", countryId: "19" },
  { id: "381", name: "Saint Peter", stateCode: "09", countryId: "19" },
  { id: "382", name: "Saint Philip", stateCode: "10", countryId: "19" },
  { id: "383", name: "Saint Thomas", stateCode: "11", countryId: "19" },
  { id: "384", name: "Brest Region", stateCode: "BR", countryId: "20" },
  { id: "385", name: "Gomel Region", stateCode: "HO", countryId: "20" },
  { id: "386", name: "Grodno Region", stateCode: "HR", countryId: "20" },
  { id: "387", name: "Minsk", stateCode: "HM", countryId: "20" },
  { id: "388", name: "Minsk Region", stateCode: "MI", countryId: "20" },
  { id: "389", name: "Mogilev Region", stateCode: "MA", countryId: "20" },
  { id: "390", name: "Vitebsk Region", stateCode: "VI", countryId: "20" },
  { id: "391", name: "Antwerp", stateCode: "VAN", countryId: "21" },
  {
    id: "392",
    name: "Brussels-Capital Region",
    stateCode: "BRU",
    countryId: "21",
  },
  { id: "393", name: "East Flanders", stateCode: "VOV", countryId: "21" },
  { id: "394", name: "Flanders", stateCode: "VLG", countryId: "21" },
  { id: "395", name: "Flemish Brabant", stateCode: "VBR", countryId: "21" },
  { id: "396", name: "Hainaut", stateCode: "WHT", countryId: "21" },
  { id: "397", name: "Liège", stateCode: "WLG", countryId: "21" },
  { id: "398", name: "Limburg", stateCode: "VLI", countryId: "21" },
  { id: "399", name: "Luxembourg", stateCode: "WLX", countryId: "21" },
  { id: "400", name: "Namur", stateCode: "WNA", countryId: "21" },
  { id: "401", name: "Wallonia", stateCode: "WAL", countryId: "21" },
  { id: "402", name: "Walloon Brabant", stateCode: "WBR", countryId: "21" },
  { id: "403", name: "West Flanders", stateCode: "VWV", countryId: "21" },
  { id: "404", name: "Belize District", stateCode: "BZ", countryId: "22" },
  { id: "405", name: "Cayo District", stateCode: "CY", countryId: "22" },
  { id: "406", name: "Corozal District", stateCode: "CZL", countryId: "22" },
  { id: "407", name: "Orange Walk District", stateCode: "OW", countryId: "22" },
  { id: "408", name: "Stann Creek District", stateCode: "SC", countryId: "22" },
  { id: "409", name: "Toledo District", stateCode: "TOL", countryId: "22" },
  { id: "410", name: "Alibori Department", stateCode: "AL", countryId: "23" },
  { id: "411", name: "Atakora Department", stateCode: "AK", countryId: "23" },
  {
    id: "412",
    name: "Atlantique Department",
    stateCode: "AQ",
    countryId: "23",
  },
  { id: "413", name: "Borgou Department", stateCode: "BO", countryId: "23" },
  { id: "414", name: "Collines Department", stateCode: "CO", countryId: "23" },
  { id: "415", name: "Donga Department", stateCode: "DO", countryId: "23" },
  { id: "416", name: "Kouffo Department", stateCode: "KO", countryId: "23" },
  { id: "417", name: "Littoral Department", stateCode: "LI", countryId: "23" },
  { id: "418", name: "Mono Department", stateCode: "MO", countryId: "23" },
  { id: "419", name: "Ouémé Department", stateCode: "OU", countryId: "23" },
  { id: "420", name: "Plateau Department", stateCode: "PL", countryId: "23" },
  { id: "421", name: "Zou Department", stateCode: "ZO", countryId: "23" },
  { id: "422", name: "Devonshire", stateCode: "DEV", countryId: "24" },
  { id: "423", name: "Hamilton", stateCode: "HA", countryId: "24" },
  { id: "424", name: "Paget", stateCode: "PAG", countryId: "24" },
  { id: "425", name: "Pembroke", stateCode: "PEM", countryId: "24" },
  { id: "426", name: "Saint George\\'s", stateCode: "SGE", countryId: "24" },
  { id: "427", name: "Sandys", stateCode: "SAN", countryId: "24" },
  { id: "428", name: "Smith\\'s", stateCode: "SMI", countryId: "24" },
  { id: "429", name: "Southampton", stateCode: "SOU", countryId: "24" },
  { id: "430", name: "Warwick", stateCode: "WAR", countryId: "24" },
  { id: "431", name: "Bumthang District", stateCode: "33", countryId: "25" },
  { id: "432", name: "Chukha District", stateCode: "12", countryId: "25" },
  { id: "433", name: "Dagana District", stateCode: "22", countryId: "25" },
  { id: "434", name: "Gasa District", stateCode: "GA", countryId: "25" },
  { id: "435", name: "Haa District", stateCode: "13", countryId: "25" },
  { id: "436", name: "Lhuntse District", stateCode: "44", countryId: "25" },
  { id: "437", name: "Mongar District", stateCode: "42", countryId: "25" },
  { id: "438", name: "Paro District", stateCode: "11", countryId: "25" },
  { id: "439", name: "Pemagatshel District", stateCode: "43", countryId: "25" },
  { id: "440", name: "Punakha District", stateCode: "23", countryId: "25" },
  {
    id: "441",
    name: "Samdrup Jongkhar District",
    stateCode: "45",
    countryId: "25",
  },
  { id: "442", name: "Samtse District", stateCode: "14", countryId: "25" },
  { id: "443", name: "Sarpang District", stateCode: "31", countryId: "25" },
  { id: "444", name: "Thimphu District", stateCode: "15", countryId: "25" },
  { id: "445", name: "Trashigang District", stateCode: "41", countryId: "25" },
  { id: "446", name: "Trongsa District", stateCode: "32", countryId: "25" },
  { id: "447", name: "Tsirang District", stateCode: "21", countryId: "25" },
  {
    id: "448",
    name: "Wangdue Phodrang District",
    stateCode: "24",
    countryId: "25",
  },
  { id: "449", name: "Zhemgang District", stateCode: "34", countryId: "25" },
  { id: "450", name: "Beni Department", stateCode: "B", countryId: "26" },
  { id: "451", name: "Chuquisaca Department", stateCode: "H", countryId: "26" },
  { id: "452", name: "Cochabamba Department", stateCode: "C", countryId: "26" },
  { id: "453", name: "La Paz Department", stateCode: "L", countryId: "26" },
  { id: "454", name: "Oruro Department", stateCode: "O", countryId: "26" },
  { id: "455", name: "Pando Department", stateCode: "N", countryId: "26" },
  { id: "456", name: "Potosí Department", stateCode: "P", countryId: "26" },
  { id: "457", name: "Santa Cruz Department", stateCode: "S", countryId: "26" },
  { id: "458", name: "Tarija Department", stateCode: "T", countryId: "26" },
  { id: "459", name: "Bonaire", stateCode: "BQ1", countryId: "27" },
  { id: "460", name: "Saba", stateCode: "BQ2", countryId: "27" },
  { id: "461", name: "Sint Eustatius", stateCode: "BQ3", countryId: "27" },
  {
    id: "462",
    name: "Bosnian Podrinje Canton",
    stateCode: "05",
    countryId: "28",
  },
  { id: "463", name: "Brčko District", stateCode: "BRC", countryId: "28" },
  { id: "464", name: "Canton 10", stateCode: "10", countryId: "28" },
  {
    id: "465",
    name: "Central Bosnia Canton",
    stateCode: "06",
    countryId: "28",
  },
  {
    id: "466",
    name: "Federation of Bosnia and Herzegovina",
    stateCode: "BIH",
    countryId: "28",
  },
  {
    id: "467",
    name: "Herzegovina-Neretva Canton",
    stateCode: "07",
    countryId: "28",
  },
  { id: "468", name: "Posavina Canton", stateCode: "02", countryId: "28" },
  { id: "469", name: "Republika Srpska", stateCode: "SRP", countryId: "28" },
  { id: "470", name: "Sarajevo Canton", stateCode: "09", countryId: "28" },
  { id: "471", name: "Tuzla Canton", stateCode: "03", countryId: "28" },
  { id: "472", name: "Una-Sana Canton", stateCode: "01", countryId: "28" },
  {
    id: "473",
    name: "West Herzegovina Canton",
    stateCode: "08",
    countryId: "28",
  },
  { id: "474", name: "Zenica-Doboj Canton", stateCode: "04", countryId: "28" },
  { id: "475", name: "Central District", stateCode: "CE", countryId: "29" },
  { id: "476", name: "Ghanzi District", stateCode: "GH", countryId: "29" },
  { id: "477", name: "Kgalagadi District", stateCode: "KG", countryId: "29" },
  { id: "478", name: "Kgatleng District", stateCode: "KL", countryId: "29" },
  { id: "479", name: "Kweneng District", stateCode: "KW", countryId: "29" },
  { id: "480", name: "Ngamiland", stateCode: "NG", countryId: "29" },
  { id: "481", name: "North-East District", stateCode: "NE", countryId: "29" },
  { id: "482", name: "North-West District", stateCode: "NW", countryId: "29" },
  { id: "483", name: "South-East District", stateCode: "SE", countryId: "29" },
  { id: "484", name: "Southern District", stateCode: "SO", countryId: "29" },
  { id: "485", name: "Acre", stateCode: "AC", countryId: "31" },
  { id: "486", name: "Alagoas", stateCode: "AL", countryId: "31" },
  { id: "487", name: "Amapá", stateCode: "AP", countryId: "31" },
  { id: "488", name: "Amazonas", stateCode: "AM", countryId: "31" },
  { id: "489", name: "Bahia", stateCode: "BA", countryId: "31" },
  { id: "490", name: "Ceará", stateCode: "CE", countryId: "31" },
  { id: "491", name: "Distrito Federal", stateCode: "DF", countryId: "31" },
  { id: "492", name: "Espírito Santo", stateCode: "ES", countryId: "31" },
  { id: "493", name: "Goiás", stateCode: "GO", countryId: "31" },
  { id: "494", name: "Maranhão", stateCode: "MA", countryId: "31" },
  { id: "495", name: "Mato Grosso", stateCode: "MT", countryId: "31" },
  { id: "496", name: "Mato Grosso do Sul", stateCode: "MS", countryId: "31" },
  { id: "497", name: "Minas Gerais", stateCode: "MG", countryId: "31" },
  { id: "498", name: "Pará", stateCode: "PA", countryId: "31" },
  { id: "499", name: "Paraíba", stateCode: "PB", countryId: "31" },
  { id: "500", name: "Paraná", stateCode: "PR", countryId: "31" },
  { id: "501", name: "Pernambuco", stateCode: "PE", countryId: "31" },
  { id: "502", name: "Piauí", stateCode: "PI", countryId: "31" },
  { id: "503", name: "Rio de Janeiro", stateCode: "RJ", countryId: "31" },
  { id: "504", name: "Rio Grande do Norte", stateCode: "RN", countryId: "31" },
  { id: "505", name: "Rio Grande do Sul", stateCode: "RS", countryId: "31" },
  { id: "506", name: "Rondônia", stateCode: "RO", countryId: "31" },
  { id: "507", name: "Roraima", stateCode: "RR", countryId: "31" },
  { id: "508", name: "Santa Catarina", stateCode: "SC", countryId: "31" },
  { id: "509", name: "São Paulo", stateCode: "SP", countryId: "31" },
  { id: "510", name: "Sergipe", stateCode: "SE", countryId: "31" },
  { id: "511", name: "Tocantins", stateCode: "TO", countryId: "31" },
  { id: "512", name: "Belait District", stateCode: "BE", countryId: "33" },
  {
    id: "513",
    name: "Brunei-Muara District",
    stateCode: "BM",
    countryId: "33",
  },
  { id: "514", name: "Temburong District", stateCode: "TE", countryId: "33" },
  { id: "515", name: "Tutong District", stateCode: "TU", countryId: "33" },
  { id: "516", name: "Blagoevgrad Province", stateCode: "01", countryId: "34" },
  { id: "517", name: "Burgas Province", stateCode: "02", countryId: "34" },
  { id: "518", name: "Dobrich Province", stateCode: "08", countryId: "34" },
  { id: "519", name: "Gabrovo Province", stateCode: "07", countryId: "34" },
  { id: "520", name: "Haskovo Province", stateCode: "26", countryId: "34" },
  { id: "521", name: "Kardzhali Province", stateCode: "09", countryId: "34" },
  { id: "522", name: "Kyustendil Province", stateCode: "10", countryId: "34" },
  { id: "523", name: "Lovech Province", stateCode: "11", countryId: "34" },
  { id: "524", name: "Montana Province", stateCode: "12", countryId: "34" },
  { id: "525", name: "Pazardzhik Province", stateCode: "13", countryId: "34" },
  { id: "526", name: "Pernik Province", stateCode: "14", countryId: "34" },
  { id: "527", name: "Pleven Province", stateCode: "15", countryId: "34" },
  { id: "528", name: "Plovdiv Province", stateCode: "16", countryId: "34" },
  { id: "529", name: "Razgrad Province", stateCode: "17", countryId: "34" },
  { id: "530", name: "Ruse Province", stateCode: "18", countryId: "34" },
  { id: "531", name: "Shumen", stateCode: "27", countryId: "34" },
  { id: "532", name: "Silistra Province", stateCode: "19", countryId: "34" },
  { id: "533", name: "Sliven Province", stateCode: "20", countryId: "34" },
  { id: "534", name: "Smolyan Province", stateCode: "21", countryId: "34" },
  { id: "535", name: "Sofia City Province", stateCode: "22", countryId: "34" },
  { id: "536", name: "Sofia Province", stateCode: "23", countryId: "34" },
  {
    id: "537",
    name: "Stara Zagora Province",
    stateCode: "24",
    countryId: "34",
  },
  { id: "538", name: "Targovishte Province", stateCode: "25", countryId: "34" },
  { id: "539", name: "Varna Province", stateCode: "03", countryId: "34" },
  {
    id: "540",
    name: "Veliko Tarnovo Province",
    stateCode: "04",
    countryId: "34",
  },
  { id: "541", name: "Vidin Province", stateCode: "05", countryId: "34" },
  { id: "542", name: "Vratsa Province", stateCode: "06", countryId: "34" },
  { id: "543", name: "Yambol Province", stateCode: "28", countryId: "34" },
  { id: "544", name: "Balé Province", stateCode: "BAL", countryId: "35" },
  { id: "545", name: "Bam Province", stateCode: "BAM", countryId: "35" },
  { id: "546", name: "Banwa Province", stateCode: "BAN", countryId: "35" },
  { id: "547", name: "Bazèga Province", stateCode: "BAZ", countryId: "35" },
  {
    id: "548",
    name: "Boucle du Mouhoun Region",
    stateCode: "01",
    countryId: "35",
  },
  { id: "549", name: "Bougouriba Province", stateCode: "BGR", countryId: "35" },
  { id: "550", name: "Boulgou", stateCode: "BLG", countryId: "35" },
  { id: "551", name: "Cascades Region", stateCode: "02", countryId: "35" },
  { id: "552", name: "Centre", stateCode: "03", countryId: "35" },
  { id: "553", name: "Centre-Est Region", stateCode: "04", countryId: "35" },
  { id: "554", name: "Centre-Nord Region", stateCode: "05", countryId: "35" },
  { id: "555", name: "Centre-Ouest Region", stateCode: "06", countryId: "35" },
  { id: "556", name: "Centre-Sud Region", stateCode: "07", countryId: "35" },
  { id: "557", name: "Comoé Province", stateCode: "COM", countryId: "35" },
  { id: "558", name: "Est Region", stateCode: "08", countryId: "35" },
  { id: "559", name: "Ganzourgou Province", stateCode: "GAN", countryId: "35" },
  { id: "560", name: "Gnagna Province", stateCode: "GNA", countryId: "35" },
  { id: "561", name: "Gourma Province", stateCode: "GOU", countryId: "35" },
  { id: "562", name: "Hauts-Bassins Region", stateCode: "09", countryId: "35" },
  { id: "563", name: "Houet Province", stateCode: "HOU", countryId: "35" },
  { id: "564", name: "Ioba Province", stateCode: "IOB", countryId: "35" },
  { id: "565", name: "Kadiogo Province", stateCode: "KAD", countryId: "35" },
  { id: "566", name: "Kénédougou Province", stateCode: "KEN", countryId: "35" },
  { id: "567", name: "Komondjari Province", stateCode: "KMD", countryId: "35" },
  { id: "568", name: "Kompienga Province", stateCode: "KMP", countryId: "35" },
  { id: "569", name: "Kossi Province", stateCode: "KOS", countryId: "35" },
  { id: "570", name: "Koulpélogo Province", stateCode: "KOP", countryId: "35" },
  { id: "571", name: "Kouritenga Province", stateCode: "KOT", countryId: "35" },
  { id: "572", name: "Kourwéogo Province", stateCode: "KOW", countryId: "35" },
  { id: "573", name: "Léraba Province", stateCode: "LER", countryId: "35" },
  { id: "574", name: "Loroum Province", stateCode: "LOR", countryId: "35" },
  { id: "575", name: "Mouhoun", stateCode: "MOU", countryId: "35" },
  { id: "576", name: "Nahouri Province", stateCode: "NAO", countryId: "35" },
  { id: "577", name: "Namentenga Province", stateCode: "NAM", countryId: "35" },
  { id: "578", name: "Nayala Province", stateCode: "NAY", countryId: "35" },
  {
    id: "579",
    name: "Nord Region, Burkina Faso",
    stateCode: "10",
    countryId: "35",
  },
  { id: "580", name: "Noumbiel Province", stateCode: "NOU", countryId: "35" },
  { id: "581", name: "Oubritenga Province", stateCode: "OUB", countryId: "35" },
  { id: "582", name: "Oudalan Province", stateCode: "OUD", countryId: "35" },
  { id: "583", name: "Passoré Province", stateCode: "PAS", countryId: "35" },
  {
    id: "584",
    name: "Plateau-Central Region",
    stateCode: "11",
    countryId: "35",
  },
  { id: "585", name: "Poni Province", stateCode: "PON", countryId: "35" },
  { id: "586", name: "Sahel Region", stateCode: "12", countryId: "35" },
  { id: "587", name: "Sanguié Province", stateCode: "SNG", countryId: "35" },
  { id: "588", name: "Sanmatenga Province", stateCode: "SMT", countryId: "35" },
  { id: "589", name: "Séno Province", stateCode: "SEN", countryId: "35" },
  { id: "590", name: "Sissili Province", stateCode: "SIS", countryId: "35" },
  { id: "591", name: "Soum Province", stateCode: "SOM", countryId: "35" },
  { id: "592", name: "Sourou Province", stateCode: "SOR", countryId: "35" },
  { id: "593", name: "Sud-Ouest Region", stateCode: "13", countryId: "35" },
  { id: "594", name: "Tapoa Province", stateCode: "TAP", countryId: "35" },
  { id: "595", name: "Tuy Province", stateCode: "TUI", countryId: "35" },
  { id: "596", name: "Yagha Province", stateCode: "YAG", countryId: "35" },
  { id: "597", name: "Yatenga Province", stateCode: "YAT", countryId: "35" },
  { id: "598", name: "Ziro Province", stateCode: "ZIR", countryId: "35" },
  { id: "599", name: "Zondoma Province", stateCode: "ZON", countryId: "35" },
  { id: "600", name: "Zoundwéogo Province", stateCode: "ZOU", countryId: "35" },
  { id: "601", name: "Bubanza Province", stateCode: "BB", countryId: "36" },
  {
    id: "602",
    name: "Bujumbura Mairie Province",
    stateCode: "BM",
    countryId: "36",
  },
  {
    id: "603",
    name: "Bujumbura Rural Province",
    stateCode: "BL",
    countryId: "36",
  },
  { id: "604", name: "Bururi Province", stateCode: "BR", countryId: "36" },
  { id: "605", name: "Cankuzo Province", stateCode: "CA", countryId: "36" },
  { id: "606", name: "Cibitoke Province", stateCode: "CI", countryId: "36" },
  { id: "607", name: "Gitega Province", stateCode: "GI", countryId: "36" },
  { id: "608", name: "Karuzi Province", stateCode: "KR", countryId: "36" },
  { id: "609", name: "Kayanza Province", stateCode: "KY", countryId: "36" },
  { id: "610", name: "Kirundo Province", stateCode: "KI", countryId: "36" },
  { id: "611", name: "Makamba Province", stateCode: "MA", countryId: "36" },
  { id: "612", name: "Muramvya Province", stateCode: "MU", countryId: "36" },
  { id: "613", name: "Muyinga Province", stateCode: "MY", countryId: "36" },
  { id: "614", name: "Mwaro Province", stateCode: "MW", countryId: "36" },
  { id: "615", name: "Ngozi Province", stateCode: "NG", countryId: "36" },
  { id: "616", name: "Rumonge Province", stateCode: "RM", countryId: "36" },
  { id: "617", name: "Rutana Province", stateCode: "RT", countryId: "36" },
  { id: "618", name: "Ruyigi Province", stateCode: "RY", countryId: "36" },
  { id: "619", name: "Banteay Meanchey", stateCode: "1", countryId: "37" },
  { id: "620", name: "Battambang", stateCode: "2", countryId: "37" },
  { id: "621", name: "Kampong Cham", stateCode: "3", countryId: "37" },
  { id: "622", name: "Kampong Chhnang", stateCode: "4", countryId: "37" },
  { id: "623", name: "Kampong Speu", stateCode: "5", countryId: "37" },
  { id: "624", name: "Kampong Thom", stateCode: "6", countryId: "37" },
  { id: "625", name: "Kampot", stateCode: "7", countryId: "37" },
  { id: "626", name: "Kandal", stateCode: "8", countryId: "37" },
  { id: "627", name: "Kep", stateCode: "23", countryId: "37" },
  { id: "628", name: "Koh Kong", stateCode: "9", countryId: "37" },
  { id: "629", name: "Kratie", stateCode: "10", countryId: "37" },
  { id: "630", name: "Mondulkiri", stateCode: "11", countryId: "37" },
  { id: "631", name: "Oddar Meanchey", stateCode: "22", countryId: "37" },
  { id: "632", name: "Pailin", stateCode: "24", countryId: "37" },
  { id: "633", name: "Phnom Penh", stateCode: "12", countryId: "37" },
  { id: "634", name: "Preah Vihear", stateCode: "13", countryId: "37" },
  { id: "635", name: "Prey Veng", stateCode: "14", countryId: "37" },
  { id: "636", name: "Pursat", stateCode: "15", countryId: "37" },
  { id: "637", name: "Ratanakiri", stateCode: "16", countryId: "37" },
  { id: "638", name: "Siem Reap", stateCode: "17", countryId: "37" },
  { id: "639", name: "Sihanoukville", stateCode: "18", countryId: "37" },
  { id: "640", name: "Stung Treng", stateCode: "19", countryId: "37" },
  { id: "641", name: "Svay Rieng", stateCode: "20", countryId: "37" },
  { id: "642", name: "Takeo", stateCode: "21", countryId: "37" },
  { id: "643", name: "Adamawa", stateCode: "AD", countryId: "38" },
  { id: "644", name: "Centre", stateCode: "CE", countryId: "38" },
  { id: "645", name: "East", stateCode: "ES", countryId: "38" },
  { id: "646", name: "Far North", stateCode: "EN", countryId: "38" },
  { id: "647", name: "Littoral", stateCode: "LT", countryId: "38" },
  { id: "648", name: "North", stateCode: "NO", countryId: "38" },
  { id: "649", name: "Northwest", stateCode: "NW", countryId: "38" },
  { id: "650", name: "South", stateCode: "SU", countryId: "38" },
  { id: "651", name: "Southwest", stateCode: "SW", countryId: "38" },
  { id: "652", name: "West", stateCode: "OU", countryId: "38" },
  { id: "653", name: "Alberta", stateCode: "AB", countryId: "39" },
  { id: "654", name: "British Columbia", stateCode: "BC", countryId: "39" },
  { id: "655", name: "Manitoba", stateCode: "MB", countryId: "39" },
  { id: "656", name: "New Brunswick", stateCode: "NB", countryId: "39" },
  {
    id: "657",
    name: "Newfoundland and Labrador",
    stateCode: "NL",
    countryId: "39",
  },
  {
    id: "658",
    name: "Northwest Territories",
    stateCode: "NT",
    countryId: "39",
  },
  { id: "659", name: "Nova Scotia", stateCode: "NS", countryId: "39" },
  { id: "660", name: "Nunavut", stateCode: "NU", countryId: "39" },
  { id: "661", name: "Ontario", stateCode: "ON", countryId: "39" },
  { id: "662", name: "Prince Edward Island", stateCode: "PE", countryId: "39" },
  { id: "663", name: "Quebec", stateCode: "QC", countryId: "39" },
  { id: "664", name: "Saskatchewan", stateCode: "SK", countryId: "39" },
  { id: "665", name: "Yukon", stateCode: "YT", countryId: "39" },
  { id: "666", name: "Barlavento Islands", stateCode: "B", countryId: "40" },
  { id: "667", name: "Boa Vista", stateCode: "BV", countryId: "40" },
  { id: "668", name: "Brava", stateCode: "BR", countryId: "40" },
  { id: "669", name: "Maio Municipality", stateCode: "MA", countryId: "40" },
  { id: "670", name: "Mosteiros", stateCode: "MO", countryId: "40" },
  { id: "671", name: "Paul", stateCode: "PA", countryId: "40" },
  { id: "672", name: "Porto Novo", stateCode: "PN", countryId: "40" },
  { id: "673", name: "Praia", stateCode: "PR", countryId: "40" },
  {
    id: "674",
    name: "Ribeira Brava Municipality",
    stateCode: "RB",
    countryId: "40",
  },
  { id: "675", name: "Ribeira Grande", stateCode: "RG", countryId: "40" },
  {
    id: "676",
    name: "Ribeira Grande de Santiago",
    stateCode: "RS",
    countryId: "40",
  },
  { id: "677", name: "Sal", stateCode: "SL", countryId: "40" },
  { id: "678", name: "Santa Catarina", stateCode: "CA", countryId: "40" },
  {
    id: "679",
    name: "Santa Catarina do Fogo",
    stateCode: "CF",
    countryId: "40",
  },
  { id: "680", name: "Santa Cruz", stateCode: "CR", countryId: "40" },
  { id: "681", name: "São Domingos", stateCode: "SD", countryId: "40" },
  { id: "682", name: "São Filipe", stateCode: "SF", countryId: "40" },
  {
    id: "683",
    name: "São Lourenço dos Órgãos",
    stateCode: "SO",
    countryId: "40",
  },
  { id: "684", name: "São Miguel", stateCode: "SM", countryId: "40" },
  { id: "685", name: "São Vicente", stateCode: "SV", countryId: "40" },
  { id: "686", name: "Sotavento Islands", stateCode: "S", countryId: "40" },
  { id: "687", name: "Tarrafal", stateCode: "TA", countryId: "40" },
  {
    id: "688",
    name: "Tarrafal de São Nicolau",
    stateCode: "TS",
    countryId: "40",
  },
  {
    id: "689",
    name: "Bamingui-Bangoran Prefecture",
    stateCode: "BB",
    countryId: "42",
  },
  { id: "690", name: "Bangui", stateCode: "BGF", countryId: "42" },
  {
    id: "691",
    name: "Basse-Kotto Prefecture",
    stateCode: "BK",
    countryId: "42",
  },
  {
    id: "692",
    name: "Haut-Mbomou Prefecture",
    stateCode: "HM",
    countryId: "42",
  },
  {
    id: "693",
    name: "Haute-Kotto Prefecture",
    stateCode: "HK",
    countryId: "42",
  },
  { id: "694", name: "Kémo Prefecture", stateCode: "KG", countryId: "42" },
  { id: "695", name: "Lobaye Prefecture", stateCode: "LB", countryId: "42" },
  { id: "696", name: "Mambéré-Kadéï", stateCode: "HS", countryId: "42" },
  { id: "697", name: "Mbomou Prefecture", stateCode: "MB", countryId: "42" },
  {
    id: "698",
    name: "Nana-Grébizi Economic Prefecture",
    stateCode: "KB",
    countryId: "42",
  },
  {
    id: "699",
    name: "Nana-Mambéré Prefecture",
    stateCode: "NM",
    countryId: "42",
  },
  {
    id: "700",
    name: "Ombella-M\\'Poko Prefecture",
    stateCode: "MP",
    countryId: "42",
  },
  { id: "701", name: "Ouaka Prefecture", stateCode: "UK", countryId: "42" },
  { id: "702", name: "Ouham Prefecture", stateCode: "AC", countryId: "42" },
  {
    id: "703",
    name: "Ouham-Pendé Prefecture",
    stateCode: "OP",
    countryId: "42",
  },
  { id: "704", name: "Sangha-Mbaéré", stateCode: "SE", countryId: "42" },
  { id: "705", name: "Vakaga Prefecture", stateCode: "VK", countryId: "42" },
  { id: "706", name: "Bahr el Gazel", stateCode: "BG", countryId: "43" },
  { id: "707", name: "Batha", stateCode: "BA", countryId: "43" },
  { id: "708", name: "Borkou", stateCode: "BO", countryId: "43" },
  { id: "709", name: "Chari-Baguirmi", stateCode: "CB", countryId: "43" },
  { id: "710", name: "Ennedi-Est", stateCode: "EE", countryId: "43" },
  { id: "711", name: "Ennedi-Ouest", stateCode: "EO", countryId: "43" },
  { id: "712", name: "Guéra", stateCode: "GR", countryId: "43" },
  { id: "713", name: "Hadjer-Lamis", stateCode: "HL", countryId: "43" },
  { id: "714", name: "Kanem", stateCode: "KA", countryId: "43" },
  { id: "715", name: "Lac", stateCode: "LC", countryId: "43" },
  { id: "716", name: "Logone Occidental", stateCode: "LO", countryId: "43" },
  { id: "717", name: "Logone Oriental", stateCode: "LR", countryId: "43" },
  { id: "718", name: "Mandoul", stateCode: "MA", countryId: "43" },
  { id: "719", name: "Mayo-Kebbi Est", stateCode: "ME", countryId: "43" },
  { id: "720", name: "Mayo-Kebbi Ouest", stateCode: "MO", countryId: "43" },
  { id: "721", name: "Moyen-Chari", stateCode: "MC", countryId: "43" },
  { id: "722", name: "N\\'Djamena", stateCode: "ND", countryId: "43" },
  { id: "723", name: "Ouaddaï", stateCode: "OD", countryId: "43" },
  { id: "724", name: "Salamat", stateCode: "SA", countryId: "43" },
  { id: "725", name: "Sila", stateCode: "SI", countryId: "43" },
  { id: "726", name: "Tandjilé", stateCode: "TA", countryId: "43" },
  { id: "727", name: "Tibesti", stateCode: "TI", countryId: "43" },
  { id: "728", name: "Wadi Fira", stateCode: "WF", countryId: "43" },
  {
    id: "729",
    name: "Aisén del General Carlos Ibañez del Campo",
    stateCode: "AI",
    countryId: "44",
  },
  { id: "730", name: "Antofagasta", stateCode: "AN", countryId: "44" },
  { id: "731", name: "Arica y Parinacota", stateCode: "AP", countryId: "44" },
  { id: "732", name: "Atacama", stateCode: "AT", countryId: "44" },
  { id: "733", name: "Biobío", stateCode: "BI", countryId: "44" },
  { id: "734", name: "Coquimbo", stateCode: "CO", countryId: "44" },
  { id: "735", name: "La Araucanía", stateCode: "AR", countryId: "44" },
  {
    id: "736",
    name: "Libertador General Bernardo O\\'Higgins",
    stateCode: "LI",
    countryId: "44",
  },
  { id: "737", name: "Los Lagos", stateCode: "LL", countryId: "44" },
  { id: "738", name: "Los Ríos", stateCode: "LR", countryId: "44" },
  {
    id: "739",
    name: "Magallanes y de la Antártica Chilena",
    stateCode: "MA",
    countryId: "44",
  },
  { id: "740", name: "Maule", stateCode: "ML", countryId: "44" },
  { id: "741", name: "Ñuble", stateCode: "NB", countryId: "44" },
  {
    id: "742",
    name: "Región Metropolitana de Santiago",
    stateCode: "RM",
    countryId: "44",
  },
  { id: "743", name: "Tarapacá", stateCode: "TA", countryId: "44" },
  { id: "744", name: "Valparaíso", stateCode: "VS", countryId: "44" },
  { id: "745", name: "Anhui", stateCode: "AH", countryId: "45" },
  { id: "746", name: "Beijing", stateCode: "BJ", countryId: "45" },
  { id: "747", name: "Chongqing", stateCode: "CQ", countryId: "45" },
  { id: "748", name: "Fujian", stateCode: "FJ", countryId: "45" },
  { id: "749", name: "Gansu", stateCode: "GS", countryId: "45" },
  { id: "750", name: "Guangdong", stateCode: "GD", countryId: "45" },
  { id: "751", name: "Guangxi Zhuang", stateCode: "GX", countryId: "45" },
  { id: "752", name: "Guizhou", stateCode: "GZ", countryId: "45" },
  { id: "753", name: "Hainan", stateCode: "HI", countryId: "45" },
  { id: "754", name: "Hebei", stateCode: "HE", countryId: "45" },
  { id: "755", name: "Heilongjiang", stateCode: "HL", countryId: "45" },
  { id: "756", name: "Henan", stateCode: "HA", countryId: "45" },
  { id: "757", name: "Hong Kong SAR", stateCode: "HK", countryId: "45" },
  { id: "758", name: "Hubei", stateCode: "HB", countryId: "45" },
  { id: "759", name: "Hunan", stateCode: "HN", countryId: "45" },
  { id: "760", name: "Inner Mongolia", stateCode: "NM", countryId: "45" },
  { id: "761", name: "Jiangsu", stateCode: "JS", countryId: "45" },
  { id: "762", name: "Jiangxi", stateCode: "JX", countryId: "45" },
  { id: "763", name: "Jilin", stateCode: "JL", countryId: "45" },
  { id: "764", name: "Liaoning", stateCode: "LN", countryId: "45" },
  { id: "765", name: "Macau SAR", stateCode: "MO", countryId: "45" },
  { id: "766", name: "Ningxia Huizu", stateCode: "NX", countryId: "45" },
  { id: "767", name: "Qinghai", stateCode: "QH", countryId: "45" },
  { id: "768", name: "Shaanxi", stateCode: "SN", countryId: "45" },
  { id: "769", name: "Shandong", stateCode: "SD", countryId: "45" },
  { id: "770", name: "Shanghai", stateCode: "SH", countryId: "45" },
  { id: "771", name: "Shanxi", stateCode: "SX", countryId: "45" },
  { id: "772", name: "Sichuan", stateCode: "SC", countryId: "45" },
  { id: "773", name: "Taiwan", stateCode: "TW", countryId: "45" },
  { id: "774", name: "Tianjin", stateCode: "TJ", countryId: "45" },
  { id: "775", name: "Xinjiang", stateCode: "XJ", countryId: "45" },
  { id: "776", name: "Xizang", stateCode: "XZ", countryId: "45" },
  { id: "777", name: "Yunnan", stateCode: "YN", countryId: "45" },
  { id: "778", name: "Zhejiang", stateCode: "ZJ", countryId: "45" },
  { id: "779", name: "Amazonas", stateCode: "AMA", countryId: "48" },
  { id: "780", name: "Antioquia", stateCode: "ANT", countryId: "48" },
  { id: "781", name: "Arauca", stateCode: "ARA", countryId: "48" },
  {
    id: "782",
    name: "Archipiélago de San Andrés, Providencia y Santa Catalina",
    stateCode: "SAP",
    countryId: "48",
  },
  { id: "783", name: "Atlántico", stateCode: "ATL", countryId: "48" },
  { id: "784", name: "Bogotá D.C.", stateCode: "DC", countryId: "48" },
  { id: "785", name: "Bolívar", stateCode: "BOL", countryId: "48" },
  { id: "786", name: "Boyacá", stateCode: "BOY", countryId: "48" },
  { id: "787", name: "Caldas", stateCode: "CAL", countryId: "48" },
  { id: "788", name: "Caquetá", stateCode: "CAQ", countryId: "48" },
  { id: "789", name: "Casanare", stateCode: "CAS", countryId: "48" },
  { id: "790", name: "Cauca", stateCode: "CAU", countryId: "48" },
  { id: "791", name: "Cesar", stateCode: "CES", countryId: "48" },
  { id: "792", name: "Chocó", stateCode: "CHO", countryId: "48" },
  { id: "793", name: "Córdoba", stateCode: "COR", countryId: "48" },
  { id: "794", name: "Cundinamarca", stateCode: "CUN", countryId: "48" },
  { id: "795", name: "Guainía", stateCode: "GUA", countryId: "48" },
  { id: "796", name: "Guaviare", stateCode: "GUV", countryId: "48" },
  { id: "797", name: "Huila", stateCode: "HUI", countryId: "48" },
  { id: "798", name: "La Guajira", stateCode: "LAG", countryId: "48" },
  { id: "799", name: "Magdalena", stateCode: "MAG", countryId: "48" },
  { id: "800", name: "Meta", stateCode: "MET", countryId: "48" },
  { id: "801", name: "Nariño", stateCode: "NAR", countryId: "48" },
  { id: "802", name: "Norte de Santander", stateCode: "NSA", countryId: "48" },
  { id: "803", name: "Putumayo", stateCode: "PUT", countryId: "48" },
  { id: "804", name: "Quindío", stateCode: "QUI", countryId: "48" },
  { id: "805", name: "Risaralda", stateCode: "RIS", countryId: "48" },
  { id: "806", name: "Santander", stateCode: "SAN", countryId: "48" },
  { id: "807", name: "Sucre", stateCode: "SUC", countryId: "48" },
  { id: "808", name: "Tolima", stateCode: "TOL", countryId: "48" },
  { id: "809", name: "Valle del Cauca", stateCode: "VAC", countryId: "48" },
  { id: "810", name: "Vaupés", stateCode: "VAU", countryId: "48" },
  { id: "811", name: "Vichada", stateCode: "VID", countryId: "48" },
  { id: "812", name: "Anjouan", stateCode: "A", countryId: "49" },
  { id: "813", name: "Grande Comore", stateCode: "G", countryId: "49" },
  { id: "814", name: "Mohéli", stateCode: "M", countryId: "49" },
  { id: "815", name: "Bouenza Department", stateCode: "11", countryId: "50" },
  { id: "816", name: "Brazzaville", stateCode: "BZV", countryId: "50" },
  { id: "817", name: "Cuvette Department", stateCode: "8", countryId: "50" },
  {
    id: "818",
    name: "Cuvette-Ouest Department",
    stateCode: "15",
    countryId: "50",
  },
  { id: "819", name: "Kouilou Department", stateCode: "5", countryId: "50" },
  { id: "820", name: "Lékoumou Department", stateCode: "2", countryId: "50" },
  { id: "821", name: "Likouala Department", stateCode: "7", countryId: "50" },
  { id: "822", name: "Niari Department", stateCode: "9", countryId: "50" },
  { id: "823", name: "Plateaux Department", stateCode: "14", countryId: "50" },
  { id: "824", name: "Pointe-Noire", stateCode: "16", countryId: "50" },
  { id: "825", name: "Pool Department", stateCode: "12", countryId: "50" },
  { id: "826", name: "Sangha Department", stateCode: "13", countryId: "50" },
  { id: "827", name: "Alajuela Province", stateCode: "A", countryId: "52" },
  { id: "828", name: "Guanacaste Province", stateCode: "G", countryId: "52" },
  { id: "829", name: "Heredia Province", stateCode: "H", countryId: "52" },
  { id: "830", name: "Limón Province", stateCode: "L", countryId: "52" },
  { id: "831", name: "Provincia de Cartago", stateCode: "C", countryId: "52" },
  { id: "832", name: "Puntarenas Province", stateCode: "P", countryId: "52" },
  { id: "833", name: "San José Province", stateCode: "SJ", countryId: "52" },
  { id: "834", name: "Abidjan", stateCode: "AB", countryId: "53" },
  { id: "835", name: "Agnéby", stateCode: "16", countryId: "53" },
  { id: "836", name: "Bafing Region", stateCode: "17", countryId: "53" },
  {
    id: "837",
    name: "Bas-Sassandra District",
    stateCode: "BS",
    countryId: "53",
  },
  { id: "838", name: "Bas-Sassandra Region", stateCode: "09", countryId: "53" },
  { id: "839", name: "Comoé District", stateCode: "CM", countryId: "53" },
  { id: "840", name: "Denguélé District", stateCode: "DN", countryId: "53" },
  { id: "841", name: "Denguélé Region", stateCode: "10", countryId: "53" },
  { id: "842", name: "Dix-Huit Montagnes", stateCode: "06", countryId: "53" },
  { id: "843", name: "Fromager", stateCode: "18", countryId: "53" },
  { id: "844", name: "Gôh-Djiboua District", stateCode: "GD", countryId: "53" },
  { id: "845", name: "Haut-Sassandra", stateCode: "02", countryId: "53" },
  { id: "846", name: "Lacs District", stateCode: "LC", countryId: "53" },
  { id: "847", name: "Lacs Region", stateCode: "07", countryId: "53" },
  { id: "848", name: "Lagunes District", stateCode: "LG", countryId: "53" },
  { id: "849", name: "Lagunes region", stateCode: "01", countryId: "53" },
  { id: "850", name: "Marahoué Region", stateCode: "12", countryId: "53" },
  { id: "851", name: "Montagnes District", stateCode: "MG", countryId: "53" },
  { id: "852", name: "Moyen-Cavally", stateCode: "19", countryId: "53" },
  { id: "853", name: "Moyen-Comoé", stateCode: "05", countryId: "53" },
  { id: "854", name: "N\\'zi-Comoé", stateCode: "11", countryId: "53" },
  {
    id: "855",
    name: "Sassandra-Marahoué District",
    stateCode: "SM",
    countryId: "53",
  },
  { id: "856", name: "Savanes Region", stateCode: "03", countryId: "53" },
  { id: "857", name: "Sud-Bandama", stateCode: "15", countryId: "53" },
  { id: "858", name: "Sud-Comoé", stateCode: "13", countryId: "53" },
  {
    id: "859",
    name: "Vallée du Bandama District",
    stateCode: "VB",
    countryId: "53",
  },
  {
    id: "860",
    name: "Vallée du Bandama Region",
    stateCode: "04",
    countryId: "53",
  },
  { id: "861", name: "Woroba District", stateCode: "WR", countryId: "53" },
  { id: "862", name: "Worodougou", stateCode: "14", countryId: "53" },
  { id: "863", name: "Yamoussoukro", stateCode: "YM", countryId: "53" },
  { id: "864", name: "Zanzan Region", stateCode: "ZZ", countryId: "53" },
  { id: "865", name: "Bjelovar-Bilogora", stateCode: "07", countryId: "54" },
  { id: "866", name: "Brod-Posavina", stateCode: "12", countryId: "54" },
  { id: "867", name: "Dubrovnik-Neretva", stateCode: "19", countryId: "54" },
  { id: "868", name: "Istria", stateCode: "18", countryId: "54" },
  { id: "869", name: "Karlovac", stateCode: "04", countryId: "54" },
  { id: "870", name: "Koprivnica-Križevci", stateCode: "06", countryId: "54" },
  { id: "871", name: "Krapina-Zagorje", stateCode: "02", countryId: "54" },
  { id: "872", name: "Lika-Senj", stateCode: "09", countryId: "54" },
  { id: "873", name: "Međimurje", stateCode: "20", countryId: "54" },
  { id: "874", name: "Osijek-Baranja", stateCode: "14", countryId: "54" },
  { id: "875", name: "Požega-Slavonia", stateCode: "11", countryId: "54" },
  {
    id: "876",
    name: "Primorje-Gorski Kotar",
    stateCode: "08",
    countryId: "54",
  },
  { id: "877", name: "Šibenik-Knin", stateCode: "15", countryId: "54" },
  { id: "878", name: "Sisak-Moslavina", stateCode: "03", countryId: "54" },
  { id: "879", name: "Split-Dalmatia", stateCode: "17", countryId: "54" },
  { id: "880", name: "Varaždin", stateCode: "05", countryId: "54" },
  { id: "881", name: "Virovitica-Podravina", stateCode: "10", countryId: "54" },
  { id: "882", name: "Vukovar-Syrmia", stateCode: "16", countryId: "54" },
  { id: "883", name: "Zadar", stateCode: "13", countryId: "54" },
  { id: "884", name: "Zagreb", stateCode: "01", countryId: "54" },
  { id: "885", name: "Zagreb", stateCode: "21", countryId: "54" },
  { id: "886", name: "Artemisa Province", stateCode: "15", countryId: "55" },
  { id: "887", name: "Camagüey Province", stateCode: "09", countryId: "55" },
  {
    id: "888",
    name: "Ciego de Ávila Province",
    stateCode: "08",
    countryId: "55",
  },
  { id: "889", name: "Cienfuegos Province", stateCode: "06", countryId: "55" },
  { id: "890", name: "Granma Province", stateCode: "12", countryId: "55" },
  { id: "891", name: "Guantánamo Province", stateCode: "14", countryId: "55" },
  { id: "892", name: "Havana Province", stateCode: "03", countryId: "55" },
  { id: "893", name: "Holguín Province", stateCode: "11", countryId: "55" },
  { id: "894", name: "Isla de la Juventud", stateCode: "99", countryId: "55" },
  { id: "895", name: "Las Tunas Province", stateCode: "10", countryId: "55" },
  { id: "896", name: "Matanzas Province", stateCode: "04", countryId: "55" },
  { id: "897", name: "Mayabeque Province", stateCode: "16", countryId: "55" },
  {
    id: "898",
    name: "Pinar del Río Province",
    stateCode: "01",
    countryId: "55",
  },
  {
    id: "899",
    name: "Sancti Spíritus Province",
    stateCode: "07",
    countryId: "55",
  },
  {
    id: "900",
    name: "Santiago de Cuba Province",
    stateCode: "13",
    countryId: "55",
  },
  { id: "901", name: "Villa Clara Province", stateCode: "05", countryId: "55" },
  {
    id: "902",
    name: "Famagusta District (Mağusa)",
    stateCode: "04",
    countryId: "57",
  },
  {
    id: "903",
    name: "Kyrenia District (Keryneia)",
    stateCode: "06",
    countryId: "57",
  },
  {
    id: "904",
    name: "Larnaca District (Larnaka)",
    stateCode: "03",
    countryId: "57",
  },
  {
    id: "905",
    name: "Limassol District (Leymasun)",
    stateCode: "02",
    countryId: "57",
  },
  {
    id: "906",
    name: "Nicosia District (Lefkoşa)",
    stateCode: "01",
    countryId: "57",
  },
  {
    id: "907",
    name: "Paphos District (Pafos)",
    stateCode: "05",
    countryId: "57",
  },
  { id: "908", name: "Benešov", stateCode: "201", countryId: "58" },
  { id: "909", name: "Beroun", stateCode: "202", countryId: "58" },
  { id: "910", name: "Blansko", stateCode: "641", countryId: "58" },
  { id: "911", name: "Břeclav", stateCode: "644", countryId: "58" },
  { id: "912", name: "Brno-město", stateCode: "642", countryId: "58" },
  { id: "913", name: "Brno-venkov", stateCode: "643", countryId: "58" },
  { id: "914", name: "Bruntál", stateCode: "801", countryId: "58" },
  { id: "915", name: "Česká Lípa", stateCode: "511", countryId: "58" },
  { id: "916", name: "České Budějovice", stateCode: "311", countryId: "58" },
  { id: "917", name: "Český Krumlov", stateCode: "312", countryId: "58" },
  { id: "918", name: "Cheb", stateCode: "411", countryId: "58" },
  { id: "919", name: "Chomutov", stateCode: "422", countryId: "58" },
  { id: "920", name: "Chrudim", stateCode: "531", countryId: "58" },
  { id: "921", name: "Děčín", stateCode: "421", countryId: "58" },
  { id: "922", name: "Domažlice", stateCode: "321", countryId: "58" },
  { id: "923", name: "Frýdek-Místek", stateCode: "802", countryId: "58" },
  { id: "924", name: "Havlíčkův Brod", stateCode: "631", countryId: "58" },
  { id: "925", name: "Hodonín", stateCode: "645", countryId: "58" },
  { id: "926", name: "Hradec Králové", stateCode: "521", countryId: "58" },
  { id: "927", name: "Jablonec nad Nisou", stateCode: "512", countryId: "58" },
  { id: "928", name: "Jeseník", stateCode: "711", countryId: "58" },
  { id: "929", name: "Jičín", stateCode: "522", countryId: "58" },
  { id: "930", name: "Jihlava", stateCode: "632", countryId: "58" },
  { id: "931", name: "Jihočeský kraj", stateCode: "31", countryId: "58" },
  { id: "932", name: "Jihomoravský kraj", stateCode: "64", countryId: "58" },
  { id: "933", name: "Jindřichův Hradec", stateCode: "313", countryId: "58" },
  { id: "934", name: "Karlovarský kraj", stateCode: "41", countryId: "58" },
  { id: "935", name: "Karlovy Vary", stateCode: "412", countryId: "58" },
  { id: "936", name: "Karviná", stateCode: "803", countryId: "58" },
  { id: "937", name: "Kladno", stateCode: "203", countryId: "58" },
  { id: "938", name: "Klatovy", stateCode: "322", countryId: "58" },
  { id: "939", name: "Kolín", stateCode: "204", countryId: "58" },
  { id: "940", name: "Kraj Vysočina", stateCode: "63", countryId: "58" },
  { id: "941", name: "Královéhradecký kraj", stateCode: "52", countryId: "58" },
  { id: "942", name: "Kroměříž", stateCode: "721", countryId: "58" },
  { id: "943", name: "Kutná Hora", stateCode: "205", countryId: "58" },
  { id: "944", name: "Liberec", stateCode: "513", countryId: "58" },
  { id: "945", name: "Liberecký kraj", stateCode: "51", countryId: "58" },
  { id: "946", name: "Litoměřice", stateCode: "423", countryId: "58" },
  { id: "947", name: "Louny", stateCode: "424", countryId: "58" },
  { id: "948", name: "Mělník", stateCode: "206", countryId: "58" },
  { id: "949", name: "Mladá Boleslav", stateCode: "207", countryId: "58" },
  { id: "950", name: "Moravskoslezský kraj", stateCode: "80", countryId: "58" },
  { id: "951", name: "Most", stateCode: "425", countryId: "58" },
  { id: "952", name: "Náchod", stateCode: "523", countryId: "58" },
  { id: "953", name: "Nový Jičín", stateCode: "804", countryId: "58" },
  { id: "954", name: "Nymburk", stateCode: "208", countryId: "58" },
  { id: "955", name: "Olomouc", stateCode: "712", countryId: "58" },
  { id: "956", name: "Olomoucký kraj", stateCode: "71", countryId: "58" },
  { id: "957", name: "Opava", stateCode: "805", countryId: "58" },
  { id: "958", name: "Ostrava-město", stateCode: "806", countryId: "58" },
  { id: "959", name: "Pardubice", stateCode: "532", countryId: "58" },
  { id: "960", name: "Pardubický kraj", stateCode: "53", countryId: "58" },
  { id: "961", name: "Pelhřimov", stateCode: "633", countryId: "58" },
  { id: "962", name: "Písek", stateCode: "314", countryId: "58" },
  { id: "963", name: "Plzeň-jih", stateCode: "324", countryId: "58" },
  { id: "964", name: "Plzeň-město", stateCode: "323", countryId: "58" },
  { id: "965", name: "Plzeň-sever", stateCode: "325", countryId: "58" },
  { id: "966", name: "Plzeňský kraj", stateCode: "32", countryId: "58" },
  { id: "967", name: "Prachatice", stateCode: "315", countryId: "58" },
  { id: "968", name: "Praha-východ", stateCode: "209", countryId: "58" },
  { id: "969", name: "Praha-západ", stateCode: "20A", countryId: "58" },
  { id: "970", name: "Praha, Hlavní město", stateCode: "10", countryId: "58" },
  { id: "971", name: "Přerov", stateCode: "714", countryId: "58" },
  { id: "972", name: "Příbram", stateCode: "20B", countryId: "58" },
  { id: "973", name: "Prostějov", stateCode: "713", countryId: "58" },
  { id: "974", name: "Rakovník", stateCode: "20C", countryId: "58" },
  { id: "975", name: "Rokycany", stateCode: "326", countryId: "58" },
  { id: "976", name: "Rychnov nad Kněžnou", stateCode: "524", countryId: "58" },
  { id: "977", name: "Semily", stateCode: "514", countryId: "58" },
  { id: "978", name: "Sokolov", stateCode: "413", countryId: "58" },
  { id: "979", name: "Strakonice", stateCode: "316", countryId: "58" },
  { id: "980", name: "Středočeský kraj", stateCode: "20", countryId: "58" },
  { id: "981", name: "Šumperk", stateCode: "715", countryId: "58" },
  { id: "982", name: "Svitavy", stateCode: "533", countryId: "58" },
  { id: "983", name: "Tábor", stateCode: "317", countryId: "58" },
  { id: "984", name: "Tachov", stateCode: "327", countryId: "58" },
  { id: "985", name: "Teplice", stateCode: "426", countryId: "58" },
  { id: "986", name: "Třebíč", stateCode: "634", countryId: "58" },
  { id: "987", name: "Trutnov", stateCode: "525", countryId: "58" },
  { id: "988", name: "Uherské Hradiště", stateCode: "722", countryId: "58" },
  { id: "989", name: "Ústecký kraj", stateCode: "42", countryId: "58" },
  { id: "990", name: "Ústí nad Labem", stateCode: "427", countryId: "58" },
  { id: "991", name: "Ústí nad Orlicí", stateCode: "534", countryId: "58" },
  { id: "992", name: "Vsetín", stateCode: "723", countryId: "58" },
  { id: "993", name: "Vyškov", stateCode: "646", countryId: "58" },
  { id: "994", name: "Žďár nad Sázavou", stateCode: "635", countryId: "58" },
  { id: "995", name: "Zlín", stateCode: "724", countryId: "58" },
  { id: "996", name: "Zlínský kraj", stateCode: "72", countryId: "58" },
  { id: "997", name: "Znojmo", stateCode: "647", countryId: "58" },
  { id: "998", name: "Bas-Uélé", stateCode: "BU", countryId: "59" },
  { id: "999", name: "Équateur", stateCode: "EQ", countryId: "59" },
  { id: "1000", name: "Haut-Katanga", stateCode: "HK", countryId: "59" },
  { id: "1001", name: "Haut-Lomami", stateCode: "HL", countryId: "59" },
  { id: "1002", name: "Haut-Uélé", stateCode: "HU", countryId: "59" },
  { id: "1003", name: "Ituri", stateCode: "IT", countryId: "59" },
  { id: "1004", name: "Kasaï", stateCode: "KS", countryId: "59" },
  { id: "1005", name: "Kasaï Central", stateCode: "KC", countryId: "59" },
  { id: "1006", name: "Kasaï Oriental", stateCode: "KE", countryId: "59" },
  { id: "1007", name: "Kinshasa", stateCode: "KN", countryId: "59" },
  { id: "1008", name: "Kongo Central", stateCode: "BC", countryId: "59" },
  { id: "1009", name: "Kwango", stateCode: "KG", countryId: "59" },
  { id: "1010", name: "Kwilu", stateCode: "KL", countryId: "59" },
  { id: "1011", name: "Lomami", stateCode: "LO", countryId: "59" },
  { id: "1012", name: "Lualaba", stateCode: "LU", countryId: "59" },
  { id: "1013", name: "Mai-Ndombe", stateCode: "MN", countryId: "59" },
  { id: "1014", name: "Maniema", stateCode: "MA", countryId: "59" },
  { id: "1015", name: "Mongala", stateCode: "MO", countryId: "59" },
  { id: "1016", name: "Nord-Kivu", stateCode: "NK", countryId: "59" },
  { id: "1017", name: "Nord-Ubangi", stateCode: "NU", countryId: "59" },
  { id: "1018", name: "Sankuru", stateCode: "SA", countryId: "59" },
  { id: "1019", name: "Sud-Kivu", stateCode: "SK", countryId: "59" },
  { id: "1020", name: "Sud-Ubangi", stateCode: "SU", countryId: "59" },
  { id: "1021", name: "Tanganyika", stateCode: "TA", countryId: "59" },
  { id: "1022", name: "Tshopo", stateCode: "TO", countryId: "59" },
  { id: "1023", name: "Tshuapa", stateCode: "TU", countryId: "59" },
  {
    id: "1024",
    name: "Capital Region of Denmark",
    stateCode: "84",
    countryId: "60",
  },
  {
    id: "1025",
    name: "Central Denmark Region",
    stateCode: "82",
    countryId: "60",
  },
  {
    id: "1026",
    name: "North Denmark Region",
    stateCode: "81",
    countryId: "60",
  },
  {
    id: "1027",
    name: "Region of Southern Denmark",
    stateCode: "83",
    countryId: "60",
  },
  { id: "1028", name: "Region Zealand", stateCode: "85", countryId: "60" },
  { id: "1029", name: "Ali Sabieh Region", stateCode: "AS", countryId: "61" },
  { id: "1030", name: "Arta Region", stateCode: "AR", countryId: "61" },
  { id: "1031", name: "Dikhil Region", stateCode: "DI", countryId: "61" },
  { id: "1032", name: "Djibouti", stateCode: "DJ", countryId: "61" },
  { id: "1033", name: "Obock Region", stateCode: "OB", countryId: "61" },
  { id: "1034", name: "Tadjourah Region", stateCode: "TA", countryId: "61" },
  { id: "1035", name: "Saint Andrew Parish", stateCode: "02", countryId: "62" },
  { id: "1036", name: "Saint David Parish", stateCode: "03", countryId: "62" },
  { id: "1037", name: "Saint George Parish", stateCode: "04", countryId: "62" },
  { id: "1038", name: "Saint John Parish", stateCode: "05", countryId: "62" },
  { id: "1039", name: "Saint Joseph Parish", stateCode: "06", countryId: "62" },
  { id: "1040", name: "Saint Luke Parish", stateCode: "07", countryId: "62" },
  { id: "1041", name: "Saint Mark Parish", stateCode: "08", countryId: "62" },
  {
    id: "1042",
    name: "Saint Patrick Parish",
    stateCode: "09",
    countryId: "62",
  },
  { id: "1043", name: "Saint Paul Parish", stateCode: "10", countryId: "62" },
  { id: "1044", name: "Saint Peter Parish", stateCode: "11", countryId: "62" },
  { id: "1045", name: "Azua Province", stateCode: "02", countryId: "63" },
  { id: "1046", name: "Baoruco Province", stateCode: "03", countryId: "63" },
  { id: "1047", name: "Barahona Province", stateCode: "04", countryId: "63" },
  { id: "1048", name: "Dajabón Province", stateCode: "05", countryId: "63" },
  { id: "1049", name: "Distrito Nacional", stateCode: "01", countryId: "63" },
  { id: "1050", name: "Duarte Province", stateCode: "06", countryId: "63" },
  { id: "1051", name: "El Seibo Province", stateCode: "08", countryId: "63" },
  { id: "1052", name: "Espaillat Province", stateCode: "09", countryId: "63" },
  { id: "1053", name: "Hato Mayor Province", stateCode: "30", countryId: "63" },
  {
    id: "1054",
    name: "Hermanas Mirabal Province",
    stateCode: "19",
    countryId: "63",
  },
  { id: "1055", name: "Independencia", stateCode: "10", countryId: "63" },
  {
    id: "1056",
    name: "La Altagracia Province",
    stateCode: "11",
    countryId: "63",
  },
  { id: "1057", name: "La Romana Province", stateCode: "12", countryId: "63" },
  { id: "1058", name: "La Vega Province", stateCode: "13", countryId: "63" },
  {
    id: "1059",
    name: "María Trinidad Sánchez Province",
    stateCode: "14",
    countryId: "63",
  },
  {
    id: "1060",
    name: "Monseñor Nouel Province",
    stateCode: "28",
    countryId: "63",
  },
  {
    id: "1061",
    name: "Monte Cristi Province",
    stateCode: "15",
    countryId: "63",
  },
  {
    id: "1062",
    name: "Monte Plata Province",
    stateCode: "29",
    countryId: "63",
  },
  { id: "1063", name: "Pedernales Province", stateCode: "16", countryId: "63" },
  { id: "1064", name: "Peravia Province", stateCode: "17", countryId: "63" },
  {
    id: "1065",
    name: "Puerto Plata Province",
    stateCode: "18",
    countryId: "63",
  },
  { id: "1066", name: "Samaná Province", stateCode: "20", countryId: "63" },
  {
    id: "1067",
    name: "San Cristóbal Province",
    stateCode: "21",
    countryId: "63",
  },
  {
    id: "1068",
    name: "San José de Ocoa Province",
    stateCode: "31",
    countryId: "63",
  },
  { id: "1069", name: "San Juan Province", stateCode: "22", countryId: "63" },
  {
    id: "1070",
    name: "San Pedro de Macorís",
    stateCode: "23",
    countryId: "63",
  },
  {
    id: "1071",
    name: "Sánchez Ramírez Province",
    stateCode: "24",
    countryId: "63",
  },
  { id: "1072", name: "Santiago Province", stateCode: "25", countryId: "63" },
  {
    id: "1073",
    name: "Santiago Rodríguez Province",
    stateCode: "26",
    countryId: "63",
  },
  {
    id: "1074",
    name: "Santo Domingo Province",
    stateCode: "32",
    countryId: "63",
  },
  { id: "1075", name: "Valverde Province", stateCode: "27", countryId: "63" },
  { id: "1076", name: "Aileu municipality", stateCode: "AL", countryId: "64" },
  { id: "1077", name: "Ainaro Municipality", stateCode: "AN", countryId: "64" },
  { id: "1078", name: "Baucau Municipality", stateCode: "BA", countryId: "64" },
  {
    id: "1079",
    name: "Bobonaro Municipality",
    stateCode: "BO",
    countryId: "64",
  },
  {
    id: "1080",
    name: "Cova Lima Municipality",
    stateCode: "CO",
    countryId: "64",
  },
  { id: "1081", name: "Dili municipality", stateCode: "DI", countryId: "64" },
  { id: "1082", name: "Ermera District", stateCode: "ER", countryId: "64" },
  { id: "1083", name: "Lautém Municipality", stateCode: "LA", countryId: "64" },
  {
    id: "1084",
    name: "Liquiçá Municipality",
    stateCode: "LI",
    countryId: "64",
  },
  { id: "1085", name: "Manatuto District", stateCode: "MT", countryId: "64" },
  {
    id: "1086",
    name: "Manufahi Municipality",
    stateCode: "MF",
    countryId: "64",
  },
  {
    id: "1087",
    name: "Viqueque Municipality",
    stateCode: "VI",
    countryId: "64",
  },
  { id: "1088", name: "Azuay", stateCode: "A", countryId: "65" },
  { id: "1089", name: "Bolívar", stateCode: "B", countryId: "65" },
  { id: "1090", name: "Cañar", stateCode: "F", countryId: "65" },
  { id: "1091", name: "Carchi", stateCode: "C", countryId: "65" },
  { id: "1092", name: "Chimborazo", stateCode: "H", countryId: "65" },
  { id: "1093", name: "Cotopaxi", stateCode: "X", countryId: "65" },
  { id: "1094", name: "El Oro", stateCode: "O", countryId: "65" },
  { id: "1095", name: "Esmeraldas", stateCode: "E", countryId: "65" },
  { id: "1096", name: "Galápagos", stateCode: "W", countryId: "65" },
  { id: "1097", name: "Guayas", stateCode: "G", countryId: "65" },
  { id: "1098", name: "Imbabura", stateCode: "I", countryId: "65" },
  { id: "1099", name: "Loja", stateCode: "L", countryId: "65" },
  { id: "1100", name: "Los Ríos", stateCode: "R", countryId: "65" },
  { id: "1101", name: "Manabí", stateCode: "M", countryId: "65" },
  { id: "1102", name: "Morona-Santiago", stateCode: "S", countryId: "65" },
  { id: "1103", name: "Napo", stateCode: "N", countryId: "65" },
  { id: "1104", name: "Orellana", stateCode: "D", countryId: "65" },
  { id: "1105", name: "Pastaza", stateCode: "Y", countryId: "65" },
  { id: "1106", name: "Pichincha", stateCode: "P", countryId: "65" },
  { id: "1107", name: "Santa Elena", stateCode: "SE", countryId: "65" },
  {
    id: "1108",
    name: "Santo Domingo de los Tsáchilas",
    stateCode: "SD",
    countryId: "65",
  },
  { id: "1109", name: "Sucumbíos", stateCode: "U", countryId: "65" },
  { id: "1110", name: "Tungurahua", stateCode: "T", countryId: "65" },
  { id: "1111", name: "Zamora Chinchipe", stateCode: "Z", countryId: "65" },
  { id: "1112", name: "Alexandria", stateCode: "ALX", countryId: "66" },
  { id: "1113", name: "Aswan", stateCode: "ASN", countryId: "66" },
  { id: "1114", name: "Asyut", stateCode: "AST", countryId: "66" },
  { id: "1115", name: "Beheira", stateCode: "BH", countryId: "66" },
  { id: "1116", name: "Beni Suef", stateCode: "BNS", countryId: "66" },
  { id: "1117", name: "Cairo", stateCode: "C", countryId: "66" },
  { id: "1118", name: "Dakahlia", stateCode: "DK", countryId: "66" },
  { id: "1119", name: "Damietta", stateCode: "DT", countryId: "66" },
  { id: "1120", name: "Faiyum", stateCode: "FYM", countryId: "66" },
  { id: "1121", name: "Gharbia", stateCode: "GH", countryId: "66" },
  { id: "1122", name: "Giza", stateCode: "GZ", countryId: "66" },
  { id: "1123", name: "Ismailia", stateCode: "IS", countryId: "66" },
  { id: "1124", name: "Kafr el-Sheikh", stateCode: "KFS", countryId: "66" },
  { id: "1125", name: "Luxor", stateCode: "LX", countryId: "66" },
  { id: "1126", name: "Matrouh", stateCode: "MT", countryId: "66" },
  { id: "1127", name: "Minya", stateCode: "MN", countryId: "66" },
  { id: "1128", name: "Monufia", stateCode: "MNF", countryId: "66" },
  { id: "1129", name: "New Valley", stateCode: "WAD", countryId: "66" },
  { id: "1130", name: "North Sinai", stateCode: "SIN", countryId: "66" },
  { id: "1131", name: "Port Said", stateCode: "PTS", countryId: "66" },
  { id: "1132", name: "Qalyubia", stateCode: "KB", countryId: "66" },
  { id: "1133", name: "Qena", stateCode: "KN", countryId: "66" },
  { id: "1134", name: "Red Sea", stateCode: "BA", countryId: "66" },
  { id: "1135", name: "Sharqia", stateCode: "SHR", countryId: "66" },
  { id: "1136", name: "Sohag", stateCode: "SHG", countryId: "66" },
  { id: "1137", name: "South Sinai", stateCode: "JS", countryId: "66" },
  { id: "1138", name: "Suez", stateCode: "SUZ", countryId: "66" },
  {
    id: "1139",
    name: "Ahuachapán Department",
    stateCode: "AH",
    countryId: "67",
  },
  { id: "1140", name: "Cabañas Department", stateCode: "CA", countryId: "67" },
  {
    id: "1141",
    name: "Chalatenango Department",
    stateCode: "CH",
    countryId: "67",
  },
  {
    id: "1142",
    name: "Cuscatlán Department",
    stateCode: "CU",
    countryId: "67",
  },
  {
    id: "1143",
    name: "La Libertad Department",
    stateCode: "LI",
    countryId: "67",
  },
  { id: "1144", name: "La Paz Department", stateCode: "PA", countryId: "67" },
  { id: "1145", name: "La Unión Department", stateCode: "UN", countryId: "67" },
  { id: "1146", name: "Morazán Department", stateCode: "MO", countryId: "67" },
  {
    id: "1147",
    name: "San Miguel Department",
    stateCode: "SM",
    countryId: "67",
  },
  {
    id: "1148",
    name: "San Salvador Department",
    stateCode: "SS",
    countryId: "67",
  },
  {
    id: "1149",
    name: "San Vicente Department",
    stateCode: "SV",
    countryId: "67",
  },
  {
    id: "1150",
    name: "Santa Ana Department",
    stateCode: "SA",
    countryId: "67",
  },
  {
    id: "1151",
    name: "Sonsonate Department",
    stateCode: "SO",
    countryId: "67",
  },
  { id: "1152", name: "Usulután Department", stateCode: "US", countryId: "67" },
  { id: "1153", name: "Annobón Province", stateCode: "AN", countryId: "68" },
  {
    id: "1154",
    name: "Bioko Norte Province",
    stateCode: "BN",
    countryId: "68",
  },
  { id: "1155", name: "Bioko Sur Province", stateCode: "BS", countryId: "68" },
  { id: "1156", name: "Centro Sur Province", stateCode: "CS", countryId: "68" },
  { id: "1157", name: "Insular Region", stateCode: "I", countryId: "68" },
  { id: "1158", name: "Kié-Ntem Province", stateCode: "KN", countryId: "68" },
  { id: "1159", name: "Litoral Province", stateCode: "LI", countryId: "68" },
  { id: "1160", name: "Río Muni", stateCode: "C", countryId: "68" },
  { id: "1161", name: "Wele-Nzas Province", stateCode: "WN", countryId: "68" },
  { id: "1162", name: "Anseba Region", stateCode: "AN", countryId: "69" },
  { id: "1163", name: "Debub Region", stateCode: "DU", countryId: "69" },
  { id: "1164", name: "Gash-Barka Region", stateCode: "GB", countryId: "69" },
  { id: "1165", name: "Maekel Region", stateCode: "MA", countryId: "69" },
  {
    id: "1166",
    name: "Northern Red Sea Region",
    stateCode: "SK",
    countryId: "69",
  },
  {
    id: "1167",
    name: "Southern Red Sea Region",
    stateCode: "DK",
    countryId: "69",
  },
  { id: "1168", name: "Harju County", stateCode: "37", countryId: "70" },
  { id: "1169", name: "Hiiu County", stateCode: "39", countryId: "70" },
  { id: "1170", name: "Ida-Viru County", stateCode: "44", countryId: "70" },
  { id: "1171", name: "Järva County", stateCode: "51", countryId: "70" },
  { id: "1172", name: "Jõgeva County", stateCode: "49", countryId: "70" },
  { id: "1173", name: "Lääne County", stateCode: "57", countryId: "70" },
  { id: "1174", name: "Lääne-Viru County", stateCode: "59", countryId: "70" },
  { id: "1175", name: "Pärnu County", stateCode: "67", countryId: "70" },
  { id: "1176", name: "Põlva County", stateCode: "65", countryId: "70" },
  { id: "1177", name: "Rapla County", stateCode: "70", countryId: "70" },
  { id: "1178", name: "Saare County", stateCode: "74", countryId: "70" },
  { id: "1179", name: "Tartu County", stateCode: "78", countryId: "70" },
  { id: "1180", name: "Valga County", stateCode: "82", countryId: "70" },
  { id: "1181", name: "Viljandi County", stateCode: "84", countryId: "70" },
  { id: "1182", name: "Võru County", stateCode: "86", countryId: "70" },
  { id: "1183", name: "Addis Ababa", stateCode: "AA", countryId: "71" },
  { id: "1184", name: "Afar Region", stateCode: "AF", countryId: "71" },
  { id: "1185", name: "Amhara Region", stateCode: "AM", countryId: "71" },
  {
    id: "1186",
    name: "Benishangul-Gumuz Region",
    stateCode: "BE",
    countryId: "71",
  },
  { id: "1187", name: "Dire Dawa", stateCode: "DD", countryId: "71" },
  { id: "1188", name: "Gambela Region", stateCode: "GA", countryId: "71" },
  { id: "1189", name: "Harari Region", stateCode: "HA", countryId: "71" },
  { id: "1190", name: "Oromia Region", stateCode: "OR", countryId: "71" },
  { id: "1191", name: "Somali Region", stateCode: "SO", countryId: "71" },
  {
    id: "1192",
    name: "Southern Nations, Nationalities, and Peoples\\' Region",
    stateCode: "SN",
    countryId: "71",
  },
  { id: "1193", name: "Tigray Region", stateCode: "TI", countryId: "71" },
  { id: "1194", name: "Ba", stateCode: "01", countryId: "74" },
  { id: "1195", name: "Bua", stateCode: "02", countryId: "74" },
  { id: "1196", name: "Cakaudrove", stateCode: "03", countryId: "74" },
  { id: "1197", name: "Central Division", stateCode: "C", countryId: "74" },
  { id: "1198", name: "Eastern Division", stateCode: "E", countryId: "74" },
  { id: "1199", name: "Kadavu", stateCode: "04", countryId: "74" },
  { id: "1200", name: "Lau", stateCode: "05", countryId: "74" },
  { id: "1201", name: "Lomaiviti", stateCode: "06", countryId: "74" },
  { id: "1202", name: "Macuata", stateCode: "07", countryId: "74" },
  { id: "1203", name: "Nadroga-Navosa", stateCode: "08", countryId: "74" },
  { id: "1204", name: "Naitasiri", stateCode: "09", countryId: "74" },
  { id: "1205", name: "Namosi", stateCode: "10", countryId: "74" },
  { id: "1206", name: "Northern Division", stateCode: "N", countryId: "74" },
  { id: "1207", name: "Ra", stateCode: "11", countryId: "74" },
  { id: "1208", name: "Rewa", stateCode: "12", countryId: "74" },
  { id: "1209", name: "Rotuma", stateCode: "R", countryId: "74" },
  { id: "1210", name: "Serua", stateCode: "13", countryId: "74" },
  { id: "1211", name: "Tailevu", stateCode: "14", countryId: "74" },
  { id: "1212", name: "Western Division", stateCode: "W", countryId: "74" },
  { id: "1213", name: "Åland Islands", stateCode: "01", countryId: "75" },
  { id: "1214", name: "Central Finland", stateCode: "08", countryId: "75" },
  {
    id: "1215",
    name: "Central Ostrobothnia",
    stateCode: "07",
    countryId: "75",
  },
  { id: "1216", name: "Finland Proper", stateCode: "19", countryId: "75" },
  { id: "1217", name: "Kainuu", stateCode: "05", countryId: "75" },
  { id: "1218", name: "Kymenlaakso", stateCode: "09", countryId: "75" },
  { id: "1219", name: "Lapland", stateCode: "10", countryId: "75" },
  { id: "1220", name: "North Karelia", stateCode: "13", countryId: "75" },
  {
    id: "1221",
    name: "Northern Ostrobothnia",
    stateCode: "14",
    countryId: "75",
  },
  { id: "1222", name: "Northern Savonia", stateCode: "15", countryId: "75" },
  { id: "1223", name: "Ostrobothnia", stateCode: "12", countryId: "75" },
  { id: "1224", name: "Päijänne Tavastia", stateCode: "16", countryId: "75" },
  { id: "1225", name: "Pirkanmaa", stateCode: "11", countryId: "75" },
  { id: "1226", name: "Satakunta", stateCode: "17", countryId: "75" },
  { id: "1227", name: "South Karelia", stateCode: "02", countryId: "75" },
  {
    id: "1228",
    name: "Southern Ostrobothnia",
    stateCode: "03",
    countryId: "75",
  },
  { id: "1229", name: "Southern Savonia", stateCode: "04", countryId: "75" },
  { id: "1230", name: "Tavastia Proper", stateCode: "06", countryId: "75" },
  { id: "1231", name: "Uusimaa", stateCode: "18", countryId: "75" },
  { id: "1232", name: "Ain", stateCode: "01", countryId: "76" },
  { id: "1233", name: "Aisne", stateCode: "02", countryId: "76" },
  { id: "1234", name: "Allier", stateCode: "03", countryId: "76" },
  {
    id: "1235",
    name: "Alpes-de-Haute-Provence",
    stateCode: "04",
    countryId: "76",
  },
  { id: "1236", name: "Alpes-Maritimes", stateCode: "06", countryId: "76" },
  { id: "1237", name: "Alsace", stateCode: "6AE", countryId: "76" },
  { id: "1238", name: "Ardèche", stateCode: "07", countryId: "76" },
  { id: "1239", name: "Ardennes", stateCode: "08", countryId: "76" },
  { id: "1240", name: "Ariège", stateCode: "09", countryId: "76" },
  { id: "1241", name: "Aube", stateCode: "10", countryId: "76" },
  { id: "1242", name: "Aude", stateCode: "11", countryId: "76" },
  {
    id: "1243",
    name: "Auvergne-Rhône-Alpes",
    stateCode: "ARA",
    countryId: "76",
  },
  { id: "1244", name: "Aveyron", stateCode: "12", countryId: "76" },
  { id: "1245", name: "Bas-Rhin", stateCode: "67", countryId: "76" },
  { id: "1246", name: "Bouches-du-Rhône", stateCode: "13", countryId: "76" },
  {
    id: "1247",
    name: "Bourgogne-Franche-Comté",
    stateCode: "BFC",
    countryId: "76",
  },
  { id: "1248", name: "Bretagne", stateCode: "BRE", countryId: "76" },
  { id: "1249", name: "Calvados", stateCode: "14", countryId: "76" },
  { id: "1250", name: "Cantal", stateCode: "15", countryId: "76" },
  {
    id: "1251",
    name: "Centre-Val de Loire",
    stateCode: "CVL",
    countryId: "76",
  },
  { id: "1252", name: "Charente", stateCode: "16", countryId: "76" },
  { id: "1253", name: "Charente-Maritime", stateCode: "17", countryId: "76" },
  { id: "1254", name: "Cher", stateCode: "18", countryId: "76" },
  { id: "1255", name: "Clipperton", stateCode: "CP", countryId: "76" },
  { id: "1256", name: "Corrèze", stateCode: "19", countryId: "76" },
  { id: "1257", name: "Corse", stateCode: "20R", countryId: "76" },
  { id: "1258", name: "Corse-du-Sud", stateCode: "2A", countryId: "76" },
  { id: "1259", name: "Côte-d\\'Or", stateCode: "21", countryId: "76" },
  { id: "1260", name: "Côtes-d\\'Armor", stateCode: "22", countryId: "76" },
  { id: "1261", name: "Creuse", stateCode: "23", countryId: "76" },
  { id: "1262", name: "Deux-Sèvres", stateCode: "79", countryId: "76" },
  { id: "1263", name: "Dordogne", stateCode: "24", countryId: "76" },
  { id: "1264", name: "Doubs", stateCode: "25", countryId: "76" },
  { id: "1265", name: "Drôme", stateCode: "26", countryId: "76" },
  { id: "1266", name: "Essonne", stateCode: "91", countryId: "76" },
  { id: "1267", name: "Eure", stateCode: "27", countryId: "76" },
  { id: "1268", name: "Eure-et-Loir", stateCode: "28", countryId: "76" },
  { id: "1269", name: "Finistère", stateCode: "29", countryId: "76" },
  { id: "1270", name: "French Guiana", stateCode: "973", countryId: "76" },
  { id: "1271", name: "French Polynesia", stateCode: "PF", countryId: "76" },
  {
    id: "1272",
    name: "French Southern and Antarctic Lands",
    stateCode: "TF",
    countryId: "76",
  },
  { id: "1273", name: "Gard", stateCode: "30", countryId: "76" },
  { id: "1274", name: "Gers", stateCode: "32", countryId: "76" },
  { id: "1275", name: "Gironde", stateCode: "33", countryId: "76" },
  { id: "1276", name: "Grand-Est", stateCode: "GES", countryId: "76" },
  { id: "1277", name: "Guadeloupe", stateCode: "971", countryId: "76" },
  { id: "1278", name: "Haut-Rhin", stateCode: "68", countryId: "76" },
  { id: "1279", name: "Haute-Corse", stateCode: "2B", countryId: "76" },
  { id: "1280", name: "Haute-Garonne", stateCode: "31", countryId: "76" },
  { id: "1281", name: "Haute-Loire", stateCode: "43", countryId: "76" },
  { id: "1282", name: "Haute-Marne", stateCode: "52", countryId: "76" },
  { id: "1283", name: "Haute-Saône", stateCode: "70", countryId: "76" },
  { id: "1284", name: "Haute-Savoie", stateCode: "74", countryId: "76" },
  { id: "1285", name: "Haute-Vienne", stateCode: "87", countryId: "76" },
  { id: "1286", name: "Hautes-Alpes", stateCode: "05", countryId: "76" },
  { id: "1287", name: "Hautes-Pyrénées", stateCode: "65", countryId: "76" },
  { id: "1288", name: "Hauts-de-France", stateCode: "HDF", countryId: "76" },
  { id: "1289", name: "Hauts-de-Seine", stateCode: "92", countryId: "76" },
  { id: "1290", name: "Hérault", stateCode: "34", countryId: "76" },
  { id: "1291", name: "Île-de-France", stateCode: "IDF", countryId: "76" },
  { id: "1292", name: "Ille-et-Vilaine", stateCode: "35", countryId: "76" },
  { id: "1293", name: "Indre", stateCode: "36", countryId: "76" },
  { id: "1294", name: "Indre-et-Loire", stateCode: "37", countryId: "76" },
  { id: "1295", name: "Isère", stateCode: "38", countryId: "76" },
  { id: "1296", name: "Jura", stateCode: "39", countryId: "76" },
  { id: "1297", name: "La Réunion", stateCode: "974", countryId: "76" },
  { id: "1298", name: "Landes", stateCode: "40", countryId: "76" },
  { id: "1299", name: "Loir-et-Cher", stateCode: "41", countryId: "76" },
  { id: "1300", name: "Loire", stateCode: "42", countryId: "76" },
  { id: "1301", name: "Loire-Atlantique", stateCode: "44", countryId: "76" },
  { id: "1302", name: "Loiret", stateCode: "45", countryId: "76" },
  { id: "1303", name: "Lot", stateCode: "46", countryId: "76" },
  { id: "1304", name: "Lot-et-Garonne", stateCode: "47", countryId: "76" },
  { id: "1305", name: "Lozère", stateCode: "48", countryId: "76" },
  { id: "1306", name: "Maine-et-Loire", stateCode: "49", countryId: "76" },
  { id: "1307", name: "Manche", stateCode: "50", countryId: "76" },
  { id: "1308", name: "Marne", stateCode: "51", countryId: "76" },
  { id: "1309", name: "Martinique", stateCode: "972", countryId: "76" },
  { id: "1310", name: "Mayenne", stateCode: "53", countryId: "76" },
  { id: "1311", name: "Mayotte", stateCode: "976", countryId: "76" },
  { id: "1312", name: "Métropole de Lyon", stateCode: "69M", countryId: "76" },
  { id: "1313", name: "Meurthe-et-Moselle", stateCode: "54", countryId: "76" },
  { id: "1314", name: "Meuse", stateCode: "55", countryId: "76" },
  { id: "1315", name: "Morbihan", stateCode: "56", countryId: "76" },
  { id: "1316", name: "Moselle", stateCode: "57", countryId: "76" },
  { id: "1317", name: "Nièvre", stateCode: "58", countryId: "76" },
  { id: "1318", name: "Nord", stateCode: "59", countryId: "76" },
  { id: "1319", name: "Normandie", stateCode: "NOR", countryId: "76" },
  { id: "1320", name: "Nouvelle-Aquitaine", stateCode: "NAQ", countryId: "76" },
  { id: "1321", name: "Occitanie", stateCode: "OCC", countryId: "76" },
  { id: "1322", name: "Oise", stateCode: "60", countryId: "76" },
  { id: "1323", name: "Orne", stateCode: "61", countryId: "76" },
  { id: "1324", name: "Paris", stateCode: "75C", countryId: "76" },
  { id: "1325", name: "Pas-de-Calais", stateCode: "62", countryId: "76" },
  { id: "1326", name: "Pays-de-la-Loire", stateCode: "PDL", countryId: "76" },
  {
    id: "1327",
    name: "Provence-Alpes-Côte-d’Azur",
    stateCode: "PAC",
    countryId: "76",
  },
  { id: "1328", name: "Puy-de-Dôme", stateCode: "63", countryId: "76" },
  {
    id: "1329",
    name: "Pyrénées-Atlantiques",
    stateCode: "64",
    countryId: "76",
  },
  { id: "1330", name: "Pyrénées-Orientales", stateCode: "66", countryId: "76" },
  { id: "1331", name: "Rhône", stateCode: "69", countryId: "76" },
  {
    id: "1332",
    name: "Saint Pierre and Miquelon",
    stateCode: "PM",
    countryId: "76",
  },
  { id: "1333", name: "Saint-Barthélemy", stateCode: "BL", countryId: "76" },
  { id: "1334", name: "Saint-Martin", stateCode: "MF", countryId: "76" },
  { id: "1335", name: "Saône-et-Loire", stateCode: "71", countryId: "76" },
  { id: "1336", name: "Sarthe", stateCode: "72", countryId: "76" },
  { id: "1337", name: "Savoie", stateCode: "73", countryId: "76" },
  { id: "1338", name: "Seine-et-Marne", stateCode: "77", countryId: "76" },
  { id: "1339", name: "Seine-Maritime", stateCode: "76", countryId: "76" },
  { id: "1340", name: "Seine-Saint-Denis", stateCode: "93", countryId: "76" },
  { id: "1341", name: "Somme", stateCode: "80", countryId: "76" },
  { id: "1342", name: "Tarn", stateCode: "81", countryId: "76" },
  { id: "1343", name: "Tarn-et-Garonne", stateCode: "82", countryId: "76" },
  {
    id: "1344",
    name: "Territoire de Belfort",
    stateCode: "90",
    countryId: "76",
  },
  { id: "1345", name: "Val-d\\'Oise", stateCode: "95", countryId: "76" },
  { id: "1346", name: "Val-de-Marne", stateCode: "94", countryId: "76" },
  { id: "1347", name: "Var", stateCode: "83", countryId: "76" },
  { id: "1348", name: "Vaucluse", stateCode: "84", countryId: "76" },
  { id: "1349", name: "Vendée", stateCode: "85", countryId: "76" },
  { id: "1350", name: "Vienne", stateCode: "86", countryId: "76" },
  { id: "1351", name: "Vosges", stateCode: "88", countryId: "76" },
  { id: "1352", name: "Wallis and Futuna", stateCode: "WF", countryId: "76" },
  { id: "1353", name: "Yonne", stateCode: "89", countryId: "76" },
  { id: "1354", name: "Yvelines", stateCode: "78", countryId: "76" },
  { id: "1355", name: "Estuaire Province", stateCode: "1", countryId: "80" },
  { id: "1356", name: "Haut-Ogooué Province", stateCode: "2", countryId: "80" },
  {
    id: "1357",
    name: "Moyen-Ogooué Province",
    stateCode: "3",
    countryId: "80",
  },
  { id: "1358", name: "Ngounié Province", stateCode: "4", countryId: "80" },
  { id: "1359", name: "Nyanga Province", stateCode: "5", countryId: "80" },
  {
    id: "1360",
    name: "Ogooué-Ivindo Province",
    stateCode: "6",
    countryId: "80",
  },
  { id: "1361", name: "Ogooué-Lolo Province", stateCode: "7", countryId: "80" },
  {
    id: "1362",
    name: "Ogooué-Maritime Province",
    stateCode: "8",
    countryId: "80",
  },
  { id: "1363", name: "Woleu-Ntem Province", stateCode: "9", countryId: "80" },
  { id: "1364", name: "Banjul", stateCode: "B", countryId: "81" },
  {
    id: "1365",
    name: "Central River Division",
    stateCode: "M",
    countryId: "81",
  },
  { id: "1366", name: "Lower River Division", stateCode: "L", countryId: "81" },
  { id: "1367", name: "North Bank Division", stateCode: "N", countryId: "81" },
  { id: "1368", name: "Upper River Division", stateCode: "U", countryId: "81" },
  { id: "1369", name: "West Coast Division", stateCode: "W", countryId: "81" },
  { id: "1370", name: "Adjara", stateCode: "AJ", countryId: "82" },
  {
    id: "1371",
    name: "Autonomous Republic of Abkhazia",
    stateCode: "AB",
    countryId: "82",
  },
  { id: "1372", name: "Guria", stateCode: "GU", countryId: "82" },
  { id: "1373", name: "Imereti", stateCode: "IM", countryId: "82" },
  { id: "1374", name: "Kakheti", stateCode: "KA", countryId: "82" },
  {
    id: "1375",
    name: "Khelvachauri Municipality",
    stateCode: "29",
    countryId: "82",
  },
  { id: "1376", name: "Kvemo Kartli", stateCode: "KK", countryId: "82" },
  { id: "1377", name: "Mtskheta-Mtianeti", stateCode: "MM", countryId: "82" },
  {
    id: "1378",
    name: "Racha-Lechkhumi and Kvemo Svaneti",
    stateCode: "RL",
    countryId: "82",
  },
  {
    id: "1379",
    name: "Samegrelo-Zemo Svaneti",
    stateCode: "SZ",
    countryId: "82",
  },
  { id: "1380", name: "Samtskhe-Javakheti", stateCode: "SJ", countryId: "82" },
  { id: "1381", name: "Senaki Municipality", stateCode: "50", countryId: "82" },
  { id: "1382", name: "Shida Kartli", stateCode: "SK", countryId: "82" },
  { id: "1383", name: "Tbilisi", stateCode: "TB", countryId: "82" },
  { id: "1384", name: "Baden-Württemberg", stateCode: "BW", countryId: "83" },
  { id: "1385", name: "Bavaria", stateCode: "BY", countryId: "83" },
  { id: "1386", name: "Berlin", stateCode: "BE", countryId: "83" },
  { id: "1387", name: "Brandenburg", stateCode: "BB", countryId: "83" },
  { id: "1388", name: "Bremen", stateCode: "HB", countryId: "83" },
  { id: "1389", name: "Hamburg", stateCode: "HH", countryId: "83" },
  { id: "1390", name: "Hesse", stateCode: "HE", countryId: "83" },
  { id: "1391", name: "Lower Saxony", stateCode: "NI", countryId: "83" },
  {
    id: "1392",
    name: "Mecklenburg-Vorpommern",
    stateCode: "MV",
    countryId: "83",
  },
  {
    id: "1393",
    name: "North Rhine-Westphalia",
    stateCode: "NW",
    countryId: "83",
  },
  {
    id: "1394",
    name: "Rhineland-Palatinate",
    stateCode: "RP",
    countryId: "83",
  },
  { id: "1395", name: "Saarland", stateCode: "SL", countryId: "83" },
  { id: "1396", name: "Saxony", stateCode: "SN", countryId: "83" },
  { id: "1397", name: "Saxony-Anhalt", stateCode: "ST", countryId: "83" },
  { id: "1398", name: "Schleswig-Holstein", stateCode: "SH", countryId: "83" },
  { id: "1399", name: "Thuringia", stateCode: "TH", countryId: "83" },
  { id: "1400", name: "Ahafo", stateCode: "AF", countryId: "84" },
  { id: "1401", name: "Ashanti", stateCode: "AH", countryId: "84" },
  { id: "1402", name: "Bono", stateCode: "BO", countryId: "84" },
  { id: "1403", name: "Bono East", stateCode: "BE", countryId: "84" },
  { id: "1404", name: "Central", stateCode: "CP", countryId: "84" },
  { id: "1405", name: "Eastern", stateCode: "EP", countryId: "84" },
  { id: "1406", name: "Greater Accra", stateCode: "AA", countryId: "84" },
  { id: "1407", name: "North East", stateCode: "NE", countryId: "84" },
  { id: "1408", name: "Northern", stateCode: "NP", countryId: "84" },
  { id: "1409", name: "Oti", stateCode: "OT", countryId: "84" },
  { id: "1410", name: "Savannah", stateCode: "SV", countryId: "84" },
  { id: "1411", name: "Upper East", stateCode: "UE", countryId: "84" },
  { id: "1412", name: "Upper West", stateCode: "UW", countryId: "84" },
  { id: "1413", name: "Volta", stateCode: "TV", countryId: "84" },
  { id: "1414", name: "Western", stateCode: "WP", countryId: "84" },
  { id: "1415", name: "Western North", stateCode: "WN", countryId: "84" },
  {
    id: "1416",
    name: "Achaea Regional Unit",
    stateCode: "13",
    countryId: "86",
  },
  {
    id: "1417",
    name: "Aetolia-Acarnania Regional Unit",
    stateCode: "01",
    countryId: "86",
  },
  { id: "1418", name: "Arcadia Prefecture", stateCode: "12", countryId: "86" },
  {
    id: "1419",
    name: "Argolis Regional Unit",
    stateCode: "11",
    countryId: "86",
  },
  { id: "1420", name: "Attica Region", stateCode: "I", countryId: "86" },
  {
    id: "1421",
    name: "Boeotia Regional Unit",
    stateCode: "03",
    countryId: "86",
  },
  {
    id: "1422",
    name: "Central Greece Region",
    stateCode: "H",
    countryId: "86",
  },
  { id: "1423", name: "Central Macedonia", stateCode: "B", countryId: "86" },
  {
    id: "1424",
    name: "Chania Regional Unit",
    stateCode: "94",
    countryId: "86",
  },
  { id: "1425", name: "Corfu Prefecture", stateCode: "22", countryId: "86" },
  {
    id: "1426",
    name: "Corinthia Regional Unit",
    stateCode: "15",
    countryId: "86",
  },
  { id: "1427", name: "Crete Region", stateCode: "M", countryId: "86" },
  { id: "1428", name: "Drama Regional Unit", stateCode: "52", countryId: "86" },
  {
    id: "1429",
    name: "East Attica Regional Unit",
    stateCode: "A2",
    countryId: "86",
  },
  {
    id: "1430",
    name: "East Macedonia and Thrace",
    stateCode: "A",
    countryId: "86",
  },
  { id: "1431", name: "Epirus Region", stateCode: "D", countryId: "86" },
  { id: "1432", name: "Euboea", stateCode: "04", countryId: "86" },
  { id: "1433", name: "Grevena Prefecture", stateCode: "51", countryId: "86" },
  {
    id: "1434",
    name: "Imathia Regional Unit",
    stateCode: "53",
    countryId: "86",
  },
  {
    id: "1435",
    name: "Ioannina Regional Unit",
    stateCode: "33",
    countryId: "86",
  },
  {
    id: "1436",
    name: "Ionian Islands Region",
    stateCode: "F",
    countryId: "86",
  },
  {
    id: "1437",
    name: "Karditsa Regional Unit",
    stateCode: "41",
    countryId: "86",
  },
  {
    id: "1438",
    name: "Kastoria Regional Unit",
    stateCode: "56",
    countryId: "86",
  },
  {
    id: "1439",
    name: "Kefalonia Prefecture",
    stateCode: "23",
    countryId: "86",
  },
  {
    id: "1440",
    name: "Kilkis Regional Unit",
    stateCode: "57",
    countryId: "86",
  },
  { id: "1441", name: "Kozani Prefecture", stateCode: "58", countryId: "86" },
  { id: "1442", name: "Laconia", stateCode: "16", countryId: "86" },
  { id: "1443", name: "Larissa Prefecture", stateCode: "42", countryId: "86" },
  {
    id: "1444",
    name: "Lefkada Regional Unit",
    stateCode: "24",
    countryId: "86",
  },
  { id: "1445", name: "Pella Regional Unit", stateCode: "59", countryId: "86" },
  { id: "1446", name: "Peloponnese Region", stateCode: "J", countryId: "86" },
  {
    id: "1447",
    name: "Phthiotis Prefecture",
    stateCode: "06",
    countryId: "86",
  },
  { id: "1448", name: "Preveza Prefecture", stateCode: "34", countryId: "86" },
  { id: "1449", name: "Serres Prefecture", stateCode: "62", countryId: "86" },
  { id: "1450", name: "South Aegean", stateCode: "L", countryId: "86" },
  {
    id: "1451",
    name: "Thessaloniki Regional Unit",
    stateCode: "54",
    countryId: "86",
  },
  { id: "1452", name: "West Greece Region", stateCode: "G", countryId: "86" },
  {
    id: "1453",
    name: "West Macedonia Region",
    stateCode: "C",
    countryId: "86",
  },
  {
    id: "1454",
    name: "Carriacou and Petite Martinique",
    stateCode: "10",
    countryId: "88",
  },
  { id: "1455", name: "Saint Andrew Parish", stateCode: "01", countryId: "88" },
  { id: "1456", name: "Saint David Parish", stateCode: "02", countryId: "88" },
  { id: "1457", name: "Saint George Parish", stateCode: "03", countryId: "88" },
  { id: "1458", name: "Saint John Parish", stateCode: "04", countryId: "88" },
  { id: "1459", name: "Saint Mark Parish", stateCode: "05", countryId: "88" },
  {
    id: "1460",
    name: "Saint Patrick Parish",
    stateCode: "06",
    countryId: "88",
  },
  {
    id: "1461",
    name: "Alta Verapaz Department",
    stateCode: "AV",
    countryId: "91",
  },
  {
    id: "1462",
    name: "Baja Verapaz Department",
    stateCode: "BV",
    countryId: "91",
  },
  {
    id: "1463",
    name: "Chimaltenango Department",
    stateCode: "CM",
    countryId: "91",
  },
  {
    id: "1464",
    name: "Chiquimula Department",
    stateCode: "CQ",
    countryId: "91",
  },
  {
    id: "1465",
    name: "El Progreso Department",
    stateCode: "PR",
    countryId: "91",
  },
  {
    id: "1466",
    name: "Escuintla Department",
    stateCode: "ES",
    countryId: "91",
  },
  {
    id: "1467",
    name: "Guatemala Department",
    stateCode: "GU",
    countryId: "91",
  },
  {
    id: "1468",
    name: "Huehuetenango Department",
    stateCode: "HU",
    countryId: "91",
  },
  { id: "1469", name: "Izabal Department", stateCode: "IZ", countryId: "91" },
  { id: "1470", name: "Jalapa Department", stateCode: "JA", countryId: "91" },
  { id: "1471", name: "Jutiapa Department", stateCode: "JU", countryId: "91" },
  { id: "1472", name: "Petén Department", stateCode: "PE", countryId: "91" },
  {
    id: "1473",
    name: "Quetzaltenango Department",
    stateCode: "QZ",
    countryId: "91",
  },
  { id: "1474", name: "Quiché Department", stateCode: "QC", countryId: "91" },
  {
    id: "1475",
    name: "Retalhuleu Department",
    stateCode: "RE",
    countryId: "91",
  },
  {
    id: "1476",
    name: "Sacatepéquez Department",
    stateCode: "SA",
    countryId: "91",
  },
  {
    id: "1477",
    name: "San Marcos Department",
    stateCode: "SM",
    countryId: "91",
  },
  {
    id: "1478",
    name: "Santa Rosa Department",
    stateCode: "SR",
    countryId: "91",
  },
  { id: "1479", name: "Sololá Department", stateCode: "SO", countryId: "91" },
  {
    id: "1480",
    name: "Suchitepéquez Department",
    stateCode: "SU",
    countryId: "91",
  },
  {
    id: "1481",
    name: "Totonicapán Department",
    stateCode: "TO",
    countryId: "91",
  },
  { id: "1482", name: "Beyla Prefecture", stateCode: "BE", countryId: "93" },
  { id: "1483", name: "Boffa Prefecture", stateCode: "BF", countryId: "93" },
  { id: "1484", name: "Boké Prefecture", stateCode: "BK", countryId: "93" },
  { id: "1485", name: "Boké Region", stateCode: "B", countryId: "93" },
  { id: "1486", name: "Conakry", stateCode: "C", countryId: "93" },
  { id: "1487", name: "Coyah Prefecture", stateCode: "CO", countryId: "93" },
  { id: "1488", name: "Dabola Prefecture", stateCode: "DB", countryId: "93" },
  { id: "1489", name: "Dalaba Prefecture", stateCode: "DL", countryId: "93" },
  {
    id: "1490",
    name: "Dinguiraye Prefecture",
    stateCode: "DI",
    countryId: "93",
  },
  { id: "1491", name: "Dubréka Prefecture", stateCode: "DU", countryId: "93" },
  { id: "1492", name: "Faranah Prefecture", stateCode: "FA", countryId: "93" },
  {
    id: "1493",
    name: "Forécariah Prefecture",
    stateCode: "FO",
    countryId: "93",
  },
  { id: "1494", name: "Fria Prefecture", stateCode: "FR", countryId: "93" },
  { id: "1495", name: "Gaoual Prefecture", stateCode: "GA", countryId: "93" },
  {
    id: "1496",
    name: "Guéckédou Prefecture",
    stateCode: "GU",
    countryId: "93",
  },
  { id: "1497", name: "Kankan Prefecture", stateCode: "KA", countryId: "93" },
  { id: "1498", name: "Kankan Region", stateCode: "K", countryId: "93" },
  { id: "1499", name: "Kérouané Prefecture", stateCode: "KE", countryId: "93" },
  { id: "1500", name: "Kindia Prefecture", stateCode: "KD", countryId: "93" },
  { id: "1501", name: "Kindia Region", stateCode: "D", countryId: "93" },
  {
    id: "1502",
    name: "Kissidougou Prefecture",
    stateCode: "KS",
    countryId: "93",
  },
  { id: "1503", name: "Koubia Prefecture", stateCode: "KB", countryId: "93" },
  { id: "1504", name: "Koundara Prefecture", stateCode: "KN", countryId: "93" },
  {
    id: "1505",
    name: "Kouroussa Prefecture",
    stateCode: "KO",
    countryId: "93",
  },
  { id: "1506", name: "Labé Prefecture", stateCode: "LA", countryId: "93" },
  { id: "1507", name: "Labé Region", stateCode: "L", countryId: "93" },
  { id: "1508", name: "Lélouma Prefecture", stateCode: "LE", countryId: "93" },
  { id: "1509", name: "Lola Prefecture", stateCode: "LO", countryId: "93" },
  { id: "1510", name: "Macenta Prefecture", stateCode: "MC", countryId: "93" },
  { id: "1511", name: "Mali Prefecture", stateCode: "ML", countryId: "93" },
  { id: "1512", name: "Mamou Prefecture", stateCode: "MM", countryId: "93" },
  { id: "1513", name: "Mamou Region", stateCode: "M", countryId: "93" },
  { id: "1514", name: "Mandiana Prefecture", stateCode: "MD", countryId: "93" },
  {
    id: "1515",
    name: "Nzérékoré Prefecture",
    stateCode: "NZ",
    countryId: "93",
  },
  { id: "1516", name: "Nzérékoré Region", stateCode: "N", countryId: "93" },
  { id: "1517", name: "Pita Prefecture", stateCode: "PI", countryId: "93" },
  { id: "1518", name: "Siguiri Prefecture", stateCode: "SI", countryId: "93" },
  { id: "1519", name: "Télimélé Prefecture", stateCode: "TE", countryId: "93" },
  { id: "1520", name: "Tougué Prefecture", stateCode: "TO", countryId: "93" },
  { id: "1521", name: "Yomou Prefecture", stateCode: "YO", countryId: "93" },
  { id: "1522", name: "Bafatá", stateCode: "BA", countryId: "94" },
  { id: "1523", name: "Biombo Region", stateCode: "BM", countryId: "94" },
  { id: "1524", name: "Bolama Region", stateCode: "BL", countryId: "94" },
  { id: "1525", name: "Cacheu Region", stateCode: "CA", countryId: "94" },
  { id: "1526", name: "Gabú Region", stateCode: "GA", countryId: "94" },
  { id: "1527", name: "Leste Province", stateCode: "L", countryId: "94" },
  { id: "1528", name: "Norte Province", stateCode: "N", countryId: "94" },
  { id: "1529", name: "Oio Region", stateCode: "OI", countryId: "94" },
  { id: "1530", name: "Quinara Region", stateCode: "QU", countryId: "94" },
  { id: "1531", name: "Sul Province", stateCode: "S", countryId: "94" },
  { id: "1532", name: "Tombali Region", stateCode: "TO", countryId: "94" },
  { id: "1533", name: "Barima-Waini", stateCode: "BA", countryId: "95" },
  { id: "1534", name: "Cuyuni-Mazaruni", stateCode: "CU", countryId: "95" },
  { id: "1535", name: "Demerara-Mahaica", stateCode: "DE", countryId: "95" },
  {
    id: "1536",
    name: "East Berbice-Corentyne",
    stateCode: "EB",
    countryId: "95",
  },
  {
    id: "1537",
    name: "Essequibo Islands-West Demerara",
    stateCode: "ES",
    countryId: "95",
  },
  { id: "1538", name: "Mahaica-Berbice", stateCode: "MA", countryId: "95" },
  { id: "1539", name: "Pomeroon-Supenaam", stateCode: "PM", countryId: "95" },
  { id: "1540", name: "Potaro-Siparuni", stateCode: "PT", countryId: "95" },
  {
    id: "1541",
    name: "Upper Demerara-Berbice",
    stateCode: "UD",
    countryId: "95",
  },
  {
    id: "1542",
    name: "Upper Takutu-Upper Essequibo",
    stateCode: "UT",
    countryId: "95",
  },
  { id: "1543", name: "Artibonite", stateCode: "AR", countryId: "96" },
  { id: "1544", name: "Centre", stateCode: "CE", countryId: "96" },
  { id: "1545", name: "Grand\\'Anse", stateCode: "GA", countryId: "96" },
  { id: "1546", name: "Nippes", stateCode: "NI", countryId: "96" },
  { id: "1547", name: "Nord", stateCode: "ND", countryId: "96" },
  { id: "1548", name: "Nord-Est", stateCode: "NE", countryId: "96" },
  { id: "1549", name: "Nord-Ouest", stateCode: "NO", countryId: "96" },
  { id: "1550", name: "Ouest", stateCode: "OU", countryId: "96" },
  { id: "1551", name: "Sud", stateCode: "SD", countryId: "96" },
  { id: "1552", name: "Sud-Est", stateCode: "SE", countryId: "96" },
  {
    id: "1553",
    name: "Atlántida Department",
    stateCode: "AT",
    countryId: "98",
  },
  {
    id: "1554",
    name: "Bay Islands Department",
    stateCode: "IB",
    countryId: "98",
  },
  {
    id: "1555",
    name: "Choluteca Department",
    stateCode: "CH",
    countryId: "98",
  },
  { id: "1556", name: "Colón Department", stateCode: "CL", countryId: "98" },
  {
    id: "1557",
    name: "Comayagua Department",
    stateCode: "CM",
    countryId: "98",
  },
  { id: "1558", name: "Copán Department", stateCode: "CP", countryId: "98" },
  { id: "1559", name: "Cortés Department", stateCode: "CR", countryId: "98" },
  {
    id: "1560",
    name: "El Paraíso Department",
    stateCode: "EP",
    countryId: "98",
  },
  {
    id: "1561",
    name: "Francisco Morazán Department",
    stateCode: "FM",
    countryId: "98",
  },
  {
    id: "1562",
    name: "Gracias a Dios Department",
    stateCode: "GD",
    countryId: "98",
  },
  { id: "1563", name: "Intibucá Department", stateCode: "IN", countryId: "98" },
  { id: "1564", name: "La Paz Department", stateCode: "LP", countryId: "98" },
  { id: "1565", name: "Lempira Department", stateCode: "LE", countryId: "98" },
  {
    id: "1566",
    name: "Ocotepeque Department",
    stateCode: "OC",
    countryId: "98",
  },
  { id: "1567", name: "Olancho Department", stateCode: "OL", countryId: "98" },
  {
    id: "1568",
    name: "Santa Bárbara Department",
    stateCode: "SB",
    countryId: "98",
  },
  { id: "1569", name: "Valle Department", stateCode: "VA", countryId: "98" },
  { id: "1570", name: "Yoro Department", stateCode: "YO", countryId: "98" },
  {
    id: "1571",
    name: "Central and Western",
    stateCode: "HCW",
    countryId: "99",
  },
  { id: "1572", name: "Eastern", stateCode: "HEA", countryId: "99" },
  { id: "1573", name: "Islands", stateCode: "NIS", countryId: "99" },
  { id: "1574", name: "Kowloon City", stateCode: "KKC", countryId: "99" },
  { id: "1575", name: "Kwai Tsing", stateCode: "NKT", countryId: "99" },
  { id: "1576", name: "Kwun Tong", stateCode: "KKT", countryId: "99" },
  { id: "1577", name: "North", stateCode: "NNO", countryId: "99" },
  { id: "1578", name: "Sai Kung", stateCode: "NSK", countryId: "99" },
  { id: "1579", name: "Sha Tin", stateCode: "NST", countryId: "99" },
  { id: "1580", name: "Sham Shui Po", stateCode: "KSS", countryId: "99" },
  { id: "1581", name: "Southern", stateCode: "HSO", countryId: "99" },
  { id: "1582", name: "Tai Po", stateCode: "NTP", countryId: "99" },
  { id: "1583", name: "Tsuen Wan", stateCode: "NTW", countryId: "99" },
  { id: "1584", name: "Tuen Mun", stateCode: "NTM", countryId: "99" },
  { id: "1585", name: "Wan Chai", stateCode: "HWC", countryId: "99" },
  { id: "1586", name: "Wong Tai Sin", stateCode: "KWT", countryId: "99" },
  { id: "1587", name: "Yau Tsim Mong", stateCode: "KYT", countryId: "99" },
  { id: "1588", name: "Yuen Long", stateCode: "NYL", countryId: "99" },
  { id: "1589", name: "Bács-Kiskun", stateCode: "BK", countryId: "100" },
  { id: "1590", name: "Baranya", stateCode: "BA", countryId: "100" },
  { id: "1591", name: "Békés", stateCode: "BE", countryId: "100" },
  { id: "1592", name: "Békéscsaba", stateCode: "BC", countryId: "100" },
  {
    id: "1593",
    name: "Borsod-Abaúj-Zemplén",
    stateCode: "BZ",
    countryId: "100",
  },
  { id: "1594", name: "Budapest", stateCode: "BU", countryId: "100" },
  { id: "1595", name: "Csongrád County", stateCode: "CS", countryId: "100" },
  { id: "1596", name: "Debrecen", stateCode: "DE", countryId: "100" },
  { id: "1597", name: "Dunaújváros", stateCode: "DU", countryId: "100" },
  { id: "1598", name: "Eger", stateCode: "EG", countryId: "100" },
  { id: "1599", name: "Érd", stateCode: "ER", countryId: "100" },
  { id: "1600", name: "Fejér County", stateCode: "FE", countryId: "100" },
  { id: "1601", name: "Győr", stateCode: "GY", countryId: "100" },
  {
    id: "1602",
    name: "Győr-Moson-Sopron County",
    stateCode: "GS",
    countryId: "100",
  },
  { id: "1603", name: "Hajdú-Bihar County", stateCode: "HB", countryId: "100" },
  { id: "1604", name: "Heves County", stateCode: "HE", countryId: "100" },
  { id: "1605", name: "Hódmezővásárhely", stateCode: "HV", countryId: "100" },
  {
    id: "1606",
    name: "Jász-Nagykun-Szolnok County",
    stateCode: "JN",
    countryId: "100",
  },
  { id: "1607", name: "Kaposvár", stateCode: "KV", countryId: "100" },
  { id: "1608", name: "Kecskemét", stateCode: "KM", countryId: "100" },
  { id: "1609", name: "Komárom-Esztergom", stateCode: "KE", countryId: "100" },
  { id: "1610", name: "Miskolc", stateCode: "MI", countryId: "100" },
  { id: "1611", name: "Nagykanizsa", stateCode: "NK", countryId: "100" },
  { id: "1612", name: "Nógrád County", stateCode: "NO", countryId: "100" },
  { id: "1613", name: "Nyíregyháza", stateCode: "NY", countryId: "100" },
  { id: "1614", name: "Pécs", stateCode: "PS", countryId: "100" },
  { id: "1615", name: "Pest County", stateCode: "PE", countryId: "100" },
  { id: "1616", name: "Salgótarján", stateCode: "ST", countryId: "100" },
  { id: "1617", name: "Somogy County", stateCode: "SO", countryId: "100" },
  { id: "1618", name: "Sopron", stateCode: "SN", countryId: "100" },
  {
    id: "1619",
    name: "Szabolcs-Szatmár-Bereg County",
    stateCode: "SZ",
    countryId: "100",
  },
  { id: "1620", name: "Szeged", stateCode: "SD", countryId: "100" },
  { id: "1621", name: "Székesfehérvár", stateCode: "SF", countryId: "100" },
  { id: "1622", name: "Szekszárd", stateCode: "SS", countryId: "100" },
  { id: "1623", name: "Szolnok", stateCode: "SK", countryId: "100" },
  { id: "1624", name: "Szombathely", stateCode: "SH", countryId: "100" },
  { id: "1625", name: "Tatabánya", stateCode: "TB", countryId: "100" },
  { id: "1626", name: "Tolna County", stateCode: "TO", countryId: "100" },
  { id: "1627", name: "Vas County", stateCode: "VA", countryId: "100" },
  { id: "1628", name: "Veszprém", stateCode: "VM", countryId: "100" },
  { id: "1629", name: "Veszprém County", stateCode: "VE", countryId: "100" },
  { id: "1630", name: "Zala County", stateCode: "ZA", countryId: "100" },
  { id: "1631", name: "Zalaegerszeg", stateCode: "ZE", countryId: "100" },
  { id: "1632", name: "Capital Region", stateCode: "1", countryId: "101" },
  { id: "1633", name: "Eastern Region", stateCode: "7", countryId: "101" },
  { id: "1634", name: "Northeastern Region", stateCode: "6", countryId: "101" },
  { id: "1635", name: "Northwestern Region", stateCode: "5", countryId: "101" },
  {
    id: "1636",
    name: "Southern Peninsula Region",
    stateCode: "2",
    countryId: "101",
  },
  { id: "1637", name: "Southern Region", stateCode: "8", countryId: "101" },
  { id: "1638", name: "Western Region", stateCode: "3", countryId: "101" },
  { id: "1639", name: "Westfjords", stateCode: "4", countryId: "101" },
  {
    id: "1640",
    name: "Andaman and Nicobar Islands",
    stateCode: "AN",
    countryId: "102",
  },
  { id: "1641", name: "Andhra Pradesh", stateCode: "AP", countryId: "102" },
  { id: "1642", name: "Arunachal Pradesh", stateCode: "AR", countryId: "102" },
  { id: "1643", name: "Assam", stateCode: "AS", countryId: "102" },
  { id: "1644", name: "Bihar", stateCode: "BR", countryId: "102" },
  { id: "1645", name: "Chandigarh", stateCode: "CH", countryId: "102" },
  { id: "1646", name: "Chhattisgarh", stateCode: "CT", countryId: "102" },
  {
    id: "1647",
    name: "Dadra and Nagar Haveli and Daman and Diu",
    stateCode: "DH",
    countryId: "102",
  },
  { id: "1648", name: "Delhi", stateCode: "DL", countryId: "102" },
  { id: "1649", name: "Goa", stateCode: "GA", countryId: "102" },
  { id: "1650", name: "Gujarat", stateCode: "GJ", countryId: "102" },
  { id: "1651", name: "Haryana", stateCode: "HR", countryId: "102" },
  { id: "1652", name: "Himachal Pradesh", stateCode: "HP", countryId: "102" },
  { id: "1653", name: "Jammu and Kashmir", stateCode: "JK", countryId: "102" },
  { id: "1654", name: "Jharkhand", stateCode: "JH", countryId: "102" },
  { id: "1655", name: "Karnataka", stateCode: "KA", countryId: "102" },
  { id: "1656", name: "Kerala", stateCode: "KL", countryId: "102" },
  { id: "1657", name: "Ladakh", stateCode: "LA", countryId: "102" },
  { id: "1658", name: "Lakshadweep", stateCode: "LD", countryId: "102" },
  { id: "1659", name: "Madhya Pradesh", stateCode: "MP", countryId: "102" },
  { id: "1660", name: "Maharashtra", stateCode: "MH", countryId: "102" },
  { id: "1661", name: "Manipur", stateCode: "MN", countryId: "102" },
  { id: "1662", name: "Meghalaya", stateCode: "ML", countryId: "102" },
  { id: "1663", name: "Mizoram", stateCode: "MZ", countryId: "102" },
  { id: "1664", name: "Nagaland", stateCode: "NL", countryId: "102" },
  { id: "1665", name: "Odisha", stateCode: "OR", countryId: "102" },
  { id: "1666", name: "Puducherry", stateCode: "PY", countryId: "102" },
  { id: "1667", name: "Punjab", stateCode: "PB", countryId: "102" },
  { id: "1668", name: "Rajasthan", stateCode: "RJ", countryId: "102" },
  { id: "1669", name: "Sikkim", stateCode: "SK", countryId: "102" },
  { id: "1670", name: "Tamil Nadu", stateCode: "TN", countryId: "102" },
  { id: "1671", name: "Telangana", stateCode: "TG", countryId: "102" },
  { id: "1672", name: "Tripura", stateCode: "TR", countryId: "102" },
  { id: "1673", name: "Uttar Pradesh", stateCode: "UP", countryId: "102" },
  { id: "1674", name: "Uttarakhand", stateCode: "UT", countryId: "102" },
  { id: "1675", name: "West Bengal", stateCode: "WB", countryId: "102" },
  { id: "1676", name: "Aceh", stateCode: "AC", countryId: "103" },
  { id: "1677", name: "Bali", stateCode: "BA", countryId: "103" },
  { id: "1678", name: "Banten", stateCode: "BT", countryId: "103" },
  { id: "1679", name: "Bengkulu", stateCode: "BE", countryId: "103" },
  { id: "1680", name: "DI Yogyakarta", stateCode: "YO", countryId: "103" },
  { id: "1681", name: "DKI Jakarta", stateCode: "JK", countryId: "103" },
  { id: "1682", name: "Gorontalo", stateCode: "GO", countryId: "103" },
  { id: "1683", name: "Jambi", stateCode: "JA", countryId: "103" },
  { id: "1684", name: "Jawa Barat", stateCode: "JB", countryId: "103" },
  { id: "1685", name: "Jawa Tengah", stateCode: "JT", countryId: "103" },
  { id: "1686", name: "Jawa Timur", stateCode: "JI", countryId: "103" },
  { id: "1687", name: "Kalimantan Barat", stateCode: "KB", countryId: "103" },
  { id: "1688", name: "Kalimantan Selatan", stateCode: "KS", countryId: "103" },
  { id: "1689", name: "Kalimantan Tengah", stateCode: "KT", countryId: "103" },
  { id: "1690", name: "Kalimantan Timur", stateCode: "KI", countryId: "103" },
  { id: "1691", name: "Kalimantan Utara", stateCode: "KU", countryId: "103" },
  {
    id: "1692",
    name: "Kepulauan Bangka Belitung",
    stateCode: "BB",
    countryId: "103",
  },
  { id: "1693", name: "Kepulauan Riau", stateCode: "KR", countryId: "103" },
  { id: "1694", name: "Lampung", stateCode: "LA", countryId: "103" },
  { id: "1695", name: "Maluku", stateCode: "MA", countryId: "103" },
  { id: "1696", name: "Maluku Utara", stateCode: "MU", countryId: "103" },
  {
    id: "1697",
    name: "Nusa Tenggara Barat",
    stateCode: "NB",
    countryId: "103",
  },
  {
    id: "1698",
    name: "Nusa Tenggara Timur",
    stateCode: "NT",
    countryId: "103",
  },
  { id: "1699", name: "Papua", stateCode: "PA", countryId: "103" },
  { id: "1700", name: "Papua Barat", stateCode: "PB", countryId: "103" },
  { id: "1701", name: "Riau", stateCode: "RI", countryId: "103" },
  { id: "1702", name: "Sulawesi Barat", stateCode: "SR", countryId: "103" },
  { id: "1703", name: "Sulawesi Selatan", stateCode: "SN", countryId: "103" },
  { id: "1704", name: "Sulawesi Tengah", stateCode: "ST", countryId: "103" },
  { id: "1705", name: "Sulawesi Tenggara", stateCode: "SG", countryId: "103" },
  { id: "1706", name: "Sulawesi Utara", stateCode: "SA", countryId: "103" },
  { id: "1707", name: "Sumatera Barat", stateCode: "SB", countryId: "103" },
  { id: "1708", name: "Sumatera Selatan", stateCode: "SS", countryId: "103" },
  { id: "1709", name: "Sumatera Utara", stateCode: "SU", countryId: "103" },
  { id: "1710", name: "Alborz", stateCode: "30", countryId: "104" },
  { id: "1711", name: "Ardabil", stateCode: "24", countryId: "104" },
  { id: "1712", name: "Bushehr", stateCode: "18", countryId: "104" },
  {
    id: "1713",
    name: "Chaharmahal and Bakhtiari",
    stateCode: "14",
    countryId: "104",
  },
  { id: "1714", name: "East Azerbaijan", stateCode: "03", countryId: "104" },
  { id: "1715", name: "Fars", stateCode: "07", countryId: "104" },
  { id: "1716", name: "Gilan", stateCode: "01", countryId: "104" },
  { id: "1717", name: "Golestan", stateCode: "27", countryId: "104" },
  { id: "1718", name: "Hamadan", stateCode: "13", countryId: "104" },
  { id: "1719", name: "Hormozgan", stateCode: "22", countryId: "104" },
  { id: "1720", name: "Ilam", stateCode: "16", countryId: "104" },
  { id: "1721", name: "Isfahan", stateCode: "10", countryId: "104" },
  { id: "1722", name: "Kerman", stateCode: "08", countryId: "104" },
  { id: "1723", name: "Kermanshah", stateCode: "05", countryId: "104" },
  { id: "1724", name: "Khuzestan", stateCode: "06", countryId: "104" },
  {
    id: "1725",
    name: "Kohgiluyeh and Boyer-Ahmad",
    stateCode: "17",
    countryId: "104",
  },
  { id: "1726", name: "Kurdistan", stateCode: "12", countryId: "104" },
  { id: "1727", name: "Lorestan", stateCode: "15", countryId: "104" },
  { id: "1728", name: "Markazi", stateCode: "00", countryId: "104" },
  { id: "1729", name: "Mazandaran", stateCode: "02", countryId: "104" },
  { id: "1730", name: "North Khorasan", stateCode: "28", countryId: "104" },
  { id: "1731", name: "Qazvin", stateCode: "26", countryId: "104" },
  { id: "1732", name: "Qom", stateCode: "25", countryId: "104" },
  { id: "1733", name: "Razavi Khorasan", stateCode: "09", countryId: "104" },
  { id: "1734", name: "Semnan", stateCode: "20", countryId: "104" },
  {
    id: "1735",
    name: "Sistan and Baluchestan",
    stateCode: "11",
    countryId: "104",
  },
  { id: "1736", name: "South Khorasan", stateCode: "29", countryId: "104" },
  { id: "1737", name: "Tehran", stateCode: "23", countryId: "104" },
  { id: "1738", name: "West Azarbaijan", stateCode: "04", countryId: "104" },
  { id: "1739", name: "Yazd", stateCode: "21", countryId: "104" },
  { id: "1740", name: "Zanjan", stateCode: "19", countryId: "104" },
  { id: "1741", name: "Al Anbar", stateCode: "AN", countryId: "105" },
  { id: "1742", name: "Al Muthanna", stateCode: "MU", countryId: "105" },
  { id: "1743", name: "Al-Qādisiyyah", stateCode: "QA", countryId: "105" },
  { id: "1744", name: "Babylon", stateCode: "BB", countryId: "105" },
  { id: "1745", name: "Baghdad", stateCode: "BG", countryId: "105" },
  { id: "1746", name: "Basra", stateCode: "BA", countryId: "105" },
  { id: "1747", name: "Dhi Qar", stateCode: "DQ", countryId: "105" },
  { id: "1748", name: "Diyala", stateCode: "DI", countryId: "105" },
  { id: "1749", name: "Dohuk", stateCode: "DA", countryId: "105" },
  { id: "1750", name: "Erbil", stateCode: "AR", countryId: "105" },
  { id: "1751", name: "Karbala", stateCode: "KA", countryId: "105" },
  { id: "1752", name: "Kirkuk", stateCode: "KI", countryId: "105" },
  { id: "1753", name: "Maysan", stateCode: "MA", countryId: "105" },
  { id: "1754", name: "Najaf", stateCode: "NA", countryId: "105" },
  { id: "1755", name: "Nineveh", stateCode: "NI", countryId: "105" },
  { id: "1756", name: "Saladin", stateCode: "SD", countryId: "105" },
  { id: "1757", name: "Sulaymaniyah", stateCode: "SU", countryId: "105" },
  { id: "1758", name: "Wasit", stateCode: "WA", countryId: "105" },
  { id: "1759", name: "Carlow", stateCode: "CW", countryId: "106" },
  { id: "1760", name: "Cavan", stateCode: "CN", countryId: "106" },
  { id: "1761", name: "Clare", stateCode: "CE", countryId: "106" },
  { id: "1762", name: "Connacht", stateCode: "C", countryId: "106" },
  { id: "1763", name: "Cork", stateCode: "CO", countryId: "106" },
  { id: "1764", name: "Donegal", stateCode: "DL", countryId: "106" },
  { id: "1765", name: "Dublin", stateCode: "D", countryId: "106" },
  { id: "1766", name: "Galway", stateCode: "G", countryId: "106" },
  { id: "1767", name: "Kerry", stateCode: "KY", countryId: "106" },
  { id: "1768", name: "Kildare", stateCode: "KE", countryId: "106" },
  { id: "1769", name: "Kilkenny", stateCode: "KK", countryId: "106" },
  { id: "1770", name: "Laois", stateCode: "LS", countryId: "106" },
  { id: "1771", name: "Leinster", stateCode: "L", countryId: "106" },
  { id: "1772", name: "Limerick", stateCode: "LK", countryId: "106" },
  { id: "1773", name: "Longford", stateCode: "LD", countryId: "106" },
  { id: "1774", name: "Louth", stateCode: "LH", countryId: "106" },
  { id: "1775", name: "Mayo", stateCode: "MO", countryId: "106" },
  { id: "1776", name: "Meath", stateCode: "MH", countryId: "106" },
  { id: "1777", name: "Monaghan", stateCode: "MN", countryId: "106" },
  { id: "1778", name: "Munster", stateCode: "M", countryId: "106" },
  { id: "1779", name: "Offaly", stateCode: "OY", countryId: "106" },
  { id: "1780", name: "Roscommon", stateCode: "RN", countryId: "106" },
  { id: "1781", name: "Sligo", stateCode: "SO", countryId: "106" },
  { id: "1782", name: "Tipperary", stateCode: "TA", countryId: "106" },
  { id: "1783", name: "Ulster", stateCode: "U", countryId: "106" },
  { id: "1784", name: "Waterford", stateCode: "WD", countryId: "106" },
  { id: "1785", name: "Westmeath", stateCode: "WH", countryId: "106" },
  { id: "1786", name: "Wexford", stateCode: "WX", countryId: "106" },
  { id: "1787", name: "Wicklow", stateCode: "WW", countryId: "106" },
  { id: "1788", name: "Central District", stateCode: "M", countryId: "107" },
  { id: "1789", name: "Haifa District", stateCode: "HA", countryId: "107" },
  { id: "1790", name: "Jerusalem District", stateCode: "JM", countryId: "107" },
  { id: "1791", name: "Northern District", stateCode: "Z", countryId: "107" },
  { id: "1792", name: "Southern District", stateCode: "D", countryId: "107" },
  { id: "1793", name: "Tel Aviv District", stateCode: "TA", countryId: "107" },
  { id: "1794", name: "Abruzzo", stateCode: "65", countryId: "108" },
  { id: "1795", name: "Agrigento", stateCode: "AG", countryId: "108" },
  { id: "1796", name: "Alessandria", stateCode: "AL", countryId: "108" },
  { id: "1797", name: "Ancona", stateCode: "AN", countryId: "108" },
  { id: "1798", name: "Aosta Valley", stateCode: "23", countryId: "108" },
  { id: "1799", name: "Apulia", stateCode: "75", countryId: "108" },
  { id: "1800", name: "Ascoli Piceno", stateCode: "AP", countryId: "108" },
  { id: "1801", name: "Asti", stateCode: "AT", countryId: "108" },
  { id: "1802", name: "Avellino", stateCode: "AV", countryId: "108" },
  {
    id: "1803",
    name: "Barletta-Andria-Trani",
    stateCode: "BT",
    countryId: "108",
  },
  { id: "1804", name: "Basilicata", stateCode: "77", countryId: "108" },
  { id: "1805", name: "Belluno", stateCode: "BL", countryId: "108" },
  { id: "1806", name: "Benevento", stateCode: "BN", countryId: "108" },
  { id: "1807", name: "Bergamo", stateCode: "BG", countryId: "108" },
  { id: "1808", name: "Biella", stateCode: "BI", countryId: "108" },
  { id: "1809", name: "Brescia", stateCode: "BS", countryId: "108" },
  { id: "1810", name: "Brindisi", stateCode: "BR", countryId: "108" },
  { id: "1811", name: "Calabria", stateCode: "78", countryId: "108" },
  { id: "1812", name: "Caltanissetta", stateCode: "CL", countryId: "108" },
  { id: "1813", name: "Campania", stateCode: "72", countryId: "108" },
  { id: "1814", name: "Campobasso", stateCode: "CB", countryId: "108" },
  { id: "1815", name: "Caserta", stateCode: "CE", countryId: "108" },
  { id: "1816", name: "Catanzaro", stateCode: "CZ", countryId: "108" },
  { id: "1817", name: "Chieti", stateCode: "CH", countryId: "108" },
  { id: "1818", name: "Como", stateCode: "CO", countryId: "108" },
  { id: "1819", name: "Cosenza", stateCode: "CS", countryId: "108" },
  { id: "1820", name: "Cremona", stateCode: "CR", countryId: "108" },
  { id: "1821", name: "Crotone", stateCode: "KR", countryId: "108" },
  { id: "1822", name: "Cuneo", stateCode: "CN", countryId: "108" },
  { id: "1823", name: "Emilia-Romagna", stateCode: "45", countryId: "108" },
  { id: "1824", name: "Enna", stateCode: "EN", countryId: "108" },
  { id: "1825", name: "Fermo", stateCode: "FM", countryId: "108" },
  { id: "1826", name: "Ferrara", stateCode: "FE", countryId: "108" },
  { id: "1827", name: "Foggia", stateCode: "FG", countryId: "108" },
  { id: "1828", name: "Forlì-Cesena", stateCode: "FC", countryId: "108" },
  {
    id: "1829",
    name: "Friuli–Venezia Giulia",
    stateCode: "36",
    countryId: "108",
  },
  { id: "1830", name: "Frosinone", stateCode: "FR", countryId: "108" },
  { id: "1831", name: "Gorizia", stateCode: "GO", countryId: "108" },
  { id: "1832", name: "Grosseto", stateCode: "GR", countryId: "108" },
  { id: "1833", name: "Imperia", stateCode: "IM", countryId: "108" },
  { id: "1834", name: "Isernia", stateCode: "IS", countryId: "108" },
  { id: "1835", name: "L\\'Aquila", stateCode: "AQ", countryId: "108" },
  { id: "1836", name: "La Spezia", stateCode: "SP", countryId: "108" },
  { id: "1837", name: "Latina", stateCode: "LT", countryId: "108" },
  { id: "1838", name: "Lazio", stateCode: "62", countryId: "108" },
  { id: "1839", name: "Lecce", stateCode: "LE", countryId: "108" },
  { id: "1840", name: "Lecco", stateCode: "LC", countryId: "108" },
  { id: "1841", name: "Liguria", stateCode: "42", countryId: "108" },
  { id: "1842", name: "Livorno", stateCode: "LI", countryId: "108" },
  { id: "1843", name: "Lodi", stateCode: "LO", countryId: "108" },
  { id: "1844", name: "Lombardy", stateCode: "25", countryId: "108" },
  { id: "1845", name: "Lucca", stateCode: "LU", countryId: "108" },
  { id: "1846", name: "Macerata", stateCode: "MC", countryId: "108" },
  { id: "1847", name: "Mantua", stateCode: "MN", countryId: "108" },
  { id: "1848", name: "Marche", stateCode: "57", countryId: "108" },
  { id: "1849", name: "Massa and Carrara", stateCode: "MS", countryId: "108" },
  { id: "1850", name: "Matera", stateCode: "MT", countryId: "108" },
  { id: "1851", name: "Medio Campidano", stateCode: "VS", countryId: "108" },
  { id: "1852", name: "Modena", stateCode: "MO", countryId: "108" },
  { id: "1853", name: "Molise", stateCode: "67", countryId: "108" },
  { id: "1854", name: "Monza and Brianza", stateCode: "MB", countryId: "108" },
  { id: "1855", name: "Novara", stateCode: "NO", countryId: "108" },
  { id: "1856", name: "Nuoro", stateCode: "NU", countryId: "108" },
  { id: "1857", name: "Oristano", stateCode: "OR", countryId: "108" },
  { id: "1858", name: "Padua", stateCode: "PD", countryId: "108" },
  { id: "1859", name: "Palermo", stateCode: "PA", countryId: "108" },
  { id: "1860", name: "Parma", stateCode: "PR", countryId: "108" },
  { id: "1861", name: "Pavia", stateCode: "PV", countryId: "108" },
  { id: "1862", name: "Perugia", stateCode: "PG", countryId: "108" },
  { id: "1863", name: "Pesaro and Urbino", stateCode: "PU", countryId: "108" },
  { id: "1864", name: "Pescara", stateCode: "PE", countryId: "108" },
  { id: "1865", name: "Piacenza", stateCode: "PC", countryId: "108" },
  { id: "1866", name: "Piedmont", stateCode: "21", countryId: "108" },
  { id: "1867", name: "Pisa", stateCode: "PI", countryId: "108" },
  { id: "1868", name: "Pistoia", stateCode: "PT", countryId: "108" },
  { id: "1869", name: "Pordenone", stateCode: "PN", countryId: "108" },
  { id: "1870", name: "Potenza", stateCode: "PZ", countryId: "108" },
  { id: "1871", name: "Prato", stateCode: "PO", countryId: "108" },
  { id: "1872", name: "Ragusa", stateCode: "RG", countryId: "108" },
  { id: "1873", name: "Ravenna", stateCode: "RA", countryId: "108" },
  { id: "1874", name: "Reggio Emilia", stateCode: "RE", countryId: "108" },
  { id: "1875", name: "Rieti", stateCode: "RI", countryId: "108" },
  { id: "1876", name: "Rimini", stateCode: "RN", countryId: "108" },
  { id: "1877", name: "Rovigo", stateCode: "RO", countryId: "108" },
  { id: "1878", name: "Salerno", stateCode: "SA", countryId: "108" },
  { id: "1879", name: "Sardinia", stateCode: "88", countryId: "108" },
  { id: "1880", name: "Sassari", stateCode: "SS", countryId: "108" },
  { id: "1881", name: "Savona", stateCode: "SV", countryId: "108" },
  { id: "1882", name: "Sicily", stateCode: "82", countryId: "108" },
  { id: "1883", name: "Siena", stateCode: "SI", countryId: "108" },
  { id: "1884", name: "Siracusa", stateCode: "SR", countryId: "108" },
  { id: "1885", name: "Sondrio", stateCode: "SO", countryId: "108" },
  { id: "1886", name: "South Sardinia", stateCode: "SU", countryId: "108" },
  { id: "1887", name: "Taranto", stateCode: "TA", countryId: "108" },
  { id: "1888", name: "Teramo", stateCode: "TE", countryId: "108" },
  { id: "1889", name: "Terni", stateCode: "TR", countryId: "108" },
  { id: "1890", name: "Trapani", stateCode: "TP", countryId: "108" },
  {
    id: "1891",
    name: "Trentino-South Tyrol",
    stateCode: "32",
    countryId: "108",
  },
  { id: "1892", name: "Treviso", stateCode: "TV", countryId: "108" },
  { id: "1893", name: "Trieste", stateCode: "TS", countryId: "108" },
  { id: "1894", name: "Tuscany", stateCode: "52", countryId: "108" },
  { id: "1895", name: "Udine", stateCode: "UD", countryId: "108" },
  { id: "1896", name: "Umbria", stateCode: "55", countryId: "108" },
  { id: "1897", name: "Varese", stateCode: "VA", countryId: "108" },
  { id: "1898", name: "Veneto", stateCode: "34", countryId: "108" },
  {
    id: "1899",
    name: "Verbano-Cusio-Ossola",
    stateCode: "VB",
    countryId: "108",
  },
  { id: "1900", name: "Vercelli", stateCode: "VC", countryId: "108" },
  { id: "1901", name: "Verona", stateCode: "VR", countryId: "108" },
  { id: "1902", name: "Vibo Valentia", stateCode: "VV", countryId: "108" },
  { id: "1903", name: "Vicenza", stateCode: "VI", countryId: "108" },
  { id: "1904", name: "Viterbo", stateCode: "VT", countryId: "108" },
  { id: "1905", name: "Clarendon Parish", stateCode: "13", countryId: "109" },
  { id: "1906", name: "Hanover Parish", stateCode: "09", countryId: "109" },
  { id: "1907", name: "Kingston Parish", stateCode: "01", countryId: "109" },
  { id: "1908", name: "Manchester Parish", stateCode: "12", countryId: "109" },
  { id: "1909", name: "Portland Parish", stateCode: "04", countryId: "109" },
  { id: "1910", name: "Saint Andrew", stateCode: "02", countryId: "109" },
  { id: "1911", name: "Saint Ann Parish", stateCode: "06", countryId: "109" },
  {
    id: "1912",
    name: "Saint Catherine Parish",
    stateCode: "14",
    countryId: "109",
  },
  {
    id: "1913",
    name: "Saint Elizabeth Parish",
    stateCode: "11",
    countryId: "109",
  },
  { id: "1914", name: "Saint James Parish", stateCode: "08", countryId: "109" },
  { id: "1915", name: "Saint Mary Parish", stateCode: "05", countryId: "109" },
  {
    id: "1916",
    name: "Saint Thomas Parish",
    stateCode: "03",
    countryId: "109",
  },
  { id: "1917", name: "Trelawny Parish", stateCode: "07", countryId: "109" },
  {
    id: "1918",
    name: "Westmoreland Parish",
    stateCode: "10",
    countryId: "109",
  },
  { id: "1919", name: "Aichi Prefecture", stateCode: "23", countryId: "110" },
  { id: "1920", name: "Akita Prefecture", stateCode: "05", countryId: "110" },
  { id: "1921", name: "Aomori Prefecture", stateCode: "02", countryId: "110" },
  { id: "1922", name: "Chiba Prefecture", stateCode: "12", countryId: "110" },
  { id: "1923", name: "Ehime Prefecture", stateCode: "38", countryId: "110" },
  { id: "1924", name: "Fukui Prefecture", stateCode: "18", countryId: "110" },
  { id: "1925", name: "Fukuoka Prefecture", stateCode: "40", countryId: "110" },
  {
    id: "1926",
    name: "Fukushima Prefecture",
    stateCode: "07",
    countryId: "110",
  },
  { id: "1927", name: "Gifu Prefecture", stateCode: "21", countryId: "110" },
  { id: "1928", name: "Gunma Prefecture", stateCode: "10", countryId: "110" },
  {
    id: "1929",
    name: "Hiroshima Prefecture",
    stateCode: "34",
    countryId: "110",
  },
  {
    id: "1930",
    name: "Hokkaidō Prefecture",
    stateCode: "01",
    countryId: "110",
  },
  { id: "1931", name: "Hyōgo Prefecture", stateCode: "28", countryId: "110" },
  { id: "1932", name: "Ibaraki Prefecture", stateCode: "08", countryId: "110" },
  {
    id: "1933",
    name: "Ishikawa Prefecture",
    stateCode: "17",
    countryId: "110",
  },
  { id: "1934", name: "Iwate Prefecture", stateCode: "03", countryId: "110" },
  { id: "1935", name: "Kagawa Prefecture", stateCode: "37", countryId: "110" },
  {
    id: "1936",
    name: "Kagoshima Prefecture",
    stateCode: "46",
    countryId: "110",
  },
  {
    id: "1937",
    name: "Kanagawa Prefecture",
    stateCode: "14",
    countryId: "110",
  },
  { id: "1938", name: "Kōchi Prefecture", stateCode: "39", countryId: "110" },
  {
    id: "1939",
    name: "Kumamoto Prefecture",
    stateCode: "43",
    countryId: "110",
  },
  { id: "1940", name: "Kyōto Prefecture", stateCode: "26", countryId: "110" },
  { id: "1941", name: "Mie Prefecture", stateCode: "24", countryId: "110" },
  { id: "1942", name: "Miyagi Prefecture", stateCode: "04", countryId: "110" },
  {
    id: "1943",
    name: "Miyazaki Prefecture",
    stateCode: "45",
    countryId: "110",
  },
  { id: "1944", name: "Nagano Prefecture", stateCode: "20", countryId: "110" },
  {
    id: "1945",
    name: "Nagasaki Prefecture",
    stateCode: "42",
    countryId: "110",
  },
  { id: "1946", name: "Nara Prefecture", stateCode: "29", countryId: "110" },
  { id: "1947", name: "Niigata Prefecture", stateCode: "15", countryId: "110" },
  { id: "1948", name: "Ōita Prefecture", stateCode: "44", countryId: "110" },
  { id: "1949", name: "Okayama Prefecture", stateCode: "33", countryId: "110" },
  { id: "1950", name: "Okinawa Prefecture", stateCode: "47", countryId: "110" },
  { id: "1951", name: "Ōsaka Prefecture", stateCode: "27", countryId: "110" },
  { id: "1952", name: "Saga Prefecture", stateCode: "41", countryId: "110" },
  { id: "1953", name: "Saitama Prefecture", stateCode: "11", countryId: "110" },
  { id: "1954", name: "Shiga Prefecture", stateCode: "25", countryId: "110" },
  { id: "1955", name: "Shimane Prefecture", stateCode: "32", countryId: "110" },
  {
    id: "1956",
    name: "Shizuoka Prefecture",
    stateCode: "22",
    countryId: "110",
  },
  { id: "1957", name: "Tochigi Prefecture", stateCode: "09", countryId: "110" },
  {
    id: "1958",
    name: "Tokushima Prefecture",
    stateCode: "36",
    countryId: "110",
  },
  { id: "1959", name: "Tokyo", stateCode: "13", countryId: "110" },
  { id: "1960", name: "Tottori Prefecture", stateCode: "31", countryId: "110" },
  { id: "1961", name: "Toyama Prefecture", stateCode: "16", countryId: "110" },
  {
    id: "1962",
    name: "Wakayama Prefecture",
    stateCode: "30",
    countryId: "110",
  },
  {
    id: "1963",
    name: "Yamagata Prefecture",
    stateCode: "06",
    countryId: "110",
  },
  {
    id: "1964",
    name: "Yamaguchi Prefecture",
    stateCode: "35",
    countryId: "110",
  },
  {
    id: "1965",
    name: "Yamanashi Prefecture",
    stateCode: "19",
    countryId: "110",
  },
  { id: "1966", name: "Ajloun", stateCode: "AJ", countryId: "112" },
  { id: "1967", name: "Amman", stateCode: "AM", countryId: "112" },
  { id: "1968", name: "Aqaba", stateCode: "AQ", countryId: "112" },
  { id: "1969", name: "Balqa", stateCode: "BA", countryId: "112" },
  { id: "1970", name: "Irbid", stateCode: "IR", countryId: "112" },
  { id: "1971", name: "Jerash", stateCode: "JA", countryId: "112" },
  { id: "1972", name: "Karak", stateCode: "KA", countryId: "112" },
  { id: "1973", name: "Ma\\'an", stateCode: "MN", countryId: "112" },
  { id: "1974", name: "Madaba", stateCode: "MD", countryId: "112" },
  { id: "1975", name: "Mafraq", stateCode: "MA", countryId: "112" },
  { id: "1976", name: "Tafilah", stateCode: "AT", countryId: "112" },
  { id: "1977", name: "Zarqa", stateCode: "AZ", countryId: "112" },
  { id: "1978", name: "Akmola Region", stateCode: "AKM", countryId: "113" },
  { id: "1979", name: "Aktobe Region", stateCode: "AKT", countryId: "113" },
  { id: "1980", name: "Almaty", stateCode: "ALA", countryId: "113" },
  { id: "1981", name: "Almaty Region", stateCode: "ALM", countryId: "113" },
  { id: "1982", name: "Atyrau Region", stateCode: "ATY", countryId: "113" },
  { id: "1983", name: "Baikonur", stateCode: "BAY", countryId: "113" },
  {
    id: "1984",
    name: "East Kazakhstan Region",
    stateCode: "VOS",
    countryId: "113",
  },
  { id: "1985", name: "Jambyl Region", stateCode: "ZHA", countryId: "113" },
  { id: "1986", name: "Karaganda Region", stateCode: "KAR", countryId: "113" },
  { id: "1987", name: "Kostanay Region", stateCode: "KUS", countryId: "113" },
  { id: "1988", name: "Kyzylorda Region", stateCode: "KZY", countryId: "113" },
  { id: "1989", name: "Mangystau Region", stateCode: "MAN", countryId: "113" },
  {
    id: "1990",
    name: "North Kazakhstan Region",
    stateCode: "SEV",
    countryId: "113",
  },
  { id: "1991", name: "Nur-Sultan", stateCode: "AST", countryId: "113" },
  { id: "1992", name: "Pavlodar Region", stateCode: "PAV", countryId: "113" },
  { id: "1993", name: "Turkestan Region", stateCode: "YUZ", countryId: "113" },
  {
    id: "1994",
    name: "West Kazakhstan Province",
    stateCode: "ZAP",
    countryId: "113",
  },
  { id: "1995", name: "Baringo", stateCode: "01", countryId: "114" },
  { id: "1996", name: "Bomet", stateCode: "02", countryId: "114" },
  { id: "1997", name: "Bungoma", stateCode: "03", countryId: "114" },
  { id: "1998", name: "Busia", stateCode: "04", countryId: "114" },
  { id: "1999", name: "Elgeyo-Marakwet", stateCode: "05", countryId: "114" },
  { id: "2000", name: "Embu", stateCode: "06", countryId: "114" },
  { id: "2001", name: "Garissa", stateCode: "07", countryId: "114" },
  { id: "2002", name: "Homa Bay", stateCode: "08", countryId: "114" },
  { id: "2003", name: "Isiolo", stateCode: "09", countryId: "114" },
  { id: "2004", name: "Kajiado", stateCode: "10", countryId: "114" },
  { id: "2005", name: "Kakamega", stateCode: "11", countryId: "114" },
  { id: "2006", name: "Kericho", stateCode: "12", countryId: "114" },
  { id: "2007", name: "Kiambu", stateCode: "13", countryId: "114" },
  { id: "2008", name: "Kilifi", stateCode: "14", countryId: "114" },
  { id: "2009", name: "Kirinyaga", stateCode: "15", countryId: "114" },
  { id: "2010", name: "Kisii", stateCode: "16", countryId: "114" },
  { id: "2011", name: "Kisumu", stateCode: "17", countryId: "114" },
  { id: "2012", name: "Kitui", stateCode: "18", countryId: "114" },
  { id: "2013", name: "Kwale", stateCode: "19", countryId: "114" },
  { id: "2014", name: "Laikipia", stateCode: "20", countryId: "114" },
  { id: "2015", name: "Lamu", stateCode: "21", countryId: "114" },
  { id: "2016", name: "Machakos", stateCode: "22", countryId: "114" },
  { id: "2017", name: "Makueni", stateCode: "23", countryId: "114" },
  { id: "2018", name: "Mandera", stateCode: "24", countryId: "114" },
  { id: "2019", name: "Marsabit", stateCode: "25", countryId: "114" },
  { id: "2020", name: "Meru", stateCode: "26", countryId: "114" },
  { id: "2021", name: "Migori", stateCode: "27", countryId: "114" },
  { id: "2022", name: "Mombasa", stateCode: "28", countryId: "114" },
  { id: "2023", name: "Murang\\'a", stateCode: "29", countryId: "114" },
  { id: "2024", name: "Nairobi City", stateCode: "30", countryId: "114" },
  { id: "2025", name: "Nakuru", stateCode: "31", countryId: "114" },
  { id: "2026", name: "Nandi", stateCode: "32", countryId: "114" },
  { id: "2027", name: "Narok", stateCode: "33", countryId: "114" },
  { id: "2028", name: "Nyamira", stateCode: "34", countryId: "114" },
  { id: "2029", name: "Nyandarua", stateCode: "35", countryId: "114" },
  { id: "2030", name: "Nyeri", stateCode: "36", countryId: "114" },
  { id: "2031", name: "Samburu", stateCode: "37", countryId: "114" },
  { id: "2032", name: "Siaya", stateCode: "38", countryId: "114" },
  { id: "2033", name: "Taita–Taveta", stateCode: "39", countryId: "114" },
  { id: "2034", name: "Tana River", stateCode: "40", countryId: "114" },
  { id: "2035", name: "Tharaka-Nithi", stateCode: "41", countryId: "114" },
  { id: "2036", name: "Trans Nzoia", stateCode: "42", countryId: "114" },
  { id: "2037", name: "Turkana", stateCode: "43", countryId: "114" },
  { id: "2038", name: "Uasin Gishu", stateCode: "44", countryId: "114" },
  { id: "2039", name: "Vihiga", stateCode: "45", countryId: "114" },
  { id: "2040", name: "Wajir", stateCode: "46", countryId: "114" },
  { id: "2041", name: "West Pokot", stateCode: "47", countryId: "114" },
  { id: "2042", name: "Gilbert Islands", stateCode: "G", countryId: "115" },
  { id: "2043", name: "Line Islands", stateCode: "L", countryId: "115" },
  { id: "2044", name: "Phoenix Islands", stateCode: "P", countryId: "115" },
  {
    id: "2045",
    name: "Đakovica District (Gjakove)",
    stateCode: "XDG",
    countryId: "116",
  },
  { id: "2046", name: "Gjilan District", stateCode: "XGJ", countryId: "116" },
  {
    id: "2047",
    name: "Kosovska Mitrovica District",
    stateCode: "XKM",
    countryId: "116",
  },
  { id: "2048", name: "Peć District", stateCode: "XPE", countryId: "116" },
  {
    id: "2049",
    name: "Pristina (Priştine)",
    stateCode: "XPI",
    countryId: "116",
  },
  { id: "2050", name: "Prizren District", stateCode: "XPR", countryId: "116" },
  {
    id: "2051",
    name: "Uroševac District (Ferizaj)",
    stateCode: "XUF",
    countryId: "116",
  },
  { id: "2052", name: "Al Ahmadi", stateCode: "AH", countryId: "117" },
  { id: "2053", name: "Al Farwaniyah", stateCode: "FA", countryId: "117" },
  { id: "2054", name: "Al Jahra", stateCode: "JA", countryId: "117" },
  { id: "2055", name: "Capital", stateCode: "KU", countryId: "117" },
  { id: "2056", name: "Hawalli", stateCode: "HA", countryId: "117" },
  { id: "2057", name: "Mubarak Al-Kabeer", stateCode: "MU", countryId: "117" },
  { id: "2058", name: "Batken Region", stateCode: "B", countryId: "118" },
  { id: "2059", name: "Bishkek", stateCode: "GB", countryId: "118" },
  { id: "2060", name: "Chuy Region", stateCode: "C", countryId: "118" },
  { id: "2061", name: "Issyk-Kul Region", stateCode: "Y", countryId: "118" },
  { id: "2062", name: "Jalal-Abad Region", stateCode: "J", countryId: "118" },
  { id: "2063", name: "Naryn Region", stateCode: "N", countryId: "118" },
  { id: "2064", name: "Osh", stateCode: "GO", countryId: "118" },
  { id: "2065", name: "Osh Region", stateCode: "O", countryId: "118" },
  { id: "2066", name: "Talas Region", stateCode: "T", countryId: "118" },
  { id: "2067", name: "Attapeu Province", stateCode: "AT", countryId: "119" },
  { id: "2068", name: "Bokeo Province", stateCode: "BK", countryId: "119" },
  {
    id: "2069",
    name: "Bolikhamsai Province",
    stateCode: "BL",
    countryId: "119",
  },
  { id: "2070", name: "Champasak Province", stateCode: "CH", countryId: "119" },
  { id: "2071", name: "Houaphanh Province", stateCode: "HO", countryId: "119" },
  {
    id: "2072",
    name: "Khammouane Province",
    stateCode: "KH",
    countryId: "119",
  },
  {
    id: "2073",
    name: "Luang Namtha Province",
    stateCode: "LM",
    countryId: "119",
  },
  {
    id: "2074",
    name: "Luang Prabang Province",
    stateCode: "LP",
    countryId: "119",
  },
  { id: "2075", name: "Oudomxay Province", stateCode: "OU", countryId: "119" },
  { id: "2076", name: "Phongsaly Province", stateCode: "PH", countryId: "119" },
  {
    id: "2077",
    name: "Sainyabuli Province",
    stateCode: "XA",
    countryId: "119",
  },
  { id: "2078", name: "Salavan Province", stateCode: "SL", countryId: "119" },
  {
    id: "2079",
    name: "Savannakhet Province",
    stateCode: "SV",
    countryId: "119",
  },
  { id: "2080", name: "Sekong Province", stateCode: "XE", countryId: "119" },
  {
    id: "2081",
    name: "Vientiane Prefecture",
    stateCode: "VT",
    countryId: "119",
  },
  { id: "2082", name: "Vientiane Province", stateCode: "VI", countryId: "119" },
  { id: "2083", name: "Xaisomboun", stateCode: "XN", countryId: "119" },
  {
    id: "2084",
    name: "Xaisomboun Province",
    stateCode: "XS",
    countryId: "119",
  },
  {
    id: "2085",
    name: "Xiangkhouang Province",
    stateCode: "XI",
    countryId: "119",
  },
  {
    id: "2086",
    name: "Aglona Municipality",
    stateCode: "001",
    countryId: "120",
  },
  {
    id: "2087",
    name: "Aizkraukle Municipality",
    stateCode: "002",
    countryId: "120",
  },
  {
    id: "2088",
    name: "Aizpute Municipality",
    stateCode: "003",
    countryId: "120",
  },
  {
    id: "2089",
    name: "Aknīste Municipality",
    stateCode: "004",
    countryId: "120",
  },
  {
    id: "2090",
    name: "Aloja Municipality",
    stateCode: "005",
    countryId: "120",
  },
  {
    id: "2091",
    name: "Alsunga Municipality",
    stateCode: "006",
    countryId: "120",
  },
  {
    id: "2092",
    name: "Alūksne Municipality",
    stateCode: "007",
    countryId: "120",
  },
  {
    id: "2093",
    name: "Amata Municipality",
    stateCode: "008",
    countryId: "120",
  },
  { id: "2094", name: "Ape Municipality", stateCode: "009", countryId: "120" },
  { id: "2095", name: "Auce Municipality", stateCode: "010", countryId: "120" },
  {
    id: "2096",
    name: "Babīte Municipality",
    stateCode: "012",
    countryId: "120",
  },
  {
    id: "2097",
    name: "Baldone Municipality",
    stateCode: "013",
    countryId: "120",
  },
  {
    id: "2098",
    name: "Baltinava Municipality",
    stateCode: "014",
    countryId: "120",
  },
  {
    id: "2099",
    name: "Balvi Municipality",
    stateCode: "015",
    countryId: "120",
  },
  {
    id: "2100",
    name: "Bauska Municipality",
    stateCode: "016",
    countryId: "120",
  },
  {
    id: "2101",
    name: "Beverīna Municipality",
    stateCode: "017",
    countryId: "120",
  },
  {
    id: "2102",
    name: "Brocēni Municipality",
    stateCode: "018",
    countryId: "120",
  },
  {
    id: "2103",
    name: "Burtnieki Municipality",
    stateCode: "019",
    countryId: "120",
  },
  {
    id: "2104",
    name: "Carnikava Municipality",
    stateCode: "020",
    countryId: "120",
  },
  {
    id: "2105",
    name: "Cēsis Municipality",
    stateCode: "022",
    countryId: "120",
  },
  {
    id: "2106",
    name: "Cesvaine Municipality",
    stateCode: "021",
    countryId: "120",
  },
  {
    id: "2107",
    name: "Cibla Municipality",
    stateCode: "023",
    countryId: "120",
  },
  {
    id: "2108",
    name: "Dagda Municipality",
    stateCode: "024",
    countryId: "120",
  },
  { id: "2109", name: "Daugavpils", stateCode: "DGV", countryId: "120" },
  {
    id: "2110",
    name: "Daugavpils Municipality",
    stateCode: "025",
    countryId: "120",
  },
  {
    id: "2111",
    name: "Dobele Municipality",
    stateCode: "026",
    countryId: "120",
  },
  {
    id: "2112",
    name: "Dundaga Municipality",
    stateCode: "027",
    countryId: "120",
  },
  {
    id: "2113",
    name: "Durbe Municipality",
    stateCode: "028",
    countryId: "120",
  },
  {
    id: "2114",
    name: "Engure Municipality",
    stateCode: "029",
    countryId: "120",
  },
  {
    id: "2115",
    name: "Ērgļi Municipality",
    stateCode: "030",
    countryId: "120",
  },
  {
    id: "2116",
    name: "Garkalne Municipality",
    stateCode: "031",
    countryId: "120",
  },
  {
    id: "2117",
    name: "Grobiņa Municipality",
    stateCode: "032",
    countryId: "120",
  },
  {
    id: "2118",
    name: "Gulbene Municipality",
    stateCode: "033",
    countryId: "120",
  },
  {
    id: "2119",
    name: "Iecava Municipality",
    stateCode: "034",
    countryId: "120",
  },
  {
    id: "2120",
    name: "Ikšķile Municipality",
    stateCode: "035",
    countryId: "120",
  },
  {
    id: "2121",
    name: "Ilūkste Municipality",
    stateCode: "036",
    countryId: "120",
  },
  {
    id: "2122",
    name: "Inčukalns Municipality",
    stateCode: "037",
    countryId: "120",
  },
  {
    id: "2123",
    name: "Jaunjelgava Municipality",
    stateCode: "038",
    countryId: "120",
  },
  {
    id: "2124",
    name: "Jaunpiebalga Municipality",
    stateCode: "039",
    countryId: "120",
  },
  {
    id: "2125",
    name: "Jaunpils Municipality",
    stateCode: "040",
    countryId: "120",
  },
  { id: "2126", name: "Jēkabpils", stateCode: "JKB", countryId: "120" },
  {
    id: "2127",
    name: "Jēkabpils Municipality",
    stateCode: "042",
    countryId: "120",
  },
  { id: "2128", name: "Jelgava", stateCode: "JEL", countryId: "120" },
  {
    id: "2129",
    name: "Jelgava Municipality",
    stateCode: "041",
    countryId: "120",
  },
  { id: "2130", name: "Jūrmala", stateCode: "JUR", countryId: "120" },
  {
    id: "2131",
    name: "Kandava Municipality",
    stateCode: "043",
    countryId: "120",
  },
  {
    id: "2132",
    name: "Kārsava Municipality",
    stateCode: "044",
    countryId: "120",
  },
  {
    id: "2133",
    name: "Ķegums Municipality",
    stateCode: "051",
    countryId: "120",
  },
  {
    id: "2134",
    name: "Ķekava Municipality",
    stateCode: "052",
    countryId: "120",
  },
  {
    id: "2135",
    name: "Kocēni Municipality",
    stateCode: "045",
    countryId: "120",
  },
  {
    id: "2136",
    name: "Koknese Municipality",
    stateCode: "046",
    countryId: "120",
  },
  {
    id: "2137",
    name: "Krāslava Municipality",
    stateCode: "047",
    countryId: "120",
  },
  {
    id: "2138",
    name: "Krimulda Municipality",
    stateCode: "048",
    countryId: "120",
  },
  {
    id: "2139",
    name: "Krustpils Municipality",
    stateCode: "049",
    countryId: "120",
  },
  {
    id: "2140",
    name: "Kuldīga Municipality",
    stateCode: "050",
    countryId: "120",
  },
  {
    id: "2141",
    name: "Lielvārde Municipality",
    stateCode: "053",
    countryId: "120",
  },
  { id: "2142", name: "Liepāja", stateCode: "LPX", countryId: "120" },
  {
    id: "2143",
    name: "Līgatne Municipality",
    stateCode: "055",
    countryId: "120",
  },
  {
    id: "2144",
    name: "Limbaži Municipality",
    stateCode: "054",
    countryId: "120",
  },
  {
    id: "2145",
    name: "Līvāni Municipality",
    stateCode: "056",
    countryId: "120",
  },
  {
    id: "2146",
    name: "Lubāna Municipality",
    stateCode: "057",
    countryId: "120",
  },
  {
    id: "2147",
    name: "Ludza Municipality",
    stateCode: "058",
    countryId: "120",
  },
  {
    id: "2148",
    name: "Madona Municipality",
    stateCode: "059",
    countryId: "120",
  },
  {
    id: "2149",
    name: "Mālpils Municipality",
    stateCode: "061",
    countryId: "120",
  },
  {
    id: "2150",
    name: "Mārupe Municipality",
    stateCode: "062",
    countryId: "120",
  },
  {
    id: "2151",
    name: "Mazsalaca Municipality",
    stateCode: "060",
    countryId: "120",
  },
  {
    id: "2152",
    name: "Mērsrags Municipality",
    stateCode: "063",
    countryId: "120",
  },
  {
    id: "2153",
    name: "Naukšēni Municipality",
    stateCode: "064",
    countryId: "120",
  },
  {
    id: "2154",
    name: "Nereta Municipality",
    stateCode: "065",
    countryId: "120",
  },
  { id: "2155", name: "Nīca Municipality", stateCode: "066", countryId: "120" },
  { id: "2156", name: "Ogre Municipality", stateCode: "067", countryId: "120" },
  {
    id: "2157",
    name: "Olaine Municipality",
    stateCode: "068",
    countryId: "120",
  },
  {
    id: "2158",
    name: "Ozolnieki Municipality",
    stateCode: "069",
    countryId: "120",
  },
  {
    id: "2159",
    name: "Pārgauja Municipality",
    stateCode: "070",
    countryId: "120",
  },
  {
    id: "2160",
    name: "Pāvilosta Municipality",
    stateCode: "071",
    countryId: "120",
  },
  {
    id: "2161",
    name: "Pļaviņas Municipality",
    stateCode: "072",
    countryId: "120",
  },
  {
    id: "2162",
    name: "Preiļi Municipality",
    stateCode: "073",
    countryId: "120",
  },
  {
    id: "2163",
    name: "Priekule Municipality",
    stateCode: "074",
    countryId: "120",
  },
  {
    id: "2164",
    name: "Priekuļi Municipality",
    stateCode: "075",
    countryId: "120",
  },
  {
    id: "2165",
    name: "Rauna Municipality",
    stateCode: "076",
    countryId: "120",
  },
  { id: "2166", name: "Rēzekne", stateCode: "REZ", countryId: "120" },
  {
    id: "2167",
    name: "Rēzekne Municipality",
    stateCode: "077",
    countryId: "120",
  },
  {
    id: "2168",
    name: "Riebiņi Municipality",
    stateCode: "078",
    countryId: "120",
  },
  { id: "2169", name: "Riga", stateCode: "RIX", countryId: "120" },
  { id: "2170", name: "Roja Municipality", stateCode: "079", countryId: "120" },
  {
    id: "2171",
    name: "Ropaži Municipality",
    stateCode: "080",
    countryId: "120",
  },
  {
    id: "2172",
    name: "Rucava Municipality",
    stateCode: "081",
    countryId: "120",
  },
  {
    id: "2173",
    name: "Rugāji Municipality",
    stateCode: "082",
    countryId: "120",
  },
  {
    id: "2174",
    name: "Rūjiena Municipality",
    stateCode: "084",
    countryId: "120",
  },
  {
    id: "2175",
    name: "Rundāle Municipality",
    stateCode: "083",
    countryId: "120",
  },
  { id: "2176", name: "Sala Municipality", stateCode: "085", countryId: "120" },
  {
    id: "2177",
    name: "Salacgrīva Municipality",
    stateCode: "086",
    countryId: "120",
  },
  {
    id: "2178",
    name: "Salaspils Municipality",
    stateCode: "087",
    countryId: "120",
  },
  {
    id: "2179",
    name: "Saldus Municipality",
    stateCode: "088",
    countryId: "120",
  },
  {
    id: "2180",
    name: "Saulkrasti Municipality",
    stateCode: "089",
    countryId: "120",
  },
  { id: "2181", name: "Sēja Municipality", stateCode: "090", countryId: "120" },
  {
    id: "2182",
    name: "Sigulda Municipality",
    stateCode: "091",
    countryId: "120",
  },
  {
    id: "2183",
    name: "Skrīveri Municipality",
    stateCode: "092",
    countryId: "120",
  },
  {
    id: "2184",
    name: "Skrunda Municipality",
    stateCode: "093",
    countryId: "120",
  },
  {
    id: "2185",
    name: "Smiltene Municipality",
    stateCode: "094",
    countryId: "120",
  },
  {
    id: "2186",
    name: "Stopiņi Municipality",
    stateCode: "095",
    countryId: "120",
  },
  {
    id: "2187",
    name: "Strenči Municipality",
    stateCode: "096",
    countryId: "120",
  },
  {
    id: "2188",
    name: "Talsi Municipality",
    stateCode: "097",
    countryId: "120",
  },
  {
    id: "2189",
    name: "Tērvete Municipality",
    stateCode: "098",
    countryId: "120",
  },
  {
    id: "2190",
    name: "Tukums Municipality",
    stateCode: "099",
    countryId: "120",
  },
  {
    id: "2191",
    name: "Vaiņode Municipality",
    stateCode: "100",
    countryId: "120",
  },
  {
    id: "2192",
    name: "Valka Municipality",
    stateCode: "101",
    countryId: "120",
  },
  { id: "2193", name: "Valmiera", stateCode: "VMR", countryId: "120" },
  {
    id: "2194",
    name: "Varakļāni Municipality",
    stateCode: "102",
    countryId: "120",
  },
  {
    id: "2195",
    name: "Vārkava Municipality",
    stateCode: "103",
    countryId: "120",
  },
  {
    id: "2196",
    name: "Vecpiebalga Municipality",
    stateCode: "104",
    countryId: "120",
  },
  {
    id: "2197",
    name: "Vecumnieki Municipality",
    stateCode: "105",
    countryId: "120",
  },
  { id: "2198", name: "Ventspils", stateCode: "VEN", countryId: "120" },
  {
    id: "2199",
    name: "Ventspils Municipality",
    stateCode: "106",
    countryId: "120",
  },
  {
    id: "2200",
    name: "Viesīte Municipality",
    stateCode: "107",
    countryId: "120",
  },
  {
    id: "2201",
    name: "Viļaka Municipality",
    stateCode: "108",
    countryId: "120",
  },
  {
    id: "2202",
    name: "Viļāni Municipality",
    stateCode: "109",
    countryId: "120",
  },
  {
    id: "2203",
    name: "Zilupe Municipality",
    stateCode: "110",
    countryId: "120",
  },
  { id: "2204", name: "Akkar", stateCode: "AK", countryId: "121" },
  { id: "2205", name: "Baalbek-Hermel", stateCode: "BH", countryId: "121" },
  { id: "2206", name: "Beirut", stateCode: "BA", countryId: "121" },
  { id: "2207", name: "Beqaa", stateCode: "BI", countryId: "121" },
  { id: "2208", name: "Mount Lebanon", stateCode: "JL", countryId: "121" },
  { id: "2209", name: "Nabatieh", stateCode: "NA", countryId: "121" },
  { id: "2210", name: "North", stateCode: "AS", countryId: "121" },
  { id: "2211", name: "South", stateCode: "JA", countryId: "121" },
  { id: "2212", name: "Berea District", stateCode: "D", countryId: "122" },
  {
    id: "2213",
    name: "Butha-Buthe District",
    stateCode: "B",
    countryId: "122",
  },
  { id: "2214", name: "Leribe District", stateCode: "C", countryId: "122" },
  { id: "2215", name: "Mafeteng District", stateCode: "E", countryId: "122" },
  { id: "2216", name: "Maseru District", stateCode: "A", countryId: "122" },
  {
    id: "2217",
    name: "Mohale\\'s Hoek District",
    stateCode: "F",
    countryId: "122",
  },
  { id: "2218", name: "Mokhotlong District", stateCode: "J", countryId: "122" },
  {
    id: "2219",
    name: "Qacha\\'s Nek District",
    stateCode: "H",
    countryId: "122",
  },
  { id: "2220", name: "Quthing District", stateCode: "G", countryId: "122" },
  {
    id: "2221",
    name: "Thaba-Tseka District",
    stateCode: "K",
    countryId: "122",
  },
  { id: "2222", name: "Bomi County", stateCode: "BM", countryId: "123" },
  { id: "2223", name: "Bong County", stateCode: "BG", countryId: "123" },
  { id: "2224", name: "Gbarpolu County", stateCode: "GP", countryId: "123" },
  { id: "2225", name: "Grand Bassa County", stateCode: "GB", countryId: "123" },
  {
    id: "2226",
    name: "Grand Cape Mount County",
    stateCode: "CM",
    countryId: "123",
  },
  { id: "2227", name: "Grand Gedeh County", stateCode: "GG", countryId: "123" },
  { id: "2228", name: "Grand Kru County", stateCode: "GK", countryId: "123" },
  { id: "2229", name: "Lofa County", stateCode: "LO", countryId: "123" },
  { id: "2230", name: "Margibi County", stateCode: "MG", countryId: "123" },
  { id: "2231", name: "Maryland County", stateCode: "MY", countryId: "123" },
  { id: "2232", name: "Montserrado County", stateCode: "MO", countryId: "123" },
  { id: "2233", name: "Nimba", stateCode: "NI", countryId: "123" },
  { id: "2234", name: "River Cess County", stateCode: "RI", countryId: "123" },
  { id: "2235", name: "River Gee County", stateCode: "RG", countryId: "123" },
  { id: "2236", name: "Sinoe County", stateCode: "SI", countryId: "123" },
  { id: "2237", name: "Al Wahat District", stateCode: "WA", countryId: "124" },
  { id: "2238", name: "Benghazi", stateCode: "BA", countryId: "124" },
  { id: "2239", name: "Derna District", stateCode: "DR", countryId: "124" },
  { id: "2240", name: "Ghat District", stateCode: "GT", countryId: "124" },
  { id: "2241", name: "Jabal al Akhdar", stateCode: "JA", countryId: "124" },
  {
    id: "2242",
    name: "Jabal al Gharbi District",
    stateCode: "JG",
    countryId: "124",
  },
  { id: "2243", name: "Jafara", stateCode: "JI", countryId: "124" },
  { id: "2244", name: "Jufra", stateCode: "JU", countryId: "124" },
  { id: "2245", name: "Kufra District", stateCode: "KF", countryId: "124" },
  { id: "2246", name: "Marj District", stateCode: "MJ", countryId: "124" },
  { id: "2247", name: "Misrata District", stateCode: "MI", countryId: "124" },
  { id: "2248", name: "Murqub", stateCode: "MB", countryId: "124" },
  { id: "2249", name: "Murzuq District", stateCode: "MQ", countryId: "124" },
  { id: "2250", name: "Nalut District", stateCode: "NL", countryId: "124" },
  { id: "2251", name: "Nuqat al Khams", stateCode: "NQ", countryId: "124" },
  { id: "2252", name: "Sabha District", stateCode: "SB", countryId: "124" },
  { id: "2253", name: "Sirte District", stateCode: "SR", countryId: "124" },
  { id: "2254", name: "Tripoli District", stateCode: "TB", countryId: "124" },
  {
    id: "2255",
    name: "Wadi al Hayaa District",
    stateCode: "WD",
    countryId: "124",
  },
  {
    id: "2256",
    name: "Wadi al Shatii District",
    stateCode: "WS",
    countryId: "124",
  },
  { id: "2257", name: "Zawiya District", stateCode: "ZA", countryId: "124" },
  { id: "2258", name: "Balzers", stateCode: "01", countryId: "125" },
  { id: "2259", name: "Eschen", stateCode: "02", countryId: "125" },
  { id: "2260", name: "Gamprin", stateCode: "03", countryId: "125" },
  { id: "2261", name: "Mauren", stateCode: "04", countryId: "125" },
  { id: "2262", name: "Planken", stateCode: "05", countryId: "125" },
  { id: "2263", name: "Ruggell", stateCode: "06", countryId: "125" },
  { id: "2264", name: "Schaan", stateCode: "07", countryId: "125" },
  { id: "2265", name: "Schellenberg", stateCode: "08", countryId: "125" },
  { id: "2266", name: "Triesen", stateCode: "09", countryId: "125" },
  { id: "2267", name: "Triesenberg", stateCode: "10", countryId: "125" },
  { id: "2268", name: "Vaduz", stateCode: "11", countryId: "125" },
  {
    id: "2269",
    name: "Akmenė District Municipality",
    stateCode: "01",
    countryId: "126",
  },
  {
    id: "2270",
    name: "Alytus City Municipality",
    stateCode: "02",
    countryId: "126",
  },
  { id: "2271", name: "Alytus County", stateCode: "AL", countryId: "126" },
  {
    id: "2272",
    name: "Alytus District Municipality",
    stateCode: "03",
    countryId: "126",
  },
  {
    id: "2273",
    name: "Birštonas Municipality",
    stateCode: "05",
    countryId: "126",
  },
  {
    id: "2274",
    name: "Biržai District Municipality",
    stateCode: "06",
    countryId: "126",
  },
  {
    id: "2275",
    name: "Druskininkai municipality",
    stateCode: "07",
    countryId: "126",
  },
  {
    id: "2276",
    name: "Elektrėnai municipality",
    stateCode: "08",
    countryId: "126",
  },
  {
    id: "2277",
    name: "Ignalina District Municipality",
    stateCode: "09",
    countryId: "126",
  },
  {
    id: "2278",
    name: "Jonava District Municipality",
    stateCode: "10",
    countryId: "126",
  },
  {
    id: "2279",
    name: "Joniškis District Municipality",
    stateCode: "11",
    countryId: "126",
  },
  {
    id: "2280",
    name: "Jurbarkas District Municipality",
    stateCode: "12",
    countryId: "126",
  },
  {
    id: "2281",
    name: "Kaišiadorys District Municipality",
    stateCode: "13",
    countryId: "126",
  },
  {
    id: "2282",
    name: "Kalvarija municipality",
    stateCode: "14",
    countryId: "126",
  },
  {
    id: "2283",
    name: "Kaunas City Municipality",
    stateCode: "15",
    countryId: "126",
  },
  { id: "2284", name: "Kaunas County", stateCode: "KU", countryId: "126" },
  {
    id: "2285",
    name: "Kaunas District Municipality",
    stateCode: "16",
    countryId: "126",
  },
  {
    id: "2286",
    name: "Kazlų Rūda municipality",
    stateCode: "17",
    countryId: "126",
  },
  {
    id: "2287",
    name: "Kėdainiai District Municipality",
    stateCode: "18",
    countryId: "126",
  },
  {
    id: "2288",
    name: "Kelmė District Municipality",
    stateCode: "19",
    countryId: "126",
  },
  {
    id: "2289",
    name: "Klaipeda City Municipality",
    stateCode: "20",
    countryId: "126",
  },
  { id: "2290", name: "Klaipėda County", stateCode: "KL", countryId: "126" },
  {
    id: "2291",
    name: "Klaipėda District Municipality",
    stateCode: "21",
    countryId: "126",
  },
  {
    id: "2292",
    name: "Kretinga District Municipality",
    stateCode: "22",
    countryId: "126",
  },
  {
    id: "2293",
    name: "Kupiškis District Municipality",
    stateCode: "23",
    countryId: "126",
  },
  {
    id: "2294",
    name: "Lazdijai District Municipality",
    stateCode: "24",
    countryId: "126",
  },
  { id: "2295", name: "Marijampolė County", stateCode: "MR", countryId: "126" },
  {
    id: "2296",
    name: "Marijampolė Municipality",
    stateCode: "25",
    countryId: "126",
  },
  {
    id: "2297",
    name: "Mažeikiai District Municipality",
    stateCode: "26",
    countryId: "126",
  },
  {
    id: "2298",
    name: "Molėtai District Municipality",
    stateCode: "27",
    countryId: "126",
  },
  {
    id: "2299",
    name: "Neringa Municipality",
    stateCode: "28",
    countryId: "126",
  },
  {
    id: "2300",
    name: "Pagėgiai municipality",
    stateCode: "29",
    countryId: "126",
  },
  {
    id: "2301",
    name: "Pakruojis District Municipality",
    stateCode: "30",
    countryId: "126",
  },
  {
    id: "2302",
    name: "Palanga City Municipality",
    stateCode: "31",
    countryId: "126",
  },
  {
    id: "2303",
    name: "Panevėžys City Municipality",
    stateCode: "32",
    countryId: "126",
  },
  { id: "2304", name: "Panevėžys County", stateCode: "PN", countryId: "126" },
  {
    id: "2305",
    name: "Panevėžys District Municipality",
    stateCode: "33",
    countryId: "126",
  },
  {
    id: "2306",
    name: "Pasvalys District Municipality",
    stateCode: "34",
    countryId: "126",
  },
  {
    id: "2307",
    name: "Plungė District Municipality",
    stateCode: "35",
    countryId: "126",
  },
  {
    id: "2308",
    name: "Prienai District Municipality",
    stateCode: "36",
    countryId: "126",
  },
  {
    id: "2309",
    name: "Radviliškis District Municipality",
    stateCode: "37",
    countryId: "126",
  },
  {
    id: "2310",
    name: "Raseiniai District Municipality",
    stateCode: "38",
    countryId: "126",
  },
  {
    id: "2311",
    name: "Rietavas municipality",
    stateCode: "39",
    countryId: "126",
  },
  {
    id: "2312",
    name: "Rokiškis District Municipality",
    stateCode: "40",
    countryId: "126",
  },
  {
    id: "2313",
    name: "Šakiai District Municipality",
    stateCode: "41",
    countryId: "126",
  },
  {
    id: "2314",
    name: "Šalčininkai District Municipality",
    stateCode: "42",
    countryId: "126",
  },
  {
    id: "2315",
    name: "Šiauliai City Municipality",
    stateCode: "43",
    countryId: "126",
  },
  { id: "2316", name: "Šiauliai County", stateCode: "SA", countryId: "126" },
  {
    id: "2317",
    name: "Šiauliai District Municipality",
    stateCode: "44",
    countryId: "126",
  },
  {
    id: "2318",
    name: "Šilalė District Municipality",
    stateCode: "45",
    countryId: "126",
  },
  {
    id: "2319",
    name: "Šilutė District Municipality",
    stateCode: "46",
    countryId: "126",
  },
  {
    id: "2320",
    name: "Širvintos District Municipality",
    stateCode: "47",
    countryId: "126",
  },
  {
    id: "2321",
    name: "Skuodas District Municipality",
    stateCode: "48",
    countryId: "126",
  },
  {
    id: "2322",
    name: "Švenčionys District Municipality",
    stateCode: "49",
    countryId: "126",
  },
  { id: "2323", name: "Tauragė County", stateCode: "TA", countryId: "126" },
  {
    id: "2324",
    name: "Tauragė District Municipality",
    stateCode: "50",
    countryId: "126",
  },
  { id: "2325", name: "Telšiai County", stateCode: "TE", countryId: "126" },
  {
    id: "2326",
    name: "Telšiai District Municipality",
    stateCode: "51",
    countryId: "126",
  },
  {
    id: "2327",
    name: "Trakai District Municipality",
    stateCode: "52",
    countryId: "126",
  },
  {
    id: "2328",
    name: "Ukmergė District Municipality",
    stateCode: "53",
    countryId: "126",
  },
  { id: "2329", name: "Utena County", stateCode: "UT", countryId: "126" },
  {
    id: "2330",
    name: "Utena District Municipality",
    stateCode: "54",
    countryId: "126",
  },
  {
    id: "2331",
    name: "Varėna District Municipality",
    stateCode: "55",
    countryId: "126",
  },
  {
    id: "2332",
    name: "Vilkaviškis District Municipality",
    stateCode: "56",
    countryId: "126",
  },
  {
    id: "2333",
    name: "Vilnius City Municipality",
    stateCode: "57",
    countryId: "126",
  },
  { id: "2334", name: "Vilnius County", stateCode: "VL", countryId: "126" },
  {
    id: "2335",
    name: "Vilnius District Municipality",
    stateCode: "58",
    countryId: "126",
  },
  {
    id: "2336",
    name: "Visaginas Municipality",
    stateCode: "59",
    countryId: "126",
  },
  {
    id: "2337",
    name: "Zarasai District Municipality",
    stateCode: "60",
    countryId: "126",
  },
  { id: "2338", name: "Canton of Capellen", stateCode: "CA", countryId: "127" },
  { id: "2339", name: "Canton of Clervaux", stateCode: "CL", countryId: "127" },
  { id: "2340", name: "Canton of Diekirch", stateCode: "DI", countryId: "127" },
  {
    id: "2341",
    name: "Canton of Echternach",
    stateCode: "EC",
    countryId: "127",
  },
  {
    id: "2342",
    name: "Canton of Esch-sur-Alzette",
    stateCode: "ES",
    countryId: "127",
  },
  {
    id: "2343",
    name: "Canton of Grevenmacher",
    stateCode: "GR",
    countryId: "127",
  },
  {
    id: "2344",
    name: "Canton of Luxembourg",
    stateCode: "LU",
    countryId: "127",
  },
  { id: "2345", name: "Canton of Mersch", stateCode: "ME", countryId: "127" },
  { id: "2346", name: "Canton of Redange", stateCode: "RD", countryId: "127" },
  { id: "2347", name: "Canton of Remich", stateCode: "RM", countryId: "127" },
  { id: "2348", name: "Canton of Vianden", stateCode: "VD", countryId: "127" },
  { id: "2349", name: "Canton of Wiltz", stateCode: "WI", countryId: "127" },
  { id: "2350", name: "Diekirch District", stateCode: "D", countryId: "127" },
  {
    id: "2351",
    name: "Grevenmacher District",
    stateCode: "G",
    countryId: "127",
  },
  { id: "2352", name: "Luxembourg District", stateCode: "L", countryId: "127" },
  {
    id: "2353",
    name: "Antananarivo Province",
    stateCode: "T",
    countryId: "129",
  },
  {
    id: "2354",
    name: "Antsiranana Province",
    stateCode: "D",
    countryId: "129",
  },
  {
    id: "2355",
    name: "Fianarantsoa Province",
    stateCode: "F",
    countryId: "129",
  },
  { id: "2356", name: "Mahajanga Province", stateCode: "M", countryId: "129" },
  { id: "2357", name: "Toamasina Province", stateCode: "A", countryId: "129" },
  { id: "2358", name: "Toliara Province", stateCode: "U", countryId: "129" },
  { id: "2359", name: "Balaka District", stateCode: "BA", countryId: "130" },
  { id: "2360", name: "Blantyre District", stateCode: "BL", countryId: "130" },
  { id: "2361", name: "Central Region", stateCode: "C", countryId: "130" },
  { id: "2362", name: "Chikwawa District", stateCode: "CK", countryId: "130" },
  {
    id: "2363",
    name: "Chiradzulu District",
    stateCode: "CR",
    countryId: "130",
  },
  { id: "2364", name: "Chitipa district", stateCode: "CT", countryId: "130" },
  { id: "2365", name: "Dedza District", stateCode: "DE", countryId: "130" },
  { id: "2366", name: "Dowa District", stateCode: "DO", countryId: "130" },
  { id: "2367", name: "Karonga District", stateCode: "KR", countryId: "130" },
  { id: "2368", name: "Kasungu District", stateCode: "KS", countryId: "130" },
  { id: "2369", name: "Likoma District", stateCode: "LK", countryId: "130" },
  { id: "2370", name: "Lilongwe District", stateCode: "LI", countryId: "130" },
  { id: "2371", name: "Machinga District", stateCode: "MH", countryId: "130" },
  { id: "2372", name: "Mangochi District", stateCode: "MG", countryId: "130" },
  { id: "2373", name: "Mchinji District", stateCode: "MC", countryId: "130" },
  { id: "2374", name: "Mulanje District", stateCode: "MU", countryId: "130" },
  { id: "2375", name: "Mwanza District", stateCode: "MW", countryId: "130" },
  { id: "2376", name: "Mzimba District", stateCode: "MZ", countryId: "130" },
  {
    id: "2377",
    name: "Nkhata Bay District",
    stateCode: "NB",
    countryId: "130",
  },
  {
    id: "2378",
    name: "Nkhotakota District",
    stateCode: "NK",
    countryId: "130",
  },
  { id: "2379", name: "Northern Region", stateCode: "N", countryId: "130" },
  { id: "2380", name: "Nsanje District", stateCode: "NS", countryId: "130" },
  { id: "2381", name: "Ntcheu District", stateCode: "NU", countryId: "130" },
  { id: "2382", name: "Ntchisi District", stateCode: "NI", countryId: "130" },
  { id: "2383", name: "Phalombe District", stateCode: "PH", countryId: "130" },
  { id: "2384", name: "Rumphi District", stateCode: "RU", countryId: "130" },
  { id: "2385", name: "Salima District", stateCode: "SA", countryId: "130" },
  { id: "2386", name: "Southern Region", stateCode: "S", countryId: "130" },
  { id: "2387", name: "Thyolo District", stateCode: "TH", countryId: "130" },
  { id: "2388", name: "Zomba District", stateCode: "ZO", countryId: "130" },
  { id: "2389", name: "Johor", stateCode: "01", countryId: "131" },
  { id: "2390", name: "Kedah", stateCode: "02", countryId: "131" },
  { id: "2391", name: "Kelantan", stateCode: "03", countryId: "131" },
  { id: "2392", name: "Kuala Lumpur", stateCode: "14", countryId: "131" },
  { id: "2393", name: "Labuan", stateCode: "15", countryId: "131" },
  { id: "2394", name: "Malacca", stateCode: "04", countryId: "131" },
  { id: "2395", name: "Negeri Sembilan", stateCode: "05", countryId: "131" },
  { id: "2396", name: "Pahang", stateCode: "06", countryId: "131" },
  { id: "2397", name: "Penang", stateCode: "07", countryId: "131" },
  { id: "2398", name: "Perak", stateCode: "08", countryId: "131" },
  { id: "2399", name: "Perlis", stateCode: "09", countryId: "131" },
  { id: "2400", name: "Putrajaya", stateCode: "16", countryId: "131" },
  { id: "2401", name: "Sabah", stateCode: "12", countryId: "131" },
  { id: "2402", name: "Sarawak", stateCode: "13", countryId: "131" },
  { id: "2403", name: "Selangor", stateCode: "10", countryId: "131" },
  { id: "2404", name: "Terengganu", stateCode: "11", countryId: "131" },
  { id: "2405", name: "Addu Atoll", stateCode: "01", countryId: "132" },
  { id: "2406", name: "Alif Alif Atoll", stateCode: "02", countryId: "132" },
  { id: "2407", name: "Alif Dhaal Atoll", stateCode: "00", countryId: "132" },
  { id: "2408", name: "Central Province", stateCode: "CE", countryId: "132" },
  { id: "2409", name: "Dhaalu Atoll", stateCode: "17", countryId: "132" },
  { id: "2410", name: "Faafu Atoll", stateCode: "14", countryId: "132" },
  { id: "2411", name: "Gaafu Alif Atoll", stateCode: "27", countryId: "132" },
  { id: "2412", name: "Gaafu Dhaalu Atoll", stateCode: "28", countryId: "132" },
  { id: "2413", name: "Gnaviyani Atoll", stateCode: "29", countryId: "132" },
  { id: "2414", name: "Haa Alif Atoll", stateCode: "07", countryId: "132" },
  { id: "2415", name: "Haa Dhaalu Atoll", stateCode: "23", countryId: "132" },
  { id: "2416", name: "Kaafu Atoll", stateCode: "26", countryId: "132" },
  { id: "2417", name: "Laamu Atoll", stateCode: "05", countryId: "132" },
  { id: "2418", name: "Lhaviyani Atoll", stateCode: "03", countryId: "132" },
  { id: "2419", name: "Malé", stateCode: "MLE", countryId: "132" },
  { id: "2420", name: "Meemu Atoll", stateCode: "12", countryId: "132" },
  { id: "2421", name: "Noonu Atoll", stateCode: "25", countryId: "132" },
  {
    id: "2422",
    name: "North Central Province",
    stateCode: "NC",
    countryId: "132",
  },
  { id: "2423", name: "North Province", stateCode: "NO", countryId: "132" },
  { id: "2424", name: "Raa Atoll", stateCode: "13", countryId: "132" },
  { id: "2425", name: "Shaviyani Atoll", stateCode: "24", countryId: "132" },
  {
    id: "2426",
    name: "South Central Province",
    stateCode: "SC",
    countryId: "132",
  },
  { id: "2427", name: "South Province", stateCode: "SU", countryId: "132" },
  { id: "2428", name: "Thaa Atoll", stateCode: "08", countryId: "132" },
  {
    id: "2429",
    name: "Upper South Province",
    stateCode: "US",
    countryId: "132",
  },
  { id: "2430", name: "Vaavu Atoll", stateCode: "04", countryId: "132" },
  { id: "2431", name: "Bamako", stateCode: "BKO", countryId: "133" },
  { id: "2432", name: "Gao Region", stateCode: "7", countryId: "133" },
  { id: "2433", name: "Kayes Region", stateCode: "1", countryId: "133" },
  { id: "2434", name: "Kidal Region", stateCode: "8", countryId: "133" },
  { id: "2435", name: "Koulikoro Region", stateCode: "2", countryId: "133" },
  { id: "2436", name: "Ménaka Region", stateCode: "9", countryId: "133" },
  { id: "2437", name: "Mopti Region", stateCode: "5", countryId: "133" },
  { id: "2438", name: "Ségou Region", stateCode: "4", countryId: "133" },
  { id: "2439", name: "Sikasso Region", stateCode: "3", countryId: "133" },
  { id: "2440", name: "Taoudénit Region", stateCode: "10", countryId: "133" },
  { id: "2441", name: "Tombouctou Region", stateCode: "6", countryId: "133" },
  { id: "2442", name: "Attard", stateCode: "01", countryId: "134" },
  { id: "2443", name: "Balzan", stateCode: "02", countryId: "134" },
  { id: "2444", name: "Birgu", stateCode: "03", countryId: "134" },
  { id: "2445", name: "Birkirkara", stateCode: "04", countryId: "134" },
  { id: "2446", name: "Birżebbuġa", stateCode: "05", countryId: "134" },
  { id: "2447", name: "Cospicua", stateCode: "06", countryId: "134" },
  { id: "2448", name: "Dingli", stateCode: "07", countryId: "134" },
  { id: "2449", name: "Fgura", stateCode: "08", countryId: "134" },
  { id: "2450", name: "Floriana", stateCode: "09", countryId: "134" },
  { id: "2451", name: "Fontana", stateCode: "10", countryId: "134" },
  { id: "2452", name: "Għajnsielem", stateCode: "13", countryId: "134" },
  { id: "2453", name: "Għarb", stateCode: "14", countryId: "134" },
  { id: "2454", name: "Għargħur", stateCode: "15", countryId: "134" },
  { id: "2455", name: "Għasri", stateCode: "16", countryId: "134" },
  { id: "2456", name: "Għaxaq", stateCode: "17", countryId: "134" },
  { id: "2457", name: "Gudja", stateCode: "11", countryId: "134" },
  { id: "2458", name: "Gżira", stateCode: "12", countryId: "134" },
  { id: "2459", name: "Ħamrun", stateCode: "18", countryId: "134" },
  { id: "2460", name: "Iklin", stateCode: "19", countryId: "134" },
  { id: "2461", name: "Kalkara", stateCode: "21", countryId: "134" },
  { id: "2462", name: "Kerċem", stateCode: "22", countryId: "134" },
  { id: "2463", name: "Kirkop", stateCode: "23", countryId: "134" },
  { id: "2464", name: "Lija", stateCode: "24", countryId: "134" },
  { id: "2465", name: "Luqa", stateCode: "25", countryId: "134" },
  { id: "2466", name: "Marsa", stateCode: "26", countryId: "134" },
  { id: "2467", name: "Marsaskala", stateCode: "27", countryId: "134" },
  { id: "2468", name: "Marsaxlokk", stateCode: "28", countryId: "134" },
  { id: "2469", name: "Mdina", stateCode: "29", countryId: "134" },
  { id: "2470", name: "Mellieħa", stateCode: "30", countryId: "134" },
  { id: "2471", name: "Mġarr", stateCode: "31", countryId: "134" },
  { id: "2472", name: "Mosta", stateCode: "32", countryId: "134" },
  { id: "2473", name: "Mqabba", stateCode: "33", countryId: "134" },
  { id: "2474", name: "Msida", stateCode: "34", countryId: "134" },
  { id: "2475", name: "Mtarfa", stateCode: "35", countryId: "134" },
  { id: "2476", name: "Munxar", stateCode: "36", countryId: "134" },
  { id: "2477", name: "Nadur", stateCode: "37", countryId: "134" },
  { id: "2478", name: "Naxxar", stateCode: "38", countryId: "134" },
  { id: "2479", name: "Paola", stateCode: "39", countryId: "134" },
  { id: "2480", name: "Pembroke", stateCode: "40", countryId: "134" },
  { id: "2481", name: "Pietà", stateCode: "41", countryId: "134" },
  { id: "2482", name: "Qala", stateCode: "42", countryId: "134" },
  { id: "2483", name: "Qormi", stateCode: "43", countryId: "134" },
  { id: "2484", name: "Qrendi", stateCode: "44", countryId: "134" },
  { id: "2485", name: "Rabat", stateCode: "46", countryId: "134" },
  { id: "2486", name: "Saint Lawrence", stateCode: "50", countryId: "134" },
  { id: "2487", name: "San Ġwann", stateCode: "49", countryId: "134" },
  { id: "2488", name: "Sannat", stateCode: "52", countryId: "134" },
  { id: "2489", name: "Santa Luċija", stateCode: "53", countryId: "134" },
  { id: "2490", name: "Santa Venera", stateCode: "54", countryId: "134" },
  { id: "2491", name: "Senglea", stateCode: "20", countryId: "134" },
  { id: "2492", name: "Siġġiewi", stateCode: "55", countryId: "134" },
  { id: "2493", name: "Sliema", stateCode: "56", countryId: "134" },
  { id: "2494", name: "St. Julian\\'s", stateCode: "48", countryId: "134" },
  { id: "2495", name: "St. Paul\\'s Bay", stateCode: "51", countryId: "134" },
  { id: "2496", name: "Swieqi", stateCode: "57", countryId: "134" },
  { id: "2497", name: "Ta\\' Xbiex", stateCode: "58", countryId: "134" },
  { id: "2498", name: "Tarxien", stateCode: "59", countryId: "134" },
  { id: "2499", name: "Valletta", stateCode: "60", countryId: "134" },
  { id: "2500", name: "Victoria", stateCode: "45", countryId: "134" },
  { id: "2501", name: "Xagħra", stateCode: "61", countryId: "134" },
  { id: "2502", name: "Xewkija", stateCode: "62", countryId: "134" },
  { id: "2503", name: "Xgħajra", stateCode: "63", countryId: "134" },
  { id: "2504", name: "Żabbar", stateCode: "64", countryId: "134" },
  { id: "2505", name: "Żebbuġ Gozo", stateCode: "65", countryId: "134" },
  { id: "2506", name: "Żebbuġ Malta", stateCode: "66", countryId: "134" },
  { id: "2507", name: "Żejtun", stateCode: "67", countryId: "134" },
  { id: "2508", name: "Żurrieq", stateCode: "68", countryId: "134" },
  { id: "2509", name: "Ralik Chain", stateCode: "L", countryId: "136" },
  { id: "2510", name: "Ratak Chain", stateCode: "T", countryId: "136" },
  { id: "2511", name: "Adrar", stateCode: "07", countryId: "138" },
  { id: "2512", name: "Assaba", stateCode: "03", countryId: "138" },
  { id: "2513", name: "Brakna", stateCode: "05", countryId: "138" },
  { id: "2514", name: "Dakhlet Nouadhibou", stateCode: "08", countryId: "138" },
  { id: "2515", name: "Gorgol", stateCode: "04", countryId: "138" },
  { id: "2516", name: "Guidimaka", stateCode: "10", countryId: "138" },
  { id: "2517", name: "Hodh Ech Chargui", stateCode: "01", countryId: "138" },
  { id: "2518", name: "Hodh El Gharbi", stateCode: "02", countryId: "138" },
  { id: "2519", name: "Inchiri", stateCode: "12", countryId: "138" },
  { id: "2520", name: "Nouakchott-Nord", stateCode: "14", countryId: "138" },
  { id: "2521", name: "Nouakchott-Ouest", stateCode: "13", countryId: "138" },
  { id: "2522", name: "Nouakchott-Sud", stateCode: "15", countryId: "138" },
  { id: "2523", name: "Tagant", stateCode: "09", countryId: "138" },
  { id: "2524", name: "Tiris Zemmour", stateCode: "11", countryId: "138" },
  { id: "2525", name: "Trarza", stateCode: "06", countryId: "138" },
  { id: "2526", name: "Agalega Islands", stateCode: "AG", countryId: "139" },
  { id: "2527", name: "Black River", stateCode: "BL", countryId: "139" },
  { id: "2528", name: "Flacq", stateCode: "FL", countryId: "139" },
  { id: "2529", name: "Grand Port", stateCode: "GP", countryId: "139" },
  { id: "2530", name: "Moka", stateCode: "MO", countryId: "139" },
  { id: "2531", name: "Pamplemousses", stateCode: "PA", countryId: "139" },
  { id: "2532", name: "Plaines Wilhems", stateCode: "PW", countryId: "139" },
  { id: "2533", name: "Port Louis", stateCode: "PL", countryId: "139" },
  { id: "2534", name: "Rivière du Rempart", stateCode: "RR", countryId: "139" },
  { id: "2535", name: "Rodrigues Island", stateCode: "RO", countryId: "139" },
  {
    id: "2536",
    name: "Saint Brandon Islands",
    stateCode: "CC",
    countryId: "139",
  },
  { id: "2537", name: "Savanne", stateCode: "SA", countryId: "139" },
  { id: "2538", name: "Aguascalientes", stateCode: "AGU", countryId: "141" },
  { id: "2539", name: "Baja California", stateCode: "BCN", countryId: "141" },
  {
    id: "2540",
    name: "Baja California Sur",
    stateCode: "BCS",
    countryId: "141",
  },
  { id: "2541", name: "Campeche", stateCode: "CAM", countryId: "141" },
  { id: "2542", name: "Chiapas", stateCode: "CHP", countryId: "141" },
  { id: "2543", name: "Chihuahua", stateCode: "CHH", countryId: "141" },
  { id: "2544", name: "Ciudad de México", stateCode: "CMX", countryId: "141" },
  {
    id: "2545",
    name: "Coahuila de Zaragoza",
    stateCode: "COA",
    countryId: "141",
  },
  { id: "2546", name: "Colima", stateCode: "COL", countryId: "141" },
  { id: "2547", name: "Durango", stateCode: "DUR", countryId: "141" },
  { id: "2548", name: "Estado de México", stateCode: "MEX", countryId: "141" },
  { id: "2549", name: "Guanajuato", stateCode: "GUA", countryId: "141" },
  { id: "2550", name: "Guerrero", stateCode: "GRO", countryId: "141" },
  { id: "2551", name: "Hidalgo", stateCode: "HID", countryId: "141" },
  { id: "2552", name: "Jalisco", stateCode: "JAL", countryId: "141" },
  {
    id: "2553",
    name: "Michoacán de Ocampo",
    stateCode: "MIC",
    countryId: "141",
  },
  { id: "2554", name: "Morelos", stateCode: "MOR", countryId: "141" },
  { id: "2555", name: "Nayarit", stateCode: "NAY", countryId: "141" },
  { id: "2556", name: "Nuevo León", stateCode: "NLE", countryId: "141" },
  { id: "2557", name: "Oaxaca", stateCode: "OAX", countryId: "141" },
  { id: "2558", name: "Puebla", stateCode: "PUE", countryId: "141" },
  { id: "2559", name: "Querétaro", stateCode: "QUE", countryId: "141" },
  { id: "2560", name: "Quintana Roo", stateCode: "ROO", countryId: "141" },
  { id: "2561", name: "San Luis Potosí", stateCode: "SLP", countryId: "141" },
  { id: "2562", name: "Sinaloa", stateCode: "SIN", countryId: "141" },
  { id: "2563", name: "Sonora", stateCode: "SON", countryId: "141" },
  { id: "2564", name: "Tabasco", stateCode: "TAB", countryId: "141" },
  { id: "2565", name: "Tamaulipas", stateCode: "TAM", countryId: "141" },
  { id: "2566", name: "Tlaxcala", stateCode: "TLA", countryId: "141" },
  {
    id: "2567",
    name: "Veracruz de Ignacio de la Llave",
    stateCode: "VER",
    countryId: "141",
  },
  { id: "2568", name: "Yucatán", stateCode: "YUC", countryId: "141" },
  { id: "2569", name: "Zacatecas", stateCode: "ZAC", countryId: "141" },
  { id: "2570", name: "Chuuk State", stateCode: "TRK", countryId: "142" },
  { id: "2571", name: "Kosrae State", stateCode: "KSA", countryId: "142" },
  { id: "2572", name: "Pohnpei State", stateCode: "PNI", countryId: "142" },
  { id: "2573", name: "Yap State", stateCode: "YAP", countryId: "142" },
  {
    id: "2574",
    name: "Anenii Noi District",
    stateCode: "AN",
    countryId: "143",
  },
  { id: "2575", name: "Bălți Municipality", stateCode: "BA", countryId: "143" },
  {
    id: "2576",
    name: "Basarabeasca District",
    stateCode: "BS",
    countryId: "143",
  },
  {
    id: "2577",
    name: "Bender Municipality",
    stateCode: "BD",
    countryId: "143",
  },
  { id: "2578", name: "Briceni District", stateCode: "BR", countryId: "143" },
  { id: "2579", name: "Cahul District", stateCode: "CA", countryId: "143" },
  { id: "2580", name: "Călărași District", stateCode: "CL", countryId: "143" },
  { id: "2581", name: "Cantemir District", stateCode: "CT", countryId: "143" },
  { id: "2582", name: "Căușeni District", stateCode: "CS", countryId: "143" },
  {
    id: "2583",
    name: "Chișinău Municipality",
    stateCode: "CU",
    countryId: "143",
  },
  { id: "2584", name: "Cimișlia District", stateCode: "CM", countryId: "143" },
  { id: "2585", name: "Criuleni District", stateCode: "CR", countryId: "143" },
  { id: "2586", name: "Dondușeni District", stateCode: "DO", countryId: "143" },
  { id: "2587", name: "Drochia District", stateCode: "DR", countryId: "143" },
  { id: "2588", name: "Dubăsari District", stateCode: "DU", countryId: "143" },
  { id: "2589", name: "Edineț District", stateCode: "ED", countryId: "143" },
  { id: "2590", name: "Fălești District", stateCode: "FA", countryId: "143" },
  { id: "2591", name: "Florești District", stateCode: "FL", countryId: "143" },
  { id: "2592", name: "Gagauzia", stateCode: "GA", countryId: "143" },
  { id: "2593", name: "Glodeni District", stateCode: "GL", countryId: "143" },
  { id: "2594", name: "Hîncești District", stateCode: "HI", countryId: "143" },
  { id: "2595", name: "Ialoveni District", stateCode: "IA", countryId: "143" },
  { id: "2596", name: "Nisporeni District", stateCode: "NI", countryId: "143" },
  { id: "2597", name: "Ocnița District", stateCode: "OC", countryId: "143" },
  { id: "2598", name: "Orhei District", stateCode: "OR", countryId: "143" },
  { id: "2599", name: "Rezina District", stateCode: "RE", countryId: "143" },
  { id: "2600", name: "Rîșcani District", stateCode: "RI", countryId: "143" },
  { id: "2601", name: "Sîngerei District", stateCode: "SI", countryId: "143" },
  {
    id: "2602",
    name: "Șoldănești District",
    stateCode: "SD",
    countryId: "143",
  },
  { id: "2603", name: "Soroca District", stateCode: "SO", countryId: "143" },
  {
    id: "2604",
    name: "Ștefan Vodă District",
    stateCode: "SV",
    countryId: "143",
  },
  { id: "2605", name: "Strășeni District", stateCode: "ST", countryId: "143" },
  { id: "2606", name: "Taraclia District", stateCode: "TA", countryId: "143" },
  { id: "2607", name: "Telenești District", stateCode: "TE", countryId: "143" },
  {
    id: "2608",
    name: "Transnistria autonomous territorial unit",
    stateCode: "SN",
    countryId: "143",
  },
  { id: "2609", name: "Ungheni District", stateCode: "UN", countryId: "143" },
  { id: "2610", name: "La Colle", stateCode: "CL", countryId: "144" },
  { id: "2611", name: "La Condamine", stateCode: "CO", countryId: "144" },
  { id: "2612", name: "Moneghetti", stateCode: "MG", countryId: "144" },
  {
    id: "2613",
    name: "Arkhangai Province",
    stateCode: "073",
    countryId: "145",
  },
  {
    id: "2614",
    name: "Bayan-Ölgii Province",
    stateCode: "071",
    countryId: "145",
  },
  {
    id: "2615",
    name: "Bayankhongor Province",
    stateCode: "069",
    countryId: "145",
  },
  { id: "2616", name: "Bulgan Province", stateCode: "067", countryId: "145" },
  {
    id: "2617",
    name: "Darkhan-Uul Province",
    stateCode: "037",
    countryId: "145",
  },
  { id: "2618", name: "Dornod Province", stateCode: "061", countryId: "145" },
  {
    id: "2619",
    name: "Dornogovi Province",
    stateCode: "063",
    countryId: "145",
  },
  { id: "2620", name: "Dundgovi Province", stateCode: "059", countryId: "145" },
  {
    id: "2621",
    name: "Govi-Altai Province",
    stateCode: "065",
    countryId: "145",
  },
  {
    id: "2622",
    name: "Govisümber Province",
    stateCode: "064",
    countryId: "145",
  },
  { id: "2623", name: "Khentii Province", stateCode: "039", countryId: "145" },
  { id: "2624", name: "Khovd Province", stateCode: "043", countryId: "145" },
  { id: "2625", name: "Khövsgöl Province", stateCode: "041", countryId: "145" },
  { id: "2626", name: "Ömnögovi Province", stateCode: "053", countryId: "145" },
  { id: "2627", name: "Orkhon Province", stateCode: "035", countryId: "145" },
  {
    id: "2628",
    name: "Övörkhangai Province",
    stateCode: "055",
    countryId: "145",
  },
  { id: "2629", name: "Selenge Province", stateCode: "049", countryId: "145" },
  {
    id: "2630",
    name: "Sükhbaatar Province",
    stateCode: "051",
    countryId: "145",
  },
  { id: "2631", name: "Töv Province", stateCode: "047", countryId: "145" },
  { id: "2632", name: "Uvs Province", stateCode: "046", countryId: "145" },
  { id: "2633", name: "Zavkhan Province", stateCode: "057", countryId: "145" },
  {
    id: "2634",
    name: "Andrijevica Municipality",
    stateCode: "01",
    countryId: "146",
  },
  { id: "2635", name: "Bar Municipality", stateCode: "02", countryId: "146" },
  {
    id: "2636",
    name: "Berane Municipality",
    stateCode: "03",
    countryId: "146",
  },
  {
    id: "2637",
    name: "Bijelo Polje Municipality",
    stateCode: "04",
    countryId: "146",
  },
  { id: "2638", name: "Budva Municipality", stateCode: "05", countryId: "146" },
  {
    id: "2639",
    name: "Danilovgrad Municipality",
    stateCode: "07",
    countryId: "146",
  },
  {
    id: "2640",
    name: "Gusinje Municipality",
    stateCode: "22",
    countryId: "146",
  },
  {
    id: "2641",
    name: "Kolašin Municipality",
    stateCode: "09",
    countryId: "146",
  },
  { id: "2642", name: "Kotor Municipality", stateCode: "10", countryId: "146" },
  {
    id: "2643",
    name: "Mojkovac Municipality",
    stateCode: "11",
    countryId: "146",
  },
  {
    id: "2644",
    name: "Nikšić Municipality",
    stateCode: "12",
    countryId: "146",
  },
  {
    id: "2645",
    name: "Old Royal Capital Cetinje",
    stateCode: "06",
    countryId: "146",
  },
  {
    id: "2646",
    name: "Petnjica Municipality",
    stateCode: "23",
    countryId: "146",
  },
  { id: "2647", name: "Plav Municipality", stateCode: "13", countryId: "146" },
  {
    id: "2648",
    name: "Pljevlja Municipality",
    stateCode: "14",
    countryId: "146",
  },
  {
    id: "2649",
    name: "Plužine Municipality",
    stateCode: "15",
    countryId: "146",
  },
  {
    id: "2650",
    name: "Podgorica Municipality",
    stateCode: "16",
    countryId: "146",
  },
  {
    id: "2651",
    name: "Rožaje Municipality",
    stateCode: "17",
    countryId: "146",
  },
  {
    id: "2652",
    name: "Šavnik Municipality",
    stateCode: "18",
    countryId: "146",
  },
  { id: "2653", name: "Tivat Municipality", stateCode: "19", countryId: "146" },
  {
    id: "2654",
    name: "Ulcinj Municipality",
    stateCode: "20",
    countryId: "146",
  },
  {
    id: "2655",
    name: "Žabljak Municipality",
    stateCode: "21",
    countryId: "146",
  },
  {
    id: "2656",
    name: "Agadir-Ida-Ou-Tanane",
    stateCode: "AGD",
    countryId: "148",
  },
  { id: "2657", name: "Al Haouz", stateCode: "HAO", countryId: "148" },
  { id: "2658", name: "Al Hoceïma", stateCode: "HOC", countryId: "148" },
  { id: "2659", name: "Aousserd (EH)", stateCode: "AOU", countryId: "148" },
  {
    id: "2660",
    name: "Assa-Zag (EH-partial)",
    stateCode: "ASZ",
    countryId: "148",
  },
  { id: "2661", name: "Azilal", stateCode: "AZI", countryId: "148" },
  { id: "2662", name: "Béni Mellal", stateCode: "BEM", countryId: "148" },
  {
    id: "2663",
    name: "Béni Mellal-Khénifra",
    stateCode: "05",
    countryId: "148",
  },
  { id: "2664", name: "Benslimane", stateCode: "BES", countryId: "148" },
  { id: "2665", name: "Berkane", stateCode: "BER", countryId: "148" },
  { id: "2666", name: "Berrechid", stateCode: "BRR", countryId: "148" },
  { id: "2667", name: "Boujdour (EH)", stateCode: "BOD", countryId: "148" },
  { id: "2668", name: "Boulemane", stateCode: "BOM", countryId: "148" },
  { id: "2669", name: "Casablanca", stateCode: "CAS", countryId: "148" },
  { id: "2670", name: "Casablanca-Settat", stateCode: "06", countryId: "148" },
  { id: "2671", name: "Chefchaouen", stateCode: "CHE", countryId: "148" },
  { id: "2672", name: "Chichaoua", stateCode: "CHI", countryId: "148" },
  { id: "2673", name: "Chtouka-Ait Baha", stateCode: "CHT", countryId: "148" },
  {
    id: "2674",
    name: "Dakhla-Oued Ed-Dahab (EH)",
    stateCode: "12",
    countryId: "148",
  },
  { id: "2675", name: "Drâa-Tafilalet", stateCode: "08", countryId: "148" },
  { id: "2676", name: "Driouch", stateCode: "DRI", countryId: "148" },
  { id: "2677", name: "El Hajeb", stateCode: "HAJ", countryId: "148" },
  { id: "2678", name: "El Jadida", stateCode: "JDI", countryId: "148" },
  {
    id: "2679",
    name: "El Kelâa des Sraghna",
    stateCode: "KES",
    countryId: "148",
  },
  { id: "2680", name: "Errachidia", stateCode: "ERR", countryId: "148" },
  {
    id: "2681",
    name: "Es-Semara (EH-partial)",
    stateCode: "ESM",
    countryId: "148",
  },
  { id: "2682", name: "Essaouira", stateCode: "ESI", countryId: "148" },
  { id: "2683", name: "Fahs-Anjra", stateCode: "FAH", countryId: "148" },
  { id: "2684", name: "Fès", stateCode: "FES", countryId: "148" },
  { id: "2685", name: "Fès-Meknès", stateCode: "03", countryId: "148" },
  { id: "2686", name: "Figuig", stateCode: "FIG", countryId: "148" },
  { id: "2687", name: "Fquih Ben Salah", stateCode: "FQH", countryId: "148" },
  { id: "2688", name: "Guelmim", stateCode: "GUE", countryId: "148" },
  {
    id: "2689",
    name: "Guelmim-Oued Noun (EH-partial)",
    stateCode: "10",
    countryId: "148",
  },
  { id: "2690", name: "Guercif", stateCode: "GUF", countryId: "148" },
  { id: "2691", name: "Ifrane", stateCode: "IFR", countryId: "148" },
  {
    id: "2692",
    name: "Inezgane-Ait Melloul",
    stateCode: "INE",
    countryId: "148",
  },
  { id: "2693", name: "Jerada", stateCode: "JRA", countryId: "148" },
  { id: "2694", name: "Kénitra", stateCode: "KEN", countryId: "148" },
  { id: "2695", name: "Khémisset", stateCode: "KHE", countryId: "148" },
  { id: "2696", name: "Khénifra", stateCode: "KHN", countryId: "148" },
  { id: "2697", name: "Khouribga", stateCode: "KHO", countryId: "148" },
  { id: "2698", name: "L\\'Oriental", stateCode: "02", countryId: "148" },
  { id: "2699", name: "Laâyoune (EH)", stateCode: "LAA", countryId: "148" },
  {
    id: "2700",
    name: "Laâyoune-Sakia El Hamra (EH-partial)",
    stateCode: "11",
    countryId: "148",
  },
  { id: "2701", name: "Larache", stateCode: "LAR", countryId: "148" },
  { id: "2702", name: "M’diq-Fnideq", stateCode: "MDF", countryId: "148" },
  { id: "2703", name: "Marrakech", stateCode: "MAR", countryId: "148" },
  { id: "2704", name: "Marrakesh-Safi", stateCode: "07", countryId: "148" },
  { id: "2705", name: "Médiouna", stateCode: "MED", countryId: "148" },
  { id: "2706", name: "Meknès", stateCode: "MEK", countryId: "148" },
  { id: "2707", name: "Midelt", stateCode: "MID", countryId: "148" },
  { id: "2708", name: "Mohammadia", stateCode: "MOH", countryId: "148" },
  { id: "2709", name: "Moulay Yacoub", stateCode: "MOU", countryId: "148" },
  { id: "2710", name: "Nador", stateCode: "NAD", countryId: "148" },
  { id: "2711", name: "Nouaceur", stateCode: "NOU", countryId: "148" },
  { id: "2712", name: "Ouarzazate", stateCode: "OUA", countryId: "148" },
  {
    id: "2713",
    name: "Oued Ed-Dahab (EH)",
    stateCode: "OUD",
    countryId: "148",
  },
  { id: "2714", name: "Ouezzane", stateCode: "OUZ", countryId: "148" },
  { id: "2715", name: "Oujda-Angad", stateCode: "OUJ", countryId: "148" },
  { id: "2716", name: "Rabat", stateCode: "RAB", countryId: "148" },
  { id: "2717", name: "Rabat-Salé-Kénitra", stateCode: "04", countryId: "148" },
  { id: "2718", name: "Rehamna", stateCode: "REH", countryId: "148" },
  { id: "2719", name: "Safi", stateCode: "SAF", countryId: "148" },
  { id: "2720", name: "Salé", stateCode: "SAL", countryId: "148" },
  { id: "2721", name: "Sefrou", stateCode: "SEF", countryId: "148" },
  { id: "2722", name: "Settat", stateCode: "SET", countryId: "148" },
  { id: "2723", name: "Sidi Bennour", stateCode: "SIB", countryId: "148" },
  { id: "2724", name: "Sidi Ifni", stateCode: "SIF", countryId: "148" },
  { id: "2725", name: "Sidi Kacem", stateCode: "SIK", countryId: "148" },
  { id: "2726", name: "Sidi Slimane", stateCode: "SIL", countryId: "148" },
  { id: "2727", name: "Skhirate-Témara", stateCode: "SKH", countryId: "148" },
  { id: "2728", name: "Souss-Massa", stateCode: "09", countryId: "148" },
  {
    id: "2729",
    name: "Tan-Tan (EH-partial)",
    stateCode: "TNT",
    countryId: "148",
  },
  { id: "2730", name: "Tanger-Assilah", stateCode: "TNG", countryId: "148" },
  {
    id: "2731",
    name: "Tanger-Tétouan-Al Hoceïma",
    stateCode: "01",
    countryId: "148",
  },
  { id: "2732", name: "Taounate", stateCode: "TAO", countryId: "148" },
  { id: "2733", name: "Taourirt", stateCode: "TAI", countryId: "148" },
  {
    id: "2734",
    name: "Tarfaya (EH-partial)",
    stateCode: "TAF",
    countryId: "148",
  },
  { id: "2735", name: "Taroudannt", stateCode: "TAR", countryId: "148" },
  { id: "2736", name: "Tata", stateCode: "TAT", countryId: "148" },
  { id: "2737", name: "Taza", stateCode: "TAZ", countryId: "148" },
  { id: "2738", name: "Tétouan", stateCode: "TET", countryId: "148" },
  { id: "2739", name: "Tinghir", stateCode: "TIN", countryId: "148" },
  { id: "2740", name: "Tiznit", stateCode: "TIZ", countryId: "148" },
  { id: "2741", name: "Youssoufia", stateCode: "YUS", countryId: "148" },
  { id: "2742", name: "Zagora", stateCode: "ZAG", countryId: "148" },
  {
    id: "2743",
    name: "Cabo Delgado Province",
    stateCode: "P",
    countryId: "149",
  },
  { id: "2744", name: "Gaza Province", stateCode: "G", countryId: "149" },
  { id: "2745", name: "Inhambane Province", stateCode: "I", countryId: "149" },
  { id: "2746", name: "Manica Province", stateCode: "B", countryId: "149" },
  { id: "2747", name: "Maputo", stateCode: "MPM", countryId: "149" },
  { id: "2748", name: "Maputo Province", stateCode: "L", countryId: "149" },
  { id: "2749", name: "Nampula Province", stateCode: "N", countryId: "149" },
  { id: "2750", name: "Niassa Province", stateCode: "A", countryId: "149" },
  { id: "2751", name: "Sofala Province", stateCode: "S", countryId: "149" },
  { id: "2752", name: "Tete Province", stateCode: "T", countryId: "149" },
  { id: "2753", name: "Zambezia Province", stateCode: "Q", countryId: "149" },
  { id: "2754", name: "Ayeyarwady Region", stateCode: "07", countryId: "150" },
  { id: "2755", name: "Bago", stateCode: "02", countryId: "150" },
  { id: "2756", name: "Chin State", stateCode: "14", countryId: "150" },
  { id: "2757", name: "Kachin State", stateCode: "11", countryId: "150" },
  { id: "2758", name: "Kayah State", stateCode: "12", countryId: "150" },
  { id: "2759", name: "Kayin State", stateCode: "13", countryId: "150" },
  { id: "2760", name: "Magway Region", stateCode: "03", countryId: "150" },
  { id: "2761", name: "Mandalay Region", stateCode: "04", countryId: "150" },
  { id: "2762", name: "Mon State", stateCode: "15", countryId: "150" },
  {
    id: "2763",
    name: "Naypyidaw Union Territory",
    stateCode: "18",
    countryId: "150",
  },
  { id: "2764", name: "Rakhine State", stateCode: "16", countryId: "150" },
  { id: "2765", name: "Sagaing Region", stateCode: "01", countryId: "150" },
  { id: "2766", name: "Shan State", stateCode: "17", countryId: "150" },
  { id: "2767", name: "Tanintharyi Region", stateCode: "05", countryId: "150" },
  { id: "2768", name: "Yangon Region", stateCode: "06", countryId: "150" },
  { id: "2769", name: "Erongo Region", stateCode: "ER", countryId: "151" },
  { id: "2770", name: "Hardap Region", stateCode: "HA", countryId: "151" },
  { id: "2771", name: "Karas Region", stateCode: "KA", countryId: "151" },
  {
    id: "2772",
    name: "Kavango East Region",
    stateCode: "KE",
    countryId: "151",
  },
  {
    id: "2773",
    name: "Kavango West Region",
    stateCode: "KW",
    countryId: "151",
  },
  { id: "2774", name: "Khomas Region", stateCode: "KH", countryId: "151" },
  { id: "2775", name: "Kunene Region", stateCode: "KU", countryId: "151" },
  { id: "2776", name: "Ohangwena Region", stateCode: "OW", countryId: "151" },
  { id: "2777", name: "Omaheke Region", stateCode: "OH", countryId: "151" },
  { id: "2778", name: "Omusati Region", stateCode: "OS", countryId: "151" },
  { id: "2779", name: "Oshana Region", stateCode: "ON", countryId: "151" },
  { id: "2780", name: "Oshikoto Region", stateCode: "OT", countryId: "151" },
  {
    id: "2781",
    name: "Otjozondjupa Region",
    stateCode: "OD",
    countryId: "151",
  },
  { id: "2782", name: "Zambezi Region", stateCode: "CA", countryId: "151" },
  { id: "2783", name: "Aiwo District", stateCode: "01", countryId: "152" },
  { id: "2784", name: "Anabar District", stateCode: "02", countryId: "152" },
  { id: "2785", name: "Anetan District", stateCode: "03", countryId: "152" },
  { id: "2786", name: "Anibare District", stateCode: "04", countryId: "152" },
  { id: "2787", name: "Baiti District", stateCode: "05", countryId: "152" },
  { id: "2788", name: "Boe District", stateCode: "06", countryId: "152" },
  { id: "2789", name: "Buada District", stateCode: "07", countryId: "152" },
  {
    id: "2790",
    name: "Denigomodu District",
    stateCode: "08",
    countryId: "152",
  },
  { id: "2791", name: "Ewa District", stateCode: "09", countryId: "152" },
  { id: "2792", name: "Ijuw District", stateCode: "10", countryId: "152" },
  { id: "2793", name: "Meneng District", stateCode: "11", countryId: "152" },
  { id: "2794", name: "Nibok District", stateCode: "12", countryId: "152" },
  { id: "2795", name: "Uaboe District", stateCode: "13", countryId: "152" },
  { id: "2796", name: "Yaren District", stateCode: "14", countryId: "152" },
  { id: "2797", name: "Bagmati Zone", stateCode: "BA", countryId: "153" },
  { id: "2798", name: "Bheri Zone", stateCode: "BH", countryId: "153" },
  { id: "2799", name: "Central Region", stateCode: "1", countryId: "153" },
  { id: "2800", name: "Dhaulagiri Zone", stateCode: "DH", countryId: "153" },
  {
    id: "2801",
    name: "Eastern Development Region",
    stateCode: "4",
    countryId: "153",
  },
  {
    id: "2802",
    name: "Far-Western Development Region",
    stateCode: "5",
    countryId: "153",
  },
  { id: "2803", name: "Gandaki Zone", stateCode: "GA", countryId: "153" },
  { id: "2804", name: "Janakpur Zone", stateCode: "JA", countryId: "153" },
  { id: "2805", name: "Karnali Zone", stateCode: "KA", countryId: "153" },
  { id: "2806", name: "Kosi Zone", stateCode: "KO", countryId: "153" },
  { id: "2807", name: "Lumbini Zone", stateCode: "LU", countryId: "153" },
  { id: "2808", name: "Mahakali Zone", stateCode: "MA", countryId: "153" },
  { id: "2809", name: "Mechi Zone", stateCode: "ME", countryId: "153" },
  { id: "2810", name: "Mid-Western Region", stateCode: "2", countryId: "153" },
  { id: "2811", name: "Narayani Zone", stateCode: "NA", countryId: "153" },
  { id: "2812", name: "Rapti Zone", stateCode: "RA", countryId: "153" },
  { id: "2813", name: "Sagarmatha Zone", stateCode: "SA", countryId: "153" },
  { id: "2814", name: "Seti Zone", stateCode: "SE", countryId: "153" },
  { id: "2815", name: "Western Region", stateCode: "3", countryId: "153" },
  { id: "2816", name: "Bonaire", stateCode: "BQ1", countryId: "154" },
  { id: "2817", name: "Drenthe", stateCode: "DR", countryId: "154" },
  { id: "2818", name: "Flevoland", stateCode: "FL", countryId: "154" },
  { id: "2819", name: "Friesland", stateCode: "FR", countryId: "154" },
  { id: "2820", name: "Gelderland", stateCode: "GE", countryId: "154" },
  { id: "2821", name: "Groningen", stateCode: "GR", countryId: "154" },
  { id: "2822", name: "Limburg", stateCode: "LI", countryId: "154" },
  { id: "2823", name: "North Brabant", stateCode: "NB", countryId: "154" },
  { id: "2824", name: "North Holland", stateCode: "NH", countryId: "154" },
  { id: "2825", name: "Overijssel", stateCode: "OV", countryId: "154" },
  { id: "2826", name: "Saba", stateCode: "BQ2", countryId: "154" },
  { id: "2827", name: "Sint Eustatius", stateCode: "BQ3", countryId: "154" },
  { id: "2828", name: "South Holland", stateCode: "ZH", countryId: "154" },
  { id: "2829", name: "Utrecht", stateCode: "UT", countryId: "154" },
  { id: "2830", name: "Zeeland", stateCode: "ZE", countryId: "154" },
  {
    id: "2831",
    name: "Loyalty Islands Province",
    stateCode: "03",
    countryId: "155",
  },
  { id: "2832", name: "North Province", stateCode: "02", countryId: "155" },
  { id: "2833", name: "South Province", stateCode: "01", countryId: "155" },
  { id: "2834", name: "Auckland Region", stateCode: "AUK", countryId: "156" },
  {
    id: "2835",
    name: "Bay of Plenty Region",
    stateCode: "BOP",
    countryId: "156",
  },
  { id: "2836", name: "Canterbury Region", stateCode: "CAN", countryId: "156" },
  { id: "2837", name: "Chatham Islands", stateCode: "CIT", countryId: "156" },
  { id: "2838", name: "Gisborne District", stateCode: "GIS", countryId: "156" },
  {
    id: "2839",
    name: "Hawke\\'s Bay Region",
    stateCode: "HKB",
    countryId: "156",
  },
  {
    id: "2840",
    name: "Manawatu-Wanganui Region",
    stateCode: "MWT",
    countryId: "156",
  },
  {
    id: "2841",
    name: "Marlborough Region",
    stateCode: "MBH",
    countryId: "156",
  },
  { id: "2842", name: "Nelson Region", stateCode: "NSN", countryId: "156" },
  { id: "2843", name: "Northland Region", stateCode: "NTL", countryId: "156" },
  { id: "2844", name: "Otago Region", stateCode: "OTA", countryId: "156" },
  { id: "2845", name: "Southland Region", stateCode: "STL", countryId: "156" },
  { id: "2846", name: "Taranaki Region", stateCode: "TKI", countryId: "156" },
  { id: "2847", name: "Tasman District", stateCode: "TAS", countryId: "156" },
  { id: "2848", name: "Waikato Region", stateCode: "WKO", countryId: "156" },
  { id: "2849", name: "Wellington Region", stateCode: "WGN", countryId: "156" },
  { id: "2850", name: "West Coast Region", stateCode: "WTC", countryId: "156" },
  { id: "2851", name: "Boaco", stateCode: "BO", countryId: "157" },
  { id: "2852", name: "Carazo", stateCode: "CA", countryId: "157" },
  { id: "2853", name: "Chinandega", stateCode: "CI", countryId: "157" },
  { id: "2854", name: "Chontales", stateCode: "CO", countryId: "157" },
  { id: "2855", name: "Estelí", stateCode: "ES", countryId: "157" },
  { id: "2856", name: "Granada", stateCode: "GR", countryId: "157" },
  { id: "2857", name: "Jinotega", stateCode: "JI", countryId: "157" },
  { id: "2858", name: "León", stateCode: "LE", countryId: "157" },
  { id: "2859", name: "Madriz", stateCode: "MD", countryId: "157" },
  { id: "2860", name: "Managua", stateCode: "MN", countryId: "157" },
  { id: "2861", name: "Masaya", stateCode: "MS", countryId: "157" },
  { id: "2862", name: "Matagalpa", stateCode: "MT", countryId: "157" },
  {
    id: "2863",
    name: "North Caribbean Coast",
    stateCode: "AN",
    countryId: "157",
  },
  { id: "2864", name: "Nueva Segovia", stateCode: "NS", countryId: "157" },
  { id: "2865", name: "Río San Juan", stateCode: "SJ", countryId: "157" },
  { id: "2866", name: "Rivas", stateCode: "RI", countryId: "157" },
  {
    id: "2867",
    name: "South Caribbean Coast",
    stateCode: "AS",
    countryId: "157",
  },
  { id: "2868", name: "Agadez Region", stateCode: "1", countryId: "158" },
  { id: "2869", name: "Diffa Region", stateCode: "2", countryId: "158" },
  { id: "2870", name: "Dosso Region", stateCode: "3", countryId: "158" },
  { id: "2871", name: "Maradi Region", stateCode: "4", countryId: "158" },
  { id: "2872", name: "Tahoua Region", stateCode: "5", countryId: "158" },
  { id: "2873", name: "Tillabéri Region", stateCode: "6", countryId: "158" },
  { id: "2874", name: "Zinder Region", stateCode: "7", countryId: "158" },
  { id: "2875", name: "Abia", stateCode: "AB", countryId: "159" },
  {
    id: "2876",
    name: "Abuja Federal Capital Territory",
    stateCode: "FC",
    countryId: "159",
  },
  { id: "2877", name: "Adamawa", stateCode: "AD", countryId: "159" },
  { id: "2878", name: "Akwa Ibom", stateCode: "AK", countryId: "159" },
  { id: "2879", name: "Anambra", stateCode: "AN", countryId: "159" },
  { id: "2880", name: "Bauchi", stateCode: "BA", countryId: "159" },
  { id: "2881", name: "Bayelsa", stateCode: "BY", countryId: "159" },
  { id: "2882", name: "Benue", stateCode: "BE", countryId: "159" },
  { id: "2883", name: "Borno", stateCode: "BO", countryId: "159" },
  { id: "2884", name: "Cross River", stateCode: "CR", countryId: "159" },
  { id: "2885", name: "Delta", stateCode: "DE", countryId: "159" },
  { id: "2886", name: "Ebonyi", stateCode: "EB", countryId: "159" },
  { id: "2887", name: "Edo", stateCode: "ED", countryId: "159" },
  { id: "2888", name: "Ekiti", stateCode: "EK", countryId: "159" },
  { id: "2889", name: "Enugu", stateCode: "EN", countryId: "159" },
  { id: "2890", name: "Gombe", stateCode: "GO", countryId: "159" },
  { id: "2891", name: "Imo", stateCode: "IM", countryId: "159" },
  { id: "2892", name: "Jigawa", stateCode: "JI", countryId: "159" },
  { id: "2893", name: "Kaduna", stateCode: "KD", countryId: "159" },
  { id: "2894", name: "Kano", stateCode: "KN", countryId: "159" },
  { id: "2895", name: "Katsina", stateCode: "KT", countryId: "159" },
  { id: "2896", name: "Kebbi", stateCode: "KE", countryId: "159" },
  { id: "2897", name: "Kogi", stateCode: "KO", countryId: "159" },
  { id: "2898", name: "Kwara", stateCode: "KW", countryId: "159" },
  { id: "2899", name: "Lagos", stateCode: "LA", countryId: "159" },
  { id: "2900", name: "Nasarawa", stateCode: "NA", countryId: "159" },
  { id: "2901", name: "Niger", stateCode: "NI", countryId: "159" },
  { id: "2902", name: "Ogun", stateCode: "OG", countryId: "159" },
  { id: "2903", name: "Ondo", stateCode: "ON", countryId: "159" },
  { id: "2904", name: "Osun", stateCode: "OS", countryId: "159" },
  { id: "2905", name: "Oyo", stateCode: "OY", countryId: "159" },
  { id: "2906", name: "Plateau", stateCode: "PL", countryId: "159" },
  { id: "2907", name: "Rivers", stateCode: "RI", countryId: "159" },
  { id: "2908", name: "Sokoto", stateCode: "SO", countryId: "159" },
  { id: "2909", name: "Taraba", stateCode: "TA", countryId: "159" },
  { id: "2910", name: "Yobe", stateCode: "YO", countryId: "159" },
  { id: "2911", name: "Zamfara", stateCode: "ZA", countryId: "159" },
  { id: "2912", name: "Chagang Province", stateCode: "04", countryId: "162" },
  { id: "2913", name: "Kangwon Province", stateCode: "07", countryId: "162" },
  {
    id: "2914",
    name: "North Hamgyong Province",
    stateCode: "09",
    countryId: "162",
  },
  {
    id: "2915",
    name: "North Hwanghae Province",
    stateCode: "06",
    countryId: "162",
  },
  {
    id: "2916",
    name: "North Pyongan Province",
    stateCode: "03",
    countryId: "162",
  },
  { id: "2917", name: "Pyongyang", stateCode: "01", countryId: "162" },
  { id: "2918", name: "Rason", stateCode: "13", countryId: "162" },
  { id: "2919", name: "Ryanggang Province", stateCode: "10", countryId: "162" },
  {
    id: "2920",
    name: "South Hamgyong Province",
    stateCode: "08",
    countryId: "162",
  },
  {
    id: "2921",
    name: "South Hwanghae Province",
    stateCode: "05",
    countryId: "162",
  },
  {
    id: "2922",
    name: "South Pyongan Province",
    stateCode: "02",
    countryId: "162",
  },
  {
    id: "2923",
    name: "Aerodrom Municipality",
    stateCode: "01",
    countryId: "163",
  },
  {
    id: "2924",
    name: "Aračinovo Municipality",
    stateCode: "02",
    countryId: "163",
  },
  {
    id: "2925",
    name: "Berovo Municipality",
    stateCode: "03",
    countryId: "163",
  },
  {
    id: "2926",
    name: "Bitola Municipality",
    stateCode: "04",
    countryId: "163",
  },
  {
    id: "2927",
    name: "Bogdanci Municipality",
    stateCode: "05",
    countryId: "163",
  },
  {
    id: "2928",
    name: "Bogovinje Municipality",
    stateCode: "06",
    countryId: "163",
  },
  {
    id: "2929",
    name: "Bosilovo Municipality",
    stateCode: "07",
    countryId: "163",
  },
  {
    id: "2930",
    name: "Brvenica Municipality",
    stateCode: "08",
    countryId: "163",
  },
  { id: "2931", name: "Butel Municipality", stateCode: "09", countryId: "163" },
  { id: "2932", name: "Čair Municipality", stateCode: "79", countryId: "163" },
  { id: "2933", name: "Čaška Municipality", stateCode: "80", countryId: "163" },
  {
    id: "2934",
    name: "Centar Municipality",
    stateCode: "77",
    countryId: "163",
  },
  {
    id: "2935",
    name: "Centar Župa Municipality",
    stateCode: "78",
    countryId: "163",
  },
  {
    id: "2936",
    name: "Češinovo-Obleševo Municipality",
    stateCode: "81",
    countryId: "163",
  },
  {
    id: "2937",
    name: "Čučer-Sandevo Municipality",
    stateCode: "82",
    countryId: "163",
  },
  {
    id: "2938",
    name: "Debarca Municipality",
    stateCode: "22",
    countryId: "163",
  },
  {
    id: "2939",
    name: "Delčevo Municipality",
    stateCode: "23",
    countryId: "163",
  },
  {
    id: "2940",
    name: "Demir Hisar Municipality",
    stateCode: "25",
    countryId: "163",
  },
  {
    id: "2941",
    name: "Demir Kapija Municipality",
    stateCode: "24",
    countryId: "163",
  },
  {
    id: "2942",
    name: "Dojran Municipality",
    stateCode: "26",
    countryId: "163",
  },
  {
    id: "2943",
    name: "Dolneni Municipality",
    stateCode: "27",
    countryId: "163",
  },
  {
    id: "2944",
    name: "Drugovo Municipality",
    stateCode: "28",
    countryId: "163",
  },
  {
    id: "2945",
    name: "Gazi Baba Municipality",
    stateCode: "17",
    countryId: "163",
  },
  {
    id: "2946",
    name: "Gevgelija Municipality",
    stateCode: "18",
    countryId: "163",
  },
  {
    id: "2947",
    name: "Gjorče Petrov Municipality",
    stateCode: "29",
    countryId: "163",
  },
  {
    id: "2948",
    name: "Gostivar Municipality",
    stateCode: "19",
    countryId: "163",
  },
  {
    id: "2949",
    name: "Gradsko Municipality",
    stateCode: "20",
    countryId: "163",
  },
  { id: "2950", name: "Greater Skopje", stateCode: "85", countryId: "163" },
  {
    id: "2951",
    name: "Ilinden Municipality",
    stateCode: "34",
    countryId: "163",
  },
  {
    id: "2952",
    name: "Jegunovce Municipality",
    stateCode: "35",
    countryId: "163",
  },
  { id: "2953", name: "Karbinci", stateCode: "37", countryId: "163" },
  {
    id: "2954",
    name: "Karpoš Municipality",
    stateCode: "38",
    countryId: "163",
  },
  {
    id: "2955",
    name: "Kavadarci Municipality",
    stateCode: "36",
    countryId: "163",
  },
  {
    id: "2956",
    name: "Kičevo Municipality",
    stateCode: "40",
    countryId: "163",
  },
  {
    id: "2957",
    name: "Kisela Voda Municipality",
    stateCode: "39",
    countryId: "163",
  },
  {
    id: "2958",
    name: "Kočani Municipality",
    stateCode: "42",
    countryId: "163",
  },
  { id: "2959", name: "Konče Municipality", stateCode: "41", countryId: "163" },
  {
    id: "2960",
    name: "Kratovo Municipality",
    stateCode: "43",
    countryId: "163",
  },
  {
    id: "2961",
    name: "Kriva Palanka Municipality",
    stateCode: "44",
    countryId: "163",
  },
  {
    id: "2962",
    name: "Krivogaštani Municipality",
    stateCode: "45",
    countryId: "163",
  },
  {
    id: "2963",
    name: "Kruševo Municipality",
    stateCode: "46",
    countryId: "163",
  },
  {
    id: "2964",
    name: "Kumanovo Municipality",
    stateCode: "47",
    countryId: "163",
  },
  {
    id: "2965",
    name: "Lipkovo Municipality",
    stateCode: "48",
    countryId: "163",
  },
  {
    id: "2966",
    name: "Lozovo Municipality",
    stateCode: "49",
    countryId: "163",
  },
  {
    id: "2967",
    name: "Makedonska Kamenica Municipality",
    stateCode: "51",
    countryId: "163",
  },
  {
    id: "2968",
    name: "Makedonski Brod Municipality",
    stateCode: "52",
    countryId: "163",
  },
  {
    id: "2969",
    name: "Mavrovo and Rostuša Municipality",
    stateCode: "50",
    countryId: "163",
  },
  {
    id: "2970",
    name: "Mogila Municipality",
    stateCode: "53",
    countryId: "163",
  },
  {
    id: "2971",
    name: "Negotino Municipality",
    stateCode: "54",
    countryId: "163",
  },
  {
    id: "2972",
    name: "Novaci Municipality",
    stateCode: "55",
    countryId: "163",
  },
  {
    id: "2973",
    name: "Novo Selo Municipality",
    stateCode: "56",
    countryId: "163",
  },
  { id: "2974", name: "Ohrid Municipality", stateCode: "58", countryId: "163" },
  {
    id: "2975",
    name: "Oslomej Municipality",
    stateCode: "57",
    countryId: "163",
  },
  {
    id: "2976",
    name: "Pehčevo Municipality",
    stateCode: "60",
    countryId: "163",
  },
  {
    id: "2977",
    name: "Petrovec Municipality",
    stateCode: "59",
    countryId: "163",
  },
  {
    id: "2978",
    name: "Plasnica Municipality",
    stateCode: "61",
    countryId: "163",
  },
  {
    id: "2979",
    name: "Prilep Municipality",
    stateCode: "62",
    countryId: "163",
  },
  {
    id: "2980",
    name: "Probištip Municipality",
    stateCode: "63",
    countryId: "163",
  },
  {
    id: "2981",
    name: "Radoviš Municipality",
    stateCode: "64",
    countryId: "163",
  },
  {
    id: "2982",
    name: "Rankovce Municipality",
    stateCode: "65",
    countryId: "163",
  },
  { id: "2983", name: "Resen Municipality", stateCode: "66", countryId: "163" },
  {
    id: "2984",
    name: "Rosoman Municipality",
    stateCode: "67",
    countryId: "163",
  },
  { id: "2985", name: "Saraj Municipality", stateCode: "68", countryId: "163" },
  {
    id: "2986",
    name: "Sopište Municipality",
    stateCode: "70",
    countryId: "163",
  },
  {
    id: "2987",
    name: "Staro Nagoričane Municipality",
    stateCode: "71",
    countryId: "163",
  },
  { id: "2988", name: "Štip Municipality", stateCode: "83", countryId: "163" },
  {
    id: "2989",
    name: "Struga Municipality",
    stateCode: "72",
    countryId: "163",
  },
  {
    id: "2990",
    name: "Strumica Municipality",
    stateCode: "73",
    countryId: "163",
  },
  {
    id: "2991",
    name: "Studeničani Municipality",
    stateCode: "74",
    countryId: "163",
  },
  {
    id: "2992",
    name: "Šuto Orizari Municipality",
    stateCode: "84",
    countryId: "163",
  },
  {
    id: "2993",
    name: "Sveti Nikole Municipality",
    stateCode: "69",
    countryId: "163",
  },
  {
    id: "2994",
    name: "Tearce Municipality",
    stateCode: "75",
    countryId: "163",
  },
  {
    id: "2995",
    name: "Tetovo Municipality",
    stateCode: "76",
    countryId: "163",
  },
  {
    id: "2996",
    name: "Valandovo Municipality",
    stateCode: "10",
    countryId: "163",
  },
  {
    id: "2997",
    name: "Vasilevo Municipality",
    stateCode: "11",
    countryId: "163",
  },
  { id: "2998", name: "Veles Municipality", stateCode: "13", countryId: "163" },
  {
    id: "2999",
    name: "Vevčani Municipality",
    stateCode: "12",
    countryId: "163",
  },
  {
    id: "3000",
    name: "Vinica Municipality",
    stateCode: "14",
    countryId: "163",
  },
  {
    id: "3001",
    name: "Vraneštica Municipality",
    stateCode: "15",
    countryId: "163",
  },
  {
    id: "3002",
    name: "Vrapčište Municipality",
    stateCode: "16",
    countryId: "163",
  },
  { id: "3003", name: "Zajas Municipality", stateCode: "31", countryId: "163" },
  {
    id: "3004",
    name: "Zelenikovo Municipality",
    stateCode: "32",
    countryId: "163",
  },
  {
    id: "3005",
    name: "Želino Municipality",
    stateCode: "30",
    countryId: "163",
  },
  {
    id: "3006",
    name: "Zrnovci Municipality",
    stateCode: "33",
    countryId: "163",
  },
  { id: "3007", name: "Agder", stateCode: "42", countryId: "165" },
  { id: "3008", name: "Innlandet", stateCode: "34", countryId: "165" },
  { id: "3009", name: "Jan Mayen", stateCode: "22", countryId: "165" },
  { id: "3010", name: "Møre og Romsdal", stateCode: "15", countryId: "165" },
  { id: "3011", name: "Nordland", stateCode: "18", countryId: "165" },
  { id: "3012", name: "Oslo", stateCode: "03", countryId: "165" },
  { id: "3013", name: "Rogaland", stateCode: "11", countryId: "165" },
  { id: "3014", name: "Svalbard", stateCode: "21", countryId: "165" },
  { id: "3015", name: "Troms og Finnmark", stateCode: "54", countryId: "165" },
  { id: "3016", name: "Trøndelag", stateCode: "50", countryId: "165" },
  {
    id: "3017",
    name: "Vestfold og Telemark",
    stateCode: "38",
    countryId: "165",
  },
  { id: "3018", name: "Vestland", stateCode: "46", countryId: "165" },
  { id: "3019", name: "Viken", stateCode: "30", countryId: "165" },
  { id: "3020", name: "Ad Dakhiliyah", stateCode: "DA", countryId: "166" },
  { id: "3021", name: "Ad Dhahirah", stateCode: "ZA", countryId: "166" },
  { id: "3022", name: "Al Batinah North", stateCode: "BS", countryId: "166" },
  { id: "3023", name: "Al Batinah Region", stateCode: "BA", countryId: "166" },
  { id: "3024", name: "Al Batinah South", stateCode: "BJ", countryId: "166" },
  { id: "3025", name: "Al Buraimi", stateCode: "BU", countryId: "166" },
  { id: "3026", name: "Al Wusta", stateCode: "WU", countryId: "166" },
  {
    id: "3027",
    name: "Ash Sharqiyah North",
    stateCode: "SS",
    countryId: "166",
  },
  {
    id: "3028",
    name: "Ash Sharqiyah Region",
    stateCode: "SH",
    countryId: "166",
  },
  {
    id: "3029",
    name: "Ash Sharqiyah South",
    stateCode: "SJ",
    countryId: "166",
  },
  { id: "3030", name: "Dhofar", stateCode: "ZU", countryId: "166" },
  { id: "3031", name: "Musandam", stateCode: "MU", countryId: "166" },
  { id: "3032", name: "Muscat", stateCode: "MA", countryId: "166" },
  { id: "3033", name: "Azad Kashmir", stateCode: "JK", countryId: "167" },
  { id: "3034", name: "Balochistan", stateCode: "BA", countryId: "167" },
  {
    id: "3035",
    name: "Federally Administered Tribal Areas",
    stateCode: "TA",
    countryId: "167",
  },
  { id: "3036", name: "Gilgit-Baltistan", stateCode: "GB", countryId: "167" },
  {
    id: "3037",
    name: "Islamabad Capital Territory",
    stateCode: "IS",
    countryId: "167",
  },
  { id: "3038", name: "Khyber Pakhtunkhwa", stateCode: "KP", countryId: "167" },
  { id: "3039", name: "Punjab", stateCode: "PB", countryId: "167" },
  { id: "3040", name: "Sindh", stateCode: "SD", countryId: "167" },
  { id: "3041", name: "Aimeliik", stateCode: "002", countryId: "168" },
  { id: "3042", name: "Airai", stateCode: "004", countryId: "168" },
  { id: "3043", name: "Angaur", stateCode: "010", countryId: "168" },
  { id: "3044", name: "Hatohobei", stateCode: "050", countryId: "168" },
  { id: "3045", name: "Kayangel", stateCode: "100", countryId: "168" },
  { id: "3046", name: "Koror", stateCode: "150", countryId: "168" },
  { id: "3047", name: "Melekeok", stateCode: "212", countryId: "168" },
  { id: "3048", name: "Ngaraard", stateCode: "214", countryId: "168" },
  { id: "3049", name: "Ngarchelong", stateCode: "218", countryId: "168" },
  { id: "3050", name: "Ngardmau", stateCode: "222", countryId: "168" },
  { id: "3051", name: "Ngatpang", stateCode: "224", countryId: "168" },
  { id: "3052", name: "Ngchesar", stateCode: "226", countryId: "168" },
  { id: "3053", name: "Ngeremlengui", stateCode: "227", countryId: "168" },
  { id: "3054", name: "Ngiwal", stateCode: "228", countryId: "168" },
  { id: "3055", name: "Peleliu", stateCode: "350", countryId: "168" },
  { id: "3056", name: "Sonsorol", stateCode: "370", countryId: "168" },
  { id: "3057", name: "Bethlehem", stateCode: "BTH", countryId: "169" },
  { id: "3058", name: "Deir El Balah", stateCode: "DEB", countryId: "169" },
  { id: "3059", name: "Gaza", stateCode: "GZA", countryId: "169" },
  { id: "3060", name: "Hebron", stateCode: "HBN", countryId: "169" },
  { id: "3061", name: "Jenin", stateCode: "JEN", countryId: "169" },
  {
    id: "3062",
    name: "Jericho and Al Aghwar",
    stateCode: "JRH",
    countryId: "169",
  },
  { id: "3063", name: "Jerusalem", stateCode: "JEM", countryId: "169" },
  { id: "3064", name: "Khan Yunis", stateCode: "KYS", countryId: "169" },
  { id: "3065", name: "Nablus", stateCode: "NBS", countryId: "169" },
  { id: "3066", name: "North Gaza", stateCode: "NGZ", countryId: "169" },
  { id: "3067", name: "Qalqilya", stateCode: "QQA", countryId: "169" },
  { id: "3068", name: "Rafah", stateCode: "RFH", countryId: "169" },
  { id: "3069", name: "Ramallah", stateCode: "RBH", countryId: "169" },
  { id: "3070", name: "Salfit", stateCode: "SLT", countryId: "169" },
  { id: "3071", name: "Tubas", stateCode: "TBS", countryId: "169" },
  { id: "3072", name: "Tulkarm", stateCode: "TKM", countryId: "169" },
  {
    id: "3073",
    name: "Bocas del Toro Province",
    stateCode: "1",
    countryId: "170",
  },
  { id: "3074", name: "Chiriquí Province", stateCode: "4", countryId: "170" },
  { id: "3075", name: "Coclé Province", stateCode: "2", countryId: "170" },
  { id: "3076", name: "Colón Province", stateCode: "3", countryId: "170" },
  { id: "3077", name: "Darién Province", stateCode: "5", countryId: "170" },
  {
    id: "3078",
    name: "Emberá-Wounaan Comarca",
    stateCode: "EM",
    countryId: "170",
  },
  { id: "3079", name: "Guna Yala", stateCode: "KY", countryId: "170" },
  { id: "3080", name: "Herrera Province", stateCode: "6", countryId: "170" },
  { id: "3081", name: "Los Santos Province", stateCode: "7", countryId: "170" },
  {
    id: "3082",
    name: "Ngöbe-Buglé Comarca",
    stateCode: "NB",
    countryId: "170",
  },
  {
    id: "3083",
    name: "Panamá Oeste Province",
    stateCode: "10",
    countryId: "170",
  },
  { id: "3084", name: "Panamá Province", stateCode: "8", countryId: "170" },
  { id: "3085", name: "Veraguas Province", stateCode: "9", countryId: "170" },
  { id: "3086", name: "Bougainville", stateCode: "NSB", countryId: "171" },
  { id: "3087", name: "Central Province", stateCode: "CPM", countryId: "171" },
  { id: "3088", name: "Chimbu Province", stateCode: "CPK", countryId: "171" },
  { id: "3089", name: "East New Britain", stateCode: "EBR", countryId: "171" },
  {
    id: "3090",
    name: "Eastern Highlands Province",
    stateCode: "EHG",
    countryId: "171",
  },
  { id: "3091", name: "Enga Province", stateCode: "EPW", countryId: "171" },
  { id: "3092", name: "Gulf", stateCode: "GPK", countryId: "171" },
  { id: "3093", name: "Hela", stateCode: "HLA", countryId: "171" },
  { id: "3094", name: "Jiwaka Province", stateCode: "JWK", countryId: "171" },
  { id: "3095", name: "Madang Province", stateCode: "MPM", countryId: "171" },
  { id: "3096", name: "Manus Province", stateCode: "MRL", countryId: "171" },
  {
    id: "3097",
    name: "Milne Bay Province",
    stateCode: "MBA",
    countryId: "171",
  },
  { id: "3098", name: "Morobe Province", stateCode: "MPL", countryId: "171" },
  {
    id: "3099",
    name: "New Ireland Province",
    stateCode: "NIK",
    countryId: "171",
  },
  { id: "3100", name: "Oro Province", stateCode: "NPP", countryId: "171" },
  { id: "3101", name: "Port Moresby", stateCode: "NCD", countryId: "171" },
  { id: "3102", name: "Sandaun Province", stateCode: "SAN", countryId: "171" },
  {
    id: "3103",
    name: "Southern Highlands Province",
    stateCode: "SHM",
    countryId: "171",
  },
  {
    id: "3104",
    name: "West New Britain Province",
    stateCode: "WBK",
    countryId: "171",
  },
  {
    id: "3105",
    name: "Western Highlands Province",
    stateCode: "WHM",
    countryId: "171",
  },
  { id: "3106", name: "Western Province", stateCode: "WPD", countryId: "171" },
  {
    id: "3107",
    name: "Alto Paraguay Department",
    stateCode: "16",
    countryId: "172",
  },
  {
    id: "3108",
    name: "Alto Paraná Department",
    stateCode: "10",
    countryId: "172",
  },
  { id: "3109", name: "Amambay Department", stateCode: "13", countryId: "172" },
  { id: "3110", name: "Asuncion", stateCode: "ASU", countryId: "172" },
  {
    id: "3111",
    name: "Boquerón Department",
    stateCode: "19",
    countryId: "172",
  },
  { id: "3112", name: "Caaguazú", stateCode: "5", countryId: "172" },
  { id: "3113", name: "Caazapá", stateCode: "6", countryId: "172" },
  { id: "3114", name: "Canindeyú", stateCode: "14", countryId: "172" },
  { id: "3115", name: "Central Department", stateCode: "11", countryId: "172" },
  {
    id: "3116",
    name: "Concepción Department",
    stateCode: "1",
    countryId: "172",
  },
  {
    id: "3117",
    name: "Cordillera Department",
    stateCode: "3",
    countryId: "172",
  },
  { id: "3118", name: "Guairá Department", stateCode: "4", countryId: "172" },
  { id: "3119", name: "Itapúa", stateCode: "7", countryId: "172" },
  { id: "3120", name: "Misiones Department", stateCode: "8", countryId: "172" },
  {
    id: "3121",
    name: "Ñeembucú Department",
    stateCode: "12",
    countryId: "172",
  },
  {
    id: "3122",
    name: "Paraguarí Department",
    stateCode: "9",
    countryId: "172",
  },
  {
    id: "3123",
    name: "Presidente Hayes Department",
    stateCode: "15",
    countryId: "172",
  },
  {
    id: "3124",
    name: "San Pedro Department",
    stateCode: "2",
    countryId: "172",
  },
  { id: "3125", name: "Amazonas", stateCode: "AMA", countryId: "173" },
  { id: "3126", name: "Áncash", stateCode: "ANC", countryId: "173" },
  { id: "3127", name: "Apurímac", stateCode: "APU", countryId: "173" },
  { id: "3128", name: "Arequipa", stateCode: "ARE", countryId: "173" },
  { id: "3129", name: "Ayacucho", stateCode: "AYA", countryId: "173" },
  { id: "3130", name: "Cajamarca", stateCode: "CAJ", countryId: "173" },
  { id: "3131", name: "Callao", stateCode: "CAL", countryId: "173" },
  { id: "3132", name: "Cusco", stateCode: "CUS", countryId: "173" },
  { id: "3133", name: "Huancavelica", stateCode: "HUV", countryId: "173" },
  { id: "3134", name: "Huanuco", stateCode: "HUC", countryId: "173" },
  { id: "3135", name: "Ica", stateCode: "ICA", countryId: "173" },
  { id: "3136", name: "Junín", stateCode: "JUN", countryId: "173" },
  { id: "3137", name: "La Libertad", stateCode: "LAL", countryId: "173" },
  { id: "3138", name: "Lambayeque", stateCode: "LAM", countryId: "173" },
  { id: "3139", name: "Lima", stateCode: "LIM", countryId: "173" },
  { id: "3140", name: "Loreto", stateCode: "LOR", countryId: "173" },
  { id: "3141", name: "Madre de Dios", stateCode: "MDD", countryId: "173" },
  { id: "3142", name: "Moquegua", stateCode: "MOQ", countryId: "173" },
  { id: "3143", name: "Pasco", stateCode: "PAS", countryId: "173" },
  { id: "3144", name: "Piura", stateCode: "PIU", countryId: "173" },
  { id: "3145", name: "Puno", stateCode: "PUN", countryId: "173" },
  { id: "3146", name: "San Martín", stateCode: "SAM", countryId: "173" },
  { id: "3147", name: "Tacna", stateCode: "TAC", countryId: "173" },
  { id: "3148", name: "Tumbes", stateCode: "TUM", countryId: "173" },
  { id: "3149", name: "Ucayali", stateCode: "UCA", countryId: "173" },
  { id: "3150", name: "Abra", stateCode: "ABR", countryId: "174" },
  { id: "3151", name: "Agusan del Norte", stateCode: "AGN", countryId: "174" },
  { id: "3152", name: "Agusan del Sur", stateCode: "AGS", countryId: "174" },
  { id: "3153", name: "Aklan", stateCode: "AKL", countryId: "174" },
  { id: "3154", name: "Albay", stateCode: "ALB", countryId: "174" },
  { id: "3155", name: "Antique", stateCode: "ANT", countryId: "174" },
  { id: "3156", name: "Apayao", stateCode: "APA", countryId: "174" },
  { id: "3157", name: "Aurora", stateCode: "AUR", countryId: "174" },
  {
    id: "3158",
    name: "Autonomous Region in Muslim Mindanao",
    stateCode: "14",
    countryId: "174",
  },
  { id: "3159", name: "Basilan", stateCode: "BAS", countryId: "174" },
  { id: "3160", name: "Bataan", stateCode: "BAN", countryId: "174" },
  { id: "3161", name: "Batanes", stateCode: "BTN", countryId: "174" },
  { id: "3162", name: "Batangas", stateCode: "BTG", countryId: "174" },
  { id: "3163", name: "Benguet", stateCode: "BEN", countryId: "174" },
  { id: "3164", name: "Bicol", stateCode: "05", countryId: "174" },
  { id: "3165", name: "Biliran", stateCode: "BIL", countryId: "174" },
  { id: "3166", name: "Bohol", stateCode: "BOH", countryId: "174" },
  { id: "3167", name: "Bukidnon", stateCode: "BUK", countryId: "174" },
  { id: "3168", name: "Bulacan", stateCode: "BUL", countryId: "174" },
  { id: "3169", name: "Cagayan", stateCode: "CAG", countryId: "174" },
  { id: "3170", name: "Cagayan Valley", stateCode: "02", countryId: "174" },
  { id: "3171", name: "Calabarzon", stateCode: "40", countryId: "174" },
  { id: "3172", name: "Camarines Norte", stateCode: "CAN", countryId: "174" },
  { id: "3173", name: "Camarines Sur", stateCode: "CAS", countryId: "174" },
  { id: "3174", name: "Camiguin", stateCode: "CAM", countryId: "174" },
  { id: "3175", name: "Capiz", stateCode: "CAP", countryId: "174" },
  { id: "3176", name: "Caraga", stateCode: "13", countryId: "174" },
  { id: "3177", name: "Catanduanes", stateCode: "CAT", countryId: "174" },
  { id: "3178", name: "Cavite", stateCode: "CAV", countryId: "174" },
  { id: "3179", name: "Cebu", stateCode: "CEB", countryId: "174" },
  { id: "3180", name: "Central Luzon", stateCode: "03", countryId: "174" },
  { id: "3181", name: "Central Visayas", stateCode: "07", countryId: "174" },
  { id: "3182", name: "Compostela Valley", stateCode: "COM", countryId: "174" },
  {
    id: "3183",
    name: "Cordillera Administrative",
    stateCode: "15",
    countryId: "174",
  },
  { id: "3184", name: "Cotabato", stateCode: "NCO", countryId: "174" },
  { id: "3185", name: "Davao", stateCode: "11", countryId: "174" },
  { id: "3186", name: "Davao del Norte", stateCode: "DAV", countryId: "174" },
  { id: "3187", name: "Davao del Sur", stateCode: "DAS", countryId: "174" },
  { id: "3188", name: "Davao Occidental", stateCode: "DVO", countryId: "174" },
  { id: "3189", name: "Davao Oriental", stateCode: "DAO", countryId: "174" },
  { id: "3190", name: "Dinagat Islands", stateCode: "DIN", countryId: "174" },
  { id: "3191", name: "Eastern Samar", stateCode: "EAS", countryId: "174" },
  { id: "3192", name: "Eastern Visayas", stateCode: "08", countryId: "174" },
  { id: "3193", name: "Guimaras", stateCode: "GUI", countryId: "174" },
  { id: "3194", name: "Ifugao", stateCode: "IFU", countryId: "174" },
  { id: "3195", name: "Ilocos", stateCode: "01", countryId: "174" },
  { id: "3196", name: "Ilocos Norte", stateCode: "ILN", countryId: "174" },
  { id: "3197", name: "Ilocos Sur", stateCode: "ILS", countryId: "174" },
  { id: "3198", name: "Iloilo", stateCode: "ILI", countryId: "174" },
  { id: "3199", name: "Isabela", stateCode: "ISA", countryId: "174" },
  { id: "3200", name: "Kalinga", stateCode: "KAL", countryId: "174" },
  { id: "3201", name: "La Union", stateCode: "LUN", countryId: "174" },
  { id: "3202", name: "Laguna", stateCode: "LAG", countryId: "174" },
  { id: "3203", name: "Lanao del Norte", stateCode: "LAN", countryId: "174" },
  { id: "3204", name: "Lanao del Sur", stateCode: "LAS", countryId: "174" },
  { id: "3205", name: "Leyte", stateCode: "LEY", countryId: "174" },
  { id: "3206", name: "Maguindanao", stateCode: "MAG", countryId: "174" },
  { id: "3207", name: "Marinduque", stateCode: "MAD", countryId: "174" },
  { id: "3208", name: "Masbate", stateCode: "MAS", countryId: "174" },
  { id: "3209", name: "Metro Manila", stateCode: "NCR", countryId: "174" },
  { id: "3210", name: "Mimaropa", stateCode: "41", countryId: "174" },
  {
    id: "3211",
    name: "Misamis Occidental",
    stateCode: "MSC",
    countryId: "174",
  },
  { id: "3212", name: "Misamis Oriental", stateCode: "MSR", countryId: "174" },
  { id: "3213", name: "Mountain Province", stateCode: "MOU", countryId: "174" },
  { id: "3214", name: "Negros Occidental", stateCode: "NEC", countryId: "174" },
  { id: "3215", name: "Negros Oriental", stateCode: "NER", countryId: "174" },
  { id: "3216", name: "Northern Mindanao", stateCode: "10", countryId: "174" },
  { id: "3217", name: "Northern Samar", stateCode: "NSA", countryId: "174" },
  { id: "3218", name: "Nueva Ecija", stateCode: "NUE", countryId: "174" },
  { id: "3219", name: "Nueva Vizcaya", stateCode: "NUV", countryId: "174" },
  {
    id: "3220",
    name: "Occidental Mindoro",
    stateCode: "MDC",
    countryId: "174",
  },
  { id: "3221", name: "Oriental Mindoro", stateCode: "MDR", countryId: "174" },
  { id: "3222", name: "Palawan", stateCode: "PLW", countryId: "174" },
  { id: "3223", name: "Pampanga", stateCode: "PAM", countryId: "174" },
  { id: "3224", name: "Pangasinan", stateCode: "PAN", countryId: "174" },
  { id: "3225", name: "Quezon", stateCode: "QUE", countryId: "174" },
  { id: "3226", name: "Quirino", stateCode: "QUI", countryId: "174" },
  { id: "3227", name: "Rizal", stateCode: "RIZ", countryId: "174" },
  { id: "3228", name: "Romblon", stateCode: "ROM", countryId: "174" },
  { id: "3229", name: "Sarangani", stateCode: "SAR", countryId: "174" },
  { id: "3230", name: "Siquijor", stateCode: "SIG", countryId: "174" },
  { id: "3231", name: "Soccsksargen", stateCode: "12", countryId: "174" },
  { id: "3232", name: "Sorsogon", stateCode: "SOR", countryId: "174" },
  { id: "3233", name: "South Cotabato", stateCode: "SCO", countryId: "174" },
  { id: "3234", name: "Southern Leyte", stateCode: "SLE", countryId: "174" },
  { id: "3235", name: "Sultan Kudarat", stateCode: "SUK", countryId: "174" },
  { id: "3236", name: "Sulu", stateCode: "SLU", countryId: "174" },
  { id: "3237", name: "Surigao del Norte", stateCode: "SUN", countryId: "174" },
  { id: "3238", name: "Surigao del Sur", stateCode: "SUR", countryId: "174" },
  { id: "3239", name: "Tarlac", stateCode: "TAR", countryId: "174" },
  { id: "3240", name: "Tawi-Tawi", stateCode: "TAW", countryId: "174" },
  { id: "3241", name: "Western Samar", stateCode: "WSA", countryId: "174" },
  { id: "3242", name: "Western Visayas", stateCode: "06", countryId: "174" },
  { id: "3243", name: "Zambales", stateCode: "ZMB", countryId: "174" },
  {
    id: "3244",
    name: "Zamboanga del Norte",
    stateCode: "ZAN",
    countryId: "174",
  },
  { id: "3245", name: "Zamboanga del Sur", stateCode: "ZAS", countryId: "174" },
  {
    id: "3246",
    name: "Zamboanga Peninsula",
    stateCode: "09",
    countryId: "174",
  },
  { id: "3247", name: "Zamboanga Sibugay", stateCode: "ZSI", countryId: "174" },
  {
    id: "3248",
    name: "Greater Poland Voivodeship",
    stateCode: "WP",
    countryId: "176",
  },
  {
    id: "3249",
    name: "Kuyavian-Pomeranian Voivodeship",
    stateCode: "KP",
    countryId: "176",
  },
  {
    id: "3250",
    name: "Lesser Poland Voivodeship",
    stateCode: "MA",
    countryId: "176",
  },
  {
    id: "3251",
    name: "Lower Silesian Voivodeship",
    stateCode: "DS",
    countryId: "176",
  },
  { id: "3252", name: "Lublin Voivodeship", stateCode: "LU", countryId: "176" },
  { id: "3253", name: "Lubusz Voivodeship", stateCode: "LB", countryId: "176" },
  { id: "3254", name: "Łódź Voivodeship", stateCode: "LD", countryId: "176" },
  {
    id: "3255",
    name: "Masovian Voivodeship",
    stateCode: "MZ",
    countryId: "176",
  },
  { id: "3256", name: "Opole Voivodeship", stateCode: "OP", countryId: "176" },
  {
    id: "3257",
    name: "Podkarpackie Voivodeship",
    stateCode: "PK",
    countryId: "176",
  },
  {
    id: "3258",
    name: "Podlaskie Voivodeship",
    stateCode: "PD",
    countryId: "176",
  },
  {
    id: "3259",
    name: "Pomeranian Voivodeship",
    stateCode: "PM",
    countryId: "176",
  },
  {
    id: "3260",
    name: "Silesian Voivodeship",
    stateCode: "SL",
    countryId: "176",
  },
  {
    id: "3261",
    name: "Świętokrzyskie Voivodeship",
    stateCode: "SK",
    countryId: "176",
  },
  {
    id: "3262",
    name: "Warmian-Masurian Voivodeship",
    stateCode: "WN",
    countryId: "176",
  },
  {
    id: "3263",
    name: "West Pomeranian Voivodeship",
    stateCode: "ZP",
    countryId: "176",
  },
  { id: "3264", name: "Açores", stateCode: "20", countryId: "177" },
  { id: "3265", name: "Aveiro", stateCode: "01", countryId: "177" },
  { id: "3266", name: "Beja", stateCode: "02", countryId: "177" },
  { id: "3267", name: "Braga", stateCode: "03", countryId: "177" },
  { id: "3268", name: "Bragança", stateCode: "04", countryId: "177" },
  { id: "3269", name: "Castelo Branco", stateCode: "05", countryId: "177" },
  { id: "3270", name: "Coimbra", stateCode: "06", countryId: "177" },
  { id: "3271", name: "Évora", stateCode: "07", countryId: "177" },
  { id: "3272", name: "Faro", stateCode: "08", countryId: "177" },
  { id: "3273", name: "Guarda", stateCode: "09", countryId: "177" },
  { id: "3274", name: "Leiria", stateCode: "10", countryId: "177" },
  { id: "3275", name: "Lisbon", stateCode: "11", countryId: "177" },
  { id: "3276", name: "Madeira", stateCode: "30", countryId: "177" },
  { id: "3277", name: "Portalegre", stateCode: "12", countryId: "177" },
  { id: "3278", name: "Porto", stateCode: "13", countryId: "177" },
  { id: "3279", name: "Santarém", stateCode: "14", countryId: "177" },
  { id: "3280", name: "Setúbal", stateCode: "15", countryId: "177" },
  { id: "3281", name: "Viana do Castelo", stateCode: "16", countryId: "177" },
  { id: "3282", name: "Vila Real", stateCode: "17", countryId: "177" },
  { id: "3283", name: "Viseu", stateCode: "18", countryId: "177" },
  { id: "3284", name: "Adjuntas", stateCode: "001", countryId: "178" },
  { id: "3285", name: "Aguada", stateCode: "003", countryId: "178" },
  { id: "3286", name: "Aguadilla", stateCode: "005", countryId: "178" },
  { id: "3287", name: "Aguas Buenas", stateCode: "007", countryId: "178" },
  { id: "3288", name: "Aibonito", stateCode: "009", countryId: "178" },
  { id: "3289", name: "Añasco", stateCode: "011", countryId: "178" },
  { id: "3290", name: "Arecibo", stateCode: "013", countryId: "178" },
  { id: "3291", name: "Arecibo", stateCode: "AR", countryId: "178" },
  { id: "3292", name: "Arroyo", stateCode: "015", countryId: "178" },
  { id: "3293", name: "Barceloneta", stateCode: "017", countryId: "178" },
  { id: "3294", name: "Barranquitas", stateCode: "019", countryId: "178" },
  { id: "3295", name: "Bayamon", stateCode: "BY", countryId: "178" },
  { id: "3296", name: "Bayamón", stateCode: "021", countryId: "178" },
  { id: "3297", name: "Cabo Rojo", stateCode: "023", countryId: "178" },
  { id: "3298", name: "Caguas", stateCode: "CG", countryId: "178" },
  { id: "3299", name: "Caguas", stateCode: "025", countryId: "178" },
  { id: "3300", name: "Camuy", stateCode: "027", countryId: "178" },
  { id: "3301", name: "Canóvanas", stateCode: "029", countryId: "178" },
  { id: "3302", name: "Carolina", stateCode: "CL", countryId: "178" },
  { id: "3303", name: "Carolina", stateCode: "031", countryId: "178" },
  { id: "3304", name: "Cataño", stateCode: "033", countryId: "178" },
  { id: "3305", name: "Cayey", stateCode: "035", countryId: "178" },
  { id: "3306", name: "Ceiba", stateCode: "037", countryId: "178" },
  { id: "3307", name: "Ciales", stateCode: "039", countryId: "178" },
  { id: "3308", name: "Cidra", stateCode: "041", countryId: "178" },
  { id: "3309", name: "Coamo", stateCode: "043", countryId: "178" },
  { id: "3310", name: "Comerío", stateCode: "045", countryId: "178" },
  { id: "3311", name: "Corozal", stateCode: "047", countryId: "178" },
  { id: "3312", name: "Culebra", stateCode: "049", countryId: "178" },
  { id: "3313", name: "Dorado", stateCode: "051", countryId: "178" },
  { id: "3314", name: "Fajardo", stateCode: "053", countryId: "178" },
  { id: "3315", name: "Florida", stateCode: "054", countryId: "178" },
  { id: "3316", name: "Guánica", stateCode: "055", countryId: "178" },
  { id: "3317", name: "Guayama", stateCode: "057", countryId: "178" },
  { id: "3318", name: "Guayanilla", stateCode: "059", countryId: "178" },
  { id: "3319", name: "Guaynabo", stateCode: "GN", countryId: "178" },
  { id: "3320", name: "Guaynabo", stateCode: "061", countryId: "178" },
  { id: "3321", name: "Gurabo", stateCode: "063", countryId: "178" },
  { id: "3322", name: "Hatillo", stateCode: "065", countryId: "178" },
  { id: "3323", name: "Hormigueros", stateCode: "067", countryId: "178" },
  { id: "3324", name: "Humacao", stateCode: "069", countryId: "178" },
  { id: "3325", name: "Isabela", stateCode: "071", countryId: "178" },
  { id: "3326", name: "Jayuya", stateCode: "073", countryId: "178" },
  { id: "3327", name: "Juana Díaz", stateCode: "075", countryId: "178" },
  { id: "3328", name: "Juncos", stateCode: "077", countryId: "178" },
  { id: "3329", name: "Lajas", stateCode: "079", countryId: "178" },
  { id: "3330", name: "Lares", stateCode: "081", countryId: "178" },
  { id: "3331", name: "Las Marías", stateCode: "083", countryId: "178" },
  { id: "3332", name: "Las Piedras", stateCode: "085", countryId: "178" },
  { id: "3333", name: "Loíza", stateCode: "087", countryId: "178" },
  { id: "3334", name: "Luquillo", stateCode: "089", countryId: "178" },
  { id: "3335", name: "Manatí", stateCode: "091", countryId: "178" },
  { id: "3336", name: "Maricao", stateCode: "093", countryId: "178" },
  { id: "3337", name: "Maunabo", stateCode: "095", countryId: "178" },
  { id: "3338", name: "Mayagüez", stateCode: "MG", countryId: "178" },
  { id: "3339", name: "Mayagüez", stateCode: "097", countryId: "178" },
  { id: "3340", name: "Moca", stateCode: "099", countryId: "178" },
  { id: "3341", name: "Morovis", stateCode: "101", countryId: "178" },
  { id: "3342", name: "Naguabo", stateCode: "103", countryId: "178" },
  { id: "3343", name: "Naranjito", stateCode: "105", countryId: "178" },
  { id: "3344", name: "Orocovis", stateCode: "107", countryId: "178" },
  { id: "3345", name: "Patillas", stateCode: "109", countryId: "178" },
  { id: "3346", name: "Peñuelas", stateCode: "111", countryId: "178" },
  { id: "3347", name: "Ponce", stateCode: "PO", countryId: "178" },
  { id: "3348", name: "Ponce", stateCode: "113", countryId: "178" },
  { id: "3349", name: "Quebradillas", stateCode: "115", countryId: "178" },
  { id: "3350", name: "Rincón", stateCode: "117", countryId: "178" },
  { id: "3351", name: "Río Grande", stateCode: "119", countryId: "178" },
  { id: "3352", name: "Sabana Grande", stateCode: "121", countryId: "178" },
  { id: "3353", name: "Salinas", stateCode: "123", countryId: "178" },
  { id: "3354", name: "San Germán", stateCode: "125", countryId: "178" },
  { id: "3355", name: "San Juan", stateCode: "127", countryId: "178" },
  { id: "3356", name: "San Juan", stateCode: "SJ", countryId: "178" },
  { id: "3357", name: "San Lorenzo", stateCode: "129", countryId: "178" },
  { id: "3358", name: "San Sebastián", stateCode: "131", countryId: "178" },
  { id: "3359", name: "Santa Isabel", stateCode: "133", countryId: "178" },
  { id: "3360", name: "Toa Alta", stateCode: "135", countryId: "178" },
  { id: "3361", name: "Toa Baja", stateCode: "TB", countryId: "178" },
  { id: "3362", name: "Toa Baja", stateCode: "137", countryId: "178" },
  { id: "3363", name: "Trujillo Alto", stateCode: "TA", countryId: "178" },
  { id: "3364", name: "Trujillo Alto", stateCode: "139", countryId: "178" },
  { id: "3365", name: "Utuado", stateCode: "141", countryId: "178" },
  { id: "3366", name: "Vega Alta", stateCode: "143", countryId: "178" },
  { id: "3367", name: "Vega Baja", stateCode: "145", countryId: "178" },
  { id: "3368", name: "Vieques", stateCode: "147", countryId: "178" },
  { id: "3369", name: "Villalba", stateCode: "149", countryId: "178" },
  { id: "3370", name: "Yabucoa", stateCode: "151", countryId: "178" },
  { id: "3371", name: "Yauco", stateCode: "153", countryId: "178" },
  { id: "3372", name: "Al Daayen", stateCode: "ZA", countryId: "179" },
  { id: "3373", name: "Al Khor", stateCode: "KH", countryId: "179" },
  {
    id: "3374",
    name: "Al Rayyan Municipality",
    stateCode: "RA",
    countryId: "179",
  },
  { id: "3375", name: "Al Wakrah", stateCode: "WA", countryId: "179" },
  { id: "3376", name: "Al-Shahaniya", stateCode: "SH", countryId: "179" },
  { id: "3377", name: "Doha", stateCode: "DA", countryId: "179" },
  { id: "3378", name: "Madinat ash Shamal", stateCode: "MS", countryId: "179" },
  {
    id: "3379",
    name: "Umm Salal Municipality",
    stateCode: "US",
    countryId: "179",
  },
  { id: "3380", name: "Alba", stateCode: "AB", countryId: "181" },
  { id: "3381", name: "Arad County", stateCode: "AR", countryId: "181" },
  { id: "3382", name: "Arges", stateCode: "AG", countryId: "181" },
  { id: "3383", name: "Bacău County", stateCode: "BC", countryId: "181" },
  { id: "3384", name: "Bihor County", stateCode: "BH", countryId: "181" },
  {
    id: "3385",
    name: "Bistrița-Năsăud County",
    stateCode: "BN",
    countryId: "181",
  },
  { id: "3386", name: "Botoșani County", stateCode: "BT", countryId: "181" },
  { id: "3387", name: "Braila", stateCode: "BR", countryId: "181" },
  { id: "3388", name: "Brașov County", stateCode: "BV", countryId: "181" },
  { id: "3389", name: "Bucharest", stateCode: "B", countryId: "181" },
  { id: "3390", name: "Buzău County", stateCode: "BZ", countryId: "181" },
  { id: "3391", name: "Călărași County", stateCode: "CL", countryId: "181" },
  {
    id: "3392",
    name: "Caraș-Severin County",
    stateCode: "CS",
    countryId: "181",
  },
  { id: "3393", name: "Cluj County", stateCode: "CJ", countryId: "181" },
  { id: "3394", name: "Constanța County", stateCode: "CT", countryId: "181" },
  { id: "3395", name: "Covasna County", stateCode: "CV", countryId: "181" },
  { id: "3396", name: "Dâmbovița County", stateCode: "DB", countryId: "181" },
  { id: "3397", name: "Dolj County", stateCode: "DJ", countryId: "181" },
  { id: "3398", name: "Galați County", stateCode: "GL", countryId: "181" },
  { id: "3399", name: "Giurgiu County", stateCode: "GR", countryId: "181" },
  { id: "3400", name: "Gorj County", stateCode: "GJ", countryId: "181" },
  { id: "3401", name: "Harghita County", stateCode: "HR", countryId: "181" },
  { id: "3402", name: "Hunedoara County", stateCode: "HD", countryId: "181" },
  { id: "3403", name: "Ialomița County", stateCode: "IL", countryId: "181" },
  { id: "3404", name: "Iași County", stateCode: "IS", countryId: "181" },
  { id: "3405", name: "Ilfov County", stateCode: "IF", countryId: "181" },
  { id: "3406", name: "Maramureș County", stateCode: "MM", countryId: "181" },
  { id: "3407", name: "Mehedinți County", stateCode: "MH", countryId: "181" },
  { id: "3408", name: "Mureș County", stateCode: "MS", countryId: "181" },
  { id: "3409", name: "Neamț County", stateCode: "NT", countryId: "181" },
  { id: "3410", name: "Olt County", stateCode: "OT", countryId: "181" },
  { id: "3411", name: "Prahova County", stateCode: "PH", countryId: "181" },
  { id: "3412", name: "Sălaj County", stateCode: "SJ", countryId: "181" },
  { id: "3413", name: "Satu Mare County", stateCode: "SM", countryId: "181" },
  { id: "3414", name: "Sibiu County", stateCode: "SB", countryId: "181" },
  { id: "3415", name: "Suceava County", stateCode: "SV", countryId: "181" },
  { id: "3416", name: "Teleorman County", stateCode: "TR", countryId: "181" },
  { id: "3417", name: "Timiș County", stateCode: "TM", countryId: "181" },
  { id: "3418", name: "Tulcea County", stateCode: "TL", countryId: "181" },
  { id: "3419", name: "Vâlcea County", stateCode: "VL", countryId: "181" },
  { id: "3420", name: "Vaslui County", stateCode: "VS", countryId: "181" },
  { id: "3421", name: "Vrancea County", stateCode: "VN", countryId: "181" },
  { id: "3422", name: "Altai Krai", stateCode: "ALT", countryId: "182" },
  { id: "3423", name: "Altai Republic", stateCode: "AL", countryId: "182" },
  { id: "3424", name: "Amur Oblast", stateCode: "AMU", countryId: "182" },
  { id: "3425", name: "Arkhangelsk", stateCode: "ARK", countryId: "182" },
  { id: "3426", name: "Astrakhan Oblast", stateCode: "AST", countryId: "182" },
  { id: "3427", name: "Belgorod Oblast", stateCode: "BEL", countryId: "182" },
  { id: "3428", name: "Bryansk Oblast", stateCode: "BRY", countryId: "182" },
  { id: "3429", name: "Chechen Republic", stateCode: "CE", countryId: "182" },
  {
    id: "3430",
    name: "Chelyabinsk Oblast",
    stateCode: "CHE",
    countryId: "182",
  },
  {
    id: "3431",
    name: "Chukotka Autonomous Okrug",
    stateCode: "CHU",
    countryId: "182",
  },
  { id: "3432", name: "Chuvash Republic", stateCode: "CU", countryId: "182" },
  { id: "3433", name: "Irkutsk", stateCode: "IRK", countryId: "182" },
  { id: "3434", name: "Ivanovo Oblast", stateCode: "IVA", countryId: "182" },
  {
    id: "3435",
    name: "Jewish Autonomous Oblast",
    stateCode: "YEV",
    countryId: "182",
  },
  {
    id: "3436",
    name: "Kabardino-Balkar Republic",
    stateCode: "KB",
    countryId: "182",
  },
  { id: "3437", name: "Kaliningrad", stateCode: "KGD", countryId: "182" },
  { id: "3438", name: "Kaluga Oblast", stateCode: "KLU", countryId: "182" },
  { id: "3439", name: "Kamchatka Krai", stateCode: "KAM", countryId: "182" },
  {
    id: "3440",
    name: "Karachay-Cherkess Republic",
    stateCode: "KC",
    countryId: "182",
  },
  { id: "3441", name: "Kemerovo Oblast", stateCode: "KEM", countryId: "182" },
  { id: "3442", name: "Khabarovsk Krai", stateCode: "KHA", countryId: "182" },
  {
    id: "3443",
    name: "Khanty-Mansi Autonomous Okrug",
    stateCode: "KHM",
    countryId: "182",
  },
  { id: "3444", name: "Kirov Oblast", stateCode: "KIR", countryId: "182" },
  { id: "3445", name: "Komi Republic", stateCode: "KO", countryId: "182" },
  { id: "3446", name: "Kostroma Oblast", stateCode: "KOS", countryId: "182" },
  { id: "3447", name: "Krasnodar Krai", stateCode: "KDA", countryId: "182" },
  { id: "3448", name: "Krasnoyarsk Krai", stateCode: "KYA", countryId: "182" },
  { id: "3449", name: "Kurgan Oblast", stateCode: "KGN", countryId: "182" },
  { id: "3450", name: "Kursk Oblast", stateCode: "KRS", countryId: "182" },
  { id: "3451", name: "Leningrad Oblast", stateCode: "LEN", countryId: "182" },
  { id: "3452", name: "Lipetsk Oblast", stateCode: "LIP", countryId: "182" },
  { id: "3453", name: "Magadan Oblast", stateCode: "MAG", countryId: "182" },
  { id: "3454", name: "Mari El Republic", stateCode: "ME", countryId: "182" },
  { id: "3455", name: "Moscow", stateCode: "MOW", countryId: "182" },
  { id: "3456", name: "Moscow Oblast", stateCode: "MOS", countryId: "182" },
  { id: "3457", name: "Murmansk Oblast", stateCode: "MUR", countryId: "182" },
  {
    id: "3458",
    name: "Nenets Autonomous Okrug",
    stateCode: "NEN",
    countryId: "182",
  },
  {
    id: "3459",
    name: "Nizhny Novgorod Oblast",
    stateCode: "NIZ",
    countryId: "182",
  },
  { id: "3460", name: "Novgorod Oblast", stateCode: "NGR", countryId: "182" },
  { id: "3461", name: "Novosibirsk", stateCode: "NVS", countryId: "182" },
  { id: "3462", name: "Omsk Oblast", stateCode: "OMS", countryId: "182" },
  { id: "3463", name: "Orenburg Oblast", stateCode: "ORE", countryId: "182" },
  { id: "3464", name: "Oryol Oblast", stateCode: "ORL", countryId: "182" },
  { id: "3465", name: "Penza Oblast", stateCode: "PNZ", countryId: "182" },
  { id: "3466", name: "Perm Krai", stateCode: "PER", countryId: "182" },
  { id: "3467", name: "Primorsky Krai", stateCode: "PRI", countryId: "182" },
  { id: "3468", name: "Pskov Oblast", stateCode: "PSK", countryId: "182" },
  { id: "3469", name: "Republic of Adygea", stateCode: "AD", countryId: "182" },
  {
    id: "3470",
    name: "Republic of Bashkortostan",
    stateCode: "BA",
    countryId: "182",
  },
  {
    id: "3471",
    name: "Republic of Buryatia",
    stateCode: "BU",
    countryId: "182",
  },
  {
    id: "3472",
    name: "Republic of Dagestan",
    stateCode: "DA",
    countryId: "182",
  },
  {
    id: "3473",
    name: "Republic of Ingushetia",
    stateCode: "IN",
    countryId: "182",
  },
  {
    id: "3474",
    name: "Republic of Kalmykia",
    stateCode: "KL",
    countryId: "182",
  },
  {
    id: "3475",
    name: "Republic of Karelia",
    stateCode: "KR",
    countryId: "182",
  },
  {
    id: "3476",
    name: "Republic of Khakassia",
    stateCode: "KK",
    countryId: "182",
  },
  {
    id: "3477",
    name: "Republic of Mordovia",
    stateCode: "MO",
    countryId: "182",
  },
  {
    id: "3478",
    name: "Republic of North Ossetia-Alania",
    stateCode: "SE",
    countryId: "182",
  },
  {
    id: "3479",
    name: "Republic of Tatarstan",
    stateCode: "TA",
    countryId: "182",
  },
  { id: "3480", name: "Rostov Oblast", stateCode: "ROS", countryId: "182" },
  { id: "3481", name: "Ryazan Oblast", stateCode: "RYA", countryId: "182" },
  { id: "3482", name: "Saint Petersburg", stateCode: "SPE", countryId: "182" },
  { id: "3483", name: "Sakha Republic", stateCode: "SA", countryId: "182" },
  { id: "3484", name: "Sakhalin", stateCode: "SAK", countryId: "182" },
  { id: "3485", name: "Samara Oblast", stateCode: "SAM", countryId: "182" },
  { id: "3486", name: "Saratov Oblast", stateCode: "SAR", countryId: "182" },
  { id: "3487", name: "Smolensk Oblast", stateCode: "SMO", countryId: "182" },
  { id: "3488", name: "Stavropol Krai", stateCode: "STA", countryId: "182" },
  { id: "3489", name: "Sverdlovsk", stateCode: "SVE", countryId: "182" },
  { id: "3490", name: "Tambov Oblast", stateCode: "TAM", countryId: "182" },
  { id: "3491", name: "Tomsk Oblast", stateCode: "TOM", countryId: "182" },
  { id: "3492", name: "Tula Oblast", stateCode: "TUL", countryId: "182" },
  { id: "3493", name: "Tuva Republic", stateCode: "TY", countryId: "182" },
  { id: "3494", name: "Tver Oblast", stateCode: "TVE", countryId: "182" },
  { id: "3495", name: "Tyumen Oblast", stateCode: "TYU", countryId: "182" },
  { id: "3496", name: "Udmurt Republic", stateCode: "UD", countryId: "182" },
  { id: "3497", name: "Ulyanovsk Oblast", stateCode: "ULY", countryId: "182" },
  { id: "3498", name: "Vladimir Oblast", stateCode: "VLA", countryId: "182" },
  { id: "3499", name: "Volgograd Oblast", stateCode: "VGG", countryId: "182" },
  { id: "3500", name: "Vologda Oblast", stateCode: "VLG", countryId: "182" },
  { id: "3501", name: "Voronezh Oblast", stateCode: "VOR", countryId: "182" },
  {
    id: "3502",
    name: "Yamalo-Nenets Autonomous Okrug",
    stateCode: "YAN",
    countryId: "182",
  },
  { id: "3503", name: "Yaroslavl Oblast", stateCode: "YAR", countryId: "182" },
  { id: "3504", name: "Zabaykalsky Krai", stateCode: "ZAB", countryId: "182" },
  { id: "3505", name: "Eastern Province", stateCode: "02", countryId: "183" },
  { id: "3506", name: "Kigali district", stateCode: "01", countryId: "183" },
  { id: "3507", name: "Northern Province", stateCode: "03", countryId: "183" },
  { id: "3508", name: "Southern Province", stateCode: "05", countryId: "183" },
  { id: "3509", name: "Western Province", stateCode: "04", countryId: "183" },
  {
    id: "3510",
    name: "Christ Church Nichola Town Parish",
    stateCode: "01",
    countryId: "185",
  },
  { id: "3511", name: "Nevis", stateCode: "N", countryId: "185" },
  {
    id: "3512",
    name: "Saint Anne Sandy Point Parish",
    stateCode: "02",
    countryId: "185",
  },
  {
    id: "3513",
    name: "Saint George Gingerland Parish",
    stateCode: "04",
    countryId: "185",
  },
  {
    id: "3514",
    name: "Saint James Windward Parish",
    stateCode: "05",
    countryId: "185",
  },
  {
    id: "3515",
    name: "Saint John Capisterre Parish",
    stateCode: "06",
    countryId: "185",
  },
  {
    id: "3516",
    name: "Saint John Figtree Parish",
    stateCode: "07",
    countryId: "185",
  },
  { id: "3517", name: "Saint Kitts", stateCode: "K", countryId: "185" },
  {
    id: "3518",
    name: "Saint Mary Cayon Parish",
    stateCode: "08",
    countryId: "185",
  },
  {
    id: "3519",
    name: "Saint Paul Capisterre Parish",
    stateCode: "09",
    countryId: "185",
  },
  {
    id: "3520",
    name: "Saint Paul Charlestown Parish",
    stateCode: "10",
    countryId: "185",
  },
  {
    id: "3521",
    name: "Saint Peter Basseterre Parish",
    stateCode: "11",
    countryId: "185",
  },
  {
    id: "3522",
    name: "Saint Thomas Lowland Parish",
    stateCode: "12",
    countryId: "185",
  },
  {
    id: "3523",
    name: "Saint Thomas Middle Island Parish",
    stateCode: "13",
    countryId: "185",
  },
  {
    id: "3524",
    name: "Trinity Palmetto Point Parish",
    stateCode: "15",
    countryId: "185",
  },
  {
    id: "3525",
    name: "Anse la Raye Quarter",
    stateCode: "01",
    countryId: "186",
  },
  { id: "3526", name: "Canaries", stateCode: "12", countryId: "186" },
  { id: "3527", name: "Castries Quarter", stateCode: "02", countryId: "186" },
  { id: "3528", name: "Choiseul Quarter", stateCode: "03", countryId: "186" },
  { id: "3529", name: "Dauphin Quarter", stateCode: "04", countryId: "186" },
  { id: "3530", name: "Dennery Quarter", stateCode: "05", countryId: "186" },
  { id: "3531", name: "Gros Islet Quarter", stateCode: "06", countryId: "186" },
  { id: "3532", name: "Laborie Quarter", stateCode: "07", countryId: "186" },
  { id: "3533", name: "Micoud Quarter", stateCode: "08", countryId: "186" },
  { id: "3534", name: "Praslin Quarter", stateCode: "09", countryId: "186" },
  { id: "3535", name: "Soufrière Quarter", stateCode: "10", countryId: "186" },
  { id: "3536", name: "Vieux Fort Quarter", stateCode: "11", countryId: "186" },
  { id: "3537", name: "Charlotte Parish", stateCode: "01", countryId: "188" },
  { id: "3538", name: "Grenadines Parish", stateCode: "06", countryId: "188" },
  {
    id: "3539",
    name: "Saint Andrew Parish",
    stateCode: "02",
    countryId: "188",
  },
  { id: "3540", name: "Saint David Parish", stateCode: "03", countryId: "188" },
  {
    id: "3541",
    name: "Saint George Parish",
    stateCode: "04",
    countryId: "188",
  },
  {
    id: "3542",
    name: "Saint Patrick Parish",
    stateCode: "05",
    countryId: "188",
  },
  { id: "3543", name: "A\\'ana", stateCode: "AA", countryId: "191" },
  { id: "3544", name: "Aiga-i-le-Tai", stateCode: "AL", countryId: "191" },
  { id: "3545", name: "Atua", stateCode: "AT", countryId: "191" },
  { id: "3546", name: "Fa\\'asaleleaga", stateCode: "FA", countryId: "191" },
  { id: "3547", name: "Gaga\\'emauga", stateCode: "GE", countryId: "191" },
  { id: "3548", name: "Gaga\\'ifomauga", stateCode: "GI", countryId: "191" },
  { id: "3549", name: "Palauli", stateCode: "PA", countryId: "191" },
  { id: "3550", name: "Satupa\\'itea", stateCode: "SA", countryId: "191" },
  { id: "3551", name: "Tuamasaga", stateCode: "TU", countryId: "191" },
  { id: "3552", name: "Va\\'a-o-Fonoti", stateCode: "VF", countryId: "191" },
  { id: "3553", name: "Vaisigano", stateCode: "VS", countryId: "191" },
  { id: "3554", name: "Acquaviva", stateCode: "01", countryId: "192" },
  { id: "3555", name: "Borgo Maggiore", stateCode: "06", countryId: "192" },
  { id: "3556", name: "Chiesanuova", stateCode: "02", countryId: "192" },
  { id: "3557", name: "Domagnano", stateCode: "03", countryId: "192" },
  { id: "3558", name: "Faetano", stateCode: "04", countryId: "192" },
  { id: "3559", name: "Fiorentino", stateCode: "05", countryId: "192" },
  { id: "3560", name: "Montegiardino", stateCode: "08", countryId: "192" },
  { id: "3561", name: "San Marino", stateCode: "07", countryId: "192" },
  { id: "3562", name: "Serravalle", stateCode: "09", countryId: "192" },
  { id: "3563", name: "Príncipe Province", stateCode: "P", countryId: "193" },
  { id: "3564", name: "São Tomé Province", stateCode: "S", countryId: "193" },
  { id: "3565", name: "\\'Asir", stateCode: "14", countryId: "194" },
  { id: "3566", name: "Al Bahah", stateCode: "11", countryId: "194" },
  { id: "3567", name: "Al Jawf", stateCode: "12", countryId: "194" },
  { id: "3568", name: "Al Madinah", stateCode: "03", countryId: "194" },
  { id: "3569", name: "Al-Qassim", stateCode: "05", countryId: "194" },
  { id: "3570", name: "Eastern Province", stateCode: "04", countryId: "194" },
  { id: "3571", name: "Ha\\'il", stateCode: "06", countryId: "194" },
  { id: "3572", name: "Jizan", stateCode: "09", countryId: "194" },
  { id: "3573", name: "Makkah", stateCode: "02", countryId: "194" },
  { id: "3574", name: "Najran", stateCode: "10", countryId: "194" },
  { id: "3575", name: "Northern Borders", stateCode: "08", countryId: "194" },
  { id: "3576", name: "Riyadh", stateCode: "01", countryId: "194" },
  { id: "3577", name: "Tabuk", stateCode: "07", countryId: "194" },
  { id: "3578", name: "Dakar", stateCode: "DK", countryId: "195" },
  { id: "3579", name: "Diourbel Region", stateCode: "DB", countryId: "195" },
  { id: "3580", name: "Fatick", stateCode: "FK", countryId: "195" },
  { id: "3581", name: "Kaffrine", stateCode: "KA", countryId: "195" },
  { id: "3582", name: "Kaolack", stateCode: "KL", countryId: "195" },
  { id: "3583", name: "Kédougou", stateCode: "KE", countryId: "195" },
  { id: "3584", name: "Kolda", stateCode: "KD", countryId: "195" },
  { id: "3585", name: "Louga", stateCode: "LG", countryId: "195" },
  { id: "3586", name: "Matam", stateCode: "MT", countryId: "195" },
  { id: "3587", name: "Saint-Louis", stateCode: "SL", countryId: "195" },
  { id: "3588", name: "Sédhiou", stateCode: "SE", countryId: "195" },
  { id: "3589", name: "Tambacounda Region", stateCode: "TC", countryId: "195" },
  { id: "3590", name: "Thiès Region", stateCode: "TH", countryId: "195" },
  { id: "3591", name: "Ziguinchor", stateCode: "ZG", countryId: "195" },
  { id: "3592", name: "Belgrade", stateCode: "00", countryId: "196" },
  { id: "3593", name: "Bor District", stateCode: "14", countryId: "196" },
  { id: "3594", name: "Braničevo District", stateCode: "11", countryId: "196" },
  {
    id: "3595",
    name: "Central Banat District",
    stateCode: "02",
    countryId: "196",
  },
  { id: "3596", name: "Jablanica District", stateCode: "23", countryId: "196" },
  { id: "3597", name: "Kolubara District", stateCode: "09", countryId: "196" },
  { id: "3598", name: "Mačva District", stateCode: "08", countryId: "196" },
  { id: "3599", name: "Moravica District", stateCode: "17", countryId: "196" },
  { id: "3600", name: "Nišava District", stateCode: "20", countryId: "196" },
  {
    id: "3601",
    name: "North Bačka District",
    stateCode: "01",
    countryId: "196",
  },
  {
    id: "3602",
    name: "North Banat District",
    stateCode: "03",
    countryId: "196",
  },
  { id: "3603", name: "Pčinja District", stateCode: "24", countryId: "196" },
  { id: "3604", name: "Pirot District", stateCode: "22", countryId: "196" },
  {
    id: "3605",
    name: "Podunavlje District",
    stateCode: "10",
    countryId: "196",
  },
  {
    id: "3606",
    name: "Pomoravlje District",
    stateCode: "13",
    countryId: "196",
  },
  { id: "3607", name: "Rasina District", stateCode: "19", countryId: "196" },
  { id: "3608", name: "Raška District", stateCode: "18", countryId: "196" },
  {
    id: "3609",
    name: "South Bačka District",
    stateCode: "06",
    countryId: "196",
  },
  {
    id: "3610",
    name: "South Banat District",
    stateCode: "04",
    countryId: "196",
  },
  { id: "3611", name: "Srem District", stateCode: "07", countryId: "196" },
  { id: "3612", name: "Šumadija District", stateCode: "12", countryId: "196" },
  { id: "3613", name: "Toplica District", stateCode: "21", countryId: "196" },
  { id: "3614", name: "Vojvodina", stateCode: "VO", countryId: "196" },
  {
    id: "3615",
    name: "West Bačka District",
    stateCode: "05",
    countryId: "196",
  },
  { id: "3616", name: "Zaječar District", stateCode: "15", countryId: "196" },
  { id: "3617", name: "Zlatibor District", stateCode: "16", countryId: "196" },
  { id: "3618", name: "Anse Boileau", stateCode: "02", countryId: "197" },
  { id: "3619", name: "Anse Royale", stateCode: "05", countryId: "197" },
  { id: "3620", name: "Anse-aux-Pins", stateCode: "01", countryId: "197" },
  { id: "3621", name: "Au Cap", stateCode: "04", countryId: "197" },
  { id: "3622", name: "Baie Lazare", stateCode: "06", countryId: "197" },
  { id: "3623", name: "Baie Sainte Anne", stateCode: "07", countryId: "197" },
  { id: "3624", name: "Beau Vallon", stateCode: "08", countryId: "197" },
  { id: "3625", name: "Bel Air", stateCode: "09", countryId: "197" },
  { id: "3626", name: "Bel Ombre", stateCode: "10", countryId: "197" },
  { id: "3627", name: "Cascade", stateCode: "11", countryId: "197" },
  { id: "3628", name: "Glacis", stateCode: "12", countryId: "197" },
  { id: "3629", name: "Grand\\'Anse Mahé", stateCode: "13", countryId: "197" },
  {
    id: "3630",
    name: "Grand\\'Anse Praslin",
    stateCode: "14",
    countryId: "197",
  },
  { id: "3631", name: "La Digue", stateCode: "15", countryId: "197" },
  {
    id: "3632",
    name: "La Rivière Anglaise",
    stateCode: "16",
    countryId: "197",
  },
  { id: "3633", name: "Les Mamelles", stateCode: "24", countryId: "197" },
  { id: "3634", name: "Mont Buxton", stateCode: "17", countryId: "197" },
  { id: "3635", name: "Mont Fleuri", stateCode: "18", countryId: "197" },
  { id: "3636", name: "Plaisance", stateCode: "19", countryId: "197" },
  { id: "3637", name: "Pointe La Rue", stateCode: "20", countryId: "197" },
  { id: "3638", name: "Port Glaud", stateCode: "21", countryId: "197" },
  { id: "3639", name: "Roche Caiman", stateCode: "25", countryId: "197" },
  { id: "3640", name: "Saint Louis", stateCode: "22", countryId: "197" },
  { id: "3641", name: "Takamaka", stateCode: "23", countryId: "197" },
  { id: "3642", name: "Eastern Province", stateCode: "E", countryId: "198" },
  { id: "3643", name: "Northern Province", stateCode: "N", countryId: "198" },
  { id: "3644", name: "Southern Province", stateCode: "S", countryId: "198" },
  { id: "3645", name: "Western Area", stateCode: "W", countryId: "198" },
  { id: "3646", name: "Central Singapore", stateCode: "01", countryId: "199" },
  { id: "3647", name: "North East", stateCode: "02", countryId: "199" },
  { id: "3648", name: "North West", stateCode: "03", countryId: "199" },
  { id: "3649", name: "South East", stateCode: "04", countryId: "199" },
  { id: "3650", name: "South West", stateCode: "05", countryId: "199" },
  {
    id: "3651",
    name: "Banská Bystrica Region",
    stateCode: "BC",
    countryId: "201",
  },
  { id: "3652", name: "Bratislava Region", stateCode: "BL", countryId: "201" },
  { id: "3653", name: "Košice Region", stateCode: "KI", countryId: "201" },
  { id: "3654", name: "Nitra Region", stateCode: "NI", countryId: "201" },
  { id: "3655", name: "Prešov Region", stateCode: "PV", countryId: "201" },
  { id: "3656", name: "Trenčín Region", stateCode: "TC", countryId: "201" },
  { id: "3657", name: "Trnava Region", stateCode: "TA", countryId: "201" },
  { id: "3658", name: "Žilina Region", stateCode: "ZI", countryId: "201" },
  {
    id: "3659",
    name: "Ajdovščina Municipality",
    stateCode: "001",
    countryId: "202",
  },
  {
    id: "3660",
    name: "Ankaran Municipality",
    stateCode: "213",
    countryId: "202",
  },
  {
    id: "3661",
    name: "Beltinci Municipality",
    stateCode: "002",
    countryId: "202",
  },
  {
    id: "3662",
    name: "Benedikt Municipality",
    stateCode: "148",
    countryId: "202",
  },
  {
    id: "3663",
    name: "Bistrica ob Sotli Municipality",
    stateCode: "149",
    countryId: "202",
  },
  { id: "3664", name: "Bled Municipality", stateCode: "003", countryId: "202" },
  {
    id: "3665",
    name: "Bloke Municipality",
    stateCode: "150",
    countryId: "202",
  },
  {
    id: "3666",
    name: "Bohinj Municipality",
    stateCode: "004",
    countryId: "202",
  },
  {
    id: "3667",
    name: "Borovnica Municipality",
    stateCode: "005",
    countryId: "202",
  },
  {
    id: "3668",
    name: "Bovec Municipality",
    stateCode: "006",
    countryId: "202",
  },
  {
    id: "3669",
    name: "Braslovče Municipality",
    stateCode: "151",
    countryId: "202",
  },
  { id: "3670", name: "Brda Municipality", stateCode: "007", countryId: "202" },
  {
    id: "3671",
    name: "Brežice Municipality",
    stateCode: "009",
    countryId: "202",
  },
  {
    id: "3672",
    name: "Brezovica Municipality",
    stateCode: "008",
    countryId: "202",
  },
  {
    id: "3673",
    name: "Cankova Municipality",
    stateCode: "152",
    countryId: "202",
  },
  {
    id: "3674",
    name: "Cerklje na Gorenjskem Municipality",
    stateCode: "012",
    countryId: "202",
  },
  {
    id: "3675",
    name: "Cerknica Municipality",
    stateCode: "013",
    countryId: "202",
  },
  {
    id: "3676",
    name: "Cerkno Municipality",
    stateCode: "014",
    countryId: "202",
  },
  {
    id: "3677",
    name: "Cerkvenjak Municipality",
    stateCode: "153",
    countryId: "202",
  },
  {
    id: "3678",
    name: "City Municipality of Celje",
    stateCode: "011",
    countryId: "202",
  },
  {
    id: "3679",
    name: "City Municipality of Novo Mesto",
    stateCode: "085",
    countryId: "202",
  },
  {
    id: "3680",
    name: "Črenšovci Municipality",
    stateCode: "015",
    countryId: "202",
  },
  {
    id: "3681",
    name: "Črna na Koroškem Municipality",
    stateCode: "016",
    countryId: "202",
  },
  {
    id: "3682",
    name: "Črnomelj Municipality",
    stateCode: "017",
    countryId: "202",
  },
  {
    id: "3683",
    name: "Destrnik Municipality",
    stateCode: "018",
    countryId: "202",
  },
  {
    id: "3684",
    name: "Divača Municipality",
    stateCode: "019",
    countryId: "202",
  },
  {
    id: "3685",
    name: "Dobje Municipality",
    stateCode: "154",
    countryId: "202",
  },
  {
    id: "3686",
    name: "Dobrepolje Municipality",
    stateCode: "020",
    countryId: "202",
  },
  {
    id: "3687",
    name: "Dobrna Municipality",
    stateCode: "155",
    countryId: "202",
  },
  {
    id: "3688",
    name: "Dobrova–Polhov Gradec Municipality",
    stateCode: "021",
    countryId: "202",
  },
  {
    id: "3689",
    name: "Dobrovnik Municipality",
    stateCode: "156",
    countryId: "202",
  },
  {
    id: "3690",
    name: "Dol pri Ljubljani Municipality",
    stateCode: "022",
    countryId: "202",
  },
  {
    id: "3691",
    name: "Dolenjske Toplice Municipality",
    stateCode: "157",
    countryId: "202",
  },
  {
    id: "3692",
    name: "Domžale Municipality",
    stateCode: "023",
    countryId: "202",
  },
  {
    id: "3693",
    name: "Dornava Municipality",
    stateCode: "024",
    countryId: "202",
  },
  {
    id: "3694",
    name: "Dravograd Municipality",
    stateCode: "025",
    countryId: "202",
  },
  {
    id: "3695",
    name: "Duplek Municipality",
    stateCode: "026",
    countryId: "202",
  },
  {
    id: "3696",
    name: "Gorenja Vas–Poljane Municipality",
    stateCode: "027",
    countryId: "202",
  },
  {
    id: "3697",
    name: "Gorišnica Municipality",
    stateCode: "028",
    countryId: "202",
  },
  {
    id: "3698",
    name: "Gorje Municipality",
    stateCode: "207",
    countryId: "202",
  },
  {
    id: "3699",
    name: "Gornja Radgona Municipality",
    stateCode: "029",
    countryId: "202",
  },
  {
    id: "3700",
    name: "Gornji Grad Municipality",
    stateCode: "030",
    countryId: "202",
  },
  {
    id: "3701",
    name: "Gornji Petrovci Municipality",
    stateCode: "031",
    countryId: "202",
  },
  { id: "3702", name: "Grad Municipality", stateCode: "158", countryId: "202" },
  {
    id: "3703",
    name: "Grosuplje Municipality",
    stateCode: "032",
    countryId: "202",
  },
  {
    id: "3704",
    name: "Hajdina Municipality",
    stateCode: "159",
    countryId: "202",
  },
  {
    id: "3705",
    name: "Hoče–Slivnica Municipality",
    stateCode: "160",
    countryId: "202",
  },
  {
    id: "3706",
    name: "Hodoš Municipality",
    stateCode: "161",
    countryId: "202",
  },
  {
    id: "3707",
    name: "Horjul Municipality",
    stateCode: "162",
    countryId: "202",
  },
  {
    id: "3708",
    name: "Hrastnik Municipality",
    stateCode: "034",
    countryId: "202",
  },
  {
    id: "3709",
    name: "Hrpelje–Kozina Municipality",
    stateCode: "035",
    countryId: "202",
  },
  {
    id: "3710",
    name: "Idrija Municipality",
    stateCode: "036",
    countryId: "202",
  },
  { id: "3711", name: "Ig Municipality", stateCode: "037", countryId: "202" },
  {
    id: "3712",
    name: "Ivančna Gorica Municipality",
    stateCode: "039",
    countryId: "202",
  },
  {
    id: "3713",
    name: "Izola Municipality",
    stateCode: "040",
    countryId: "202",
  },
  {
    id: "3714",
    name: "Jesenice Municipality",
    stateCode: "041",
    countryId: "202",
  },
  {
    id: "3715",
    name: "Jezersko Municipality",
    stateCode: "163",
    countryId: "202",
  },
  {
    id: "3716",
    name: "Juršinci Municipality",
    stateCode: "042",
    countryId: "202",
  },
  {
    id: "3717",
    name: "Kamnik Municipality",
    stateCode: "043",
    countryId: "202",
  },
  {
    id: "3718",
    name: "Kanal ob Soči Municipality",
    stateCode: "044",
    countryId: "202",
  },
  {
    id: "3719",
    name: "Kidričevo Municipality",
    stateCode: "045",
    countryId: "202",
  },
  {
    id: "3720",
    name: "Kobarid Municipality",
    stateCode: "046",
    countryId: "202",
  },
  {
    id: "3721",
    name: "Kobilje Municipality",
    stateCode: "047",
    countryId: "202",
  },
  {
    id: "3722",
    name: "Kočevje Municipality",
    stateCode: "048",
    countryId: "202",
  },
  {
    id: "3723",
    name: "Komen Municipality",
    stateCode: "049",
    countryId: "202",
  },
  {
    id: "3724",
    name: "Komenda Municipality",
    stateCode: "164",
    countryId: "202",
  },
  {
    id: "3725",
    name: "Koper City Municipality",
    stateCode: "050",
    countryId: "202",
  },
  {
    id: "3726",
    name: "Kostanjevica na Krki Municipality",
    stateCode: "197",
    countryId: "202",
  },
  {
    id: "3727",
    name: "Kostel Municipality",
    stateCode: "165",
    countryId: "202",
  },
  {
    id: "3728",
    name: "Kozje Municipality",
    stateCode: "051",
    countryId: "202",
  },
  {
    id: "3729",
    name: "Kranj City Municipality",
    stateCode: "052",
    countryId: "202",
  },
  {
    id: "3730",
    name: "Kranjska Gora Municipality",
    stateCode: "053",
    countryId: "202",
  },
  {
    id: "3731",
    name: "Križevci Municipality",
    stateCode: "166",
    countryId: "202",
  },
  { id: "3732", name: "Kungota", stateCode: "055", countryId: "202" },
  {
    id: "3733",
    name: "Kuzma Municipality",
    stateCode: "056",
    countryId: "202",
  },
  {
    id: "3734",
    name: "Laško Municipality",
    stateCode: "057",
    countryId: "202",
  },
  {
    id: "3735",
    name: "Lenart Municipality",
    stateCode: "058",
    countryId: "202",
  },
  {
    id: "3736",
    name: "Lendava Municipality",
    stateCode: "059",
    countryId: "202",
  },
  {
    id: "3737",
    name: "Litija Municipality",
    stateCode: "060",
    countryId: "202",
  },
  {
    id: "3738",
    name: "Ljubljana City Municipality",
    stateCode: "061",
    countryId: "202",
  },
  {
    id: "3739",
    name: "Ljubno Municipality",
    stateCode: "062",
    countryId: "202",
  },
  {
    id: "3740",
    name: "Ljutomer Municipality",
    stateCode: "063",
    countryId: "202",
  },
  {
    id: "3741",
    name: "Log–Dragomer Municipality",
    stateCode: "208",
    countryId: "202",
  },
  {
    id: "3742",
    name: "Logatec Municipality",
    stateCode: "064",
    countryId: "202",
  },
  {
    id: "3743",
    name: "Loška Dolina Municipality",
    stateCode: "065",
    countryId: "202",
  },
  {
    id: "3744",
    name: "Loški Potok Municipality",
    stateCode: "066",
    countryId: "202",
  },
  {
    id: "3745",
    name: "Lovrenc na Pohorju Municipality",
    stateCode: "167",
    countryId: "202",
  },
  { id: "3746", name: "Luče Municipality", stateCode: "067", countryId: "202" },
  {
    id: "3747",
    name: "Lukovica Municipality",
    stateCode: "068",
    countryId: "202",
  },
  {
    id: "3748",
    name: "Majšperk Municipality",
    stateCode: "069",
    countryId: "202",
  },
  {
    id: "3749",
    name: "Makole Municipality",
    stateCode: "198",
    countryId: "202",
  },
  {
    id: "3750",
    name: "Maribor City Municipality",
    stateCode: "070",
    countryId: "202",
  },
  {
    id: "3751",
    name: "Markovci Municipality",
    stateCode: "168",
    countryId: "202",
  },
  {
    id: "3752",
    name: "Medvode Municipality",
    stateCode: "071",
    countryId: "202",
  },
  {
    id: "3753",
    name: "Mengeš Municipality",
    stateCode: "072",
    countryId: "202",
  },
  {
    id: "3754",
    name: "Metlika Municipality",
    stateCode: "073",
    countryId: "202",
  },
  {
    id: "3755",
    name: "Mežica Municipality",
    stateCode: "074",
    countryId: "202",
  },
  {
    id: "3756",
    name: "Miklavž na Dravskem Polju Municipality",
    stateCode: "169",
    countryId: "202",
  },
  {
    id: "3757",
    name: "Miren–Kostanjevica Municipality",
    stateCode: "075",
    countryId: "202",
  },
  {
    id: "3758",
    name: "Mirna Municipality",
    stateCode: "212",
    countryId: "202",
  },
  {
    id: "3759",
    name: "Mirna Peč Municipality",
    stateCode: "170",
    countryId: "202",
  },
  {
    id: "3760",
    name: "Mislinja Municipality",
    stateCode: "076",
    countryId: "202",
  },
  {
    id: "3761",
    name: "Mokronog–Trebelno Municipality",
    stateCode: "199",
    countryId: "202",
  },
  {
    id: "3762",
    name: "Moravče Municipality",
    stateCode: "077",
    countryId: "202",
  },
  {
    id: "3763",
    name: "Moravske Toplice Municipality",
    stateCode: "078",
    countryId: "202",
  },
  {
    id: "3764",
    name: "Mozirje Municipality",
    stateCode: "079",
    countryId: "202",
  },
  {
    id: "3765",
    name: "Municipality of Apače",
    stateCode: "195",
    countryId: "202",
  },
  {
    id: "3766",
    name: "Municipality of Cirkulane",
    stateCode: "196",
    countryId: "202",
  },
  {
    id: "3767",
    name: "Municipality of Ilirska Bistrica",
    stateCode: "038",
    countryId: "202",
  },
  {
    id: "3768",
    name: "Municipality of Krško",
    stateCode: "054",
    countryId: "202",
  },
  {
    id: "3769",
    name: "Municipality of Škofljica",
    stateCode: "123",
    countryId: "202",
  },
  {
    id: "3770",
    name: "Murska Sobota City Municipality",
    stateCode: "080",
    countryId: "202",
  },
  { id: "3771", name: "Muta Municipality", stateCode: "081", countryId: "202" },
  {
    id: "3772",
    name: "Naklo Municipality",
    stateCode: "082",
    countryId: "202",
  },
  {
    id: "3773",
    name: "Nazarje Municipality",
    stateCode: "083",
    countryId: "202",
  },
  {
    id: "3774",
    name: "Nova Gorica City Municipality",
    stateCode: "084",
    countryId: "202",
  },
  {
    id: "3775",
    name: "Odranci Municipality",
    stateCode: "086",
    countryId: "202",
  },
  { id: "3776", name: "Oplotnica", stateCode: "171", countryId: "202" },
  {
    id: "3777",
    name: "Ormož Municipality",
    stateCode: "087",
    countryId: "202",
  },
  {
    id: "3778",
    name: "Osilnica Municipality",
    stateCode: "088",
    countryId: "202",
  },
  {
    id: "3779",
    name: "Pesnica Municipality",
    stateCode: "089",
    countryId: "202",
  },
  {
    id: "3780",
    name: "Piran Municipality",
    stateCode: "090",
    countryId: "202",
  },
  {
    id: "3781",
    name: "Pivka Municipality",
    stateCode: "091",
    countryId: "202",
  },
  {
    id: "3782",
    name: "Podčetrtek Municipality",
    stateCode: "092",
    countryId: "202",
  },
  {
    id: "3783",
    name: "Podlehnik Municipality",
    stateCode: "172",
    countryId: "202",
  },
  {
    id: "3784",
    name: "Podvelka Municipality",
    stateCode: "093",
    countryId: "202",
  },
  {
    id: "3785",
    name: "Poljčane Municipality",
    stateCode: "200",
    countryId: "202",
  },
  {
    id: "3786",
    name: "Polzela Municipality",
    stateCode: "173",
    countryId: "202",
  },
  {
    id: "3787",
    name: "Postojna Municipality",
    stateCode: "094",
    countryId: "202",
  },
  {
    id: "3788",
    name: "Prebold Municipality",
    stateCode: "174",
    countryId: "202",
  },
  {
    id: "3789",
    name: "Preddvor Municipality",
    stateCode: "095",
    countryId: "202",
  },
  {
    id: "3790",
    name: "Prevalje Municipality",
    stateCode: "175",
    countryId: "202",
  },
  {
    id: "3791",
    name: "Ptuj City Municipality",
    stateCode: "096",
    countryId: "202",
  },
  {
    id: "3792",
    name: "Puconci Municipality",
    stateCode: "097",
    countryId: "202",
  },
  {
    id: "3793",
    name: "Rače–Fram Municipality",
    stateCode: "098",
    countryId: "202",
  },
  {
    id: "3794",
    name: "Radeče Municipality",
    stateCode: "099",
    countryId: "202",
  },
  {
    id: "3795",
    name: "Radenci Municipality",
    stateCode: "100",
    countryId: "202",
  },
  {
    id: "3796",
    name: "Radlje ob Dravi Municipality",
    stateCode: "101",
    countryId: "202",
  },
  {
    id: "3797",
    name: "Radovljica Municipality",
    stateCode: "102",
    countryId: "202",
  },
  {
    id: "3798",
    name: "Ravne na Koroškem Municipality",
    stateCode: "103",
    countryId: "202",
  },
  {
    id: "3799",
    name: "Razkrižje Municipality",
    stateCode: "176",
    countryId: "202",
  },
  {
    id: "3800",
    name: "Rečica ob Savinji Municipality",
    stateCode: "209",
    countryId: "202",
  },
  {
    id: "3801",
    name: "Renče–Vogrsko Municipality",
    stateCode: "201",
    countryId: "202",
  },
  {
    id: "3802",
    name: "Ribnica Municipality",
    stateCode: "104",
    countryId: "202",
  },
  {
    id: "3803",
    name: "Ribnica na Pohorju Municipality",
    stateCode: "177",
    countryId: "202",
  },
  {
    id: "3804",
    name: "Rogaška Slatina Municipality",
    stateCode: "106",
    countryId: "202",
  },
  {
    id: "3805",
    name: "Rogašovci Municipality",
    stateCode: "105",
    countryId: "202",
  },
  {
    id: "3806",
    name: "Rogatec Municipality",
    stateCode: "107",
    countryId: "202",
  },
  { id: "3807", name: "Ruše Municipality", stateCode: "108", countryId: "202" },
  {
    id: "3808",
    name: "Šalovci Municipality",
    stateCode: "033",
    countryId: "202",
  },
  {
    id: "3809",
    name: "Selnica ob Dravi Municipality",
    stateCode: "178",
    countryId: "202",
  },
  {
    id: "3810",
    name: "Semič Municipality",
    stateCode: "109",
    countryId: "202",
  },
  {
    id: "3811",
    name: "Šempeter–Vrtojba Municipality",
    stateCode: "183",
    countryId: "202",
  },
  {
    id: "3812",
    name: "Šenčur Municipality",
    stateCode: "117",
    countryId: "202",
  },
  {
    id: "3813",
    name: "Šentilj Municipality",
    stateCode: "118",
    countryId: "202",
  },
  {
    id: "3814",
    name: "Šentjernej Municipality",
    stateCode: "119",
    countryId: "202",
  },
  {
    id: "3815",
    name: "Šentjur Municipality",
    stateCode: "120",
    countryId: "202",
  },
  {
    id: "3816",
    name: "Šentrupert Municipality",
    stateCode: "211",
    countryId: "202",
  },
  {
    id: "3817",
    name: "Sevnica Municipality",
    stateCode: "110",
    countryId: "202",
  },
  {
    id: "3818",
    name: "Sežana Municipality",
    stateCode: "111",
    countryId: "202",
  },
  {
    id: "3819",
    name: "Škocjan Municipality",
    stateCode: "121",
    countryId: "202",
  },
  {
    id: "3820",
    name: "Škofja Loka Municipality",
    stateCode: "122",
    countryId: "202",
  },
  {
    id: "3821",
    name: "Slovenj Gradec City Municipality",
    stateCode: "112",
    countryId: "202",
  },
  {
    id: "3822",
    name: "Slovenska Bistrica Municipality",
    stateCode: "113",
    countryId: "202",
  },
  {
    id: "3823",
    name: "Slovenske Konjice Municipality",
    stateCode: "114",
    countryId: "202",
  },
  {
    id: "3824",
    name: "Šmarje pri Jelšah Municipality",
    stateCode: "124",
    countryId: "202",
  },
  {
    id: "3825",
    name: "Šmarješke Toplice Municipality",
    stateCode: "206",
    countryId: "202",
  },
  {
    id: "3826",
    name: "Šmartno ob Paki Municipality",
    stateCode: "125",
    countryId: "202",
  },
  {
    id: "3827",
    name: "Šmartno pri Litiji Municipality",
    stateCode: "194",
    countryId: "202",
  },
  {
    id: "3828",
    name: "Sodražica Municipality",
    stateCode: "179",
    countryId: "202",
  },
  {
    id: "3829",
    name: "Solčava Municipality",
    stateCode: "180",
    countryId: "202",
  },
  {
    id: "3830",
    name: "Šoštanj Municipality",
    stateCode: "126",
    countryId: "202",
  },
  { id: "3831", name: "Središče ob Dravi", stateCode: "202", countryId: "202" },
  {
    id: "3832",
    name: "Starše Municipality",
    stateCode: "115",
    countryId: "202",
  },
  {
    id: "3833",
    name: "Štore Municipality",
    stateCode: "127",
    countryId: "202",
  },
  {
    id: "3834",
    name: "Straža Municipality",
    stateCode: "203",
    countryId: "202",
  },
  {
    id: "3835",
    name: "Sveta Ana Municipality",
    stateCode: "181",
    countryId: "202",
  },
  {
    id: "3836",
    name: "Sveta Trojica v Slovenskih Goricah Municipality",
    stateCode: "204",
    countryId: "202",
  },
  {
    id: "3837",
    name: "Sveti Andraž v Slovenskih Goricah Municipality",
    stateCode: "182",
    countryId: "202",
  },
  {
    id: "3838",
    name: "Sveti Jurij ob Ščavnici Municipality",
    stateCode: "116",
    countryId: "202",
  },
  {
    id: "3839",
    name: "Sveti Jurij v Slovenskih Goricah Municipality",
    stateCode: "210",
    countryId: "202",
  },
  {
    id: "3840",
    name: "Sveti Tomaž Municipality",
    stateCode: "205",
    countryId: "202",
  },
  {
    id: "3841",
    name: "Tabor Municipality",
    stateCode: "184",
    countryId: "202",
  },
  {
    id: "3842",
    name: "Tišina Municipality",
    stateCode: "010",
    countryId: "202",
  },
  {
    id: "3843",
    name: "Tolmin Municipality",
    stateCode: "128",
    countryId: "202",
  },
  {
    id: "3844",
    name: "Trbovlje Municipality",
    stateCode: "129",
    countryId: "202",
  },
  {
    id: "3845",
    name: "Trebnje Municipality",
    stateCode: "130",
    countryId: "202",
  },
  {
    id: "3846",
    name: "Trnovska Vas Municipality",
    stateCode: "185",
    countryId: "202",
  },
  {
    id: "3847",
    name: "Tržič Municipality",
    stateCode: "131",
    countryId: "202",
  },
  {
    id: "3848",
    name: "Trzin Municipality",
    stateCode: "186",
    countryId: "202",
  },
  {
    id: "3849",
    name: "Turnišče Municipality",
    stateCode: "132",
    countryId: "202",
  },
  {
    id: "3850",
    name: "Velika Polana Municipality",
    stateCode: "187",
    countryId: "202",
  },
  {
    id: "3851",
    name: "Velike Lašče Municipality",
    stateCode: "134",
    countryId: "202",
  },
  {
    id: "3852",
    name: "Veržej Municipality",
    stateCode: "188",
    countryId: "202",
  },
  {
    id: "3853",
    name: "Videm Municipality",
    stateCode: "135",
    countryId: "202",
  },
  {
    id: "3854",
    name: "Vipava Municipality",
    stateCode: "136",
    countryId: "202",
  },
  {
    id: "3855",
    name: "Vitanje Municipality",
    stateCode: "137",
    countryId: "202",
  },
  {
    id: "3856",
    name: "Vodice Municipality",
    stateCode: "138",
    countryId: "202",
  },
  {
    id: "3857",
    name: "Vojnik Municipality",
    stateCode: "139",
    countryId: "202",
  },
  {
    id: "3858",
    name: "Vransko Municipality",
    stateCode: "189",
    countryId: "202",
  },
  {
    id: "3859",
    name: "Vrhnika Municipality",
    stateCode: "140",
    countryId: "202",
  },
  {
    id: "3860",
    name: "Vuzenica Municipality",
    stateCode: "141",
    countryId: "202",
  },
  {
    id: "3861",
    name: "Zagorje ob Savi Municipality",
    stateCode: "142",
    countryId: "202",
  },
  {
    id: "3862",
    name: "Žalec Municipality",
    stateCode: "190",
    countryId: "202",
  },
  {
    id: "3863",
    name: "Zavrč Municipality",
    stateCode: "143",
    countryId: "202",
  },
  {
    id: "3864",
    name: "Železniki Municipality",
    stateCode: "146",
    countryId: "202",
  },
  {
    id: "3865",
    name: "Žetale Municipality",
    stateCode: "191",
    countryId: "202",
  },
  { id: "3866", name: "Žiri Municipality", stateCode: "147", countryId: "202" },
  {
    id: "3867",
    name: "Žirovnica Municipality",
    stateCode: "192",
    countryId: "202",
  },
  {
    id: "3868",
    name: "Zreče Municipality",
    stateCode: "144",
    countryId: "202",
  },
  {
    id: "3869",
    name: "Žužemberk Municipality",
    stateCode: "193",
    countryId: "202",
  },
  { id: "3870", name: "Central Province", stateCode: "CE", countryId: "203" },
  { id: "3871", name: "Choiseul Province", stateCode: "CH", countryId: "203" },
  {
    id: "3872",
    name: "Guadalcanal Province",
    stateCode: "GU",
    countryId: "203",
  },
  { id: "3873", name: "Honiara", stateCode: "CT", countryId: "203" },
  { id: "3874", name: "Isabel Province", stateCode: "IS", countryId: "203" },
  {
    id: "3875",
    name: "Makira-Ulawa Province",
    stateCode: "MK",
    countryId: "203",
  },
  { id: "3876", name: "Malaita Province", stateCode: "ML", countryId: "203" },
  {
    id: "3877",
    name: "Rennell and Bellona Province",
    stateCode: "RB",
    countryId: "203",
  },
  { id: "3878", name: "Temotu Province", stateCode: "TE", countryId: "203" },
  { id: "3879", name: "Western Province", stateCode: "WE", countryId: "203" },
  { id: "3880", name: "Awdal Region", stateCode: "AW", countryId: "204" },
  { id: "3881", name: "Bakool", stateCode: "BK", countryId: "204" },
  { id: "3882", name: "Banaadir", stateCode: "BN", countryId: "204" },
  { id: "3883", name: "Bari", stateCode: "BR", countryId: "204" },
  { id: "3884", name: "Bay", stateCode: "BY", countryId: "204" },
  { id: "3885", name: "Galguduud", stateCode: "GA", countryId: "204" },
  { id: "3886", name: "Gedo", stateCode: "GE", countryId: "204" },
  { id: "3887", name: "Hiran", stateCode: "HI", countryId: "204" },
  { id: "3888", name: "Lower Juba", stateCode: "JH", countryId: "204" },
  { id: "3889", name: "Lower Shebelle", stateCode: "SH", countryId: "204" },
  { id: "3890", name: "Middle Juba", stateCode: "JD", countryId: "204" },
  { id: "3891", name: "Middle Shebelle", stateCode: "SD", countryId: "204" },
  { id: "3892", name: "Mudug", stateCode: "MU", countryId: "204" },
  { id: "3893", name: "Nugal", stateCode: "NU", countryId: "204" },
  { id: "3894", name: "Sanaag Region", stateCode: "SA", countryId: "204" },
  { id: "3895", name: "Togdheer Region", stateCode: "TO", countryId: "204" },
  { id: "3896", name: "Eastern Cape", stateCode: "EC", countryId: "205" },
  { id: "3897", name: "Free State", stateCode: "FS", countryId: "205" },
  { id: "3898", name: "Gauteng", stateCode: "GP", countryId: "205" },
  { id: "3899", name: "KwaZulu-Natal", stateCode: "KZN", countryId: "205" },
  { id: "3900", name: "Limpopo", stateCode: "LP", countryId: "205" },
  { id: "3901", name: "Mpumalanga", stateCode: "MP", countryId: "205" },
  { id: "3902", name: "North West", stateCode: "NW", countryId: "205" },
  { id: "3903", name: "Northern Cape", stateCode: "NC", countryId: "205" },
  { id: "3904", name: "Western Cape", stateCode: "WC", countryId: "205" },
  { id: "3905", name: "Busan", stateCode: "26", countryId: "207" },
  { id: "3906", name: "Daegu", stateCode: "27", countryId: "207" },
  { id: "3907", name: "Daejeon", stateCode: "30", countryId: "207" },
  { id: "3908", name: "Gangwon Province", stateCode: "42", countryId: "207" },
  { id: "3909", name: "Gwangju", stateCode: "29", countryId: "207" },
  { id: "3910", name: "Gyeonggi Province", stateCode: "41", countryId: "207" },
  { id: "3911", name: "Incheon", stateCode: "28", countryId: "207" },
  { id: "3912", name: "Jeju", stateCode: "49", countryId: "207" },
  {
    id: "3913",
    name: "North Chungcheong Province",
    stateCode: "43",
    countryId: "207",
  },
  {
    id: "3914",
    name: "North Gyeongsang Province",
    stateCode: "47",
    countryId: "207",
  },
  {
    id: "3915",
    name: "North Jeolla Province",
    stateCode: "45",
    countryId: "207",
  },
  { id: "3916", name: "Sejong City", stateCode: "50", countryId: "207" },
  { id: "3917", name: "Seoul", stateCode: "11", countryId: "207" },
  {
    id: "3918",
    name: "South Chungcheong Province",
    stateCode: "44",
    countryId: "207",
  },
  {
    id: "3919",
    name: "South Gyeongsang Province",
    stateCode: "48",
    countryId: "207",
  },
  {
    id: "3920",
    name: "South Jeolla Province",
    stateCode: "46",
    countryId: "207",
  },
  { id: "3921", name: "Ulsan", stateCode: "31", countryId: "207" },
  { id: "3922", name: "Central Equatoria", stateCode: "EC", countryId: "208" },
  { id: "3923", name: "Eastern Equatoria", stateCode: "EE", countryId: "208" },
  { id: "3924", name: "Jonglei State", stateCode: "JG", countryId: "208" },
  { id: "3925", name: "Lakes", stateCode: "LK", countryId: "208" },
  {
    id: "3926",
    name: "Northern Bahr el Ghazal",
    stateCode: "BN",
    countryId: "208",
  },
  { id: "3927", name: "Unity", stateCode: "UY", countryId: "208" },
  { id: "3928", name: "Upper Nile", stateCode: "NU", countryId: "208" },
  { id: "3929", name: "Warrap", stateCode: "WR", countryId: "208" },
  {
    id: "3930",
    name: "Western Bahr el Ghazal",
    stateCode: "BW",
    countryId: "208",
  },
  { id: "3931", name: "Western Equatoria", stateCode: "EW", countryId: "208" },
  { id: "3932", name: "A Coruña", stateCode: "C", countryId: "209" },
  { id: "3933", name: "Albacete", stateCode: "AB", countryId: "209" },
  { id: "3934", name: "Alicante", stateCode: "A", countryId: "209" },
  { id: "3935", name: "Almeria", stateCode: "AL", countryId: "209" },
  { id: "3936", name: "Araba", stateCode: "VI", countryId: "209" },
  { id: "3937", name: "Asturias", stateCode: "O", countryId: "209" },
  { id: "3938", name: "Ávila", stateCode: "AV", countryId: "209" },
  { id: "3939", name: "Badajoz", stateCode: "BA", countryId: "209" },
  { id: "3940", name: "Barcelona", stateCode: "B", countryId: "209" },
  { id: "3941", name: "Bizkaia", stateCode: "BI", countryId: "209" },
  { id: "3942", name: "Burgos", stateCode: "BU", countryId: "209" },
  { id: "3943", name: "Caceres", stateCode: "CC", countryId: "209" },
  { id: "3944", name: "Cádiz", stateCode: "CA", countryId: "209" },
  { id: "3945", name: "Canarias", stateCode: "CN", countryId: "209" },
  { id: "3946", name: "Cantabria", stateCode: "S", countryId: "209" },
  { id: "3947", name: "Castellón", stateCode: "CS", countryId: "209" },
  { id: "3948", name: "Ceuta", stateCode: "CE", countryId: "209" },
  { id: "3949", name: "Ciudad Real", stateCode: "CR", countryId: "209" },
  { id: "3950", name: "Córdoba", stateCode: "CO", countryId: "209" },
  { id: "3951", name: "Cuenca", stateCode: "CU", countryId: "209" },
  { id: "3952", name: "Gipuzkoa", stateCode: "SS", countryId: "209" },
  { id: "3953", name: "Girona", stateCode: "GI", countryId: "209" },
  { id: "3954", name: "Granada", stateCode: "GR", countryId: "209" },
  { id: "3955", name: "Guadalajara", stateCode: "GU", countryId: "209" },
  { id: "3956", name: "Huelva", stateCode: "H", countryId: "209" },
  { id: "3957", name: "Huesca", stateCode: "HU", countryId: "209" },
  { id: "3958", name: "Islas Baleares", stateCode: "PM", countryId: "209" },
  { id: "3959", name: "Jaén", stateCode: "J", countryId: "209" },
  { id: "3960", name: "La Rioja", stateCode: "LO", countryId: "209" },
  { id: "3961", name: "Las Palmas", stateCode: "GC", countryId: "209" },
  { id: "3962", name: "Léon", stateCode: "LE", countryId: "209" },
  { id: "3963", name: "Lleida", stateCode: "L", countryId: "209" },
  { id: "3964", name: "Lugo", stateCode: "LU", countryId: "209" },
  { id: "3965", name: "Madrid", stateCode: "M", countryId: "209" },
  { id: "3966", name: "Málaga", stateCode: "MA", countryId: "209" },
  { id: "3967", name: "Melilla", stateCode: "ML", countryId: "209" },
  { id: "3968", name: "Murcia", stateCode: "MU", countryId: "209" },
  { id: "3969", name: "Navarra", stateCode: "NA", countryId: "209" },
  { id: "3970", name: "Ourense", stateCode: "OR", countryId: "209" },
  { id: "3971", name: "Palencia", stateCode: "P", countryId: "209" },
  { id: "3972", name: "Pontevedra", stateCode: "PO", countryId: "209" },
  { id: "3973", name: "Salamanca", stateCode: "SA", countryId: "209" },
  {
    id: "3974",
    name: "Santa Cruz de Tenerife",
    stateCode: "TF",
    countryId: "209",
  },
  { id: "3975", name: "Segovia", stateCode: "SG", countryId: "209" },
  { id: "3976", name: "Sevilla", stateCode: "SE", countryId: "209" },
  { id: "3977", name: "Soria", stateCode: "SO", countryId: "209" },
  { id: "3978", name: "Tarragona", stateCode: "T", countryId: "209" },
  { id: "3979", name: "Teruel", stateCode: "TE", countryId: "209" },
  { id: "3980", name: "Toledo", stateCode: "TO", countryId: "209" },
  { id: "3981", name: "Valencia", stateCode: "V", countryId: "209" },
  { id: "3982", name: "Valladolid", stateCode: "VA", countryId: "209" },
  { id: "3983", name: "Zamora", stateCode: "ZA", countryId: "209" },
  { id: "3984", name: "Zaragoza", stateCode: "Z", countryId: "209" },
  { id: "3985", name: "Ampara District", stateCode: "52", countryId: "210" },
  {
    id: "3986",
    name: "Anuradhapura District",
    stateCode: "71",
    countryId: "210",
  },
  { id: "3987", name: "Badulla District", stateCode: "81", countryId: "210" },
  {
    id: "3988",
    name: "Batticaloa District",
    stateCode: "51",
    countryId: "210",
  },
  { id: "3989", name: "Central Province", stateCode: "2", countryId: "210" },
  { id: "3990", name: "Colombo District", stateCode: "11", countryId: "210" },
  { id: "3991", name: "Eastern Province", stateCode: "5", countryId: "210" },
  { id: "3992", name: "Galle District", stateCode: "31", countryId: "210" },
  { id: "3993", name: "Gampaha District", stateCode: "12", countryId: "210" },
  {
    id: "3994",
    name: "Hambantota District",
    stateCode: "33",
    countryId: "210",
  },
  { id: "3995", name: "Jaffna District", stateCode: "41", countryId: "210" },
  { id: "3996", name: "Kalutara District", stateCode: "13", countryId: "210" },
  { id: "3997", name: "Kandy District", stateCode: "21", countryId: "210" },
  { id: "3998", name: "Kegalle District", stateCode: "92", countryId: "210" },
  {
    id: "3999",
    name: "Kilinochchi District",
    stateCode: "42",
    countryId: "210",
  },
  { id: "4000", name: "Mannar District", stateCode: "43", countryId: "210" },
  { id: "4001", name: "Matale District", stateCode: "22", countryId: "210" },
  { id: "4002", name: "Matara District", stateCode: "32", countryId: "210" },
  {
    id: "4003",
    name: "Monaragala District",
    stateCode: "82",
    countryId: "210",
  },
  {
    id: "4004",
    name: "Mullaitivu District",
    stateCode: "45",
    countryId: "210",
  },
  {
    id: "4005",
    name: "North Central Province",
    stateCode: "7",
    countryId: "210",
  },
  {
    id: "4006",
    name: "North Western Province",
    stateCode: "6",
    countryId: "210",
  },
  { id: "4007", name: "Northern Province", stateCode: "4", countryId: "210" },
  {
    id: "4008",
    name: "Nuwara Eliya District",
    stateCode: "23",
    countryId: "210",
  },
  {
    id: "4009",
    name: "Polonnaruwa District",
    stateCode: "72",
    countryId: "210",
  },
  { id: "4010", name: "Puttalam District", stateCode: "62", countryId: "210" },
  { id: "4011", name: "Ratnapura district", stateCode: "91", countryId: "210" },
  {
    id: "4012",
    name: "Sabaragamuwa Province",
    stateCode: "9",
    countryId: "210",
  },
  { id: "4013", name: "Southern Province", stateCode: "3", countryId: "210" },
  {
    id: "4014",
    name: "Trincomalee District",
    stateCode: "53",
    countryId: "210",
  },
  { id: "4015", name: "Uva Province", stateCode: "8", countryId: "210" },
  { id: "4016", name: "Vavuniya District", stateCode: "44", countryId: "210" },
  { id: "4017", name: "Western Province", stateCode: "1", countryId: "210" },
  { id: "4018", name: "Al Jazirah", stateCode: "GZ", countryId: "211" },
  { id: "4019", name: "Al Qadarif", stateCode: "GD", countryId: "211" },
  { id: "4020", name: "Blue Nile", stateCode: "NB", countryId: "211" },
  { id: "4021", name: "Central Darfur", stateCode: "DC", countryId: "211" },
  { id: "4022", name: "East Darfur", stateCode: "DE", countryId: "211" },
  { id: "4023", name: "Kassala", stateCode: "KA", countryId: "211" },
  { id: "4024", name: "Khartoum", stateCode: "KH", countryId: "211" },
  { id: "4025", name: "North Darfur", stateCode: "DN", countryId: "211" },
  { id: "4026", name: "North Kordofan", stateCode: "KN", countryId: "211" },
  { id: "4027", name: "Northern", stateCode: "NO", countryId: "211" },
  { id: "4028", name: "Red Sea", stateCode: "RS", countryId: "211" },
  { id: "4029", name: "River Nile", stateCode: "NR", countryId: "211" },
  { id: "4030", name: "Sennar", stateCode: "SI", countryId: "211" },
  { id: "4031", name: "South Darfur", stateCode: "DS", countryId: "211" },
  { id: "4032", name: "South Kordofan", stateCode: "KS", countryId: "211" },
  { id: "4033", name: "West Darfur", stateCode: "DW", countryId: "211" },
  { id: "4034", name: "West Kordofan", stateCode: "GK", countryId: "211" },
  { id: "4035", name: "White Nile", stateCode: "NW", countryId: "211" },
  {
    id: "4036",
    name: "Brokopondo District",
    stateCode: "BR",
    countryId: "212",
  },
  {
    id: "4037",
    name: "Commewijne District",
    stateCode: "CM",
    countryId: "212",
  },
  { id: "4038", name: "Coronie District", stateCode: "CR", countryId: "212" },
  { id: "4039", name: "Marowijne District", stateCode: "MA", countryId: "212" },
  { id: "4040", name: "Nickerie District", stateCode: "NI", countryId: "212" },
  { id: "4041", name: "Para District", stateCode: "PR", countryId: "212" },
  {
    id: "4042",
    name: "Paramaribo District",
    stateCode: "PM",
    countryId: "212",
  },
  { id: "4043", name: "Saramacca District", stateCode: "SA", countryId: "212" },
  {
    id: "4044",
    name: "Sipaliwini District",
    stateCode: "SI",
    countryId: "212",
  },
  { id: "4045", name: "Wanica District", stateCode: "WA", countryId: "212" },
  { id: "4046", name: "Hhohho District", stateCode: "HH", countryId: "214" },
  { id: "4047", name: "Lubombo District", stateCode: "LU", countryId: "214" },
  { id: "4048", name: "Manzini District", stateCode: "MA", countryId: "214" },
  {
    id: "4049",
    name: "Shiselweni District",
    stateCode: "SH",
    countryId: "214",
  },
  { id: "4050", name: "Blekinge County", stateCode: "K", countryId: "215" },
  { id: "4051", name: "Dalarna County", stateCode: "W", countryId: "215" },
  { id: "4052", name: "Gävleborg County", stateCode: "X", countryId: "215" },
  { id: "4053", name: "Gotland County", stateCode: "I", countryId: "215" },
  { id: "4054", name: "Halland County", stateCode: "N", countryId: "215" },
  { id: "4055", name: "Jämtland County", stateCode: "0", countryId: "215" },
  { id: "4056", name: "Jönköping County", stateCode: "F", countryId: "215" },
  { id: "4057", name: "Kalmar County", stateCode: "H", countryId: "215" },
  { id: "4058", name: "Kronoberg County", stateCode: "G", countryId: "215" },
  { id: "4059", name: "Norrbotten County", stateCode: "BD", countryId: "215" },
  { id: "4060", name: "Örebro County", stateCode: "T", countryId: "215" },
  { id: "4061", name: "Östergötland County", stateCode: "E", countryId: "215" },
  { id: "4062", name: "Skåne County", stateCode: "M", countryId: "215" },
  { id: "4063", name: "Södermanland County", stateCode: "D", countryId: "215" },
  { id: "4064", name: "Stockholm County", stateCode: "AB", countryId: "215" },
  { id: "4065", name: "Uppsala County", stateCode: "C", countryId: "215" },
  { id: "4066", name: "Värmland County", stateCode: "S", countryId: "215" },
  {
    id: "4067",
    name: "Västerbotten County",
    stateCode: "AC",
    countryId: "215",
  },
  {
    id: "4068",
    name: "Västernorrland County",
    stateCode: "Y",
    countryId: "215",
  },
  { id: "4069", name: "Västmanland County", stateCode: "U", countryId: "215" },
  {
    id: "4070",
    name: "Västra Götaland County",
    stateCode: "O",
    countryId: "215",
  },
  { id: "4071", name: "Aargau", stateCode: "AG", countryId: "216" },
  {
    id: "4072",
    name: "Appenzell Ausserrhoden",
    stateCode: "AR",
    countryId: "216",
  },
  {
    id: "4073",
    name: "Appenzell Innerrhoden",
    stateCode: "AI",
    countryId: "216",
  },
  { id: "4074", name: "Basel-Land", stateCode: "BL", countryId: "216" },
  { id: "4075", name: "Basel-Stadt", stateCode: "BS", countryId: "216" },
  { id: "4076", name: "Bern", stateCode: "BE", countryId: "216" },
  { id: "4077", name: "Fribourg", stateCode: "FR", countryId: "216" },
  { id: "4078", name: "Geneva", stateCode: "GE", countryId: "216" },
  { id: "4079", name: "Glarus", stateCode: "GL", countryId: "216" },
  { id: "4080", name: "Graubünden", stateCode: "GR", countryId: "216" },
  { id: "4081", name: "Jura", stateCode: "JU", countryId: "216" },
  { id: "4082", name: "Lucerne", stateCode: "LU", countryId: "216" },
  { id: "4083", name: "Neuchâtel", stateCode: "NE", countryId: "216" },
  { id: "4084", name: "Nidwalden", stateCode: "NW", countryId: "216" },
  { id: "4085", name: "Obwalden", stateCode: "OW", countryId: "216" },
  { id: "4086", name: "Schaffhausen", stateCode: "SH", countryId: "216" },
  { id: "4087", name: "Schwyz", stateCode: "SZ", countryId: "216" },
  { id: "4088", name: "Solothurn", stateCode: "SO", countryId: "216" },
  { id: "4089", name: "St. Gallen", stateCode: "SG", countryId: "216" },
  { id: "4090", name: "Thurgau", stateCode: "TG", countryId: "216" },
  { id: "4091", name: "Ticino", stateCode: "TI", countryId: "216" },
  { id: "4092", name: "Uri", stateCode: "UR", countryId: "216" },
  { id: "4093", name: "Valais", stateCode: "VS", countryId: "216" },
  { id: "4094", name: "Vaud", stateCode: "VD", countryId: "216" },
  { id: "4095", name: "Zug", stateCode: "ZG", countryId: "216" },
  { id: "4096", name: "Zürich", stateCode: "ZH", countryId: "216" },
  { id: "4097", name: "Al-Hasakah", stateCode: "HA", countryId: "217" },
  { id: "4098", name: "Al-Raqqah", stateCode: "RA", countryId: "217" },
  { id: "4099", name: "Aleppo", stateCode: "HL", countryId: "217" },
  { id: "4100", name: "As-Suwayda", stateCode: "SU", countryId: "217" },
  { id: "4101", name: "Damascus", stateCode: "DI", countryId: "217" },
  { id: "4102", name: "Daraa", stateCode: "DR", countryId: "217" },
  { id: "4103", name: "Deir ez-Zor", stateCode: "DY", countryId: "217" },
  { id: "4104", name: "Hama", stateCode: "HM", countryId: "217" },
  { id: "4105", name: "Homs", stateCode: "HI", countryId: "217" },
  { id: "4106", name: "Idlib", stateCode: "ID", countryId: "217" },
  { id: "4107", name: "Latakia", stateCode: "LA", countryId: "217" },
  { id: "4108", name: "Quneitra", stateCode: "QU", countryId: "217" },
  { id: "4109", name: "Rif Dimashq", stateCode: "RD", countryId: "217" },
  { id: "4110", name: "Tartus", stateCode: "TA", countryId: "217" },
  { id: "4111", name: "Changhua", stateCode: "CHA", countryId: "218" },
  { id: "4112", name: "Chiayi", stateCode: "CYI", countryId: "218" },
  { id: "4113", name: "Chiayi", stateCode: "CYQ", countryId: "218" },
  { id: "4114", name: "Hsinchu", stateCode: "HSQ", countryId: "218" },
  { id: "4115", name: "Hsinchu", stateCode: "HSZ", countryId: "218" },
  { id: "4116", name: "Hualien", stateCode: "HUA", countryId: "218" },
  { id: "4117", name: "Kaohsiung", stateCode: "KHH", countryId: "218" },
  { id: "4118", name: "Keelung", stateCode: "KEE", countryId: "218" },
  { id: "4119", name: "Kinmen", stateCode: "KIN", countryId: "218" },
  { id: "4120", name: "Lienchiang", stateCode: "LIE", countryId: "218" },
  { id: "4121", name: "Miaoli", stateCode: "MIA", countryId: "218" },
  { id: "4122", name: "Nantou", stateCode: "NAN", countryId: "218" },
  { id: "4123", name: "New Taipei", stateCode: "NWT", countryId: "218" },
  { id: "4124", name: "Penghu", stateCode: "PEN", countryId: "218" },
  { id: "4125", name: "Pingtung", stateCode: "PIF", countryId: "218" },
  { id: "4126", name: "Taichung", stateCode: "TXG", countryId: "218" },
  { id: "4127", name: "Tainan", stateCode: "TNN", countryId: "218" },
  { id: "4128", name: "Taipei", stateCode: "TPE", countryId: "218" },
  { id: "4129", name: "Taitung", stateCode: "TTT", countryId: "218" },
  { id: "4130", name: "Taoyuan", stateCode: "TAO", countryId: "218" },
  { id: "4131", name: "Yilan", stateCode: "ILA", countryId: "218" },
  { id: "4132", name: "Yunlin", stateCode: "YUN", countryId: "218" },
  {
    id: "4133",
    name: "districts of Republican Subordination",
    stateCode: "RA",
    countryId: "219",
  },
  {
    id: "4134",
    name: "Gorno-Badakhshan Autonomous Province",
    stateCode: "GB",
    countryId: "219",
  },
  { id: "4135", name: "Khatlon Province", stateCode: "KT", countryId: "219" },
  { id: "4136", name: "Sughd Province", stateCode: "SU", countryId: "219" },
  { id: "4137", name: "Arusha", stateCode: "01", countryId: "220" },
  { id: "4138", name: "Dar es Salaam", stateCode: "02", countryId: "220" },
  { id: "4139", name: "Dodoma", stateCode: "03", countryId: "220" },
  { id: "4140", name: "Geita", stateCode: "27", countryId: "220" },
  { id: "4141", name: "Iringa", stateCode: "04", countryId: "220" },
  { id: "4142", name: "Kagera", stateCode: "05", countryId: "220" },
  { id: "4143", name: "Katavi", stateCode: "28", countryId: "220" },
  { id: "4144", name: "Kigoma", stateCode: "08", countryId: "220" },
  { id: "4145", name: "Kilimanjaro", stateCode: "09", countryId: "220" },
  { id: "4146", name: "Lindi", stateCode: "12", countryId: "220" },
  { id: "4147", name: "Manyara", stateCode: "26", countryId: "220" },
  { id: "4148", name: "Mara", stateCode: "13", countryId: "220" },
  { id: "4149", name: "Mbeya", stateCode: "14", countryId: "220" },
  { id: "4150", name: "Morogoro", stateCode: "16", countryId: "220" },
  { id: "4151", name: "Mtwara", stateCode: "17", countryId: "220" },
  { id: "4152", name: "Mwanza", stateCode: "18", countryId: "220" },
  { id: "4153", name: "Njombe", stateCode: "29", countryId: "220" },
  { id: "4154", name: "Pemba North", stateCode: "06", countryId: "220" },
  { id: "4155", name: "Pemba South", stateCode: "10", countryId: "220" },
  { id: "4156", name: "Pwani", stateCode: "19", countryId: "220" },
  { id: "4157", name: "Rukwa", stateCode: "20", countryId: "220" },
  { id: "4158", name: "Ruvuma", stateCode: "21", countryId: "220" },
  { id: "4159", name: "Shinyanga", stateCode: "22", countryId: "220" },
  { id: "4160", name: "Simiyu", stateCode: "30", countryId: "220" },
  { id: "4161", name: "Singida", stateCode: "23", countryId: "220" },
  { id: "4162", name: "Songwe", stateCode: "31", countryId: "220" },
  { id: "4163", name: "Tabora", stateCode: "24", countryId: "220" },
  { id: "4164", name: "Tanga", stateCode: "25", countryId: "220" },
  { id: "4165", name: "Zanzibar North", stateCode: "07", countryId: "220" },
  { id: "4166", name: "Zanzibar South", stateCode: "11", countryId: "220" },
  { id: "4167", name: "Zanzibar West", stateCode: "15", countryId: "220" },
  { id: "4168", name: "Amnat Charoen", stateCode: "37", countryId: "221" },
  { id: "4169", name: "Ang Thong", stateCode: "15", countryId: "221" },
  { id: "4170", name: "Bangkok", stateCode: "10", countryId: "221" },
  { id: "4171", name: "Bueng Kan", stateCode: "38", countryId: "221" },
  { id: "4172", name: "Buri Ram", stateCode: "31", countryId: "221" },
  { id: "4173", name: "Chachoengsao", stateCode: "24", countryId: "221" },
  { id: "4174", name: "Chai Nat", stateCode: "18", countryId: "221" },
  { id: "4175", name: "Chaiyaphum", stateCode: "36", countryId: "221" },
  { id: "4176", name: "Chanthaburi", stateCode: "22", countryId: "221" },
  { id: "4177", name: "Chiang Mai", stateCode: "50", countryId: "221" },
  { id: "4178", name: "Chiang Rai", stateCode: "57", countryId: "221" },
  { id: "4179", name: "Chon Buri", stateCode: "20", countryId: "221" },
  { id: "4180", name: "Chumphon", stateCode: "86", countryId: "221" },
  { id: "4181", name: "Kalasin", stateCode: "46", countryId: "221" },
  { id: "4182", name: "Kamphaeng Phet", stateCode: "62", countryId: "221" },
  { id: "4183", name: "Kanchanaburi", stateCode: "71", countryId: "221" },
  { id: "4184", name: "Khon Kaen", stateCode: "40", countryId: "221" },
  { id: "4185", name: "Krabi", stateCode: "81", countryId: "221" },
  { id: "4186", name: "Lampang", stateCode: "52", countryId: "221" },
  { id: "4187", name: "Lamphun", stateCode: "51", countryId: "221" },
  { id: "4188", name: "Loei", stateCode: "42", countryId: "221" },
  { id: "4189", name: "Lop Buri", stateCode: "16", countryId: "221" },
  { id: "4190", name: "Mae Hong Son", stateCode: "58", countryId: "221" },
  { id: "4191", name: "Maha Sarakham", stateCode: "44", countryId: "221" },
  { id: "4192", name: "Mukdahan", stateCode: "49", countryId: "221" },
  { id: "4193", name: "Nakhon Nayok", stateCode: "26", countryId: "221" },
  { id: "4194", name: "Nakhon Pathom", stateCode: "73", countryId: "221" },
  { id: "4195", name: "Nakhon Phanom", stateCode: "48", countryId: "221" },
  { id: "4196", name: "Nakhon Ratchasima", stateCode: "30", countryId: "221" },
  { id: "4197", name: "Nakhon Sawan", stateCode: "60", countryId: "221" },
  {
    id: "4198",
    name: "Nakhon Si Thammarat",
    stateCode: "80",
    countryId: "221",
  },
  { id: "4199", name: "Nan", stateCode: "55", countryId: "221" },
  { id: "4200", name: "Narathiwat", stateCode: "96", countryId: "221" },
  { id: "4201", name: "Nong Bua Lam Phu", stateCode: "39", countryId: "221" },
  { id: "4202", name: "Nong Khai", stateCode: "43", countryId: "221" },
  { id: "4203", name: "Nonthaburi", stateCode: "12", countryId: "221" },
  { id: "4204", name: "Pathum Thani", stateCode: "13", countryId: "221" },
  { id: "4205", name: "Pattani", stateCode: "94", countryId: "221" },
  { id: "4206", name: "Pattaya", stateCode: "S", countryId: "221" },
  { id: "4207", name: "Phangnga", stateCode: "82", countryId: "221" },
  { id: "4208", name: "Phatthalung", stateCode: "93", countryId: "221" },
  { id: "4209", name: "Phayao", stateCode: "56", countryId: "221" },
  { id: "4210", name: "Phetchabun", stateCode: "67", countryId: "221" },
  { id: "4211", name: "Phetchaburi", stateCode: "76", countryId: "221" },
  { id: "4212", name: "Phichit", stateCode: "66", countryId: "221" },
  { id: "4213", name: "Phitsanulok", stateCode: "65", countryId: "221" },
  {
    id: "4214",
    name: "Phra Nakhon Si Ayutthaya",
    stateCode: "14",
    countryId: "221",
  },
  { id: "4215", name: "Phrae", stateCode: "54", countryId: "221" },
  { id: "4216", name: "Phuket", stateCode: "83", countryId: "221" },
  { id: "4217", name: "Prachin Buri", stateCode: "25", countryId: "221" },
  {
    id: "4218",
    name: "Prachuap Khiri Khan",
    stateCode: "77",
    countryId: "221",
  },
  { id: "4219", name: "Ranong", stateCode: "85", countryId: "221" },
  { id: "4220", name: "Ratchaburi", stateCode: "70", countryId: "221" },
  { id: "4221", name: "Rayong", stateCode: "21", countryId: "221" },
  { id: "4222", name: "Roi Et", stateCode: "45", countryId: "221" },
  { id: "4223", name: "Sa Kaeo", stateCode: "27", countryId: "221" },
  { id: "4224", name: "Sakon Nakhon", stateCode: "47", countryId: "221" },
  { id: "4225", name: "Samut Prakan", stateCode: "11", countryId: "221" },
  { id: "4226", name: "Samut Sakhon", stateCode: "74", countryId: "221" },
  { id: "4227", name: "Samut Songkhram", stateCode: "75", countryId: "221" },
  { id: "4228", name: "Saraburi", stateCode: "19", countryId: "221" },
  { id: "4229", name: "Satun", stateCode: "91", countryId: "221" },
  { id: "4230", name: "Si Sa Ket", stateCode: "33", countryId: "221" },
  { id: "4231", name: "Sing Buri", stateCode: "17", countryId: "221" },
  { id: "4232", name: "Songkhla", stateCode: "90", countryId: "221" },
  { id: "4233", name: "Sukhothai", stateCode: "64", countryId: "221" },
  { id: "4234", name: "Suphan Buri", stateCode: "72", countryId: "221" },
  { id: "4235", name: "Surat Thani", stateCode: "84", countryId: "221" },
  { id: "4236", name: "Surin", stateCode: "32", countryId: "221" },
  { id: "4237", name: "Tak", stateCode: "63", countryId: "221" },
  { id: "4238", name: "Trang", stateCode: "92", countryId: "221" },
  { id: "4239", name: "Trat", stateCode: "23", countryId: "221" },
  { id: "4240", name: "Ubon Ratchathani", stateCode: "34", countryId: "221" },
  { id: "4241", name: "Udon Thani", stateCode: "41", countryId: "221" },
  { id: "4242", name: "Uthai Thani", stateCode: "61", countryId: "221" },
  { id: "4243", name: "Uttaradit", stateCode: "53", countryId: "221" },
  { id: "4244", name: "Yala", stateCode: "95", countryId: "221" },
  { id: "4245", name: "Yasothon", stateCode: "35", countryId: "221" },
  { id: "4246", name: "Acklins", stateCode: "AK", countryId: "222" },
  {
    id: "4247",
    name: "Acklins and Crooked Islands",
    stateCode: "AC",
    countryId: "222",
  },
  { id: "4248", name: "Berry Islands", stateCode: "BY", countryId: "222" },
  { id: "4249", name: "Bimini", stateCode: "BI", countryId: "222" },
  { id: "4250", name: "Black Point", stateCode: "BP", countryId: "222" },
  { id: "4251", name: "Cat Island", stateCode: "CI", countryId: "222" },
  { id: "4252", name: "Central Abaco", stateCode: "CO", countryId: "222" },
  { id: "4253", name: "Central Andros", stateCode: "CS", countryId: "222" },
  { id: "4254", name: "Central Eleuthera", stateCode: "CE", countryId: "222" },
  { id: "4255", name: "Crooked Island", stateCode: "CK", countryId: "222" },
  { id: "4256", name: "East Grand Bahama", stateCode: "EG", countryId: "222" },
  { id: "4257", name: "Exuma", stateCode: "EX", countryId: "222" },
  { id: "4258", name: "Freeport", stateCode: "FP", countryId: "222" },
  { id: "4259", name: "Fresh Creek", stateCode: "FC", countryId: "222" },
  {
    id: "4260",
    name: "Governor\\'s Harbour",
    stateCode: "GH",
    countryId: "222",
  },
  { id: "4261", name: "Grand Cay", stateCode: "GC", countryId: "222" },
  { id: "4262", name: "Green Turtle Cay", stateCode: "GT", countryId: "222" },
  { id: "4263", name: "Harbour Island", stateCode: "HI", countryId: "222" },
  { id: "4264", name: "High Rock", stateCode: "HR", countryId: "222" },
  { id: "4265", name: "Hope Town", stateCode: "HT", countryId: "222" },
  { id: "4266", name: "Inagua", stateCode: "IN", countryId: "222" },
  { id: "4267", name: "Kemps Bay", stateCode: "KB", countryId: "222" },
  { id: "4268", name: "Long Island", stateCode: "LI", countryId: "222" },
  { id: "4269", name: "Mangrove Cay", stateCode: "MC", countryId: "222" },
  { id: "4270", name: "Marsh Harbour", stateCode: "MH", countryId: "222" },
  { id: "4271", name: "Mayaguana District", stateCode: "MG", countryId: "222" },
  { id: "4272", name: "New Providence", stateCode: "NP", countryId: "222" },
  {
    id: "4273",
    name: "Nichollstown and Berry Islands",
    stateCode: "NB",
    countryId: "222",
  },
  { id: "4274", name: "North Abaco", stateCode: "NO", countryId: "222" },
  { id: "4275", name: "North Andros", stateCode: "NS", countryId: "222" },
  { id: "4276", name: "North Eleuthera", stateCode: "NE", countryId: "222" },
  { id: "4277", name: "Ragged Island", stateCode: "RI", countryId: "222" },
  { id: "4278", name: "Rock Sound", stateCode: "RS", countryId: "222" },
  { id: "4279", name: "Rum Cay District", stateCode: "RC", countryId: "222" },
  {
    id: "4280",
    name: "San Salvador and Rum Cay",
    stateCode: "SR",
    countryId: "222",
  },
  {
    id: "4281",
    name: "San Salvador Island",
    stateCode: "SS",
    countryId: "222",
  },
  { id: "4282", name: "Sandy Point", stateCode: "SP", countryId: "222" },
  { id: "4283", name: "South Abaco", stateCode: "SO", countryId: "222" },
  { id: "4284", name: "South Andros", stateCode: "SA", countryId: "222" },
  { id: "4285", name: "South Eleuthera", stateCode: "SE", countryId: "222" },
  { id: "4286", name: "Spanish Wells", stateCode: "SW", countryId: "222" },
  { id: "4287", name: "West Grand Bahama", stateCode: "WG", countryId: "222" },
  { id: "4288", name: "Centrale Region", stateCode: "C", countryId: "223" },
  { id: "4289", name: "Kara Region", stateCode: "K", countryId: "223" },
  { id: "4290", name: "Maritime", stateCode: "M", countryId: "223" },
  { id: "4291", name: "Plateaux Region", stateCode: "P", countryId: "223" },
  { id: "4292", name: "Savanes Region", stateCode: "S", countryId: "223" },
  { id: "4293", name: "Haʻapai", stateCode: "02", countryId: "225" },
  { id: "4294", name: "ʻEua", stateCode: "01", countryId: "225" },
  { id: "4295", name: "Niuas", stateCode: "03", countryId: "225" },
  { id: "4296", name: "Tongatapu", stateCode: "04", countryId: "225" },
  { id: "4297", name: "Vavaʻu", stateCode: "05", countryId: "225" },
  { id: "4298", name: "Arima", stateCode: "ARI", countryId: "226" },
  { id: "4299", name: "Chaguanas", stateCode: "CHA", countryId: "226" },
  {
    id: "4300",
    name: "Couva-Tabaquite-Talparo Regional Corporation",
    stateCode: "CTT",
    countryId: "226",
  },
  {
    id: "4301",
    name: "Diego Martin Regional Corporation",
    stateCode: "DMN",
    countryId: "226",
  },
  { id: "4302", name: "Eastern Tobago", stateCode: "ETO", countryId: "226" },
  {
    id: "4303",
    name: "Penal-Debe Regional Corporation",
    stateCode: "PED",
    countryId: "226",
  },
  { id: "4304", name: "Point Fortin", stateCode: "PTF", countryId: "226" },
  { id: "4305", name: "Port of Spain", stateCode: "POS", countryId: "226" },
  {
    id: "4306",
    name: "Princes Town Regional Corporation",
    stateCode: "PRT",
    countryId: "226",
  },
  {
    id: "4307",
    name: "Rio Claro-Mayaro Regional Corporation",
    stateCode: "MRC",
    countryId: "226",
  },
  { id: "4308", name: "San Fernando", stateCode: "SFO", countryId: "226" },
  {
    id: "4309",
    name: "San Juan-Laventille Regional Corporation",
    stateCode: "SJL",
    countryId: "226",
  },
  {
    id: "4310",
    name: "Sangre Grande Regional Corporation",
    stateCode: "SGE",
    countryId: "226",
  },
  {
    id: "4311",
    name: "Siparia Regional Corporation",
    stateCode: "SIP",
    countryId: "226",
  },
  {
    id: "4312",
    name: "Tunapuna-Piarco Regional Corporation",
    stateCode: "TUP",
    countryId: "226",
  },
  { id: "4313", name: "Western Tobago", stateCode: "WTO", countryId: "226" },
  { id: "4314", name: "Ariana", stateCode: "12", countryId: "227" },
  { id: "4315", name: "Béja", stateCode: "31", countryId: "227" },
  { id: "4316", name: "Ben Arous", stateCode: "13", countryId: "227" },
  { id: "4317", name: "Bizerte", stateCode: "23", countryId: "227" },
  { id: "4318", name: "Gabès", stateCode: "81", countryId: "227" },
  { id: "4319", name: "Gafsa", stateCode: "71", countryId: "227" },
  { id: "4320", name: "Jendouba", stateCode: "32", countryId: "227" },
  { id: "4321", name: "Kairouan", stateCode: "41", countryId: "227" },
  { id: "4322", name: "Kasserine", stateCode: "42", countryId: "227" },
  { id: "4323", name: "Kebili", stateCode: "73", countryId: "227" },
  { id: "4324", name: "Kef", stateCode: "33", countryId: "227" },
  { id: "4325", name: "Mahdia", stateCode: "53", countryId: "227" },
  { id: "4326", name: "Manouba", stateCode: "14", countryId: "227" },
  { id: "4327", name: "Medenine", stateCode: "82", countryId: "227" },
  { id: "4328", name: "Monastir", stateCode: "52", countryId: "227" },
  { id: "4329", name: "Nabeul", stateCode: "21", countryId: "227" },
  { id: "4330", name: "Sfax", stateCode: "61", countryId: "227" },
  { id: "4331", name: "Sidi Bouzid", stateCode: "43", countryId: "227" },
  { id: "4332", name: "Siliana", stateCode: "34", countryId: "227" },
  { id: "4333", name: "Sousse", stateCode: "51", countryId: "227" },
  { id: "4334", name: "Tataouine", stateCode: "83", countryId: "227" },
  { id: "4335", name: "Tozeur", stateCode: "72", countryId: "227" },
  { id: "4336", name: "Tunis", stateCode: "11", countryId: "227" },
  { id: "4337", name: "Zaghouan", stateCode: "22", countryId: "227" },
  { id: "4338", name: "Adana", stateCode: "01", countryId: "228" },
  { id: "4339", name: "Adıyaman", stateCode: "02", countryId: "228" },
  { id: "4340", name: "Afyonkarahisar", stateCode: "03", countryId: "228" },
  { id: "4341", name: "Ağrı", stateCode: "04", countryId: "228" },
  { id: "4342", name: "Aksaray", stateCode: "68", countryId: "228" },
  { id: "4343", name: "Amasya", stateCode: "05", countryId: "228" },
  { id: "4344", name: "Ankara", stateCode: "06", countryId: "228" },
  { id: "4345", name: "Antalya", stateCode: "07", countryId: "228" },
  { id: "4346", name: "Ardahan", stateCode: "75", countryId: "228" },
  { id: "4347", name: "Artvin", stateCode: "08", countryId: "228" },
  { id: "4348", name: "Aydın", stateCode: "09", countryId: "228" },
  { id: "4349", name: "Balıkesir", stateCode: "10", countryId: "228" },
  { id: "4350", name: "Bartın", stateCode: "74", countryId: "228" },
  { id: "4351", name: "Batman", stateCode: "72", countryId: "228" },
  { id: "4352", name: "Bayburt", stateCode: "69", countryId: "228" },
  { id: "4353", name: "Bilecik", stateCode: "11", countryId: "228" },
  { id: "4354", name: "Bingöl", stateCode: "12", countryId: "228" },
  { id: "4355", name: "Bitlis", stateCode: "13", countryId: "228" },
  { id: "4356", name: "Bolu", stateCode: "14", countryId: "228" },
  { id: "4357", name: "Burdur", stateCode: "15", countryId: "228" },
  { id: "4358", name: "Bursa", stateCode: "16", countryId: "228" },
  { id: "4359", name: "Çanakkale", stateCode: "17", countryId: "228" },
  { id: "4360", name: "Çankırı", stateCode: "18", countryId: "228" },
  { id: "4361", name: "Çorum", stateCode: "19", countryId: "228" },
  { id: "4362", name: "Denizli", stateCode: "20", countryId: "228" },
  { id: "4363", name: "Diyarbakır", stateCode: "21", countryId: "228" },
  { id: "4364", name: "Düzce", stateCode: "81", countryId: "228" },
  { id: "4365", name: "Edirne", stateCode: "22", countryId: "228" },
  { id: "4366", name: "Elazığ", stateCode: "23", countryId: "228" },
  { id: "4367", name: "Erzincan", stateCode: "24", countryId: "228" },
  { id: "4368", name: "Erzurum", stateCode: "25", countryId: "228" },
  { id: "4369", name: "Eskişehir", stateCode: "26", countryId: "228" },
  { id: "4370", name: "Gaziantep", stateCode: "27", countryId: "228" },
  { id: "4371", name: "Giresun", stateCode: "28", countryId: "228" },
  { id: "4372", name: "Gümüşhane", stateCode: "29", countryId: "228" },
  { id: "4373", name: "Hakkâri", stateCode: "30", countryId: "228" },
  { id: "4374", name: "Hatay", stateCode: "31", countryId: "228" },
  { id: "4375", name: "Iğdır", stateCode: "76", countryId: "228" },
  { id: "4376", name: "Isparta", stateCode: "32", countryId: "228" },
  { id: "4377", name: "İstanbul", stateCode: "34", countryId: "228" },
  { id: "4378", name: "İzmir", stateCode: "35", countryId: "228" },
  { id: "4379", name: "Kahramanmaraş", stateCode: "46", countryId: "228" },
  { id: "4380", name: "Karabük", stateCode: "78", countryId: "228" },
  { id: "4381", name: "Karaman", stateCode: "70", countryId: "228" },
  { id: "4382", name: "Kars", stateCode: "36", countryId: "228" },
  { id: "4383", name: "Kastamonu", stateCode: "37", countryId: "228" },
  { id: "4384", name: "Kayseri", stateCode: "38", countryId: "228" },
  { id: "4385", name: "Kilis", stateCode: "79", countryId: "228" },
  { id: "4386", name: "Kırıkkale", stateCode: "71", countryId: "228" },
  { id: "4387", name: "Kırklareli", stateCode: "39", countryId: "228" },
  { id: "4388", name: "Kırşehir", stateCode: "40", countryId: "228" },
  { id: "4389", name: "Kocaeli", stateCode: "41", countryId: "228" },
  { id: "4390", name: "Konya", stateCode: "42", countryId: "228" },
  { id: "4391", name: "Kütahya", stateCode: "43", countryId: "228" },
  { id: "4392", name: "Malatya", stateCode: "44", countryId: "228" },
  { id: "4393", name: "Manisa", stateCode: "45", countryId: "228" },
  { id: "4394", name: "Mardin", stateCode: "47", countryId: "228" },
  { id: "4395", name: "Mersin", stateCode: "33", countryId: "228" },
  { id: "4396", name: "Muğla", stateCode: "48", countryId: "228" },
  { id: "4397", name: "Muş", stateCode: "49", countryId: "228" },
  { id: "4398", name: "Nevşehir", stateCode: "50", countryId: "228" },
  { id: "4399", name: "Niğde", stateCode: "51", countryId: "228" },
  { id: "4400", name: "Ordu", stateCode: "52", countryId: "228" },
  { id: "4401", name: "Osmaniye", stateCode: "80", countryId: "228" },
  { id: "4402", name: "Rize", stateCode: "53", countryId: "228" },
  { id: "4403", name: "Sakarya", stateCode: "54", countryId: "228" },
  { id: "4404", name: "Samsun", stateCode: "55", countryId: "228" },
  { id: "4405", name: "Şanlıurfa", stateCode: "63", countryId: "228" },
  { id: "4406", name: "Siirt", stateCode: "56", countryId: "228" },
  { id: "4407", name: "Sinop", stateCode: "57", countryId: "228" },
  { id: "4408", name: "Sivas", stateCode: "58", countryId: "228" },
  { id: "4409", name: "Şırnak", stateCode: "73", countryId: "228" },
  { id: "4410", name: "Tekirdağ", stateCode: "59", countryId: "228" },
  { id: "4411", name: "Tokat", stateCode: "60", countryId: "228" },
  { id: "4412", name: "Trabzon", stateCode: "61", countryId: "228" },
  { id: "4413", name: "Tunceli", stateCode: "62", countryId: "228" },
  { id: "4414", name: "Uşak", stateCode: "64", countryId: "228" },
  { id: "4415", name: "Van", stateCode: "65", countryId: "228" },
  { id: "4416", name: "Yalova", stateCode: "77", countryId: "228" },
  { id: "4417", name: "Yozgat", stateCode: "66", countryId: "228" },
  { id: "4418", name: "Zonguldak", stateCode: "67", countryId: "228" },
  { id: "4419", name: "Ahal Region", stateCode: "A", countryId: "229" },
  { id: "4420", name: "Ashgabat", stateCode: "S", countryId: "229" },
  { id: "4421", name: "Balkan Region", stateCode: "B", countryId: "229" },
  { id: "4422", name: "Daşoguz Region", stateCode: "D", countryId: "229" },
  { id: "4423", name: "Lebap Region", stateCode: "L", countryId: "229" },
  { id: "4424", name: "Mary Region", stateCode: "M", countryId: "229" },
  { id: "4425", name: "Funafuti", stateCode: "FUN", countryId: "231" },
  { id: "4426", name: "Nanumanga", stateCode: "NMG", countryId: "231" },
  { id: "4427", name: "Nanumea", stateCode: "NMA", countryId: "231" },
  {
    id: "4428",
    name: "Niutao Island Council",
    stateCode: "NIT",
    countryId: "231",
  },
  { id: "4429", name: "Nui", stateCode: "NUI", countryId: "231" },
  { id: "4430", name: "Nukufetau", stateCode: "NKF", countryId: "231" },
  { id: "4431", name: "Nukulaelae", stateCode: "NKL", countryId: "231" },
  { id: "4432", name: "Vaitupu", stateCode: "VAI", countryId: "231" },
  { id: "4433", name: "Abim District", stateCode: "314", countryId: "232" },
  { id: "4434", name: "Adjumani District", stateCode: "301", countryId: "232" },
  { id: "4435", name: "Agago District", stateCode: "322", countryId: "232" },
  { id: "4436", name: "Alebtong District", stateCode: "323", countryId: "232" },
  { id: "4437", name: "Amolatar District", stateCode: "315", countryId: "232" },
  { id: "4438", name: "Amudat District", stateCode: "324", countryId: "232" },
  { id: "4439", name: "Amuria District", stateCode: "216", countryId: "232" },
  { id: "4440", name: "Amuru District", stateCode: "316", countryId: "232" },
  { id: "4441", name: "Apac District", stateCode: "302", countryId: "232" },
  { id: "4442", name: "Arua District", stateCode: "303", countryId: "232" },
  { id: "4443", name: "Budaka District", stateCode: "217", countryId: "232" },
  { id: "4444", name: "Bududa District", stateCode: "218", countryId: "232" },
  { id: "4445", name: "Bugiri District", stateCode: "201", countryId: "232" },
  { id: "4446", name: "Buhweju District", stateCode: "420", countryId: "232" },
  { id: "4447", name: "Buikwe District", stateCode: "117", countryId: "232" },
  { id: "4448", name: "Bukedea District", stateCode: "219", countryId: "232" },
  {
    id: "4449",
    name: "Bukomansimbi District",
    stateCode: "118",
    countryId: "232",
  },
  { id: "4450", name: "Bukwo District", stateCode: "220", countryId: "232" },
  {
    id: "4451",
    name: "Bulambuli District",
    stateCode: "225",
    countryId: "232",
  },
  { id: "4452", name: "Buliisa District", stateCode: "416", countryId: "232" },
  {
    id: "4453",
    name: "Bundibugyo District",
    stateCode: "401",
    countryId: "232",
  },
  {
    id: "4454",
    name: "Bunyangabu District",
    stateCode: "430",
    countryId: "232",
  },
  { id: "4455", name: "Bushenyi District", stateCode: "402", countryId: "232" },
  { id: "4456", name: "Busia District", stateCode: "202", countryId: "232" },
  { id: "4457", name: "Butaleja District", stateCode: "221", countryId: "232" },
  {
    id: "4458",
    name: "Butambala District",
    stateCode: "119",
    countryId: "232",
  },
  { id: "4459", name: "Butebo District", stateCode: "233", countryId: "232" },
  { id: "4460", name: "Buvuma District", stateCode: "120", countryId: "232" },
  { id: "4461", name: "Buyende District", stateCode: "226", countryId: "232" },
  { id: "4462", name: "Central Region", stateCode: "C", countryId: "232" },
  { id: "4463", name: "Dokolo District", stateCode: "317", countryId: "232" },
  { id: "4464", name: "Eastern Region", stateCode: "E", countryId: "232" },
  { id: "4465", name: "Gomba District", stateCode: "121", countryId: "232" },
  { id: "4466", name: "Gulu District", stateCode: "304", countryId: "232" },
  { id: "4467", name: "Ibanda District", stateCode: "417", countryId: "232" },
  { id: "4468", name: "Iganga District", stateCode: "203", countryId: "232" },
  { id: "4469", name: "Isingiro District", stateCode: "418", countryId: "232" },
  { id: "4470", name: "Jinja District", stateCode: "204", countryId: "232" },
  { id: "4471", name: "Kaabong District", stateCode: "318", countryId: "232" },
  { id: "4472", name: "Kabale District", stateCode: "404", countryId: "232" },
  { id: "4473", name: "Kabarole District", stateCode: "405", countryId: "232" },
  {
    id: "4474",
    name: "Kaberamaido District",
    stateCode: "213",
    countryId: "232",
  },
  { id: "4475", name: "Kagadi District", stateCode: "427", countryId: "232" },
  { id: "4476", name: "Kakumiro District", stateCode: "428", countryId: "232" },
  {
    id: "4477",
    name: "Kalangala District",
    stateCode: "101",
    countryId: "232",
  },
  { id: "4478", name: "Kaliro District", stateCode: "222", countryId: "232" },
  { id: "4479", name: "Kalungu District", stateCode: "122", countryId: "232" },
  { id: "4480", name: "Kampala District", stateCode: "102", countryId: "232" },
  { id: "4481", name: "Kamuli District", stateCode: "205", countryId: "232" },
  { id: "4482", name: "Kamwenge District", stateCode: "413", countryId: "232" },
  { id: "4483", name: "Kanungu District", stateCode: "414", countryId: "232" },
  {
    id: "4484",
    name: "Kapchorwa District",
    stateCode: "206",
    countryId: "232",
  },
  { id: "4485", name: "Kasese District", stateCode: "406", countryId: "232" },
  { id: "4486", name: "Katakwi District", stateCode: "207", countryId: "232" },
  { id: "4487", name: "Kayunga District", stateCode: "112", countryId: "232" },
  { id: "4488", name: "Kibaale District", stateCode: "407", countryId: "232" },
  { id: "4489", name: "Kiboga District", stateCode: "103", countryId: "232" },
  { id: "4490", name: "Kibuku District", stateCode: "227", countryId: "232" },
  { id: "4491", name: "Kiruhura District", stateCode: "419", countryId: "232" },
  {
    id: "4492",
    name: "Kiryandongo District",
    stateCode: "421",
    countryId: "232",
  },
  { id: "4493", name: "Kisoro District", stateCode: "408", countryId: "232" },
  { id: "4494", name: "Kitgum District", stateCode: "305", countryId: "232" },
  { id: "4495", name: "Koboko District", stateCode: "319", countryId: "232" },
  { id: "4496", name: "Kole District", stateCode: "325", countryId: "232" },
  { id: "4497", name: "Kotido District", stateCode: "306", countryId: "232" },
  { id: "4498", name: "Kumi District", stateCode: "208", countryId: "232" },
  { id: "4499", name: "Kween District", stateCode: "228", countryId: "232" },
  {
    id: "4500",
    name: "Kyankwanzi District",
    stateCode: "123",
    countryId: "232",
  },
  { id: "4501", name: "Kyegegwa District", stateCode: "422", countryId: "232" },
  { id: "4502", name: "Kyenjojo District", stateCode: "415", countryId: "232" },
  { id: "4503", name: "Kyotera District", stateCode: "125", countryId: "232" },
  { id: "4504", name: "Lamwo District", stateCode: "326", countryId: "232" },
  { id: "4505", name: "Lira District", stateCode: "307", countryId: "232" },
  { id: "4506", name: "Luuka District", stateCode: "229", countryId: "232" },
  { id: "4507", name: "Luwero District", stateCode: "104", countryId: "232" },
  { id: "4508", name: "Lwengo District", stateCode: "124", countryId: "232" },
  {
    id: "4509",
    name: "Lyantonde District",
    stateCode: "114",
    countryId: "232",
  },
  { id: "4510", name: "Manafwa District", stateCode: "223", countryId: "232" },
  { id: "4511", name: "Maracha District", stateCode: "320", countryId: "232" },
  { id: "4512", name: "Masaka District", stateCode: "105", countryId: "232" },
  { id: "4513", name: "Masindi District", stateCode: "409", countryId: "232" },
  { id: "4514", name: "Mayuge District", stateCode: "214", countryId: "232" },
  { id: "4515", name: "Mbale District", stateCode: "209", countryId: "232" },
  { id: "4516", name: "Mbarara District", stateCode: "410", countryId: "232" },
  { id: "4517", name: "Mitooma District", stateCode: "423", countryId: "232" },
  { id: "4518", name: "Mityana District", stateCode: "115", countryId: "232" },
  { id: "4519", name: "Moroto District", stateCode: "308", countryId: "232" },
  { id: "4520", name: "Moyo District", stateCode: "309", countryId: "232" },
  { id: "4521", name: "Mpigi District", stateCode: "106", countryId: "232" },
  { id: "4522", name: "Mubende District", stateCode: "107", countryId: "232" },
  { id: "4523", name: "Mukono District", stateCode: "108", countryId: "232" },
  {
    id: "4524",
    name: "Nakapiripirit District",
    stateCode: "311",
    countryId: "232",
  },
  { id: "4525", name: "Nakaseke District", stateCode: "116", countryId: "232" },
  {
    id: "4526",
    name: "Nakasongola District",
    stateCode: "109",
    countryId: "232",
  },
  {
    id: "4527",
    name: "Namayingo District",
    stateCode: "230",
    countryId: "232",
  },
  {
    id: "4528",
    name: "Namisindwa District",
    stateCode: "234",
    countryId: "232",
  },
  {
    id: "4529",
    name: "Namutumba District",
    stateCode: "224",
    countryId: "232",
  },
  { id: "4530", name: "Napak District", stateCode: "327", countryId: "232" },
  { id: "4531", name: "Nebbi District", stateCode: "310", countryId: "232" },
  { id: "4532", name: "Ngora District", stateCode: "231", countryId: "232" },
  { id: "4533", name: "Northern Region", stateCode: "N", countryId: "232" },
  { id: "4534", name: "Ntoroko District", stateCode: "424", countryId: "232" },
  { id: "4535", name: "Ntungamo District", stateCode: "411", countryId: "232" },
  { id: "4536", name: "Nwoya District", stateCode: "328", countryId: "232" },
  { id: "4537", name: "Omoro District", stateCode: "331", countryId: "232" },
  { id: "4538", name: "Otuke District", stateCode: "329", countryId: "232" },
  { id: "4539", name: "Oyam District", stateCode: "321", countryId: "232" },
  { id: "4540", name: "Pader District", stateCode: "312", countryId: "232" },
  { id: "4541", name: "Pakwach District", stateCode: "332", countryId: "232" },
  { id: "4542", name: "Pallisa District", stateCode: "210", countryId: "232" },
  { id: "4543", name: "Rakai District", stateCode: "110", countryId: "232" },
  { id: "4544", name: "Rubanda District", stateCode: "429", countryId: "232" },
  { id: "4545", name: "Rubirizi District", stateCode: "425", countryId: "232" },
  { id: "4546", name: "Rukiga District", stateCode: "431", countryId: "232" },
  {
    id: "4547",
    name: "Rukungiri District",
    stateCode: "412",
    countryId: "232",
  },
  {
    id: "4548",
    name: "Sembabule District",
    stateCode: "111",
    countryId: "232",
  },
  { id: "4549", name: "Serere District", stateCode: "232", countryId: "232" },
  { id: "4550", name: "Sheema District", stateCode: "426", countryId: "232" },
  { id: "4551", name: "Sironko District", stateCode: "215", countryId: "232" },
  { id: "4552", name: "Soroti District", stateCode: "211", countryId: "232" },
  { id: "4553", name: "Tororo District", stateCode: "212", countryId: "232" },
  { id: "4554", name: "Wakiso District", stateCode: "113", countryId: "232" },
  { id: "4555", name: "Western Region", stateCode: "W", countryId: "232" },
  { id: "4556", name: "Yumbe District", stateCode: "313", countryId: "232" },
  { id: "4557", name: "Zombo District", stateCode: "330", countryId: "232" },
  {
    id: "4558",
    name: "Autonomous Republic of Crimea",
    stateCode: "43",
    countryId: "233",
  },
  { id: "4559", name: "Cherkaska oblast", stateCode: "71", countryId: "233" },
  {
    id: "4560",
    name: "Chernihivska oblast",
    stateCode: "74",
    countryId: "233",
  },
  {
    id: "4561",
    name: "Chernivetska oblast",
    stateCode: "77",
    countryId: "233",
  },
  {
    id: "4562",
    name: "Dnipropetrovska oblast",
    stateCode: "12",
    countryId: "233",
  },
  { id: "4563", name: "Donetska oblast", stateCode: "14", countryId: "233" },
  {
    id: "4564",
    name: "Ivano-Frankivska oblast",
    stateCode: "26",
    countryId: "233",
  },
  { id: "4565", name: "Kharkivska oblast", stateCode: "63", countryId: "233" },
  { id: "4566", name: "Khersonska oblast", stateCode: "65", countryId: "233" },
  { id: "4567", name: "Khmelnytska oblast", stateCode: "68", countryId: "233" },
  {
    id: "4568",
    name: "Kirovohradska oblast",
    stateCode: "35",
    countryId: "233",
  },
  { id: "4569", name: "Kyiv", stateCode: "30", countryId: "233" },
  { id: "4570", name: "Kyivska oblast", stateCode: "32", countryId: "233" },
  { id: "4571", name: "Luhanska oblast", stateCode: "09", countryId: "233" },
  { id: "4572", name: "Lvivska oblast", stateCode: "46", countryId: "233" },
  { id: "4573", name: "Mykolaivska oblast", stateCode: "48", countryId: "233" },
  { id: "4574", name: "Odeska oblast", stateCode: "51", countryId: "233" },
  { id: "4575", name: "Poltavska oblast", stateCode: "53", countryId: "233" },
  { id: "4576", name: "Rivnenska oblast", stateCode: "56", countryId: "233" },
  { id: "4577", name: "Sevastopol", stateCode: "40", countryId: "233" },
  { id: "4578", name: "Sumska oblast", stateCode: "59", countryId: "233" },
  { id: "4579", name: "Ternopilska oblast", stateCode: "61", countryId: "233" },
  { id: "4580", name: "Vinnytska oblast", stateCode: "05", countryId: "233" },
  { id: "4581", name: "Volynska oblast", stateCode: "07", countryId: "233" },
  { id: "4582", name: "Zakarpatska Oblast", stateCode: "21", countryId: "233" },
  { id: "4583", name: "Zaporizka oblast", stateCode: "23", countryId: "233" },
  { id: "4584", name: "Zhytomyrska oblast", stateCode: "18", countryId: "233" },
  { id: "4585", name: "Abu Dhabi Emirate", stateCode: "AZ", countryId: "234" },
  { id: "4586", name: "Ajman Emirate", stateCode: "AJ", countryId: "234" },
  { id: "4587", name: "Dubai", stateCode: "DU", countryId: "234" },
  { id: "4588", name: "Fujairah", stateCode: "FU", countryId: "234" },
  { id: "4589", name: "Ras al-Khaimah", stateCode: "RK", countryId: "234" },
  { id: "4590", name: "Sharjah Emirate", stateCode: "SH", countryId: "234" },
  { id: "4591", name: "Umm al-Quwain", stateCode: "UQ", countryId: "234" },
  { id: "4592", name: "Aberdeen", stateCode: "ABE", countryId: "235" },
  { id: "4593", name: "Aberdeenshire", stateCode: "ABD", countryId: "235" },
  { id: "4594", name: "Angus", stateCode: "ANS", countryId: "235" },
  { id: "4595", name: "Antrim", stateCode: "ANT", countryId: "235" },
  {
    id: "4596",
    name: "Antrim and Newtownabbey",
    stateCode: "ANN",
    countryId: "235",
  },
  { id: "4597", name: "Ards", stateCode: "ARD", countryId: "235" },
  {
    id: "4598",
    name: "Ards and North Down",
    stateCode: "AND",
    countryId: "235",
  },
  { id: "4599", name: "Argyll and Bute", stateCode: "AGB", countryId: "235" },
  {
    id: "4600",
    name: "Armagh City and District Council",
    stateCode: "ARM",
    countryId: "235",
  },
  {
    id: "4601",
    name: "Armagh, Banbridge and Craigavon",
    stateCode: "ABC",
    countryId: "235",
  },
  {
    id: "4602",
    name: "Ascension Island",
    stateCode: "SH-AC",
    countryId: "235",
  },
  { id: "4603", name: "Ballymena Borough", stateCode: "BLA", countryId: "235" },
  { id: "4604", name: "Ballymoney", stateCode: "BLY", countryId: "235" },
  { id: "4605", name: "Banbridge", stateCode: "BNB", countryId: "235" },
  { id: "4606", name: "Barnsley", stateCode: "BNS", countryId: "235" },
  {
    id: "4607",
    name: "Bath and North East Somerset",
    stateCode: "BAS",
    countryId: "235",
  },
  { id: "4608", name: "Bedford", stateCode: "BDF", countryId: "235" },
  { id: "4609", name: "Belfast district", stateCode: "BFS", countryId: "235" },
  { id: "4610", name: "Birmingham", stateCode: "BIR", countryId: "235" },
  {
    id: "4611",
    name: "Blackburn with Darwen",
    stateCode: "BBD",
    countryId: "235",
  },
  { id: "4612", name: "Blackpool", stateCode: "BPL", countryId: "235" },
  {
    id: "4613",
    name: "Blaenau Gwent County Borough",
    stateCode: "BGW",
    countryId: "235",
  },
  { id: "4614", name: "Bolton", stateCode: "BOL", countryId: "235" },
  { id: "4615", name: "Bournemouth", stateCode: "BMH", countryId: "235" },
  { id: "4616", name: "Bracknell Forest", stateCode: "BRC", countryId: "235" },
  { id: "4617", name: "Bradford", stateCode: "BRD", countryId: "235" },
  {
    id: "4618",
    name: "Bridgend County Borough",
    stateCode: "BGE",
    countryId: "235",
  },
  { id: "4619", name: "Brighton and Hove", stateCode: "BNH", countryId: "235" },
  { id: "4620", name: "Buckinghamshire", stateCode: "BKM", countryId: "235" },
  { id: "4621", name: "Bury", stateCode: "BUR", countryId: "235" },
  {
    id: "4622",
    name: "Caerphilly County Borough",
    stateCode: "CAY",
    countryId: "235",
  },
  { id: "4623", name: "Calderdale", stateCode: "CLD", countryId: "235" },
  { id: "4624", name: "Cambridgeshire", stateCode: "CAM", countryId: "235" },
  { id: "4625", name: "Carmarthenshire", stateCode: "CMN", countryId: "235" },
  {
    id: "4626",
    name: "Carrickfergus Borough Council",
    stateCode: "CKF",
    countryId: "235",
  },
  { id: "4627", name: "Castlereagh", stateCode: "CSR", countryId: "235" },
  {
    id: "4628",
    name: "Causeway Coast and Glens",
    stateCode: "CCG",
    countryId: "235",
  },
  {
    id: "4629",
    name: "Central Bedfordshire",
    stateCode: "CBF",
    countryId: "235",
  },
  { id: "4630", name: "Ceredigion", stateCode: "CGN", countryId: "235" },
  { id: "4631", name: "Cheshire East", stateCode: "CHE", countryId: "235" },
  {
    id: "4632",
    name: "Cheshire West and Chester",
    stateCode: "CHW",
    countryId: "235",
  },
  {
    id: "4633",
    name: "City and County of Cardiff",
    stateCode: "CRF",
    countryId: "235",
  },
  {
    id: "4634",
    name: "City and County of Swansea",
    stateCode: "SWA",
    countryId: "235",
  },
  { id: "4635", name: "City of Bristol", stateCode: "BST", countryId: "235" },
  { id: "4636", name: "City of Derby", stateCode: "DER", countryId: "235" },
  {
    id: "4637",
    name: "City of Kingston upon Hull",
    stateCode: "KHL",
    countryId: "235",
  },
  { id: "4638", name: "City of Leicester", stateCode: "LCE", countryId: "235" },
  { id: "4639", name: "City of London", stateCode: "LND", countryId: "235" },
  {
    id: "4640",
    name: "City of Nottingham",
    stateCode: "NGM",
    countryId: "235",
  },
  {
    id: "4641",
    name: "City of Peterborough",
    stateCode: "PTE",
    countryId: "235",
  },
  { id: "4642", name: "City of Plymouth", stateCode: "PLY", countryId: "235" },
  {
    id: "4643",
    name: "City of Portsmouth",
    stateCode: "POR",
    countryId: "235",
  },
  {
    id: "4644",
    name: "City of Southampton",
    stateCode: "STH",
    countryId: "235",
  },
  {
    id: "4645",
    name: "City of Stoke-on-Trent",
    stateCode: "STE",
    countryId: "235",
  },
  {
    id: "4646",
    name: "City of Sunderland",
    stateCode: "SND",
    countryId: "235",
  },
  {
    id: "4647",
    name: "City of Westminster",
    stateCode: "WSM",
    countryId: "235",
  },
  {
    id: "4648",
    name: "City of Wolverhampton",
    stateCode: "WLV",
    countryId: "235",
  },
  { id: "4649", name: "City of York", stateCode: "YOR", countryId: "235" },
  { id: "4650", name: "Clackmannanshire", stateCode: "CLK", countryId: "235" },
  {
    id: "4651",
    name: "Coleraine Borough Council",
    stateCode: "CLR",
    countryId: "235",
  },
  {
    id: "4652",
    name: "Conwy County Borough",
    stateCode: "CWY",
    countryId: "235",
  },
  {
    id: "4653",
    name: "Cookstown District Council",
    stateCode: "CKT",
    countryId: "235",
  },
  { id: "4654", name: "Cornwall", stateCode: "CON", countryId: "235" },
  { id: "4655", name: "County Durham", stateCode: "DUR", countryId: "235" },
  { id: "4656", name: "Coventry", stateCode: "COV", countryId: "235" },
  {
    id: "4657",
    name: "Craigavon Borough Council",
    stateCode: "CGV",
    countryId: "235",
  },
  { id: "4658", name: "Cumbria", stateCode: "CMA", countryId: "235" },
  { id: "4659", name: "Darlington", stateCode: "DAL", countryId: "235" },
  { id: "4660", name: "Denbighshire", stateCode: "DEN", countryId: "235" },
  { id: "4661", name: "Derbyshire", stateCode: "DBY", countryId: "235" },
  {
    id: "4662",
    name: "Derry City and Strabane",
    stateCode: "DRS",
    countryId: "235",
  },
  {
    id: "4663",
    name: "Derry City Council",
    stateCode: "DRY",
    countryId: "235",
  },
  { id: "4664", name: "Devon", stateCode: "DEV", countryId: "235" },
  { id: "4665", name: "Doncaster", stateCode: "DNC", countryId: "235" },
  { id: "4666", name: "Dorset", stateCode: "DOR", countryId: "235" },
  {
    id: "4667",
    name: "Down District Council",
    stateCode: "DOW",
    countryId: "235",
  },
  { id: "4668", name: "Dudley", stateCode: "DUD", countryId: "235" },
  {
    id: "4669",
    name: "Dumfries and Galloway",
    stateCode: "DGY",
    countryId: "235",
  },
  { id: "4670", name: "Dundee", stateCode: "DND", countryId: "235" },
  {
    id: "4671",
    name: "Dungannon and South Tyrone Borough Council",
    stateCode: "DGN",
    countryId: "235",
  },
  { id: "4672", name: "East Ayrshire", stateCode: "EAY", countryId: "235" },
  {
    id: "4673",
    name: "East Dunbartonshire",
    stateCode: "EDU",
    countryId: "235",
  },
  { id: "4674", name: "East Lothian", stateCode: "ELN", countryId: "235" },
  { id: "4675", name: "East Renfrewshire", stateCode: "ERW", countryId: "235" },
  {
    id: "4676",
    name: "East Riding of Yorkshire",
    stateCode: "ERY",
    countryId: "235",
  },
  { id: "4677", name: "East Sussex", stateCode: "ESX", countryId: "235" },
  { id: "4678", name: "Edinburgh", stateCode: "EDH", countryId: "235" },
  { id: "4679", name: "England", stateCode: "ENG", countryId: "235" },
  { id: "4680", name: "Essex", stateCode: "ESS", countryId: "235" },
  { id: "4681", name: "Falkirk", stateCode: "FAL", countryId: "235" },
  {
    id: "4682",
    name: "Fermanagh and Omagh",
    stateCode: "FMO",
    countryId: "235",
  },
  {
    id: "4683",
    name: "Fermanagh District Council",
    stateCode: "FER",
    countryId: "235",
  },
  { id: "4684", name: "Fife", stateCode: "FIF", countryId: "235" },
  { id: "4685", name: "Flintshire", stateCode: "FLN", countryId: "235" },
  { id: "4686", name: "Gateshead", stateCode: "GAT", countryId: "235" },
  { id: "4687", name: "Glasgow", stateCode: "GLG", countryId: "235" },
  { id: "4688", name: "Gloucestershire", stateCode: "GLS", countryId: "235" },
  { id: "4689", name: "Gwynedd", stateCode: "GWN", countryId: "235" },
  { id: "4690", name: "Halton", stateCode: "HAL", countryId: "235" },
  { id: "4691", name: "Hampshire", stateCode: "HAM", countryId: "235" },
  { id: "4692", name: "Hartlepool", stateCode: "HPL", countryId: "235" },
  { id: "4693", name: "Herefordshire", stateCode: "HEF", countryId: "235" },
  { id: "4694", name: "Hertfordshire", stateCode: "HRT", countryId: "235" },
  { id: "4695", name: "Highland", stateCode: "HLD", countryId: "235" },
  { id: "4696", name: "Inverclyde", stateCode: "IVC", countryId: "235" },
  { id: "4697", name: "Isle of Wight", stateCode: "IOW", countryId: "235" },
  { id: "4698", name: "Isles of Scilly", stateCode: "IOS", countryId: "235" },
  { id: "4699", name: "Kent", stateCode: "KEN", countryId: "235" },
  { id: "4700", name: "Kirklees", stateCode: "KIR", countryId: "235" },
  { id: "4701", name: "Knowsley", stateCode: "KWL", countryId: "235" },
  { id: "4702", name: "Lancashire", stateCode: "LAN", countryId: "235" },
  {
    id: "4703",
    name: "Larne Borough Council",
    stateCode: "LRN",
    countryId: "235",
  },
  { id: "4704", name: "Leeds", stateCode: "LDS", countryId: "235" },
  { id: "4705", name: "Leicestershire", stateCode: "LEC", countryId: "235" },
  {
    id: "4706",
    name: "Limavady Borough Council",
    stateCode: "LMV",
    countryId: "235",
  },
  { id: "4707", name: "Lincolnshire", stateCode: "LIN", countryId: "235" },
  {
    id: "4708",
    name: "Lisburn and Castlereagh",
    stateCode: "LBC",
    countryId: "235",
  },
  {
    id: "4709",
    name: "Lisburn City Council",
    stateCode: "LSB",
    countryId: "235",
  },
  { id: "4710", name: "Liverpool", stateCode: "LIV", countryId: "235" },
  {
    id: "4711",
    name: "London Borough of Barking and Dagenham",
    stateCode: "BDG",
    countryId: "235",
  },
  {
    id: "4712",
    name: "London Borough of Barnet",
    stateCode: "BNE",
    countryId: "235",
  },
  {
    id: "4713",
    name: "London Borough of Bexley",
    stateCode: "BEX",
    countryId: "235",
  },
  {
    id: "4714",
    name: "London Borough of Brent",
    stateCode: "BEN",
    countryId: "235",
  },
  {
    id: "4715",
    name: "London Borough of Bromley",
    stateCode: "BRY",
    countryId: "235",
  },
  {
    id: "4716",
    name: "London Borough of Camden",
    stateCode: "CMD",
    countryId: "235",
  },
  {
    id: "4717",
    name: "London Borough of Croydon",
    stateCode: "CRY",
    countryId: "235",
  },
  {
    id: "4718",
    name: "London Borough of Ealing",
    stateCode: "EAL",
    countryId: "235",
  },
  {
    id: "4719",
    name: "London Borough of Enfield",
    stateCode: "ENF",
    countryId: "235",
  },
  {
    id: "4720",
    name: "London Borough of Hackney",
    stateCode: "HCK",
    countryId: "235",
  },
  {
    id: "4721",
    name: "London Borough of Hammersmith and Fulham",
    stateCode: "HMF",
    countryId: "235",
  },
  {
    id: "4722",
    name: "London Borough of Haringey",
    stateCode: "HRY",
    countryId: "235",
  },
  {
    id: "4723",
    name: "London Borough of Harrow",
    stateCode: "HRW",
    countryId: "235",
  },
  {
    id: "4724",
    name: "London Borough of Havering",
    stateCode: "HAV",
    countryId: "235",
  },
  {
    id: "4725",
    name: "London Borough of Hillingdon",
    stateCode: "HIL",
    countryId: "235",
  },
  {
    id: "4726",
    name: "London Borough of Hounslow",
    stateCode: "HNS",
    countryId: "235",
  },
  {
    id: "4727",
    name: "London Borough of Islington",
    stateCode: "ISL",
    countryId: "235",
  },
  {
    id: "4728",
    name: "London Borough of Lambeth",
    stateCode: "LBH",
    countryId: "235",
  },
  {
    id: "4729",
    name: "London Borough of Lewisham",
    stateCode: "LEW",
    countryId: "235",
  },
  {
    id: "4730",
    name: "London Borough of Merton",
    stateCode: "MRT",
    countryId: "235",
  },
  {
    id: "4731",
    name: "London Borough of Newham",
    stateCode: "NWM",
    countryId: "235",
  },
  {
    id: "4732",
    name: "London Borough of Redbridge",
    stateCode: "RDB",
    countryId: "235",
  },
  {
    id: "4733",
    name: "London Borough of Richmond upon Thames",
    stateCode: "RIC",
    countryId: "235",
  },
  {
    id: "4734",
    name: "London Borough of Southwark",
    stateCode: "SWK",
    countryId: "235",
  },
  {
    id: "4735",
    name: "London Borough of Sutton",
    stateCode: "STN",
    countryId: "235",
  },
  {
    id: "4736",
    name: "London Borough of Tower Hamlets",
    stateCode: "TWH",
    countryId: "235",
  },
  {
    id: "4737",
    name: "London Borough of Waltham Forest",
    stateCode: "WFT",
    countryId: "235",
  },
  {
    id: "4738",
    name: "London Borough of Wandsworth",
    stateCode: "WND",
    countryId: "235",
  },
  {
    id: "4739",
    name: "Magherafelt District Council",
    stateCode: "MFT",
    countryId: "235",
  },
  { id: "4740", name: "Manchester", stateCode: "MAN", countryId: "235" },
  { id: "4741", name: "Medway", stateCode: "MDW", countryId: "235" },
  {
    id: "4742",
    name: "Merthyr Tydfil County Borough",
    stateCode: "MTY",
    countryId: "235",
  },
  {
    id: "4743",
    name: "Metropolitan Borough of Wigan",
    stateCode: "WGN",
    countryId: "235",
  },
  {
    id: "4744",
    name: "Mid and East Antrim",
    stateCode: "MEA",
    countryId: "235",
  },
  { id: "4745", name: "Mid Ulster", stateCode: "MUL", countryId: "235" },
  { id: "4746", name: "Middlesbrough", stateCode: "MDB", countryId: "235" },
  { id: "4747", name: "Midlothian", stateCode: "MLN", countryId: "235" },
  { id: "4748", name: "Milton Keynes", stateCode: "MIK", countryId: "235" },
  { id: "4749", name: "Monmouthshire", stateCode: "MON", countryId: "235" },
  { id: "4750", name: "Moray", stateCode: "MRY", countryId: "235" },
  {
    id: "4751",
    name: "Moyle District Council",
    stateCode: "MYL",
    countryId: "235",
  },
  {
    id: "4752",
    name: "Neath Port Talbot County Borough",
    stateCode: "NTL",
    countryId: "235",
  },
  {
    id: "4753",
    name: "Newcastle upon Tyne",
    stateCode: "NET",
    countryId: "235",
  },
  { id: "4754", name: "Newport", stateCode: "NWP", countryId: "235" },
  {
    id: "4755",
    name: "Newry and Mourne District Council",
    stateCode: "NYM",
    countryId: "235",
  },
  {
    id: "4756",
    name: "Newry, Mourne and Down",
    stateCode: "NMD",
    countryId: "235",
  },
  {
    id: "4757",
    name: "Newtownabbey Borough Council",
    stateCode: "NTA",
    countryId: "235",
  },
  { id: "4758", name: "Norfolk", stateCode: "NFK", countryId: "235" },
  { id: "4759", name: "North Ayrshire", stateCode: "NAY", countryId: "235" },
  {
    id: "4760",
    name: "North Down Borough Council",
    stateCode: "NDN",
    countryId: "235",
  },
  {
    id: "4761",
    name: "North East Lincolnshire",
    stateCode: "NEL",
    countryId: "235",
  },
  { id: "4762", name: "North Lanarkshire", stateCode: "NLK", countryId: "235" },
  {
    id: "4763",
    name: "North Lincolnshire",
    stateCode: "NLN",
    countryId: "235",
  },
  { id: "4764", name: "North Somerset", stateCode: "NSM", countryId: "235" },
  { id: "4765", name: "North Tyneside", stateCode: "NTY", countryId: "235" },
  { id: "4766", name: "North Yorkshire", stateCode: "NYK", countryId: "235" },
  { id: "4767", name: "Northamptonshire", stateCode: "NTH", countryId: "235" },
  { id: "4768", name: "Northern Ireland", stateCode: "NIR", countryId: "235" },
  { id: "4769", name: "Northumberland", stateCode: "NBL", countryId: "235" },
  { id: "4770", name: "Nottinghamshire", stateCode: "NTT", countryId: "235" },
  { id: "4771", name: "Oldham", stateCode: "OLD", countryId: "235" },
  {
    id: "4772",
    name: "Omagh District Council",
    stateCode: "OMH",
    countryId: "235",
  },
  { id: "4773", name: "Orkney Islands", stateCode: "ORK", countryId: "235" },
  { id: "4774", name: "Outer Hebrides", stateCode: "ELS", countryId: "235" },
  { id: "4775", name: "Oxfordshire", stateCode: "OXF", countryId: "235" },
  { id: "4776", name: "Pembrokeshire", stateCode: "PEM", countryId: "235" },
  { id: "4777", name: "Perth and Kinross", stateCode: "PKN", countryId: "235" },
  { id: "4778", name: "Poole", stateCode: "POL", countryId: "235" },
  { id: "4779", name: "Powys", stateCode: "POW", countryId: "235" },
  { id: "4780", name: "Reading", stateCode: "RDG", countryId: "235" },
  {
    id: "4781",
    name: "Redcar and Cleveland",
    stateCode: "RCC",
    countryId: "235",
  },
  { id: "4782", name: "Renfrewshire", stateCode: "RFW", countryId: "235" },
  { id: "4783", name: "Rhondda Cynon Taf", stateCode: "RCT", countryId: "235" },
  { id: "4784", name: "Rochdale", stateCode: "RCH", countryId: "235" },
  { id: "4785", name: "Rotherham", stateCode: "ROT", countryId: "235" },
  {
    id: "4786",
    name: "Royal Borough of Greenwich",
    stateCode: "GRE",
    countryId: "235",
  },
  {
    id: "4787",
    name: "Royal Borough of Kensington and Chelsea",
    stateCode: "KEC",
    countryId: "235",
  },
  {
    id: "4788",
    name: "Royal Borough of Kingston upon Thames",
    stateCode: "KTT",
    countryId: "235",
  },
  { id: "4789", name: "Rutland", stateCode: "RUT", countryId: "235" },
  { id: "4790", name: "Saint Helena", stateCode: "SH-HL", countryId: "235" },
  { id: "4791", name: "Salford", stateCode: "SLF", countryId: "235" },
  { id: "4792", name: "Sandwell", stateCode: "SAW", countryId: "235" },
  { id: "4793", name: "Scotland", stateCode: "SCT", countryId: "235" },
  { id: "4794", name: "Scottish Borders", stateCode: "SCB", countryId: "235" },
  { id: "4795", name: "Sefton", stateCode: "SFT", countryId: "235" },
  { id: "4796", name: "Sheffield", stateCode: "SHF", countryId: "235" },
  { id: "4797", name: "Shetland Islands", stateCode: "ZET", countryId: "235" },
  { id: "4798", name: "Shropshire", stateCode: "SHR", countryId: "235" },
  { id: "4799", name: "Slough", stateCode: "SLG", countryId: "235" },
  { id: "4800", name: "Solihull", stateCode: "SOL", countryId: "235" },
  { id: "4801", name: "Somerset", stateCode: "SOM", countryId: "235" },
  { id: "4802", name: "South Ayrshire", stateCode: "SAY", countryId: "235" },
  {
    id: "4803",
    name: "South Gloucestershire",
    stateCode: "SGC",
    countryId: "235",
  },
  { id: "4804", name: "South Lanarkshire", stateCode: "SLK", countryId: "235" },
  { id: "4805", name: "South Tyneside", stateCode: "STY", countryId: "235" },
  { id: "4806", name: "Southend-on-Sea", stateCode: "SOS", countryId: "235" },
  { id: "4807", name: "St Helens", stateCode: "SHN", countryId: "235" },
  { id: "4808", name: "Staffordshire", stateCode: "STS", countryId: "235" },
  { id: "4809", name: "Stirling", stateCode: "STG", countryId: "235" },
  { id: "4810", name: "Stockport", stateCode: "SKP", countryId: "235" },
  { id: "4811", name: "Stockton-on-Tees", stateCode: "STT", countryId: "235" },
  {
    id: "4812",
    name: "Strabane District Council",
    stateCode: "STB",
    countryId: "235",
  },
  { id: "4813", name: "Suffolk", stateCode: "SFK", countryId: "235" },
  { id: "4814", name: "Surrey", stateCode: "SRY", countryId: "235" },
  { id: "4815", name: "Swindon", stateCode: "SWD", countryId: "235" },
  { id: "4816", name: "Tameside", stateCode: "TAM", countryId: "235" },
  {
    id: "4817",
    name: "Telford and Wrekin",
    stateCode: "TFW",
    countryId: "235",
  },
  { id: "4818", name: "Thurrock", stateCode: "THR", countryId: "235" },
  { id: "4819", name: "Torbay", stateCode: "TOB", countryId: "235" },
  { id: "4820", name: "Torfaen", stateCode: "TOF", countryId: "235" },
  { id: "4821", name: "Trafford", stateCode: "TRF", countryId: "235" },
  { id: "4822", name: "United Kingdom", stateCode: "UKM", countryId: "235" },
  { id: "4823", name: "Vale of Glamorgan", stateCode: "VGL", countryId: "235" },
  { id: "4824", name: "Wakefield", stateCode: "WKF", countryId: "235" },
  { id: "4825", name: "Wales", stateCode: "WLS", countryId: "235" },
  { id: "4826", name: "Walsall", stateCode: "WLL", countryId: "235" },
  { id: "4827", name: "Warrington", stateCode: "WRT", countryId: "235" },
  { id: "4828", name: "Warwickshire", stateCode: "WAR", countryId: "235" },
  { id: "4829", name: "West Berkshire", stateCode: "WBK", countryId: "235" },
  {
    id: "4830",
    name: "West Dunbartonshire",
    stateCode: "WDU",
    countryId: "235",
  },
  { id: "4831", name: "West Lothian", stateCode: "WLN", countryId: "235" },
  { id: "4832", name: "West Sussex", stateCode: "WSX", countryId: "235" },
  { id: "4833", name: "Wiltshire", stateCode: "WIL", countryId: "235" },
  {
    id: "4834",
    name: "Windsor and Maidenhead",
    stateCode: "WNM",
    countryId: "235",
  },
  { id: "4835", name: "Wirral", stateCode: "WRL", countryId: "235" },
  { id: "4836", name: "Wokingham", stateCode: "WOK", countryId: "235" },
  { id: "4837", name: "Worcestershire", stateCode: "WOR", countryId: "235" },
  {
    id: "4838",
    name: "Wrexham County Borough",
    stateCode: "WRX",
    countryId: "235",
  },
  { id: "4839", name: "Alabama", stateCode: "AL", countryId: "236" },
  { id: "4840", name: "Alaska", stateCode: "AK", countryId: "236" },
  { id: "4841", name: "American Samoa", stateCode: "AS", countryId: "236" },
  { id: "4842", name: "Arizona", stateCode: "AZ", countryId: "236" },
  { id: "4843", name: "Arkansas", stateCode: "AR", countryId: "236" },
  { id: "4844", name: "Baker Island", stateCode: "UM-81", countryId: "236" },
  { id: "4845", name: "California", stateCode: "CA", countryId: "236" },
  { id: "4846", name: "Colorado", stateCode: "CO", countryId: "236" },
  { id: "4847", name: "Connecticut", stateCode: "CT", countryId: "236" },
  { id: "4848", name: "Delaware", stateCode: "DE", countryId: "236" },
  {
    id: "4849",
    name: "District of Columbia",
    stateCode: "DC",
    countryId: "236",
  },
  { id: "4850", name: "Florida", stateCode: "FL", countryId: "236" },
  { id: "4851", name: "Georgia", stateCode: "GA", countryId: "236" },
  { id: "4852", name: "Guam", stateCode: "GU", countryId: "236" },
  { id: "4853", name: "Hawaii", stateCode: "HI", countryId: "236" },
  { id: "4854", name: "Howland Island", stateCode: "UM-84", countryId: "236" },
  { id: "4855", name: "Idaho", stateCode: "ID", countryId: "236" },
  { id: "4856", name: "Illinois", stateCode: "IL", countryId: "236" },
  { id: "4857", name: "Indiana", stateCode: "IN", countryId: "236" },
  { id: "4858", name: "Iowa", stateCode: "IA", countryId: "236" },
  { id: "4859", name: "Jarvis Island", stateCode: "UM-86", countryId: "236" },
  { id: "4860", name: "Johnston Atoll", stateCode: "UM-67", countryId: "236" },
  { id: "4861", name: "Kansas", stateCode: "KS", countryId: "236" },
  { id: "4862", name: "Kentucky", stateCode: "KY", countryId: "236" },
  { id: "4863", name: "Kingman Reef", stateCode: "UM-89", countryId: "236" },
  { id: "4864", name: "Louisiana", stateCode: "LA", countryId: "236" },
  { id: "4865", name: "Maine", stateCode: "ME", countryId: "236" },
  { id: "4866", name: "Maryland", stateCode: "MD", countryId: "236" },
  { id: "4867", name: "Massachusetts", stateCode: "MA", countryId: "236" },
  { id: "4868", name: "Michigan", stateCode: "MI", countryId: "236" },
  { id: "4869", name: "Midway Atoll", stateCode: "UM-71", countryId: "236" },
  { id: "4870", name: "Minnesota", stateCode: "MN", countryId: "236" },
  { id: "4871", name: "Mississippi", stateCode: "MS", countryId: "236" },
  { id: "4872", name: "Missouri", stateCode: "MO", countryId: "236" },
  { id: "4873", name: "Montana", stateCode: "MT", countryId: "236" },
  { id: "4874", name: "Navassa Island", stateCode: "UM-76", countryId: "236" },
  { id: "4875", name: "Nebraska", stateCode: "NE", countryId: "236" },
  { id: "4876", name: "Nevada", stateCode: "NV", countryId: "236" },
  { id: "4877", name: "New Hampshire", stateCode: "NH", countryId: "236" },
  { id: "4878", name: "New Jersey", stateCode: "NJ", countryId: "236" },
  { id: "4879", name: "New Mexico", stateCode: "NM", countryId: "236" },
  { id: "4880", name: "New York", stateCode: "NY", countryId: "236" },
  { id: "4881", name: "North Carolina", stateCode: "NC", countryId: "236" },
  { id: "4882", name: "North Dakota", stateCode: "ND", countryId: "236" },
  {
    id: "4883",
    name: "Northern Mariana Islands",
    stateCode: "MP",
    countryId: "236",
  },
  { id: "4884", name: "Ohio", stateCode: "OH", countryId: "236" },
  { id: "4885", name: "Oklahoma", stateCode: "OK", countryId: "236" },
  { id: "4886", name: "Oregon", stateCode: "OR", countryId: "236" },
  { id: "4887", name: "Palmyra Atoll", stateCode: "UM-95", countryId: "236" },
  { id: "4888", name: "Pennsylvania", stateCode: "PA", countryId: "236" },
  { id: "4889", name: "Puerto Rico", stateCode: "PR", countryId: "236" },
  { id: "4890", name: "Rhode Island", stateCode: "RI", countryId: "236" },
  { id: "4891", name: "South Carolina", stateCode: "SC", countryId: "236" },
  { id: "4892", name: "South Dakota", stateCode: "SD", countryId: "236" },
  { id: "4893", name: "Tennessee", stateCode: "TN", countryId: "236" },
  { id: "4894", name: "Texas", stateCode: "TX", countryId: "236" },
  {
    id: "4895",
    name: "United States Minor Outlying Islands",
    stateCode: "UM",
    countryId: "236",
  },
  {
    id: "4896",
    name: "United States Virgin Islands",
    stateCode: "VI",
    countryId: "236",
  },
  { id: "4897", name: "Utah", stateCode: "UT", countryId: "236" },
  { id: "4898", name: "Vermont", stateCode: "VT", countryId: "236" },
  { id: "4899", name: "Virginia", stateCode: "VA", countryId: "236" },
  { id: "4900", name: "Wake Island", stateCode: "UM-79", countryId: "236" },
  { id: "4901", name: "Washington", stateCode: "WA", countryId: "236" },
  { id: "4902", name: "West Virginia", stateCode: "WV", countryId: "236" },
  { id: "4903", name: "Wisconsin", stateCode: "WI", countryId: "236" },
  { id: "4904", name: "Wyoming", stateCode: "WY", countryId: "236" },
  { id: "4905", name: "Baker Island", stateCode: "81", countryId: "237" },
  { id: "4906", name: "Howland Island", stateCode: "84", countryId: "237" },
  { id: "4907", name: "Jarvis Island", stateCode: "86", countryId: "237" },
  { id: "4908", name: "Johnston Atoll", stateCode: "67", countryId: "237" },
  { id: "4909", name: "Kingman Reef", stateCode: "89", countryId: "237" },
  { id: "4910", name: "Midway Islands", stateCode: "71", countryId: "237" },
  { id: "4911", name: "Navassa Island", stateCode: "76", countryId: "237" },
  { id: "4912", name: "Palmyra Atoll", stateCode: "95", countryId: "237" },
  { id: "4913", name: "Wake Island", stateCode: "79", countryId: "237" },
  { id: "4914", name: "Artigas", stateCode: "AR", countryId: "238" },
  { id: "4915", name: "Canelones", stateCode: "CA", countryId: "238" },
  { id: "4916", name: "Cerro Largo", stateCode: "CL", countryId: "238" },
  { id: "4917", name: "Colonia", stateCode: "CO", countryId: "238" },
  { id: "4918", name: "Durazno", stateCode: "DU", countryId: "238" },
  { id: "4919", name: "Flores", stateCode: "FS", countryId: "238" },
  { id: "4920", name: "Florida", stateCode: "FD", countryId: "238" },
  { id: "4921", name: "Lavalleja", stateCode: "LA", countryId: "238" },
  { id: "4922", name: "Maldonado", stateCode: "MA", countryId: "238" },
  { id: "4923", name: "Montevideo", stateCode: "MO", countryId: "238" },
  { id: "4924", name: "Paysandú", stateCode: "PA", countryId: "238" },
  { id: "4925", name: "Río Negro", stateCode: "RN", countryId: "238" },
  { id: "4926", name: "Rivera", stateCode: "RV", countryId: "238" },
  { id: "4927", name: "Rocha", stateCode: "RO", countryId: "238" },
  { id: "4928", name: "Salto", stateCode: "SA", countryId: "238" },
  { id: "4929", name: "San José", stateCode: "SJ", countryId: "238" },
  { id: "4930", name: "Soriano", stateCode: "SO", countryId: "238" },
  { id: "4931", name: "Tacuarembó", stateCode: "TA", countryId: "238" },
  { id: "4932", name: "Treinta y Tres", stateCode: "TT", countryId: "238" },
  { id: "4933", name: "Andijan Region", stateCode: "AN", countryId: "239" },
  { id: "4934", name: "Bukhara Region", stateCode: "BU", countryId: "239" },
  { id: "4935", name: "Fergana Region", stateCode: "FA", countryId: "239" },
  { id: "4936", name: "Jizzakh Region", stateCode: "JI", countryId: "239" },
  { id: "4937", name: "Karakalpakstan", stateCode: "QR", countryId: "239" },
  { id: "4938", name: "Namangan Region", stateCode: "NG", countryId: "239" },
  { id: "4939", name: "Navoiy Region", stateCode: "NW", countryId: "239" },
  { id: "4940", name: "Qashqadaryo Region", stateCode: "QA", countryId: "239" },
  { id: "4941", name: "Samarqand Region", stateCode: "SA", countryId: "239" },
  { id: "4942", name: "Sirdaryo Region", stateCode: "SI", countryId: "239" },
  { id: "4943", name: "Surxondaryo Region", stateCode: "SU", countryId: "239" },
  { id: "4944", name: "Tashkent", stateCode: "TK", countryId: "239" },
  { id: "4945", name: "Tashkent Region", stateCode: "TO", countryId: "239" },
  { id: "4946", name: "Xorazm Region", stateCode: "XO", countryId: "239" },
  { id: "4947", name: "Malampa", stateCode: "MAP", countryId: "240" },
  { id: "4948", name: "Penama", stateCode: "PAM", countryId: "240" },
  { id: "4949", name: "Sanma", stateCode: "SAM", countryId: "240" },
  { id: "4950", name: "Shefa", stateCode: "SEE", countryId: "240" },
  { id: "4951", name: "Tafea", stateCode: "TAE", countryId: "240" },
  { id: "4952", name: "Torba", stateCode: "TOB", countryId: "240" },
  { id: "4953", name: "Amazonas", stateCode: "Z", countryId: "242" },
  { id: "4954", name: "Anzoátegui", stateCode: "B", countryId: "242" },
  { id: "4955", name: "Apure", stateCode: "C", countryId: "242" },
  { id: "4956", name: "Aragua", stateCode: "D", countryId: "242" },
  { id: "4957", name: "Barinas", stateCode: "E", countryId: "242" },
  { id: "4958", name: "Bolívar", stateCode: "F", countryId: "242" },
  { id: "4959", name: "Carabobo", stateCode: "G", countryId: "242" },
  { id: "4960", name: "Cojedes", stateCode: "H", countryId: "242" },
  { id: "4961", name: "Delta Amacuro", stateCode: "Y", countryId: "242" },
  { id: "4962", name: "Distrito Capital", stateCode: "A", countryId: "242" },
  { id: "4963", name: "Falcón", stateCode: "I", countryId: "242" },
  {
    id: "4964",
    name: "Federal Dependencies of Venezuela",
    stateCode: "W",
    countryId: "242",
  },
  { id: "4965", name: "Guárico", stateCode: "J", countryId: "242" },
  { id: "4966", name: "La Guaira", stateCode: "X", countryId: "242" },
  { id: "4967", name: "Lara", stateCode: "K", countryId: "242" },
  { id: "4968", name: "Mérida", stateCode: "L", countryId: "242" },
  { id: "4969", name: "Miranda", stateCode: "M", countryId: "242" },
  { id: "4970", name: "Monagas", stateCode: "N", countryId: "242" },
  { id: "4971", name: "Nueva Esparta", stateCode: "O", countryId: "242" },
  { id: "4972", name: "Portuguesa", stateCode: "P", countryId: "242" },
  { id: "4973", name: "Sucre", stateCode: "R", countryId: "242" },
  { id: "4974", name: "Táchira", stateCode: "S", countryId: "242" },
  { id: "4975", name: "Trujillo", stateCode: "T", countryId: "242" },
  { id: "4976", name: "Yaracuy", stateCode: "U", countryId: "242" },
  { id: "4977", name: "Zulia", stateCode: "V", countryId: "242" },
  { id: "4978", name: "An Giang", stateCode: "44", countryId: "243" },
  { id: "4979", name: "Bà Rịa-Vũng Tàu", stateCode: "43", countryId: "243" },
  { id: "4980", name: "Bắc Giang", stateCode: "54", countryId: "243" },
  { id: "4981", name: "Bắc Kạn", stateCode: "53", countryId: "243" },
  { id: "4982", name: "Bạc Liêu", stateCode: "55", countryId: "243" },
  { id: "4983", name: "Bắc Ninh", stateCode: "56", countryId: "243" },
  { id: "4984", name: "Bến Tre", stateCode: "50", countryId: "243" },
  { id: "4985", name: "Bình Dương", stateCode: "57", countryId: "243" },
  { id: "4986", name: "Bình Định", stateCode: "31", countryId: "243" },
  { id: "4987", name: "Bình Phước", stateCode: "58", countryId: "243" },
  { id: "4988", name: "Bình Thuận", stateCode: "40", countryId: "243" },
  { id: "4989", name: "Cà Mau", stateCode: "59", countryId: "243" },
  { id: "4990", name: "Cần Thơ", stateCode: "CT", countryId: "243" },
  { id: "4991", name: "Cao Bằng", stateCode: "04", countryId: "243" },
  { id: "4992", name: "Đà Nẵng", stateCode: "DN", countryId: "243" },
  { id: "4993", name: "Đắk Lắk", stateCode: "33", countryId: "243" },
  { id: "4994", name: "Đắk Nông", stateCode: "72", countryId: "243" },
  { id: "4995", name: "Điện Biên", stateCode: "71", countryId: "243" },
  { id: "4996", name: "Đồng Nai", stateCode: "39", countryId: "243" },
  { id: "4997", name: "Đồng Tháp", stateCode: "45", countryId: "243" },
  { id: "4998", name: "Gia Lai", stateCode: "30", countryId: "243" },
  { id: "4999", name: "Hà Giang", stateCode: "03", countryId: "243" },
  { id: "5000", name: "Hà Nam", stateCode: "63", countryId: "243" },
  { id: "5001", name: "Hà Nội", stateCode: "HN", countryId: "243" },
  { id: "5002", name: "Hà Tĩnh", stateCode: "23", countryId: "243" },
  { id: "5003", name: "Hải Dương", stateCode: "61", countryId: "243" },
  { id: "5004", name: "Hải Phòng", stateCode: "HP", countryId: "243" },
  { id: "5005", name: "Hậu Giang", stateCode: "73", countryId: "243" },
  { id: "5006", name: "Hồ Chí Minh", stateCode: "SG", countryId: "243" },
  { id: "5007", name: "Hòa Bình", stateCode: "14", countryId: "243" },
  { id: "5008", name: "Hưng Yên", stateCode: "66", countryId: "243" },
  { id: "5009", name: "Khánh Hòa", stateCode: "34", countryId: "243" },
  { id: "5010", name: "Kiên Giang", stateCode: "47", countryId: "243" },
  { id: "5011", name: "Kon Tum", stateCode: "28", countryId: "243" },
  { id: "5012", name: "Lai Châu", stateCode: "01", countryId: "243" },
  { id: "5013", name: "Lâm Đồng", stateCode: "35", countryId: "243" },
  { id: "5014", name: "Lạng Sơn", stateCode: "09", countryId: "243" },
  { id: "5015", name: "Lào Cai", stateCode: "02", countryId: "243" },
  { id: "5016", name: "Long An", stateCode: "41", countryId: "243" },
  { id: "5017", name: "Nam Định", stateCode: "67", countryId: "243" },
  { id: "5018", name: "Nghệ An", stateCode: "22", countryId: "243" },
  { id: "5019", name: "Ninh Bình", stateCode: "18", countryId: "243" },
  { id: "5020", name: "Ninh Thuận", stateCode: "36", countryId: "243" },
  { id: "5021", name: "Phú Thọ", stateCode: "68", countryId: "243" },
  { id: "5022", name: "Phú Yên", stateCode: "32", countryId: "243" },
  { id: "5023", name: "Quảng Bình", stateCode: "24", countryId: "243" },
  { id: "5024", name: "Quảng Nam", stateCode: "27", countryId: "243" },
  { id: "5025", name: "Quảng Ngãi", stateCode: "29", countryId: "243" },
  { id: "5026", name: "Quảng Ninh", stateCode: "13", countryId: "243" },
  { id: "5027", name: "Quảng Trị", stateCode: "25", countryId: "243" },
  { id: "5028", name: "Sóc Trăng", stateCode: "52", countryId: "243" },
  { id: "5029", name: "Sơn La", stateCode: "05", countryId: "243" },
  { id: "5030", name: "Tây Ninh", stateCode: "37", countryId: "243" },
  { id: "5031", name: "Thái Bình", stateCode: "20", countryId: "243" },
  { id: "5032", name: "Thái Nguyên", stateCode: "69", countryId: "243" },
  { id: "5033", name: "Thanh Hóa", stateCode: "21", countryId: "243" },
  { id: "5034", name: "Thừa Thiên-Huế", stateCode: "26", countryId: "243" },
  { id: "5035", name: "Tiền Giang", stateCode: "46", countryId: "243" },
  { id: "5036", name: "Trà Vinh", stateCode: "51", countryId: "243" },
  { id: "5037", name: "Tuyên Quang", stateCode: "07", countryId: "243" },
  { id: "5038", name: "Vĩnh Long", stateCode: "49", countryId: "243" },
  { id: "5039", name: "Vĩnh Phúc", stateCode: "70", countryId: "243" },
  { id: "5040", name: "Yên Bái", stateCode: "06", countryId: "243" },
  { id: "5041", name: "Saint Croix", stateCode: "SC", countryId: "245" },
  { id: "5042", name: "Saint John", stateCode: "SJ", countryId: "245" },
  { id: "5043", name: "Saint Thomas", stateCode: "ST", countryId: "245" },
  { id: "5044", name: "\\'Adan", stateCode: "AD", countryId: "248" },
  { id: "5045", name: "\\'Amran", stateCode: "AM", countryId: "248" },
  { id: "5046", name: "Abyan", stateCode: "AB", countryId: "248" },
  { id: "5047", name: "Al Bayda\\'", stateCode: "BA", countryId: "248" },
  { id: "5048", name: "Al Hudaydah", stateCode: "HU", countryId: "248" },
  { id: "5049", name: "Al Jawf", stateCode: "JA", countryId: "248" },
  { id: "5050", name: "Al Mahrah", stateCode: "MR", countryId: "248" },
  { id: "5051", name: "Al Mahwit", stateCode: "MW", countryId: "248" },
  { id: "5052", name: "Amanat Al Asimah", stateCode: "SA", countryId: "248" },
  { id: "5053", name: "Dhamar", stateCode: "DH", countryId: "248" },
  { id: "5054", name: "Hadhramaut", stateCode: "HD", countryId: "248" },
  { id: "5055", name: "Hajjah", stateCode: "HJ", countryId: "248" },
  { id: "5056", name: "Ibb", stateCode: "IB", countryId: "248" },
  { id: "5057", name: "Lahij", stateCode: "LA", countryId: "248" },
  { id: "5058", name: "Ma\\'rib", stateCode: "MA", countryId: "248" },
  { id: "5059", name: "Raymah", stateCode: "RA", countryId: "248" },
  { id: "5060", name: "Saada", stateCode: "SD", countryId: "248" },
  { id: "5061", name: "Sana\\'a", stateCode: "SN", countryId: "248" },
  { id: "5062", name: "Shabwah", stateCode: "SH", countryId: "248" },
  { id: "5063", name: "Socotra", stateCode: "SU", countryId: "248" },
  { id: "5064", name: "Ta\\'izz", stateCode: "TA", countryId: "248" },
  { id: "5065", name: "Central Province", stateCode: "02", countryId: "249" },
  {
    id: "5066",
    name: "Copperbelt Province",
    stateCode: "08",
    countryId: "249",
  },
  { id: "5067", name: "Eastern Province", stateCode: "03", countryId: "249" },
  { id: "5068", name: "Luapula Province", stateCode: "04", countryId: "249" },
  { id: "5069", name: "Lusaka Province", stateCode: "09", countryId: "249" },
  { id: "5070", name: "Muchinga Province", stateCode: "10", countryId: "249" },
  { id: "5071", name: "Northern Province", stateCode: "05", countryId: "249" },
  {
    id: "5072",
    name: "Northwestern Province",
    stateCode: "06",
    countryId: "249",
  },
  { id: "5073", name: "Southern Province", stateCode: "07", countryId: "249" },
  { id: "5074", name: "Western Province", stateCode: "01", countryId: "249" },
  { id: "5075", name: "Bulawayo Province", stateCode: "BU", countryId: "250" },
  { id: "5076", name: "Harare Province", stateCode: "HA", countryId: "250" },
  { id: "5077", name: "Manicaland", stateCode: "MA", countryId: "250" },
  {
    id: "5078",
    name: "Mashonaland Central Province",
    stateCode: "MC",
    countryId: "250",
  },
  {
    id: "5079",
    name: "Mashonaland East Province",
    stateCode: "ME",
    countryId: "250",
  },
  {
    id: "5080",
    name: "Mashonaland West Province",
    stateCode: "MW",
    countryId: "250",
  },
  { id: "5081", name: "Masvingo Province", stateCode: "MV", countryId: "250" },
  {
    id: "5082",
    name: "Matabeleland North Province",
    stateCode: "MN",
    countryId: "250",
  },
  {
    id: "5083",
    name: "Matabeleland South Province",
    stateCode: "MS",
    countryId: "250",
  },
  { id: "5084", name: "Midlands Province", stateCode: "MI", countryId: "250" },
];
