import { useState, useEffect } from "react";
import {
  useNotify,
  useRedirect,
  useDataProvider,
  CreateBase,
} from "react-admin";
import {
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  FormHelperText,
  Grid,
  Autocomplete,
} from "@mui/material";
import AccessHoursInput from "../../components/accessHoursInput";
import {
  errorDisplayConfig,
  getErrorMessage,
} from "../../ra-lb-tools/utils/errors";
import { MapSelector } from "../../components/mapSelector";
import { convertAccessHoursToSimpleTime } from "../../components/timeFormatter";
import { AddressInfoTooltip, LocationDetails } from "./components";
import { objectMap } from "../../ra-lb-tools/utils/tools";
import { RecordTitle } from "../../ra-lb-tools/components/recordTitle";
import { MyBulkCreate } from "../../ra-lb-tools/components/myBulkCreate";

export default function LocationCreate() {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [apiKey, setApiKey] = useState(null);
  const [coordinates, setCoordinates] = useState({ lat: "", lon: "" });
  const [inputCoordinates, setInputCoordinates] = useState(coordinates);
  const [center, setCenter] = useState({ lat: 38.56903, lng: -121.492033 });
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [notes, setNotes] = useState(undefined);
  const [timezone, setTimezone] = useState("");
  const [timezones, setTimezones] = useState([]);
  const [timezonesLoaded, setTimezonesLoaded] = useState(false);

  const [accessHours, setAccessHours] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const clickedLatLng =
    coordinates.lat !== ""
      ? { lat: coordinates.lat, lng: coordinates.lon }
      : null;

  useEffect(() => {
    dataProvider
      .retrieveMapsKey()
      .then((res) => {
        if (!res["data"]["value"]) {
          notify("Empty map api key retrieved", errorDisplayConfig);
        }
        return setApiKey(res["data"]["value"]);
      })
      .catch((err) =>
        notify(
          `Error getting map api key: ${getErrorMessage(err)}`,
          errorDisplayConfig
        )
      );
    if (!timezonesLoaded) {
      dataProvider
        .getList("locations/timezone", {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "name", order: "ASC" },
          filter: {},
        })
        .then(({ data }) => {
          setTimezones(data);
          setTimezonesLoaded(true);
        })
        .catch((error) => {
          notify(`Error loading timezones: ${error.message}`, "warning");
        });
    }
  }, []);

  const getAddress = () => ({ address1, city, state, zip, country });
  const getCoordinatesActive = () =>
    Object.values(getAddress()).every((v) => v);
  const getAddressStr = () => Object.values(getAddress()).join(", ");

  const submitActive =
    getCoordinatesActive() && coordinates.lat !== "" && coordinates.lon !== "";

  const componentsMap = new Map([
    ["locality", setCity],
    ["administrative_area_level_1", setState],
    ["country", setCountry],
    ["postal_code", setZip],
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formattedAccessHours = convertAccessHoursToSimpleTime(accessHours);

    const requestData = {
      name,
      address1,
      address2,
      lat: coordinates.lat,
      lon: coordinates.lon,
      city,
      state,
      zip,
      country,
      notes,
      accessHours: formattedAccessHours,
      timezone,
    };
    dataProvider
      .create("locations", { data: requestData })
      .then(() => {
        notify("Location Created");
        redirect("/locations");
      })
      .catch((err) => {
        notify(getErrorMessage(err), errorDisplayConfig);
      });
  };

  const handleMapClick = (event) => {
    handleCoordsChange(
      { lat: event.latLng.lat(), lon: event.latLng.lng() },
      false
    );
  };

  const handleCoordsChange = (coords, centerMap = true) => {
    coords = Object.assign({}, coordinates, coords);
    const floatCoords = objectMap({ obj: coords, valueCallback: parseFloat });

    setInputCoordinates(coords);
    setCoordinates(floatCoords);
    centerMap && setCenter({ lat: floatCoords.lat, lng: floatCoords.lon });
  };

  const getFromAddress = () => {
    if (!getCoordinatesActive()) {
      notify("Fill Address fields for getting coordinates", { type: "info" });
      return;
    }
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: getAddressStr() }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        const data = results[0];
        const locationCoord = data.geometry.location;
        const addressComponents = data.address_components;
        handleCoordsChange({
          lat: locationCoord.lat(),
          lon: locationCoord.lng(),
        });
        setLocationExtraData(addressComponents);
      } else {
        notify(`Error getting coordinates: ${status}`, errorDisplayConfig);
      }
    });
  };

  const getNameFromLocaitonComponents = (type, components) => {
    const result = components.filter((item) => item.types.includes(type));
    if (result.length > 0) {
      const item = result[0];
      return item.long_name;
    }
  };

  const setLocationExtraData = (components) => {
    for (const key of componentsMap.keys()) {
      const name = getNameFromLocaitonComponents(key, components);
      if (name != null || name != undefined) {
        const fn = componentsMap.get(key);
        fn(name);
      }
    }
  };

  return (
    <>
      <CreateBase>
        <RecordTitle />
      </CreateBase>
      <Card
        className="LocationCreate"
        sx={{ minWidth: 275, marginTop: 20, overflow: "auto", height: "100%" }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <h3>Address</h3>
              <TextField
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                value={name}
                onChange={(event) => setName(event.target.value)}
                style={{ margin: "5px" }}
              />
              <TextField
                id="address1"
                name="address1"
                label="Address1"
                variant="outlined"
                value={address1}
                onChange={(event) => setAddress1(event.target.value)}
                style={{ margin: "5px" }}
              />
              <TextField
                id="address2"
                name="address2"
                label="Address2"
                variant="outlined"
                value={address2}
                onChange={(event) => setAddress2(event.target.value)}
                style={{ margin: "5px" }}
              />
              <div className="fieldGroup">
                <h3>Location Details</h3>
                <LocationDetails
                  {...{ country, state, setCountry, setState }}
                />
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  variant="outlined"
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  style={{ margin: "5px" }}
                />
                <TextField
                  id="zip"
                  name="zip"
                  label="Zip"
                  variant="outlined"
                  value={zip}
                  onChange={(event) => setZip(event.target.value)}
                  style={{ margin: "5px" }}
                />
              </div>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "15px" }}>
              {apiKey !== null ? (
                <div>
                  <MapSelector
                    apiKey={apiKey}
                    handleMapClick={handleMapClick}
                    clickedLatLng={clickedLatLng}
                    center={center}
                  />
                  <FormHelperText>
                    Once pin appears on map - You can click exact location on
                    map to move pin if needed.
                  </FormHelperText>
                </div>
              ) : null}
              <h3>Coordinates</h3>
              <TextField
                id="lat"
                name="lat"
                label="Latitude"
                variant="outlined"
                value={inputCoordinates.lat}
                style={{ margin: "5px" }}
                onChange={(event) =>
                  handleCoordsChange({ lat: event.target.value })
                }
              />
              <TextField
                id="lon"
                name="lon"
                label="Longitude"
                variant="outlined"
                value={inputCoordinates.lon}
                style={{ margin: "5px" }}
                onChange={(event) =>
                  handleCoordsChange({ lon: event.target.value })
                }
              />
              <div className="fieldGroup" style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  onClick={getFromAddress}
                  color={getCoordinatesActive() ? "secondary" : "inherit"}
                  style={{ color: "black" }}
                  disabled={!getCoordinatesActive()}
                >
                  Get coordinates from address
                </Button>
                <AddressInfoTooltip address={getAddress()} />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <h3>Timezone</h3>
            <Autocomplete
              value={timezones.find((tz) => tz.name === timezone) || null}
              onChange={(event, newValue) => {
                setTimezone(newValue.name);
              }}
              options={timezones}
              getOptionLabel={(option) => option.name || ""}
              getOptionSelected={(option, value) => option.name === value.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  id="timezone-select"
                  fullWidth
                  style={{ marginBottom: "10px" }}
                />
              )}
            />
          </Grid>
          <AccessHoursInput
            accessHours={accessHours}
            setAccessHours={setAccessHours}
          />

          <h3>Internal Notes</h3>
          <TextField
            id="notes"
            name="notes"
            label="Notes"
            variant="outlined"
            value={notes}
            multiline
            fullWidth
            onChange={(event) => setNotes(event.target.value)}
            style={{ marginBottom: "20px" }}
          />
        </CardContent>
        <CardActions>
          &nbsp;
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={apiKey === null || !submitActive}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

export const LocationBulkCreate = () => {
  const schema = [
    {
      name: "string",
      address1: "string",
      city: "string",
      state: "string",
      zip: "string",
      country: "string",
      lat: 0,
      lon: 0,
      timezone: "string",
      address2: "string",
      description: "string",
      notes: "string",
      accessHours: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
    },
  ];

  return (
    <MyBulkCreate
      resource="locations"
      label="JSON Location List"
      schema={schema}
      requiredFields={9}
    />
  );
};
