//https://github.com/mustafasolak/country_state_city/blob/main/countries.json

export const countries = [
  {
    id: "1",
    name: "Afghanistan",
    phoneCode: "93",
    emojiU: "U+1F1E6 U+1F1EB",
    native: "افغانستان",
  },
  {
    id: "2",
    name: "Aland Islands",
    phoneCode: "+358-18",
    emojiU: "U+1F1E6 U+1F1FD",
    native: "Åland",
  },
  {
    id: "3",
    name: "Albania",
    phoneCode: "355",
    emojiU: "U+1F1E6 U+1F1F1",
    native: "Shqipëria",
  },
  {
    id: "4",
    name: "Algeria",
    phoneCode: "213",
    emojiU: "U+1F1E9 U+1F1FF",
    native: "الجزائر",
  },
  {
    id: "5",
    name: "American Samoa",
    phoneCode: "+1-684",
    emojiU: "U+1F1E6 U+1F1F8",
    native: "American Samoa",
  },
  {
    id: "6",
    name: "Andorra",
    phoneCode: "376",
    emojiU: "U+1F1E6 U+1F1E9",
    native: "Andorra",
  },
  {
    id: "7",
    name: "Angola",
    phoneCode: "244",
    emojiU: "U+1F1E6 U+1F1F4",
    native: "Angola",
  },
  {
    id: "8",
    name: "Anguilla",
    phoneCode: "+1-264",
    emojiU: "U+1F1E6 U+1F1EE",
    native: "Anguilla",
  },
  {
    id: "9",
    name: "Antarctica",
    phoneCode: "672",
    emojiU: "U+1F1E6 U+1F1F6",
    native: "Antarctica",
  },
  {
    id: "10",
    name: "Antigua And Barbuda",
    phoneCode: "+1-268",
    emojiU: "U+1F1E6 U+1F1EC",
    native: "Antigua and Barbuda",
  },
  {
    id: "11",
    name: "Argentina",
    phoneCode: "54",
    emojiU: "U+1F1E6 U+1F1F7",
    native: "Argentina",
  },
  {
    id: "12",
    name: "Armenia",
    phoneCode: "374",
    emojiU: "U+1F1E6 U+1F1F2",
    native: "Հայաստան",
  },
  {
    id: "13",
    name: "Aruba",
    phoneCode: "297",
    emojiU: "U+1F1E6 U+1F1FC",
    native: "Aruba",
  },
  {
    id: "14",
    name: "Australia",
    phoneCode: "61",
    emojiU: "U+1F1E6 U+1F1FA",
    native: "Australia",
  },
  {
    id: "15",
    name: "Austria",
    phoneCode: "43",
    emojiU: "U+1F1E6 U+1F1F9",
    native: "Österreich",
  },
  {
    id: "16",
    name: "Azerbaijan",
    phoneCode: "994",
    emojiU: "U+1F1E6 U+1F1FF",
    native: "Azərbaycan",
  },
  {
    id: "17",
    name: "Bahrain",
    phoneCode: "973",
    emojiU: "U+1F1E7 U+1F1ED",
    native: "‏البحرين",
  },
  {
    id: "18",
    name: "Bangladesh",
    phoneCode: "880",
    emojiU: "U+1F1E7 U+1F1E9",
    native: "Bangladesh",
  },
  {
    id: "19",
    name: "Barbados",
    phoneCode: "+1-246",
    emojiU: "U+1F1E7 U+1F1E7",
    native: "Barbados",
  },
  {
    id: "20",
    name: "Belarus",
    phoneCode: "375",
    emojiU: "U+1F1E7 U+1F1FE",
    native: "Белару́сь",
  },
  {
    id: "21",
    name: "Belgium",
    phoneCode: "32",
    emojiU: "U+1F1E7 U+1F1EA",
    native: "België",
  },
  {
    id: "22",
    name: "Belize",
    phoneCode: "501",
    emojiU: "U+1F1E7 U+1F1FF",
    native: "Belize",
  },
  {
    id: "23",
    name: "Benin",
    phoneCode: "229",
    emojiU: "U+1F1E7 U+1F1EF",
    native: "Bénin",
  },
  {
    id: "24",
    name: "Bermuda",
    phoneCode: "+1-441",
    emojiU: "U+1F1E7 U+1F1F2",
    native: "Bermuda",
  },
  {
    id: "25",
    name: "Bhutan",
    phoneCode: "975",
    emojiU: "U+1F1E7 U+1F1F9",
    native: "ʼbrug-yul",
  },
  {
    id: "26",
    name: "Bolivia",
    phoneCode: "591",
    emojiU: "U+1F1E7 U+1F1F4",
    native: "Bolivia",
  },
  {
    id: "27",
    name: "Bonaire, Sint Eustatius and Saba",
    phoneCode: "599",
    emojiU: "U+1F1E7 U+1F1F6",
    native: "Caribisch Nederland",
  },
  {
    id: "28",
    name: "Bosnia and Herzegovina",
    phoneCode: "387",
    emojiU: "U+1F1E7 U+1F1E6",
    native: "Bosna i Hercegovina",
  },
  {
    id: "29",
    name: "Botswana",
    phoneCode: "267",
    emojiU: "U+1F1E7 U+1F1FC",
    native: "Botswana",
  },
  {
    id: "30",
    name: "Bouvet Island",
    phoneCode: "0055",
    emojiU: "U+1F1E7 U+1F1FB",
    native: "Bouvetøya",
  },
  {
    id: "31",
    name: "Brazil",
    phoneCode: "55",
    emojiU: "U+1F1E7 U+1F1F7",
    native: "Brasil",
  },
  {
    id: "32",
    name: "British Indian Ocean Territory",
    phoneCode: "246",
    emojiU: "U+1F1EE U+1F1F4",
    native: "British Indian Ocean Territory",
  },
  {
    id: "33",
    name: "Brunei",
    phoneCode: "673",
    emojiU: "U+1F1E7 U+1F1F3",
    native: "Negara Brunei Darussalam",
  },
  {
    id: "34",
    name: "Bulgaria",
    phoneCode: "359",
    emojiU: "U+1F1E7 U+1F1EC",
    native: "България",
  },
  {
    id: "35",
    name: "Burkina Faso",
    phoneCode: "226",
    emojiU: "U+1F1E7 U+1F1EB",
    native: "Burkina Faso",
  },
  {
    id: "36",
    name: "Burundi",
    phoneCode: "257",
    emojiU: "U+1F1E7 U+1F1EE",
    native: "Burundi",
  },
  {
    id: "37",
    name: "Cambodia",
    phoneCode: "855",
    emojiU: "U+1F1F0 U+1F1ED",
    native: "Kâmpŭchéa",
  },
  {
    id: "38",
    name: "Cameroon",
    phoneCode: "237",
    emojiU: "U+1F1E8 U+1F1F2",
    native: "Cameroon",
  },
  {
    id: "39",
    name: "Canada",
    phoneCode: "1",
    emojiU: "U+1F1E8 U+1F1E6",
    native: "Canada",
  },
  {
    id: "40",
    name: "Cape Verde",
    phoneCode: "238",
    emojiU: "U+1F1E8 U+1F1FB",
    native: "Cabo Verde",
  },
  {
    id: "41",
    name: "Cayman Islands",
    phoneCode: "+1-345",
    emojiU: "U+1F1F0 U+1F1FE",
    native: "Cayman Islands",
  },
  {
    id: "42",
    name: "Central African Republic",
    phoneCode: "236",
    emojiU: "U+1F1E8 U+1F1EB",
    native: "Ködörösêse tî Bêafrîka",
  },
  {
    id: "43",
    name: "Chad",
    phoneCode: "235",
    emojiU: "U+1F1F9 U+1F1E9",
    native: "Tchad",
  },
  {
    id: "44",
    name: "Chile",
    phoneCode: "56",
    emojiU: "U+1F1E8 U+1F1F1",
    native: "Chile",
  },
  {
    id: "45",
    name: "China",
    phoneCode: "86",
    emojiU: "U+1F1E8 U+1F1F3",
    native: "中国",
  },
  {
    id: "46",
    name: "Christmas Island",
    phoneCode: "61",
    emojiU: "U+1F1E8 U+1F1FD",
    native: "Christmas Island",
  },
  {
    id: "47",
    name: "Cocos (Keeling) Islands",
    phoneCode: "61",
    emojiU: "U+1F1E8 U+1F1E8",
    native: "Cocos (Keeling) Islands",
  },
  {
    id: "48",
    name: "Colombia",
    phoneCode: "57",
    emojiU: "U+1F1E8 U+1F1F4",
    native: "Colombia",
  },
  {
    id: "49",
    name: "Comoros",
    phoneCode: "269",
    emojiU: "U+1F1F0 U+1F1F2",
    native: "Komori",
  },
  {
    id: "50",
    name: "Congo",
    phoneCode: "242",
    emojiU: "U+1F1E8 U+1F1EC",
    native: "République du Congo",
  },
  {
    id: "51",
    name: "Cook Islands",
    phoneCode: "682",
    emojiU: "U+1F1E8 U+1F1F0",
    native: "Cook Islands",
  },
  {
    id: "52",
    name: "Costa Rica",
    phoneCode: "506",
    emojiU: "U+1F1E8 U+1F1F7",
    native: "Costa Rica",
  },
  {
    id: "53",
    name: "Cote D\\'Ivoire (Ivory Coast)",
    phoneCode: "225",
    emojiU: "U+1F1E8 U+1F1EE",
    native: "",
  },
  {
    id: "54",
    name: "Croatia",
    phoneCode: "385",
    emojiU: "U+1F1ED U+1F1F7",
    native: "Hrvatska",
  },
  {
    id: "55",
    name: "Cuba",
    phoneCode: "53",
    emojiU: "U+1F1E8 U+1F1FA",
    native: "Cuba",
  },
  {
    id: "56",
    name: "Curaçao",
    phoneCode: "599",
    emojiU: "U+1F1E8 U+1F1FC",
    native: "Curaçao",
  },
  {
    id: "57",
    name: "Cyprus",
    phoneCode: "357",
    emojiU: "U+1F1E8 U+1F1FE",
    native: "Κύπρος",
  },
  {
    id: "58",
    name: "Czech Republic",
    phoneCode: "420",
    emojiU: "U+1F1E8 U+1F1FF",
    native: "Česká republika",
  },
  {
    id: "59",
    name: "Democratic Republic of the Congo",
    phoneCode: "243",
    emojiU: "U+1F1E8 U+1F1E9",
    native: "République démocratique du Congo",
  },
  {
    id: "60",
    name: "Denmark",
    phoneCode: "45",
    emojiU: "U+1F1E9 U+1F1F0",
    native: "Danmark",
  },
  {
    id: "61",
    name: "Djibouti",
    phoneCode: "253",
    emojiU: "U+1F1E9 U+1F1EF",
    native: "Djibouti",
  },
  {
    id: "62",
    name: "Dominica",
    phoneCode: "+1-767",
    emojiU: "U+1F1E9 U+1F1F2",
    native: "Dominica",
  },
  {
    id: "63",
    name: "Dominican Republic",
    phoneCode: "+1-809 and 1-829",
    emojiU: "U+1F1E9 U+1F1F4",
    native: "República Dominicana",
  },
  {
    id: "64",
    name: "East Timor",
    phoneCode: "670",
    emojiU: "U+1F1F9 U+1F1F1",
    native: "Timor-Leste",
  },
  {
    id: "65",
    name: "Ecuador",
    phoneCode: "593",
    emojiU: "U+1F1EA U+1F1E8",
    native: "Ecuador",
  },
  {
    id: "66",
    name: "Egypt",
    phoneCode: "20",
    emojiU: "U+1F1EA U+1F1EC",
    native: "مصر‎",
  },
  {
    id: "67",
    name: "El Salvador",
    phoneCode: "503",
    emojiU: "U+1F1F8 U+1F1FB",
    native: "El Salvador",
  },
  {
    id: "68",
    name: "Equatorial Guinea",
    phoneCode: "240",
    emojiU: "U+1F1EC U+1F1F6",
    native: "Guinea Ecuatorial",
  },
  {
    id: "69",
    name: "Eritrea",
    phoneCode: "291",
    emojiU: "U+1F1EA U+1F1F7",
    native: "ኤርትራ",
  },
  {
    id: "70",
    name: "Estonia",
    phoneCode: "372",
    emojiU: "U+1F1EA U+1F1EA",
    native: "Eesti",
  },
  {
    id: "71",
    name: "Ethiopia",
    phoneCode: "251",
    emojiU: "U+1F1EA U+1F1F9",
    native: "ኢትዮጵያ",
  },
  {
    id: "72",
    name: "Falkland Islands",
    phoneCode: "500",
    emojiU: "U+1F1EB U+1F1F0",
    native: "Falkland Islands",
  },
  {
    id: "73",
    name: "Faroe Islands",
    phoneCode: "298",
    emojiU: "U+1F1EB U+1F1F4",
    native: "Føroyar",
  },
  {
    id: "74",
    name: "Fiji Islands",
    phoneCode: "679",
    emojiU: "U+1F1EB U+1F1EF",
    native: "Fiji",
  },
  {
    id: "75",
    name: "Finland",
    phoneCode: "358",
    emojiU: "U+1F1EB U+1F1EE",
    native: "Suomi",
  },
  {
    id: "76",
    name: "France",
    phoneCode: "33",
    emojiU: "U+1F1EB U+1F1F7",
    native: "France",
  },
  {
    id: "77",
    name: "French Guiana",
    phoneCode: "594",
    emojiU: "U+1F1EC U+1F1EB",
    native: "Guyane française",
  },
  {
    id: "78",
    name: "French Polynesia",
    phoneCode: "689",
    emojiU: "U+1F1F5 U+1F1EB",
    native: "Polynésie française",
  },
  {
    id: "79",
    name: "French Southern Territories",
    phoneCode: "262",
    emojiU: "U+1F1F9 U+1F1EB",
    native: "Territoire des Terres australes et antarctiques fr",
  },
  {
    id: "80",
    name: "Gabon",
    phoneCode: "241",
    emojiU: "U+1F1EC U+1F1E6",
    native: "Gabon",
  },
  {
    id: "81",
    name: "Gambia The",
    phoneCode: "220",
    emojiU: "U+1F1EC U+1F1F2",
    native: "Gambia",
  },
  {
    id: "82",
    name: "Georgia",
    phoneCode: "995",
    emojiU: "U+1F1EC U+1F1EA",
    native: "საქართველო",
  },
  {
    id: "83",
    name: "Germany",
    phoneCode: "49",
    emojiU: "U+1F1E9 U+1F1EA",
    native: "Deutschland",
  },
  {
    id: "84",
    name: "Ghana",
    phoneCode: "233",
    emojiU: "U+1F1EC U+1F1ED",
    native: "Ghana",
  },
  {
    id: "85",
    name: "Gibraltar",
    phoneCode: "350",
    emojiU: "U+1F1EC U+1F1EE",
    native: "Gibraltar",
  },
  {
    id: "86",
    name: "Greece",
    phoneCode: "30",
    emojiU: "U+1F1EC U+1F1F7",
    native: "Ελλάδα",
  },
  {
    id: "87",
    name: "Greenland",
    phoneCode: "299",
    emojiU: "U+1F1EC U+1F1F1",
    native: "Kalaallit Nunaat",
  },
  {
    id: "88",
    name: "Grenada",
    phoneCode: "+1-473",
    emojiU: "U+1F1EC U+1F1E9",
    native: "Grenada",
  },
  {
    id: "89",
    name: "Guadeloupe",
    phoneCode: "590",
    emojiU: "U+1F1EC U+1F1F5",
    native: "Guadeloupe",
  },
  {
    id: "90",
    name: "Guam",
    phoneCode: "+1-671",
    emojiU: "U+1F1EC U+1F1FA",
    native: "Guam",
  },
  {
    id: "91",
    name: "Guatemala",
    phoneCode: "502",
    emojiU: "U+1F1EC U+1F1F9",
    native: "Guatemala",
  },
  {
    id: "92",
    name: "Guernsey and Alderney",
    phoneCode: "+44-1481",
    emojiU: "U+1F1EC U+1F1EC",
    native: "Guernsey",
  },
  {
    id: "93",
    name: "Guinea",
    phoneCode: "224",
    emojiU: "U+1F1EC U+1F1F3",
    native: "Guinée",
  },
  {
    id: "94",
    name: "Guinea-Bissau",
    phoneCode: "245",
    emojiU: "U+1F1EC U+1F1FC",
    native: "Guiné-Bissau",
  },
  {
    id: "95",
    name: "Guyana",
    phoneCode: "592",
    emojiU: "U+1F1EC U+1F1FE",
    native: "Guyana",
  },
  {
    id: "96",
    name: "Haiti",
    phoneCode: "509",
    emojiU: "U+1F1ED U+1F1F9",
    native: "Haïti",
  },
  {
    id: "97",
    name: "Heard Island and McDonald Islands",
    phoneCode: "672",
    emojiU: "U+1F1ED U+1F1F2",
    native: "Heard Island and McDonald Islands",
  },
  {
    id: "98",
    name: "Honduras",
    phoneCode: "504",
    emojiU: "U+1F1ED U+1F1F3",
    native: "Honduras",
  },
  {
    id: "99",
    name: "Hong Kong S.A.R.",
    phoneCode: "852",
    emojiU: "U+1F1ED U+1F1F0",
    native: "香港",
  },
  {
    id: "100",
    name: "Hungary",
    phoneCode: "36",
    emojiU: "U+1F1ED U+1F1FA",
    native: "Magyarország",
  },
  {
    id: "101",
    name: "Iceland",
    phoneCode: "354",
    emojiU: "U+1F1EE U+1F1F8",
    native: "Ísland",
  },
  {
    id: "102",
    name: "India",
    phoneCode: "91",
    emojiU: "U+1F1EE U+1F1F3",
    native: "भारत",
  },
  {
    id: "103",
    name: "Indonesia",
    phoneCode: "62",
    emojiU: "U+1F1EE U+1F1E9",
    native: "Indonesia",
  },
  {
    id: "104",
    name: "Iran",
    phoneCode: "98",
    emojiU: "U+1F1EE U+1F1F7",
    native: "ایران",
  },
  {
    id: "105",
    name: "Iraq",
    phoneCode: "964",
    emojiU: "U+1F1EE U+1F1F6",
    native: "العراق",
  },
  {
    id: "106",
    name: "Ireland",
    phoneCode: "353",
    emojiU: "U+1F1EE U+1F1EA",
    native: "Éire",
  },
  {
    id: "107",
    name: "Israel",
    phoneCode: "972",
    emojiU: "U+1F1EE U+1F1F1",
    native: "יִשְׂרָאֵל",
  },
  {
    id: "108",
    name: "Italy",
    phoneCode: "39",
    emojiU: "U+1F1EE U+1F1F9",
    native: "Italia",
  },
  {
    id: "109",
    name: "Jamaica",
    phoneCode: "+1-876",
    emojiU: "U+1F1EF U+1F1F2",
    native: "Jamaica",
  },
  {
    id: "110",
    name: "Japan",
    phoneCode: "81",
    emojiU: "U+1F1EF U+1F1F5",
    native: "日本",
  },
  {
    id: "111",
    name: "Jersey",
    phoneCode: "+44-1534",
    emojiU: "U+1F1EF U+1F1EA",
    native: "Jersey",
  },
  {
    id: "112",
    name: "Jordan",
    phoneCode: "962",
    emojiU: "U+1F1EF U+1F1F4",
    native: "الأردن",
  },
  {
    id: "113",
    name: "Kazakhstan",
    phoneCode: "7",
    emojiU: "U+1F1F0 U+1F1FF",
    native: "Қазақстан",
  },
  {
    id: "114",
    name: "Kenya",
    phoneCode: "254",
    emojiU: "U+1F1F0 U+1F1EA",
    native: "Kenya",
  },
  {
    id: "115",
    name: "Kiribati",
    phoneCode: "686",
    emojiU: "U+1F1F0 U+1F1EE",
    native: "Kiribati",
  },
  {
    id: "116",
    name: "Kosovo",
    phoneCode: "383",
    emojiU: "U+1F1FD U+1F1F0",
    native: "Republika e Kosovës",
  },
  {
    id: "117",
    name: "Kuwait",
    phoneCode: "965",
    emojiU: "U+1F1F0 U+1F1FC",
    native: "الكويت",
  },
  {
    id: "118",
    name: "Kyrgyzstan",
    phoneCode: "996",
    emojiU: "U+1F1F0 U+1F1EC",
    native: "Кыргызстан",
  },
  {
    id: "119",
    name: "Laos",
    phoneCode: "856",
    emojiU: "U+1F1F1 U+1F1E6",
    native: "ສປປລາວ",
  },
  {
    id: "120",
    name: "Latvia",
    phoneCode: "371",
    emojiU: "U+1F1F1 U+1F1FB",
    native: "Latvija",
  },
  {
    id: "121",
    name: "Lebanon",
    phoneCode: "961",
    emojiU: "U+1F1F1 U+1F1E7",
    native: "لبنان",
  },
  {
    id: "122",
    name: "Lesotho",
    phoneCode: "266",
    emojiU: "U+1F1F1 U+1F1F8",
    native: "Lesotho",
  },
  {
    id: "123",
    name: "Liberia",
    phoneCode: "231",
    emojiU: "U+1F1F1 U+1F1F7",
    native: "Liberia",
  },
  {
    id: "124",
    name: "Libya",
    phoneCode: "218",
    emojiU: "U+1F1F1 U+1F1FE",
    native: "‏ليبيا",
  },
  {
    id: "125",
    name: "Liechtenstein",
    phoneCode: "423",
    emojiU: "U+1F1F1 U+1F1EE",
    native: "Liechtenstein",
  },
  {
    id: "126",
    name: "Lithuania",
    phoneCode: "370",
    emojiU: "U+1F1F1 U+1F1F9",
    native: "Lietuva",
  },
  {
    id: "127",
    name: "Luxembourg",
    phoneCode: "352",
    emojiU: "U+1F1F1 U+1F1FA",
    native: "Luxembourg",
  },
  {
    id: "128",
    name: "Macau S.A.R.",
    phoneCode: "853",
    emojiU: "U+1F1F2 U+1F1F4",
    native: "澳門",
  },
  {
    id: "129",
    name: "Madagascar",
    phoneCode: "261",
    emojiU: "U+1F1F2 U+1F1EC",
    native: "Madagasikara",
  },
  {
    id: "130",
    name: "Malawi",
    phoneCode: "265",
    emojiU: "U+1F1F2 U+1F1FC",
    native: "Malawi",
  },
  {
    id: "131",
    name: "Malaysia",
    phoneCode: "60",
    emojiU: "U+1F1F2 U+1F1FE",
    native: "Malaysia",
  },
  {
    id: "132",
    name: "Maldives",
    phoneCode: "960",
    emojiU: "U+1F1F2 U+1F1FB",
    native: "Maldives",
  },
  {
    id: "133",
    name: "Mali",
    phoneCode: "223",
    emojiU: "U+1F1F2 U+1F1F1",
    native: "Mali",
  },
  {
    id: "134",
    name: "Malta",
    phoneCode: "356",
    emojiU: "U+1F1F2 U+1F1F9",
    native: "Malta",
  },
  {
    id: "135",
    name: "Man (Isle of)",
    phoneCode: "+44-1624",
    emojiU: "U+1F1EE U+1F1F2",
    native: "Isle of Man",
  },
  {
    id: "136",
    name: "Marshall Islands",
    phoneCode: "692",
    emojiU: "U+1F1F2 U+1F1ED",
    native: "M̧ajeļ",
  },
  {
    id: "137",
    name: "Martinique",
    phoneCode: "596",
    emojiU: "U+1F1F2 U+1F1F6",
    native: "Martinique",
  },
  {
    id: "138",
    name: "Mauritania",
    phoneCode: "222",
    emojiU: "U+1F1F2 U+1F1F7",
    native: "موريتانيا",
  },
  {
    id: "139",
    name: "Mauritius",
    phoneCode: "230",
    emojiU: "U+1F1F2 U+1F1FA",
    native: "Maurice",
  },
  {
    id: "140",
    name: "Mayotte",
    phoneCode: "262",
    emojiU: "U+1F1FE U+1F1F9",
    native: "Mayotte",
  },
  {
    id: "141",
    name: "Mexico",
    phoneCode: "52",
    emojiU: "U+1F1F2 U+1F1FD",
    native: "México",
  },
  {
    id: "142",
    name: "Micronesia",
    phoneCode: "691",
    emojiU: "U+1F1EB U+1F1F2",
    native: "Micronesia",
  },
  {
    id: "143",
    name: "Moldova",
    phoneCode: "373",
    emojiU: "U+1F1F2 U+1F1E9",
    native: "Moldova",
  },
  {
    id: "144",
    name: "Monaco",
    phoneCode: "377",
    emojiU: "U+1F1F2 U+1F1E8",
    native: "Monaco",
  },
  {
    id: "145",
    name: "Mongolia",
    phoneCode: "976",
    emojiU: "U+1F1F2 U+1F1F3",
    native: "Монгол улс",
  },
  {
    id: "146",
    name: "Montenegro",
    phoneCode: "382",
    emojiU: "U+1F1F2 U+1F1EA",
    native: "Црна Гора",
  },
  {
    id: "147",
    name: "Montserrat",
    phoneCode: "+1-664",
    emojiU: "U+1F1F2 U+1F1F8",
    native: "Montserrat",
  },
  {
    id: "148",
    name: "Morocco",
    phoneCode: "212",
    emojiU: "U+1F1F2 U+1F1E6",
    native: "المغرب",
  },
  {
    id: "149",
    name: "Mozambique",
    phoneCode: "258",
    emojiU: "U+1F1F2 U+1F1FF",
    native: "Moçambique",
  },
  {
    id: "150",
    name: "Myanmar",
    phoneCode: "95",
    emojiU: "U+1F1F2 U+1F1F2",
    native: "မြန်မာ",
  },
  {
    id: "151",
    name: "Namibia",
    phoneCode: "264",
    emojiU: "U+1F1F3 U+1F1E6",
    native: "Namibia",
  },
  {
    id: "152",
    name: "Nauru",
    phoneCode: "674",
    emojiU: "U+1F1F3 U+1F1F7",
    native: "Nauru",
  },
  {
    id: "153",
    name: "Nepal",
    phoneCode: "977",
    emojiU: "U+1F1F3 U+1F1F5",
    native: "नपल",
  },
  {
    id: "154",
    name: "Netherlands",
    phoneCode: "31",
    emojiU: "U+1F1F3 U+1F1F1",
    native: "Nederland",
  },
  {
    id: "155",
    name: "New Caledonia",
    phoneCode: "687",
    emojiU: "U+1F1F3 U+1F1E8",
    native: "Nouvelle-Calédonie",
  },
  {
    id: "156",
    name: "New Zealand",
    phoneCode: "64",
    emojiU: "U+1F1F3 U+1F1FF",
    native: "New Zealand",
  },
  {
    id: "157",
    name: "Nicaragua",
    phoneCode: "505",
    emojiU: "U+1F1F3 U+1F1EE",
    native: "Nicaragua",
  },
  {
    id: "158",
    name: "Niger",
    phoneCode: "227",
    emojiU: "U+1F1F3 U+1F1EA",
    native: "Niger",
  },
  {
    id: "159",
    name: "Nigeria",
    phoneCode: "234",
    emojiU: "U+1F1F3 U+1F1EC",
    native: "Nigeria",
  },
  {
    id: "160",
    name: "Niue",
    phoneCode: "683",
    emojiU: "U+1F1F3 U+1F1FA",
    native: "Niuē",
  },
  {
    id: "161",
    name: "Norfolk Island",
    phoneCode: "672",
    emojiU: "U+1F1F3 U+1F1EB",
    native: "Norfolk Island",
  },
  {
    id: "162",
    name: "North Korea",
    phoneCode: "850",
    emojiU: "U+1F1F0 U+1F1F5",
    native: "북한",
  },
  {
    id: "163",
    name: "North Macedonia",
    phoneCode: "389",
    emojiU: "U+1F1F2 U+1F1F0",
    native: "Северна Македонија",
  },
  {
    id: "164",
    name: "Northern Mariana Islands",
    phoneCode: "+1-670",
    emojiU: "U+1F1F2 U+1F1F5",
    native: "Northern Mariana Islands",
  },
  {
    id: "165",
    name: "Norway",
    phoneCode: "47",
    emojiU: "U+1F1F3 U+1F1F4",
    native: "Norge",
  },
  {
    id: "166",
    name: "Oman",
    phoneCode: "968",
    emojiU: "U+1F1F4 U+1F1F2",
    native: "عمان",
  },
  {
    id: "167",
    name: "Pakistan",
    phoneCode: "92",
    emojiU: "U+1F1F5 U+1F1F0",
    native: "Pakistan",
  },
  {
    id: "168",
    name: "Palau",
    phoneCode: "680",
    emojiU: "U+1F1F5 U+1F1FC",
    native: "Palau",
  },
  {
    id: "169",
    name: "Palestinian Territory Occupied",
    phoneCode: "970",
    emojiU: "U+1F1F5 U+1F1F8",
    native: "فلسطين",
  },
  {
    id: "170",
    name: "Panama",
    phoneCode: "507",
    emojiU: "U+1F1F5 U+1F1E6",
    native: "Panamá",
  },
  {
    id: "171",
    name: "Papua new Guinea",
    phoneCode: "675",
    emojiU: "U+1F1F5 U+1F1EC",
    native: "Papua Niugini",
  },
  {
    id: "172",
    name: "Paraguay",
    phoneCode: "595",
    emojiU: "U+1F1F5 U+1F1FE",
    native: "Paraguay",
  },
  {
    id: "173",
    name: "Peru",
    phoneCode: "51",
    emojiU: "U+1F1F5 U+1F1EA",
    native: "Perú",
  },
  {
    id: "174",
    name: "Philippines",
    phoneCode: "63",
    emojiU: "U+1F1F5 U+1F1ED",
    native: "Pilipinas",
  },
  {
    id: "175",
    name: "Pitcairn Island",
    phoneCode: "870",
    emojiU: "U+1F1F5 U+1F1F3",
    native: "Pitcairn Islands",
  },
  {
    id: "176",
    name: "Poland",
    phoneCode: "48",
    emojiU: "U+1F1F5 U+1F1F1",
    native: "Polska",
  },
  {
    id: "177",
    name: "Portugal",
    phoneCode: "351",
    emojiU: "U+1F1F5 U+1F1F9",
    native: "Portugal",
  },
  {
    id: "178",
    name: "Puerto Rico",
    phoneCode: "+1-787 and 1-939",
    emojiU: "U+1F1F5 U+1F1F7",
    native: "Puerto Rico",
  },
  {
    id: "179",
    name: "Qatar",
    phoneCode: "974",
    emojiU: "U+1F1F6 U+1F1E6",
    native: "قطر",
  },
  {
    id: "180",
    name: "Reunion",
    phoneCode: "262",
    emojiU: "U+1F1F7 U+1F1EA",
    native: "La Réunion",
  },
  {
    id: "181",
    name: "Romania",
    phoneCode: "40",
    emojiU: "U+1F1F7 U+1F1F4",
    native: "România",
  },
  {
    id: "182",
    name: "Russia",
    phoneCode: "7",
    emojiU: "U+1F1F7 U+1F1FA",
    native: "Россия",
  },
  {
    id: "183",
    name: "Rwanda",
    phoneCode: "250",
    emojiU: "U+1F1F7 U+1F1FC",
    native: "Rwanda",
  },
  {
    id: "184",
    name: "Saint Helena",
    phoneCode: "290",
    emojiU: "U+1F1F8 U+1F1ED",
    native: "Saint Helena",
  },
  {
    id: "185",
    name: "Saint Kitts And Nevis",
    phoneCode: "+1-869",
    emojiU: "U+1F1F0 U+1F1F3",
    native: "Saint Kitts and Nevis",
  },
  {
    id: "186",
    name: "Saint Lucia",
    phoneCode: "+1-758",
    emojiU: "U+1F1F1 U+1F1E8",
    native: "Saint Lucia",
  },
  {
    id: "187",
    name: "Saint Pierre and Miquelon",
    phoneCode: "508",
    emojiU: "U+1F1F5 U+1F1F2",
    native: "Saint-Pierre-et-Miquelon",
  },
  {
    id: "188",
    name: "Saint Vincent And The Grenadines",
    phoneCode: "+1-784",
    emojiU: "U+1F1FB U+1F1E8",
    native: "Saint Vincent and the Grenadines",
  },
  {
    id: "189",
    name: "Saint-Barthelemy",
    phoneCode: "590",
    emojiU: "U+1F1E7 U+1F1F1",
    native: "Saint-Barthélemy",
  },
  {
    id: "190",
    name: "Saint-Martin (French part)",
    phoneCode: "590",
    emojiU: "U+1F1F2 U+1F1EB",
    native: "Saint-Martin",
  },
  {
    id: "191",
    name: "Samoa",
    phoneCode: "685",
    emojiU: "U+1F1FC U+1F1F8",
    native: "Samoa",
  },
  {
    id: "192",
    name: "San Marino",
    phoneCode: "378",
    emojiU: "U+1F1F8 U+1F1F2",
    native: "San Marino",
  },
  {
    id: "193",
    name: "Sao Tome and Principe",
    phoneCode: "239",
    emojiU: "U+1F1F8 U+1F1F9",
    native: "São Tomé e Príncipe",
  },
  {
    id: "194",
    name: "Saudi Arabia",
    phoneCode: "966",
    emojiU: "U+1F1F8 U+1F1E6",
    native: "المملكة العربية السعودية",
  },
  {
    id: "195",
    name: "Senegal",
    phoneCode: "221",
    emojiU: "U+1F1F8 U+1F1F3",
    native: "Sénégal",
  },
  {
    id: "196",
    name: "Serbia",
    phoneCode: "381",
    emojiU: "U+1F1F7 U+1F1F8",
    native: "Србија",
  },
  {
    id: "197",
    name: "Seychelles",
    phoneCode: "248",
    emojiU: "U+1F1F8 U+1F1E8",
    native: "Seychelles",
  },
  {
    id: "198",
    name: "Sierra Leone",
    phoneCode: "232",
    emojiU: "U+1F1F8 U+1F1F1",
    native: "Sierra Leone",
  },
  {
    id: "199",
    name: "Singapore",
    phoneCode: "65",
    emojiU: "U+1F1F8 U+1F1EC",
    native: "Singapore",
  },
  {
    id: "200",
    name: "Sint Maarten (Dutch part)",
    phoneCode: "1721",
    emojiU: "U+1F1F8 U+1F1FD",
    native: "Sint Maarten",
  },
  {
    id: "201",
    name: "Slovakia",
    phoneCode: "421",
    emojiU: "U+1F1F8 U+1F1F0",
    native: "Slovensko",
  },
  {
    id: "202",
    name: "Slovenia",
    phoneCode: "386",
    emojiU: "U+1F1F8 U+1F1EE",
    native: "Slovenija",
  },
  {
    id: "203",
    name: "Solomon Islands",
    phoneCode: "677",
    emojiU: "U+1F1F8 U+1F1E7",
    native: "Solomon Islands",
  },
  {
    id: "204",
    name: "Somalia",
    phoneCode: "252",
    emojiU: "U+1F1F8 U+1F1F4",
    native: "Soomaaliya",
  },
  {
    id: "205",
    name: "South Africa",
    phoneCode: "27",
    emojiU: "U+1F1FF U+1F1E6",
    native: "South Africa",
  },
  {
    id: "206",
    name: "South Georgia",
    phoneCode: "500",
    emojiU: "U+1F1EC U+1F1F8",
    native: "South Georgia",
  },
  {
    id: "207",
    name: "South Korea",
    phoneCode: "82",
    emojiU: "U+1F1F0 U+1F1F7",
    native: "대한민국",
  },
  {
    id: "208",
    name: "South Sudan",
    phoneCode: "211",
    emojiU: "U+1F1F8 U+1F1F8",
    native: "South Sudan",
  },
  {
    id: "209",
    name: "Spain",
    phoneCode: "34",
    emojiU: "U+1F1EA U+1F1F8",
    native: "España",
  },
  {
    id: "210",
    name: "Sri Lanka",
    phoneCode: "94",
    emojiU: "U+1F1F1 U+1F1F0",
    native: "śrī laṃkāva",
  },
  {
    id: "211",
    name: "Sudan",
    phoneCode: "249",
    emojiU: "U+1F1F8 U+1F1E9",
    native: "السودان",
  },
  {
    id: "212",
    name: "Suriname",
    phoneCode: "597",
    emojiU: "U+1F1F8 U+1F1F7",
    native: "Suriname",
  },
  {
    id: "213",
    name: "Svalbard And Jan Mayen Islands",
    phoneCode: "47",
    emojiU: "U+1F1F8 U+1F1EF",
    native: "Svalbard og Jan Mayen",
  },
  {
    id: "214",
    name: "Swaziland",
    phoneCode: "268",
    emojiU: "U+1F1F8 U+1F1FF",
    native: "Swaziland",
  },
  {
    id: "215",
    name: "Sweden",
    phoneCode: "46",
    emojiU: "U+1F1F8 U+1F1EA",
    native: "Sverige",
  },
  {
    id: "216",
    name: "Switzerland",
    phoneCode: "41",
    emojiU: "U+1F1E8 U+1F1ED",
    native: "Schweiz",
  },
  {
    id: "217",
    name: "Syria",
    phoneCode: "963",
    emojiU: "U+1F1F8 U+1F1FE",
    native: "سوريا",
  },
  {
    id: "218",
    name: "Taiwan",
    phoneCode: "886",
    emojiU: "U+1F1F9 U+1F1FC",
    native: "臺灣",
  },
  {
    id: "219",
    name: "Tajikistan",
    phoneCode: "992",
    emojiU: "U+1F1F9 U+1F1EF",
    native: "Тоҷикистон",
  },
  {
    id: "220",
    name: "Tanzania",
    phoneCode: "255",
    emojiU: "U+1F1F9 U+1F1FF",
    native: "Tanzania",
  },
  {
    id: "221",
    name: "Thailand",
    phoneCode: "66",
    emojiU: "U+1F1F9 U+1F1ED",
    native: "ประเทศไทย",
  },
  {
    id: "222",
    name: "The Bahamas",
    phoneCode: "+1-242",
    emojiU: "U+1F1E7 U+1F1F8",
    native: "Bahamas",
  },
  {
    id: "223",
    name: "Togo",
    phoneCode: "228",
    emojiU: "U+1F1F9 U+1F1EC",
    native: "Togo",
  },
  {
    id: "224",
    name: "Tokelau",
    phoneCode: "690",
    emojiU: "U+1F1F9 U+1F1F0",
    native: "Tokelau",
  },
  {
    id: "225",
    name: "Tonga",
    phoneCode: "676",
    emojiU: "U+1F1F9 U+1F1F4",
    native: "Tonga",
  },
  {
    id: "226",
    name: "Trinidad And Tobago",
    phoneCode: "+1-868",
    emojiU: "U+1F1F9 U+1F1F9",
    native: "Trinidad and Tobago",
  },
  {
    id: "227",
    name: "Tunisia",
    phoneCode: "216",
    emojiU: "U+1F1F9 U+1F1F3",
    native: "تونس",
  },
  {
    id: "228",
    name: "Turkey",
    phoneCode: "90",
    emojiU: "U+1F1F9 U+1F1F7",
    native: "Türkiye",
  },
  {
    id: "229",
    name: "Turkmenistan",
    phoneCode: "993",
    emojiU: "U+1F1F9 U+1F1F2",
    native: "Türkmenistan",
  },
  {
    id: "230",
    name: "Turks And Caicos Islands",
    phoneCode: "+1-649",
    emojiU: "U+1F1F9 U+1F1E8",
    native: "Turks and Caicos Islands",
  },
  {
    id: "231",
    name: "Tuvalu",
    phoneCode: "688",
    emojiU: "U+1F1F9 U+1F1FB",
    native: "Tuvalu",
  },
  {
    id: "232",
    name: "Uganda",
    phoneCode: "256",
    emojiU: "U+1F1FA U+1F1EC",
    native: "Uganda",
  },
  {
    id: "233",
    name: "Ukraine",
    phoneCode: "380",
    emojiU: "U+1F1FA U+1F1E6",
    native: "Україна",
  },
  {
    id: "234",
    name: "United Arab Emirates",
    phoneCode: "971",
    emojiU: "U+1F1E6 U+1F1EA",
    native: "دولة الإمارات العربية المتحدة",
  },
  {
    id: "235",
    name: "United Kingdom",
    phoneCode: "44",
    emojiU: "U+1F1EC U+1F1E7",
    native: "United Kingdom",
  },
  {
    id: "236",
    name: "United States",
    phoneCode: "1",
    emojiU: "U+1F1FA U+1F1F8",
    native: "United States",
  },
  {
    id: "237",
    name: "United States Minor Outlying Islands",
    phoneCode: "1",
    emojiU: "U+1F1FA U+1F1F2",
    native: "United States Minor Outlying Islands",
  },
  {
    id: "238",
    name: "Uruguay",
    phoneCode: "598",
    emojiU: "U+1F1FA U+1F1FE",
    native: "Uruguay",
  },
  {
    id: "239",
    name: "Uzbekistan",
    phoneCode: "998",
    emojiU: "U+1F1FA U+1F1FF",
    native: "O‘zbekiston",
  },
  {
    id: "240",
    name: "Vanuatu",
    phoneCode: "678",
    emojiU: "U+1F1FB U+1F1FA",
    native: "Vanuatu",
  },
  {
    id: "241",
    name: "Vatican City State (Holy See)",
    phoneCode: "379",
    emojiU: "U+1F1FB U+1F1E6",
    native: "Vaticano",
  },
  {
    id: "242",
    name: "Venezuela",
    phoneCode: "58",
    emojiU: "U+1F1FB U+1F1EA",
    native: "Venezuela",
  },
  {
    id: "243",
    name: "Vietnam",
    phoneCode: "84",
    emojiU: "U+1F1FB U+1F1F3",
    native: "Việt Nam",
  },
  {
    id: "244",
    name: "Virgin Islands (British)",
    phoneCode: "+1-284",
    emojiU: "U+1F1FB U+1F1EC",
    native: "British Virgin Islands",
  },
  {
    id: "245",
    name: "Virgin Islands (US)",
    phoneCode: "+1-340",
    emojiU: "U+1F1FB U+1F1EE",
    native: "United States Virgin Islands",
  },
  {
    id: "246",
    name: "Wallis And Futuna Islands",
    phoneCode: "681",
    emojiU: "U+1F1FC U+1F1EB",
    native: "Wallis et Futuna",
  },
  {
    id: "247",
    name: "Western Sahara",
    phoneCode: "212",
    emojiU: "U+1F1EA U+1F1ED",
    native: "الصحراء الغربية",
  },
  {
    id: "248",
    name: "Yemen",
    phoneCode: "967",
    emojiU: "U+1F1FE U+1F1EA",
    native: "اليَمَن",
  },
  {
    id: "249",
    name: "Zambia",
    phoneCode: "260",
    emojiU: "U+1F1FF U+1F1F2",
    native: "Zambia",
  },
  {
    id: "250",
    name: "Zimbabwe",
    phoneCode: "263",
    emojiU: "U+1F1FF U+1F1FC",
    native: "Zimbabwe",
  },
];
