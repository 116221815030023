import {
  Link,
  TabbedShowLayout,
  TextField,
  TextInput,
  useRecordContext,
  WithRecord,
} from "react-admin";
import { MyList } from "../ra-lb-tools/components/myList";
import { LocationField, LocationInput } from "./locations/components";
import { DeveloperMode } from "@mui/icons-material";
import { getValue } from "../ra-lb-tools/utils/tools";
import { Row } from "../ra-lb-tools/components/row";
import { TitledShow } from "../ra-lb-tools/components/titledRecord";
import { LabeledColumn } from "../ra-lb-tools/components/labeledColumn";

const firmware_uri =
  "https://gitlab.com/luxer-one/harborlockers/hal-firmware/-/releases/";

const getReleaseUri = (release) => firmware_uri + release;

export const TagNameField = (props) => {
  const record = useRecordContext();
  if (!record) return null;

  const tagField = props.source || "tagName";
  const release = getValue(record, tagField);

  return (
    <Link to={getReleaseUri(release)} target="_blank">
      <TextField {...props} />
    </Link>
  );
};

const filters = [
  <TextInput source="tagName" key="tagName" alwaysOn />,
  <TextInput source="version" key="version" alwaysOn />,
  <LocationInput source="locationId" key="locationId" />,
];

export const LocationFirmwareIcon = DeveloperMode;

export const LocationFirmwareList = (props) => {
  return (
    <MyList resource="location-firmware" filters={filters} {...props}>
      <TextField source="id" />
      <LocationField source="locationId" />
      <TagNameField source="tagName" />
      <TextField source="version" />
    </MyList>
  );
};

export const LocationFirmwareShow = () => {
  return (
    <TitledShow labelField={"tagName"}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Row className={"RecordSummary"}>
            <LabeledColumn>
              <TextField source="id" />
              <LocationField source="locationId" />
              <TagNameField source="tagName" />
              <TextField source="version" />
            </LabeledColumn>
          </Row>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Locations" path="locations">
          <WithRecord
            render={({ tagName }) => (
              <LocationFirmwareList
                filter={{ tagName }}
                noHeaders
                dataGridProps={{ omit: ["Show"] }}
              />
            )}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
};
