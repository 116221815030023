import { Box, Chip } from "@mui/material";
import { ENV_TYPES, getCurrentEnv, objectMap } from "../utils/tools";
import { Link } from "react-admin";

export const EnvSwitcher = () => {
  const current = getCurrentEnv();
  const sx = {
    marginRight: "5px",
    color: "#fff",
    backgroundColor: "rgba(255,255,255,.05)",
  };

  return (
    <Box className="EnvSwitcher">
      {Object.values(
        objectMap({
          obj: ENV_TYPES,
          valueCallback: (setup, name) => {
            if (name == "LOCAL") return null;

            const isCurrent = name == current;
            const highlight = isCurrent
              ? { backgroundColor: "rgba(255,255,255,.2)" }
              : {};

            return (
              <Link key={name} to={setup.href} title={name}>
                <Chip
                  label={name[0]}
                  color="primary"
                  size="small"
                  clickable
                  style={{ ...sx, ...highlight }}
                />
              </Link>
            );
          },
        })
      )}
    </Box>
  );
};
